// Dependencies
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import { Typography } from '../Typography';
import { Box, Button } from '@mui/material';

// Actions
import { RootState } from '../../../redux/reducers';

// Constants
import { TOURS_CONFIG } from '../../../constants';

// Styles
import * as S from './styles';

interface ITour {
  tourId: string;
  position?: string | Array<number>;
}

interface IToursProps {
  config?: ITour[];
  onClose: () => void;
}

export const Tours: FC<IToursProps> = ({ config = TOURS_CONFIG, onClose }) => {
  const { t } = useTranslation();

  const breakpoint = useSelector(
    ({ uiReducer: { breakpoint } }: RootState) => breakpoint
  );

  // Create a config
  const steps = config.map((step) => {
    const { tourId } = step;

    const selectorName = breakpoint === 'sm' ? 'data-tour-sm' : 'data-tour';

    return {
      selector: `[${selectorName}="${tourId}"]`,
      position: 'bottom',
      content: ({ step }) => (
        <Box>
          <Typography marginTop={2} variant="h3">
            {step} / {config.length}
          </Typography>
          <Typography marginTop={8} variant="body1">
            {t(`welcome_tour.${tourId}`)}
          </Typography>
        </Box>
      ),
    };
  });

  const lastStepClick = () => {
    localStorage.setItem('welcomeTourClosed', 'true');
    onClose();
  };

  const handleClickTourClose = () => {
    localStorage.setItem('welcomeTourClosed', 'true');
    onClose();
  };

  // Return all tours
  return (
    <S.Tours
      onRequestClose={handleClickTourClose}
      steps={steps}
      isOpen={true}
      maskClassName={'tourMaskStyle'}
      showNavigation={false}
      prevButton={
        <Button size="small" color="secondary">
          {t('tours.prev')}
        </Button>
      }
      showNumber={false}
      nextButton={
        <Button size="small" color="secondary">
          {t('tours.next')}
        </Button>
      }
      lastStepNextButton={
        <Button onClick={lastStepClick} size="small" color="secondary">
          {t('tours.finish')}
        </Button>
      }
      disableFocusLock={true}
      startAt={0}
    />
  );
};
