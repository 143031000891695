// Dependencies
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  FormControlLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

// Constants
import { Role, ROUTES } from '../../constants';

// Interfaces
import { ILeadData, ISortBy, Order } from '../../interfaces';

// Components
import {
  Button,
  Checkbox,
  Chip,
  CreateNewClientModal,
  DataTable,
  ConfirmModal,
  Dialog,
  Icon,
  IconButton,
  Input,
  Select,
  Typography,
} from '../../components';

// Store
import { RootState } from '../../redux/reducers';
import { getCreateClientForms, getLeads } from '../../redux/actions';

// Interfaces
import { IColumn } from '../../components';
import {
  getGengerLabel,
  getLeadAgentName,
  getLeadCreateDate,
  getLeadName,
  getLeadPropertyTypeLabel,
  getLeadStatusLabel,
} from '../../utils/leads.utils';
import {
  assignBroker,
  deleteLead,
  deleteMultipleLeads,
  exportLeads,
} from '../../services/lead.service';

// Styles
import * as S from './styles';
import { LEAD_STATUSES } from '../../constants/lead.constant';
import { AssignBrokerModal } from '../../components';
import { isUserAllowed } from '../../utils';

// Export leads page
export const LeadsPage: FC = () => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // States
  const [filterBy, setFilterBy] = useState<string[]>(['']);
  const [data, setData] = useState<ILeadData[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<ISortBy>({
    field: 'date',
    order: Order.Desc,
  });
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [confirmDeleteId, setConfirmDeleteId] = useState<string | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [visibleMultiDelConfirmModal, setVisibleMultiDelConfirmModal] =
    useState<boolean>(false);
  const [openCreateClientModal, setOpenCreateClientModal] =
    useState<boolean>(false);
  const [createClientFormsData, setCreateClientFormsData] = useState({});
  const [visibleAssignBrokerModal, setVisibleAssignBrokerModal] = useState<
    string | null
  >(null);
  const [selectedLead, setSelectedLead] = useState<ILeadData | null>();
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [visibleFilterDialog, setVisibleFilterDialog] =
    useState<boolean>(false);

  // Theme
  const theme = useTheme();

  // Check platform
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Get navigate from hook
  const navigate = useNavigate();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get account from the store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  const isDrawerOpened = useSelector(
    ({ uiReducer: { isDrawerOpened } }: RootState) => isDrawerOpened
  );

  // Get users from store
  const { leads, loading, pagination } = useSelector(
    ({ leadReducer }: RootState) => leadReducer
  );

  // Fetch table data
  const fetchLeads = () => {
    dispatch(
      getLeads({
        page,
        perPage: rowsPerPage,
        sort: sortBy.field,
        'sort-order': sortBy.order,
        search: debouncedSearch,
        status: filterBy,
      })
    );
  };

  // Filter change handler
  const handleFilterChange = (e) => {
    const value = e.target.value;
    if (filterBy.indexOf(value) === -1) {
      setFilterBy([...filterBy, e.target.value]);
    } else {
      setFilterBy(filterBy.filter((filter) => filter !== value));
    }
  };

  // Go to detail
  const handleGoToDetail = (id) => {
    navigate(ROUTES.LEADS.DETAIL.replace(':id', id), {
      state: {
        rowsPerPage,
        page,
      },
    });
  };

  // Delete handler
  const handleDelete = (id) => {
    setConfirmDeleteId(id);
  };

  // Close delete confirm modal handler
  const handleCloseDeleteConfirmModal = () => {
    setConfirmDeleteId(null);
  };

  // Remove user
  const handleDeleteLead = () => {
    setDeleteLoading(true);
    deleteLead(confirmDeleteId)
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          dispatch(
            getLeads({
              page,
              perPage: rowsPerPage,
              sort: sortBy.field,
              'sort-order': sortBy.order,
              search: debouncedSearch,
              status: filterBy,
            })
          );
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' });
        }
        setDeleteLoading(false);
        setConfirmDeleteId(null);
      })
      .catch((e: any) => {
        enqueueSnackbar(e?.response?.data?.message || 'Failed to delete', {
          variant: 'error',
        });
        setDeleteLoading(false);
        setConfirmDeleteId(null);
      });
  };

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSortBy(value);
  };

  // Page change handler
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
  };

  // Open multiple delete confirm modal
  const handleOpenMultiDeleteConfirmModal = () => {
    setVisibleMultiDelConfirmModal(true);
  };

  // Close multiple delete confirm modal
  const handleCloseMultiDeleteConfirmModal = () => {
    setVisibleMultiDelConfirmModal(false);
  };

  const handleOpenCreateClientModal = (id) => {
    const lead = data.find((item) => item.id === id);
    if (lead) {
      const clientFormsData = {
        personalData: {
          first_name: lead.first_name || '',
          last_name: lead.last_name || '',
          gender: lead.gender || '',
          address: {
            zip: lead.plz || '',
          },
        },
        contactData: {
          phone: lead.phone || '',
          email: lead.email || '',
        },
        familyData: {
          marital_status: lead.has_partner ? 'partnered' : 'unmarried',
          partner_gender: lead.has_partner ? lead.partner_gender : '',
        },
        offerData: {
          option1: !!lead.onetime_payment,
          amount_one_time_payment: lead.onetime_payment
            ? lead.desired_rent
            : '',
        },
        propertyData: {
          property_type: lead.property_type || '',
          living_space: lead.living_space || '',
          year_of_construction: lead.year_of_construction || '',
        },
        propertyProperties: {
          garage_amount: lead.garage_count || '',
        },
        propertyAssesment: {
          customer_property_assessment: lead.comment || '',
          property_value: lead.property_value || '',
        },
      };
      setCreateClientFormsData(clientFormsData);
      setOpenCreateClientModal(true);
    }
  };

  const handleCloseCreateClientModal = () => {
    setOpenCreateClientModal(false);
  };

  // Remove multiple object
  const handleDeleteMultipleLeads = () => {
    setDeleteLoading(true);
    const ids = leads
      .filter((lead, index) => selected.includes(index))
      ?.map((item) => item.id);
    deleteMultipleLeads(ids)
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          dispatch(
            getLeads({
              page,
              perPage: rowsPerPage,
              sort: sortBy.field,
              'sort-order': sortBy.order,
              search: debouncedSearch,
              status: filterBy,
            })
          );
        } else {
          enqueueSnackbar(res?.message || 'Failed to delete', {
            variant: 'error',
          });
        }
        setDeleteLoading(false);
        setSelected([]);
        setVisibleMultiDelConfirmModal(false);
      })
      .catch((e: any) => {
        enqueueSnackbar(e?.response?.data?.message || 'Failed to delete', {
          variant: 'error',
        });
        setSelected([]);
        setDeleteLoading(false);
        setVisibleMultiDelConfirmModal(false);
      });
  };

  // Open assign broker modal
  const handleOpenAssignBrokerModal = (id) => {
    setVisibleAssignBrokerModal(id);
    setSelectedLead(data.find((lead) => lead.id === id));
  };

  // Assign broker
  const handleAssignBroker = async (brokerId: string) => {
    if (visibleAssignBrokerModal) {
      await assignBroker(visibleAssignBrokerModal, brokerId);
      setSelectedLead(null);
      fetchLeads();
    }
  };

  const handleExport = () => {
    const query = {
      status: filterBy,
    };
    exportLeads(query);
  };

  // Constants
  const columns: IColumn[] = [
    {
      field: 'value',
      label: t('leads_detail.value'),
      render: (row: ILeadData) => (
        <Chip label={row.is_sprengnetter ? 'Sprengnetter' : 'Kundenwert '} />
      ),
    },
    {
      field: 'name',
      label: t('leads.name'),
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {getLeadName(row)}
        </Typography>
      ),
    },

    {
      field: 'phone',
      label: t('leads.phone'),
    },
    {
      field: 'gender',
      label: t('leads.gender'),
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {t(getGengerLabel(row.gender))}
        </Typography>
      ),
    },
    {
      field: 'propertyType',
      label: t('leads.property_type'),
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500} sx={{ fontWeight: '400' }}>
          {t(getLeadPropertyTypeLabel(row))}
        </Typography>
      ),
    },
    {
      field: 'status',
      label: t('leads.status'),
      render: (row: ILeadData) => <Chip label={t(getLeadStatusLabel(row))} />,
    },
    {
      field: 'makler',
      label: t('leads.makler'),
      render: (row: ILeadData) =>
        row.assignedAgent && (
          <Stack direction="row" alignItems="center">
            <ListItemAvatar sx={{ minWidth: 60 }}>
              <Avatar
                variant="circular"
                src={row.assignedAgent?.image?.path || ''}
              />
            </ListItemAvatar>
            {getLeadAgentName(row)}
          </Stack>
        ),
      sortable: false,
    },
    {
      field: 'date',
      label: t('leads.date'),
      render: (row: ILeadData) => getLeadCreateDate(row),
    },
  ];

  // Row actions
  const rowActions = [
    {
      icon: 'document',
      label: t('leads.details'),
      onClick: handleGoToDetail,
    },
    {
      icon: 'user',
      label: t('leads.assign_broker'),
      role: Role.ROLE_ADMIN,
      onClick: handleOpenAssignBrokerModal,
    },
    {
      icon: 'add',
      label: t('leads.create_customer'),
      onClick: handleOpenCreateClientModal,
    },
    {
      icon: 'trash',
      label: t('leads.delete'),
      role: Role.ROLE_ADMIN,
      onClick: handleDelete,
    },
  ];

  // Change search handler
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleOpenSearchInput = () => {
    setVisibleSearch(true);
  };

  const handleCloseSearchInput = () => {
    setVisibleSearch(false);
  };

  const handleCloseFilterDialog = () => {
    setVisibleFilterDialog(false);
  };

  // Delayed search handler
  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  // Debounce search
  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
  }, [search, delayedChangeSearch]);

  // On page, sortBy and rowsPerPage changed
  useEffect(() => {
    dispatch(
      getLeads({
        page,
        perPage: rowsPerPage,
        sort: sortBy.field,
        'sort-order': sortBy.order,
        search: debouncedSearch,
        status: filterBy,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, rowsPerPage, debouncedSearch, filterBy]);

  // On users changed
  useEffect(() => {
    if (leads) {
      setData(leads);
    }
  }, [leads]);

  // On pagination changed
  useEffect(() => {
    if (pagination?.total || pagination?.total === 0) {
      setTotalPage(Math.ceil(pagination?.total / rowsPerPage));
    }
  }, [pagination, rowsPerPage]);

  useEffect(() => {
    dispatch(getCreateClientForms());
  }, [dispatch]);

  // Return leads page
  return (
    <S.TableCard>
      <CardHeader
        title={
          <>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Input
                disabled={loading}
                startAdornment={<Icon name="search" />}
                placeholder={t('leads.search')}
                value={search}
                onChange={handleChangeSearch}
              />
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              {visibleSearch ? (
                <Stack spacing={10} direction="row">
                  <IconButton onClick={handleCloseSearchInput}>
                    <Icon name="x-lg" />
                  </IconButton>
                  <Input
                    disabled={loading}
                    startAdornment={<Icon name="search" />}
                    placeholder={t('leads.search')}
                    value={search}
                    sx={{ flex: 1 }}
                    onChange={handleChangeSearch}
                  />
                </Stack>
              ) : (
                <Stack>
                  <IconButton
                    disabled={loading}
                    onClick={handleOpenSearchInput}
                  >
                    <Icon name="search" />
                  </IconButton>
                </Stack>
              )}
            </Box>
          </>
        }
        action={
          !visibleSearch && (
            <Stack direction="row" spacing={{ xs: 10, md: 12 }}>
              {selected &&
                selected.length > 0 &&
                isUserAllowed(account, Role.ROLE_ADMIN) && (
                  <Button
                    disabled={loading}
                    startIcon={!isMobile && <Icon name="trash" />}
                    onClick={handleOpenMultiDeleteConfirmModal}
                    sx={(theme) => ({ color: theme.palette.error.main })}
                  >
                    {t('projects.delete')}
                  </Button>
                )}
              {isUserAllowed(account, Role.ROLE_AGENTS) && (
                <Button
                  disabled={loading}
                  startIcon={<Icon name="box-arrow-up" />}
                  onClick={handleExport}
                >
                  {t('leads.export_leads')}
                </Button>
              )}
              <Select
                sx={{ display: { xs: 'none', sm: 'flex' } }}
                disabled={loading}
                value={filterBy}
                onChange={handleFilterChange}
                renderValue={() => t('leads.filter_by_status')}
              >
                {LEAD_STATUSES.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <Checkbox checked={filterBy.indexOf(item.value) > -1} />
                    <ListItemText
                      primary={t(item.label)}
                      sx={{ fontSize: 8 }}
                    />
                  </MenuItem>
                ))}
              </Select>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <IconButton>
                  <Icon name="funnel-fill" />
                </IconButton>
              </Box>
            </Stack>
          )
        }
      />
      <CardContent
        sx={(theme) => ({
          p: '0 !important',
          // @ts-ignore
          borderTop: `1px solid ${theme.palette.lightCyan}`,
        })}
      >
        <DataTable
          paginated
          rowSelectable
          columnEditable
          page={page}
          data={data}
          columns={columns}
          totalPage={totalPage}
          rowActions={rowActions}
          rowsPerPage={rowsPerPage}
          selected={selected}
          stickyHeader
          contentSize={
            isMobile
              ? { width: 'calc(100vw - 32px)' }
              : {
                  width: isDrawerOpened
                    ? 'calc(100vw - 328px)'
                    : 'calc(100vw - 160px)',
                  height: 'calc(100vh - 312px)',
                }
          }
          type="leads"
          onChangeSelected={setSelected}
          onSort={handleSort}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onRowClick={handleGoToDetail}
        />
      </CardContent>
      <ConfirmModal
        open={!!confirmDeleteId}
        onClose={handleCloseDeleteConfirmModal}
        onOk={handleDeleteLead}
        loading={deleteLoading}
      />
      <ConfirmModal
        open={visibleMultiDelConfirmModal}
        onClose={handleCloseMultiDeleteConfirmModal}
        onOk={handleDeleteMultipleLeads}
        loading={deleteLoading}
      />
      {openCreateClientModal && (
        <CreateNewClientModal
          open={openCreateClientModal}
          onClose={handleCloseCreateClientModal}
          createClientFormsData={createClientFormsData}
        />
      )}
      <AssignBrokerModal
        isLead
        target={
          (selectedLead?.first_name || '') + ' ' + selectedLead?.last_name || ''
        }
        brokerId={selectedLead?.assignedAgent && selectedLead?.assignedAgent.id}
        open={!!visibleAssignBrokerModal}
        onClose={() => setVisibleAssignBrokerModal(null)}
        onAssign={handleAssignBroker}
      />
      <Dialog
        title={t('leads.filter')}
        open={visibleFilterDialog}
        onClose={handleCloseFilterDialog}
      >
        <Stack>
          {LEAD_STATUSES.map((item, index) => (
            <MenuItem>
              <FormControlLabel
                key={index}
                label={t(item.label)}
                value={item.value}
                control={
                  <Checkbox checked={filterBy.indexOf(item.value) > -1} />
                }
                onChange={handleFilterChange}
                sx={{ width: '100%' }}
              />
            </MenuItem>
          ))}
        </Stack>
      </Dialog>
    </S.TableCard>
  );
};
