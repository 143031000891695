// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

// Components
import { Typography } from '../../../../../../components';
import VariantCard from './Card';

// Interfaces
import { RootState } from '../../../../../../redux/reducers';
import {
  ICalcType,
  IOfferCalculation,
  IPayoutVariant,
} from '../../../../../../interfaces';

// Constants
import {
  CALCULATION_OPTIONS,
  isStatusAllowed,
  LABEL_MAPPING,
  Role,
  Status,
} from '../../../../../../constants';

// Utils
import { getCurrency, isUserAllowed } from '../../../../../../utils';

// styles
import * as S from './styles';

// Create binding offer panel
const BindingOfferPanel: FC = () => {
  // States
  const [visibleCardId, setVisibleCardId] = useState<number>();
  const [visibleBindingCardId, setVisibleBindingCardId] = useState<number>();

  // Get translation from hook
  const { t } = useTranslation();

  // Get project detail from store
  const projectDetail = useSelector(
    ({ projectReducer: { projectDetail } }: RootState) => projectDetail
  );

  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Get offers from project detail
  const offers = useMemo(() => {
    const offers: any[] = [];
    let amount = 0;
    let calculationAmount = 0;
    let bindingCalculationAmount = 0;
    let calculations: Array<IOfferCalculation> = [];
    projectDetail?.payout_variants.forEach((payoutVariant: IPayoutVariant) => {
      payoutVariant.calculations.forEach((calculation) => {
        calculation.payoutVariant = payoutVariant;
        calculations.push(calculation);
      });
    });
    calculations.forEach((calculation: IOfferCalculation) => {
      if (
        !calculation.hasOwnProperty('offerCalculated') ||
        (!calculation?.offerCalculated?.preferred &&
          !calculation?.offerCalculated?.binding)
      ) {
        return;
      }

      let items: any = [];
      let calculated = calculation?.offerCalculated;
      let firstBorderFound: boolean = false;
      let border: number = 0;
      if (calculated) {
        items.push({
          label: 'Wert der Immobilie',
          value: getCurrency(calculation.propertyValue) + ' €',
        });
        for (let propName in LABEL_MAPPING) {
          let label = '';
          if (
            calculated.hasOwnProperty(propName) ||
            LABEL_MAPPING[propName]?.alwaysShow
          ) {
            const mapping: {
              label: string | Function;
              border: number;
              isSum?: boolean;
              isCurrency: boolean;
              isPercent: boolean;
              role: Role;
              hide?: boolean | Function;
              alwaysShow?: boolean;
            } = LABEL_MAPPING[propName];
            if (typeof mapping.label === 'function') {
              label = mapping.label(calculation);
            } else {
              label = mapping.label;
            }
            if (typeof mapping.hide === 'function' && mapping.hide) {
              if (mapping.hide(calculation)) {
                continue;
              }
            } else if (typeof mapping.hide === 'boolean' && mapping.hide) {
              continue;
            }

            // @ts-ignore
            let value: string = calculated[propName]?.toString();
            if (mapping.isCurrency) {
              if (value) {
                if (value.indexOf('.') !== -1) {
                  value = getCurrency(Math.round(parseFloat(value)));
                } else {
                  value = getCurrency(value);
                }
              } else {
                value = '0';
              }
            }

            if (
              (value !== '0' ||
                (calculation.calcType !== ICalcType.oneTimePayment &&
                  propName.toLowerCase().includes('annuity')) ||
                (calculation.calcType === ICalcType.oneTimePayment &&
                  propName.toLowerCase().includes('onetimepayment'))) &&
              isUserAllowed(
                account,
                mapping.role ? mapping.role : Role.ROLE_USER
              )
            ) {
              if (mapping.isCurrency) {
                value += ' €';
              }
              if (mapping.isPercent) {
                value += ' %';
              }
              border = mapping?.border;
              if (firstBorderFound) {
                if (border === 2) {
                  border = 1;
                }
              } else if (border === 2) {
                firstBorderFound = true;
              }

              items.push({
                label,
                value,
                border,
                isSum: mapping?.isSum,
                ifBinding: calculated.binding,
              });
            }
          }
        }

        let optionName =
          Object.keys(CALCULATION_OPTIONS).find((key) => {
            if (calculation.payoutVariant?.hasOwnProperty(key)) {
              return calculation.payoutVariant[key] === true;
            }
            return false;
          }) || 'lifelong_payment';

        let optionType: {
          title: string;
          color: string;
          options: Array<string>;
          optionForTitle?: string;
        } = CALCULATION_OPTIONS[optionName];
        let title = optionType.title;
        let color = optionType.color || '#bbdcd0';
        if (optionType.optionForTitle) {
          title += optionType.optionForTitle.replace(
            '%rentTime%',
            calculation.rentTime
          );
        }

        const isBinding = calculated.binding;

        if (isBinding) {
          bindingCalculationAmount++;
          amount = bindingCalculationAmount;
        } else {
          calculationAmount++;
          amount = calculationAmount;
        }

        const isDelete = isUserAllowed(account, Role.ROLE_ADMIN) && isBinding;

        const visibleInformation =
          isUserAllowed(account, Role.ROLE_ADMIN) && isBinding;

        offers.push({
          title,
          color,
          items,
          amount,
          calculated,
          calculation,
          isDelete,
          isBinding,
          visibleInformation,
        });
      }
    });

    if (!offers.filter((offer) => !offer.isBinding).length) {
      offers.push({
        isBinding: false,
        isEmpty: true,
      });
    }

    return offers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail]);

  // Toggle visible offer card handler
  const handleToggleVisible = (id: number) => {
    if (visibleCardId === id) {
      setVisibleCardId(undefined);
    } else {
      setVisibleCardId(id);
    }
  };

  // Toggle visible binding card handler
  const handleToggleBindingVisible = (id: number) => {
    if (visibleBindingCardId === id) {
      setVisibleBindingCardId(undefined);
    } else {
      setVisibleBindingCardId(id);
    }
  };

  // Constants
  let bindingCardIndex = 0;
  let indicativeCardIndex = 0;

  // On payout variant changed
  useEffect(() => {
    setVisibleCardId(undefined);
    setVisibleBindingCardId(undefined);
  }, [offers]);

  // Return binding offer panel
  return (
    <>
      <Typography mb={20} variant="h3">
        {t('project_detail.binding_offer')}
      </Typography>

      <S.Cards>
        <Grid container columns={12} spacing={{ xs: 0, sm: 20 }}>
          {!offers.filter(({ isBinding }) => isBinding)?.length && (
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={(theme) => ({ color: theme.palette['lightIndigo'] })}
              >
                {t('project_detail.no_binding_offers')}
              </Typography>
            </Grid>
          )}
          {offers
            .filter(({ isBinding }) => isBinding)
            .map((offer, index, _) => {
              bindingCardIndex++;
              return (
                <Grid key={index} item xs={12} lg={6} xl={4}>
                  <VariantCard
                    {...offer}
                    index={bindingCardIndex}
                    calculateAllowed={
                      !offer.calculation.offerCalculated?.binding &&
                      isStatusAllowed(
                        projectDetail?.status,
                        null,
                        Status.approvalOfBindingOfferByCustomer
                      ) &&
                      isUserAllowed(account, Role.ROLE_ADMIN)
                    }
                    offer={offer}
                    visibleOffer={visibleBindingCardId === bindingCardIndex}
                    onToggleVisible={handleToggleBindingVisible}
                  />
                </Grid>
              );
            })}
        </Grid>
      </S.Cards>

      <Typography mt={40} mb={20} variant="h3">
        {t('project_detail.indicative_offer')}
      </Typography>

      <S.Cards>
        <Grid container columns={12} spacing={{ xs: 0, sm: 20 }}>
          {offers
            .filter(({ isBinding }) => !isBinding)
            .map((offer, index) => {
              indicativeCardIndex++;
              return offer.isEmpty ? (
                <Grid key={index} item xs={12}>
                  <Typography
                    variant="h5"
                    sx={(theme) => ({ color: theme.palette['lightIndigo'] })}
                  >
                    {t('project_detail.no_indicative_offers')}
                  </Typography>
                </Grid>
              ) : (
                <Grid key={index} item xs={12} lg={6} xl={4}>
                  <VariantCard
                    {...offer}
                    index={indicativeCardIndex}
                    offer={offer}
                    calculateAllowed={
                      !offer.calculation.offerCalculated?.binding &&
                      isStatusAllowed(
                        projectDetail?.status,
                        null,
                        Status.approvalOfBindingOfferByCustomer
                      ) &&
                      isUserAllowed(account, Role.ROLE_ADMIN)
                    }
                    visibleOffer={visibleCardId === indicativeCardIndex}
                    onToggleVisible={handleToggleVisible}
                  />
                </Grid>
              );
            })}
        </Grid>
      </S.Cards>
    </>
  );
};

// Export binding offer panel
export default BindingOfferPanel;
