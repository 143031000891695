export enum UI_ACTIONS {
  SET_DASHBOARD_LAYOUT = 'SET_DASHBOARD_LAYOUT',
  SET_BREAK_POINT = 'SET_BREAK_POINT',
  SET_IS_DRAWER_OPENED = 'SET_IS_DRAWER_OPENED',
}

export enum AUTH_ACTIONS {
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_START = 'LOGIN_START',
  LOGIN_ERROR = 'LOGIN_ERROR',

  LOGOUT = 'LOGOUT',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_START = 'LOGOUT_START',
  LOGOUT_ERROR = 'LOGOUT_ERROR',

  REGISTER = 'REGISTER',

  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_START = 'REGISTER_START',
  REGISTER_ERROR = 'REGISTER_ERROR',

  GET_ACCOUNT = 'GET_ACCOUNT',
  GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_START = 'GET_ACCOUNT_START',
  GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR',

  GET_REGISTER_FORMS = 'GET_REGISTER_FORMS',
  GET_REGISTER_FORMS_SUCCESS = 'GET_REGISTER_FORMS_SUCCESS',
  GET_REGISTER_FORMS_START = 'GET_REGISTER_FORMS_START',
  GET_REGISTER_FORMS_ERROR = 'GET_REGISTER_FORMS_ERROR',

  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START',
  FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR',

  GET_FEATURE_ACTIVE = 'GET_FEATURE_ACTIVE',
  GET_FEATURE_ACTIVE_ERROR = 'GET_FEATURE_ACTIVE_ERROR',
  GET_FEATURE_ACTIVE_START = 'GET_FEATURE_ACTIVE_START',
  GET_FEATURE_ACTIVE_SUCCESS = 'GET_FEATURE_ACTIVE_SUCCESS',

  SET_FEATURE_ACTIVE = 'SET_FEATURE_ACTIVE',
  SET_FEATURE_ACTIVE_ERROR = 'SET_FEATURE_ACTIVE_ERROR',
  SET_FEATURE_ACTIVE_START = 'SET_FEATURE_ACTIVE_START',
  SET_FEATURE_ACTIVE_SUCCESS = 'SET_FEATURE_ACTIVE_SUCCESS',
}

export enum LEAD_ACTIONS {
  GET_LEADS = 'GET_LEADS',
  GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS',
  GET_LEADS_START = 'GET_LEADS_START',
  GET_LEADS_ERROR = 'GET_LEADS_ERROR',
  GET_LEAD_DETAIL = 'GET_LEAD_DETAIL',
  GET_LEAD_DETAIL_SUCCESS = 'GET_LEAD_DETAIL_SUCCESS',
  GET_LEAD_DETAIL_START = 'GET_LEAD_DETAIL_START',
  GET_LEAD_DETAIL_ERROR = 'GET_LEAD_DETAIL_ERROR',
}

export enum CLIENT_ACTIONS {
  GET_CREATE_CLIENT_FORMS = 'GET_CREATE_CLIENT_FORMS',
  GET_CREATE_CLIENT_FORMS_SUCCESS = 'GET_CREATE_CLIENT_FORMS_SUCCESS',
  GET_CREATE_CLIENT_FORMS_START = 'GET_CREATE_CLIENT_FORMS_START  ',
  GET_CREATE_CLIENT_FORMS_ERROR = 'GET_CREATE_CLIENT_FORMS_ERROR  ',

  CREATE_CLIENT = 'CREATE_CLIENT',
  CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS',
  CREATE_CLIENT_START = 'CREATE_CLIENT_START',
  CREATE_CLIENT_ERROR = 'CREATE_CLIENT_ERROR',

  CREATE_DRAFT = 'CREATE_DRAFT',
  CREATE_DRAFT_SUCCESS = 'CREATE_DRAFT_SUCCESS',
  CREATE_DRAFT_ERROR = 'CREATE_DRAFT_ERROR',

  GET_DRAFT = 'GET_DRAFT',
  GET_DRAFT_SUCCESS = 'GET_DRAFT_SUCCESS',
  GET_DRAFT_START = 'GET_DRAFT_START',
  GET_DRAFT_ERROR = 'GET_DRAFT_ERROR',
  GET_DRAFTS = 'GET_DRAFTS',
  GET_DRAFTS_START = 'GET_DRAFTS_START',
  GET_DRAFTS_ERROR = 'GET_DRAFTS_ERROR',
  GET_DRAFTS_SUCCESS = 'GET_DRAFTS_SUCCESS',

  UPDATE_DRAFT = 'UPDATE_DRAFT',
  UPDATE_DRAFT_SUCCESS = 'UPDATE_DRAFT_SUCCESS',
  UPDATE_DRAFT_START = 'UPDATE_DRAFT_START',
  UPDATE_DRAFT_ERROR = 'UPDATE_DRAFT_ERROR',

  GET_CLIENTS = 'GET_CLIENTS',
  GET_CLIENTS_START = 'GET_CLIENTS_START',
  GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR',
  GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS',

  DELETE_DRAFT = 'DELETE_DRAFT',
  DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS',
  DELETE_DRAFT_START = 'DELETE_DRAFT_START',
  DELETE_DRAFT_ERROR = 'DELETE_DRAFT_ERROR',
}

export enum PROJECT_ACTIONS {
  GET_PROJECTS = 'GET_PROJECTS',
  REFETCH_PROJECTS = 'REFETCH_PROJECTS',
  REFETCH_PROJECTS_START = 'REFETCH_PROJECTS_START',
  GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS',
  GET_PROJECTS_START = 'GET_PROJECTS_START',
  GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR',

  DELETE_PROJECT = 'DELETE_PROJECT',
  DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS',
  DELETE_PROJECT_START = 'DELETE_PROJECT_START',
  DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR',

  GET_PERSONAL_DATA_FORM = 'GET_PERSONAL_DATA_FORM',
  GET_PERSONAL_DATA_FORM_SUCCESS = 'GET_PERSONAL_DATA_FORM_SUCCESS',
  GET_PERSONAL_DATA_FORM_START = 'GET_PERSONAL_DATA_FORM_START',
  GET_PERSONAL_DATA_FORM_ERROR = 'GET_PERSONAL_DATA_FORM_ERROR',

  GET_MODERNIZATIONS_FORM = 'GET_MODERNIZATIONS_FORM',
  GET_MODERNIZATIONS_FORM_SUCCESS = 'GET_MODERNIZATIONS_FORM_SUCCESS',
  GET_MODERNIZATIONS_FORM_START = 'GET_MODERNIZATIONS_FORM_START',
  GET_MODERNIZATIONS_FORM_ERROR = 'GET_MODERNIZATIONS_FORM_ERROR',

  GET_OBJECT_PROPERTIES_FORM = 'GET_OBJECT_PROPERTIES_FORM',
  GET_OBJECT_PROPERTIES_FORM_SUCCESS = 'GET_OBJECT_PROPERTIES_FORM_SUCCESS',
  GET_OBJECT_PROPERTIES_FORM_START = 'GET_OBJECT_PROPERTIES_FORM_START',
  GET_OBJECT_PROPERTIES_FORM_ERROR = 'GET_OBJECT_PROPERTIES_FORM_ERROR',

  UPDATE_CUSTOMER_DATA = 'UPDATE_CUSTOMER_DATA',
  UPDATE_CUSTOMER_DATA_SUCCESS = 'UPDATE_CUSTOMER_DATA_SUCCESS',
  UPDATE_CUSTOMER_DATA_START = 'UPDATE_CUSTOMER_DATA_START',
  UPDATE_CUSTOMER_DATA_ERROR = 'UPDATE_CUSTOMER_DATA_ERROR',

  UPDATE_PROPERTY_DATA = 'UPDATE_PROPERTY_DATA',
  UPDATE_PROPERTY_DATA_SUCCESS = 'UPDATE_PROPERTY_DATA_SUCCESS',
  UPDATE_PROPERTY_DATA_START = 'UPDATE_PROPERTY_DATA_START',
  UPDATE_PROPERTY_DATA_ERROR = 'UPDATE_PROPERTY_DATA_ERROR',

  UPDATE_PREFERRED_CALCULATION_DATA = 'UPDATE_PREFERRED_CALCULATION_DATA',
  UPDATE_PREFERRED_CALCULATION_DATA_SUCCESS = 'UPDATE_PREFERRED_CALCULATION_DATA_SUCCESS',
  UPDATE_PREFERRED_CALCULATION_DATA_START = 'UPDATE_PREFERRED_CALCULATION_DATA_START',
  UPDATE_PREFERRED_CALCULATION_DATA_ERROR = 'UPDATE_PREFERRED_CALCULATION_DATA_ERROR',

  GET_PROJECT_DETAIL = 'GET_PROJECT_DETAIL',
  GET_PROJECT_DETAIL_SUCCESS = 'GET_PROJECT_DETAIL_SUCCESS',
  GET_PROJECT_DETAIL_START = 'GET_PROJECT_DETAIL_START',
  GET_PROJECT_DETAIL_ERROR = 'GET_PROJECT_DETAIL_ERROR',

  GET_REGISTRATION_FORM = 'GET_REGISTRATION_FORM',
  GET_REGISTRATION_FORM_SUCCESS = 'GET_REGISTRATION_FORM_SUCCESS',
  GET_REGISTRATION_FORM_START = 'GET_REGISTRATION_FORM_START',
  GET_REGISTRATION_FORM_ERROR = 'GET_REGISTRATION_FORM_ERROR',

  UPDATE_CALCULATION = 'UPDATE_CALCULATION',
  UPDATE_CALCULATION_SUCCESS = 'UPDATE_CALCULATION_SUCCESS',
  UPDATE_CALCULATION_START = 'UPDATE_CALCULATION_START',
  UPDATE_CALCULATION_ERROR = 'UPDATE_CALCULATION_ERROR',

  CALCULATE_PAYOUT = 'CALCULATE_PAYOUT',
  CALCULATE_PAYOUT_SUCCESS = 'CALCULATE_PAYOUT_SUCCESS',
  CALCULATE_PAYOUT_START = 'CALCULATE_PAYOUT_START',
  CALCULATE_PAYOUT_ERROR = 'CALCULATE_PAYOUT_ERROR',

  DELETE_CALCULATION = 'DELETE_CALCULATION',
  DELETE_CALCULATION_SUCCESS = 'DELETE_CALCULATION_SUCCESS',
  DELETE_CALCULATION_START = 'DELETE_CALCULATION_START',
  DELETE_CALCULATION_ERROR = 'DELETE_CALCULATION_ERROR',

  ADD_PAYOUT = 'ADD_PAYOUT',
  ADD_PAYOUT_SUCCESS = 'ADD_PAYOUT_SUCCESS',
  ADD_PAYOUT_START = 'ADD_PAYOUT_START',
  ADD_PAYOUT_ERROR = 'ADD_PAYOUT_ERROR',

  DELETE_PAYOUT = 'DELETE_PAYOUT',
  DELETE_PAYOUT_SUCCESS = 'DELETE_PAYOUT_SUCCESS',
  DELETE_PAYOUT_START = 'DELETE_PAYOUT_START',
  DELETE_PAYOUT_ERROR = 'DELETE_PAYOUT_ERROR',

  GET_YEAR_ANALYSIS_DATA = 'GET_YEAR_ANALYSIS_DATA',
  GET_YEAR_ANALYSIS_DATA_SUCCESS = 'GET_YEAR_ANALYSIS_DATA_SUCCESS',
  GET_YEAR_ANALYSIS_DATA_START = 'GET_YEAR_ANALYSIS_DATA_START',
  GET_YEAR_ANALYSIS_DATA_ERROR = 'GET_YEAR_ANALYSIS_DATA_ERROR',

  GET_MONTH_ANALYSIS_DATA = 'GET_MONTH_ANALYSIS_DATA',
  GET_MONTH_ANALYSIS_DATA_SUCCESS = 'GET_MONTH_ANALYSIS_DATA_SUCCESS',
  GET_MONTH_ANALYSIS_DATA_START = 'GET_MONTH_ANALYSIS_DATA_START',
  GET_MONTH_ANALYSIS_DATA_ERROR = 'GET_MONTH_ANALYSIS_DATA_ERROR',
}

export enum USER_ACTIONS {
  POST_USER = 'POST_USER',
  POST_USER_SUCCESS = 'POST_USER_SUCCESS',
  POST_USER_START = 'POST_USER_START',
  POST_USER_ERROR = 'POST_USER_ERROR',

  GET_USERS = 'GET_USERS',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_START = 'GET_USERS_START',
  GET_USERS_ERROR = 'GET_USERS_ERROR',

  GET_USER_DETAIL = 'GET_USER_DETAIL',
  GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS',
  GET_USER_DETAIL_START = 'GET_USER_DETAIL_START',
  GET_USER_DETAIL_ERROR = 'GET_USER_DETAIL_ERROR',

  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_START = 'UPDATE_USER_START',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',

  GET_ITERNAL_USER_START = 'GET_ITERNAL_USER_START',
  GET_ITERNAL_USER_ERROR = 'GET_ITERNAL_USER_ERROR',
  GET_ITERNAL_USER_SUCCESS = 'GET_ITERNAL_USER_SUCCESS',
  GET_ITERNAL_USER = 'GET_ITERNAL_USER',
}

export enum FORM_ACTIONS {
  ADD_STEP = 'ADD_STEP',
  DELETE_STEP = 'DELETE_STEP',

  GET_PROFILE_FORM = 'GET_PROFILE_FORM',
  GET_PROFILE_FORM_SUCCESS = 'GET_PROFILE_FORM_SUCCESS',
  GET_PROFILE_FORM_START = 'GET_PROFILE_FORM_START',
  GET_PROFILE_FORM_ERROR = 'GET_PROFILE_FORM_ERROR',

  GET_FILES_FORM = 'GET_FILES_FORM',
  GET_FILES_FORM_SUCCESS = 'GET_FILES_FORM_SUCCESS',
  GET_FILES_FORM_START = 'GET_FILES_FORM_START',
  GET_FILES_FORM_ERROR = 'GET_FILES_FORM_ERROR',

  GET_NEW_BROKER_FORM = 'GET_NEW_BROKER_FORM',
  GET_NEW_BROKER_FORM_SUCCESS = 'GET_NEW_BROKER_FORM_SUCCESS',
  GET_NEW_BROKER_FORM_START = 'GET_NEW_BROKER_FORM_START',
  GET_NEW_BROKER_FORM_ERROR = 'GET_NEW_BROKER_FORM_ERROR',

  GET_CONTACT_FORM = 'GET_CONTACT_FORM',
  GET_CONTACT_FORM_SUCCESS = 'GET_CONTACT_FORM_SUCCESS',
  GET_CONTACT_FORM_START = 'GET_CONTACT_FORM_START',
  GET_CONTACT_FORM_ERROR = 'GET_CONTACT_FORM_ERROR',

  GET_CALCULATION_FORM = 'GET_CALCULATION_FORM',
  GET_CALCULATION_FORM_SUCCESS = 'GET_CALCULATION_FORM_SUCCESS',
  GET_CALCULATION_FORM_START = 'GET_CALCULATION_FORM_START',
  GET_CALCULATION_FORM_ERROR = 'GET_CALCULATION_FORM_ERROR',

  GET_COLLECT_PAYMENT_DATA_FORM = 'GET_COLLECT_PAYMENT_DATA_FORM',
  GET_COLLECT_PAYMENT_DATA_FORM_SUCCESS = 'GET_COLLECT_PAYMENT_DATA_FORM_SUCCESS',
  GET_COLLECT_PAYMENT_DATA_FORM_START = 'GET_COLLECT_PAYMENT_DATA_FORM_START',
  GET_COLLECT_PAYMENT_DATA_FORM_ERROR = 'GET_COLLECT_PAYMENT_DATA_FORM_ERROR',

  GET_ENTER_ELIGIBLE_PERSON_FORM = 'GET_ENTER_ELIGIBLE_PERSON_FORM',
  GET_ENTER_ELIGIBLE_PERSON_FORM_SUCCESS = 'GET_ENTER_ELIGIBLE_PERSON_FORM_SUCCESS',
  GET_ENTER_ELIGIBLE_PERSON_FORM_START = 'GET_ENTER_ELIGIBLE_PERSON_FORM_START',
  GET_ENTER_ELIGIBLE_PERSON_FORM_ERROR = 'GET_ENTER_ELIGIBLE_PERSON_FORM_ERROR',

  GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM = 'GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM',
  GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM_SUCCESS = 'GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM_SUCCESS',
  GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM_START = 'GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM_START',
  GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM_ERROR = 'GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM_ERROR',

  GET_ADD_PAYOUT_FORM = 'GET_ADD_PAYOUT_FORM',
  GET_ADD_PAYOUT_FORM_SUCCESS = 'GET_ADD_PAYOUT_FORM_SUCCESS',
  GET_ADD_PAYOUT_FORM_START = 'GET_ADD_PAYOUT_FORM_START',
  GET_ADD_PAYOUT_FORM_ERROR = 'GET_ADD_PAYOUT_FORM_ERROR',

  GET_CALCULATION_RESULT_FORM = 'GET_CALCULATION_RESULT_FORM',
  GET_CALCULATION_RESULT_FORM_SUCCESS = 'GET_CALCULATION_RESULT_FORM_SUCCESS',
  GET_CALCULATION_RESULT_FORM_START = 'GET_CALCULATION_RESULT_FORM_START',
  GET_CALCULATION_RESULT_FORM_ERROR = 'GET_CALCULATION_RESULT_FORM_ERROR',

  GET_RECORD_OPERATING_COSTS_FORM = 'GET_RECORD_OPERATING_COSTS_FORM',
  GET_RECORD_OPERATING_COSTS_FORM_SUCCESS = 'GET_RECORD_OPERATING_COSTS_FORM_SUCCESS',
  GET_RECORD_OPERATING_COSTS_FORM_START = 'GET_RECORD_OPERATING_COSTS_FORM_START',
  GET_RECORD_OPERATING_COSTS_FORM_ERROR = 'GET_RECORD_OPERATING_COSTS_FORM_ERROR',

  GET_ACCEPT_PREPAYMENT_FORM = 'GET_ACCEPT_PREPAYMENT_FORM',
  GET_ACCEPT_PREPAYMENT_FORM_SUCCESS = 'GET_ACCEPT_PREPAYMENT_FORM_SUCCESS',
  GET_ACCEPT_PREPAYMENT_FORM_START = 'GET_ACCEPT_PREPAYMENT_FORM_START',
  GET_ACCEPT_PREPAYMENT_FORM_ERROR = 'GET_ACCEPT_PREPAYMENT_FORM_ERROR',

  GET_NEWS_FORM = 'GET_NEWS_FORM',
  GET_NEWS_FORM_SUCCESS = 'GET_NEWS_FORM_SUCCESS',
  GET_NEWS_FORM_START = 'GET_NEWS_FORM_START',
  GET_NEWS_FORM_ERROR = 'GET_NEWS_FORM_ERROR',

  GET_PROPERTY_OBJECT_ID_FORM = 'GET_PROPERTY_OBJECT_ID_FORM',
  GET_PROPERTY_OBJECT_ID_FORM_SUCCESS = 'GET_PROPERTY_OBJECT_ID_FORM_SUCCESS',
  GET_PROPERTY_OBJECT_ID_FORM_START = 'GET_PROPERTY_OBJECT_ID_FORM_START',
  GET_PROPERTY_OBJECT_ID_FORM_ERROR = 'GET_PROPERTY_OBJECT_ID_FORM_ERROR',
}

export enum TASK_ACTIONS {
  GET_TASKS = 'GET_TASKS',
  GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS',
  GET_TASKS_START = 'GET_TASKS_START',
  GET_TASKS_ERROR = 'GET_TASKS_ERROR',

  UPDATE_TASK_GROUP = 'UPDATE_TASK_GROUP',
  UPDATE_TASK_GROUP_SUCCESS = 'UPDATE_TASK_GROUP_SUCCESS',
  UPDATE_TASK_GROUP_START = 'UPDATE_TASK_GROUP_START',
  UPDATE_TASK_GROUP_ERROR = 'UPDATE_TASK_GROUP_ERROR',

  UPDATE_TASK = 'UPDATE_TASK',
  UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS',
  UPDATE_TASK_START = 'UPDATE_TASK_START',
  UPDATE_TASK_ERROR = 'UPDATE_TASK_ERROR',
}

export enum NEWS_ACTIONS {
  GET_NEWS = 'GET_NEWS',
  GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS',
  GET_NEWS_START = 'GET_NEWS_START',
  GET_NEWS_ERROR = 'GET_NEWS_ERROR',
}
