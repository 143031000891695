// Dependencies
import { FC, useState, useMemo, useEffect } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import { Button, Icon, IconButton, Input, Typography } from '../../../Common';

// Interfaces
import { IMessage, IProjectData } from '../../../../interfaces';
import { RootState } from '../../../../redux/reducers';
import {
  getMessages,
  sendMessageToCustomer,
} from '../../../../services/project.service';
import { useSnackbar } from 'notistack';
import { getDateTime, getProjectPrimaryImage } from '../../../../utils';
import { Highlighted } from '../../../Common/HighLighted';

// Styles
import * as S from './styles';
import { ChatType } from '..';
import {
  getNotes,
  notifyAgent,
  sendNoteToCustomer,
} from '../../../../services/client.service';
import { Mention } from 'react-mentions';
import { set } from 'date-fns/esm';

interface IChatsViewProps {
  visible: boolean;
  project?: IProjectData | any;
  loading: boolean;
  fetchClients: () => void;
  resetProjectList: (updatedProject: IProjectData) => void;
  onClickInformation: () => void;
  chatType: ChatType;
  agents?: any;
}

const convertToMentionData = (customers: any) => {
  return customers?.map((customer: any) => {
    return {
      id: customer.id,
      display: `${customer.first_name} ${customer.last_name}`,
    };
  });
};

// Create Chats iew
const ChatsView: FC<IChatsViewProps> = ({
  project,
  visible,
  loading,
  chatType,
  agents,
  onClickInformation,
  resetProjectList,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  // Theme
  const theme = useTheme();

  // Check platform
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get user data from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [message, setMessage] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);

  const customer = useMemo(() => project?.customer, [project]);
  const [idToNotify, setIdToNotify] = useState<number | string | undefined>(
    undefined
  );

  const [scrollEl, setScrollEl] = useState<any>();
  const [listRef, setListRef] = useState<any>();

  useEffect(() => {
    if (scrollEl && listRef) {
      scrollEl.scrollTop = scrollEl.clientHeight + listRef.clientHeight;
    }
  }, [scrollEl, listRef]);

  useEffect(() => {
    if (chatType === ChatType.External) {
      setMessages(
        project?.messages?.filter((msg) => {
          if (!search) return true;

          return msg.message
            ?.toLowerCase()
            .includes(search.toLowerCase().trim());
        }) || []
      );
    } else if (chatType === ChatType.Internal) {
      setMessages(
        project?.internalMessages?.filter((msg) => {
          if (!search) return true;

          return msg.message
            ?.toLowerCase()
            .includes(search.toLowerCase().trim());
        }) || []
      );
    }
  }, [project, search, chatType]);

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const handleToggleSearchInput = () => {
    if (showSearchInput) {
      setSearch('');
    }
    setShowSearchInput(!showSearchInput);
  };

  const handleSendMessage = (event) => {
    event.preventDefault();

    if (project && message && !sendLoading && chatType === ChatType.External) {
      setSendLoading(true);
      sendMessageToCustomer(project?.id, message)
        .then((res) => {
          setSendLoading(false);
          enqueueSnackbar(res.message, { variant: 'success' });
          setMessage('');
          getMessages(project?.id).then((newMessages) => {
            setSearch('');
            setMessages(newMessages);
            resetProjectList({ ...project, messages: newMessages });
            scrollEl.scrollTop =
              scrollEl.clientHeight + listRef.clientHeight + 88;
          });
        })
        .catch(() => {
          setSendLoading(false);
          enqueueSnackbar('Failed to send message', { variant: 'error' });
        });
    } else if (
      project &&
      message &&
      !sendLoading &&
      chatType === ChatType.Internal
    ) {
      setSendLoading(true);

      if (!message.includes('@')) setIdToNotify(undefined);

      sendNoteToCustomer(project?.id, message)
        .then((res) => {
          setSendLoading(false);
          enqueueSnackbar(t('chats.internal_chat_set_confirmed'), {
            variant: 'success',
          });
          setMessage('');

          if (idToNotify) {
            notifyAgent(project?.id, idToNotify)
              .then((res) => {
                enqueueSnackbar(t('chats.agent_notified'), {
                  variant: 'success',
                });
              })
              .catch((err) => {
                enqueueSnackbar(err.message, { variant: 'error' });
              })
              .finally(() => {
                setIdToNotify(undefined);
              });
          }

          getNotes(project?.id).then((newMessages) => {
            setSearch('');
            setMessages(newMessages);
            resetProjectList({ ...project, messages: newMessages });
            scrollEl.scrollTop =
              scrollEl.clientHeight + listRef.clientHeight + 88;
          });
        })
        .catch(() => {
          setSendLoading(false);
          enqueueSnackbar('Failed to send message', { variant: 'error' });
        });
    }
  };

  function mapColorsToUsers(
    messages: IMessage[],
    colors: string[]
  ): Record<string, string> {
    const userColorMap: Record<string, string> = {};
    let colorIndex = 0;

    messages.forEach((message) => {
      const user = message.user;

      // Set certain default color for all messages of the logged in user
      if (user?.id === account?.id) {
        userColorMap[user.id] =
          chatType === ChatType.External ? '#F8EFEA' : '#EFF4F8';
      }

      // Check if the user's ID is already assigned a color
      if (!userColorMap[user.id]) {
        // Assign the next available color
        userColorMap[user.id] = colors[colorIndex];
        colorIndex = (colorIndex + 1) % colors.length;
      }
    });

    return userColorMap;
  }

  const colors = [
    '#FFE4C3',
    '#FCF4E3',
    '#78cf5b',
    '#40bcdb',
    '#cdaa60',
    '#cd6081',
    '#FF3357',
    '#FF5733',
    '#8255af',
    '#ffa033',
  ];

  const userColorMap = mapColorsToUsers(messages, colors);

  // Return Chats view
  return (
    <Box
      flex={1}
      sx={(theme) => ({
        display: {
          xs: visible ? 'block' : 'none',
          lg: 'block',
        },
        borderLeft: isTablet
          ? 'none'
          : `1px solid ${theme.palette['lightCyan']}`,
      })}
    >
      {loading ? (
        <Box
          sx={{
            height: isTablet ? 'calc(100vh - 146px)' : 'calc(100vh - 236px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={54} />
        </Box>
      ) : project ? (
        <Stack direction="row">
          <Stack py={16} px={20} flex={1} minWidth={{ xs: '100%', lg: 500 }}>
            <Stack
              mb={16}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" spacing={16}>
                <Avatar
                  src={getProjectPrimaryImage(project)}
                  sx={{
                    width: '64px !important',
                    height: '48px !important',
                  }}
                />
                <Typography variant="subtitle1">
                  {chatType === ChatType.External
                    ? `${customer?.first_name} ${customer?.last_name}`
                    : `${project?.first_name} ${project?.last_name}`}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={8}>
                {showSearchInput && (
                  <Input
                    placeholder="Search..."
                    value={search}
                    onChange={handleSearchChange}
                  />
                )}
                <IconButton onClick={handleToggleSearchInput}>
                  <Icon name={showSearchInput ? 'x-lg' : 'search'} />
                </IconButton>
                {chatType === ChatType.External ? (
                  <IconButton onClick={onClickInformation}>
                    <Icon name="info" />
                  </IconButton>
                ) : null}
              </Stack>
            </Stack>
            <Box
              sx={{
                height: isTablet
                  ? 'calc(100vh - 290px)'
                  : 'calc(100vh - 400px)',
                paddingRight: isTablet ? 0 : 16,
                overflow: 'auto',
              }}
              ref={(ref: any) => {
                setScrollEl(ref);
              }}
            >
              <Stack spacing={16} ref={(ref: any) => setListRef(ref)}>
                {messages.map(({ message, date, user, editorName }, index) => {
                  const isReceived = user?.id !== account?.id;

                  return (
                    <Stack
                      key={index}
                      direction="column"
                      alignItems={isReceived ? 'flex-start' : 'flex-end'}
                    >
                      <Stack
                        width={isTablet ? '90%' : '70%'}
                        alignItems={isReceived ? 'flex-start' : 'flex-end'}
                      >
                        <Typography mb={4} variant="caption">
                          {isReceived
                            ? `${
                                chatType === ChatType.External
                                  ? editorName
                                  : user?.first_name + ' ' + user.last_name
                              }, ${getDateTime(date)}`
                            : getDateTime(date)}
                        </Typography>
                        <Typography
                          sx={(theme) => ({
                            p: 16,
                            mb: 8,
                            borderRadius: 2,
                            borderTopLeftRadius: isReceived ? 0 : 8,
                            borderBottomRightRadius: isReceived ? 8 : 0,
                            bgcolor: userColorMap[user.id],
                          })}
                        >
                          {search ? (
                            <Highlighted text={message} highlight={search} />
                          ) : (
                            message
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Box>
            <form onSubmit={handleSendMessage}>
              <Stack mt={{ xs: 12, lg: 16 }} direction="row" spacing={20}>
                {chatType === ChatType.External ? (
                  <Input
                    size={isTablet ? 'small' : 'large'}
                    sx={{ flex: 2 }}
                    placeholder={t('chats.type_message')}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                ) : (
                  <S.Mention
                    singleLine
                    value={message}
                    onChange={(e, newValue, newPlainTextValue, mentions) => {
                      if (!newPlainTextValue.includes('@'))
                        setIdToNotify(undefined);

                      setMessage(newPlainTextValue);
                    }}
                    placeholder={t('chats.internal_chat_placeholder')}
                    style={{
                      flex: 2,
                      maxWidth: '600px',
                      padding: '15px',
                      maxHeight: '52px',
                      overflow: 'hidden',
                      border: 'none',
                    }}
                    customSuggestionsContainer={(children) => (
                      <Box
                        sx={{
                          zIndex: 1,
                          height: '100%',
                          background: 'white',
                          padding: '16px 16px',
                        }}
                      >
                        {children}
                      </Box>
                    )}
                  >
                    <Mention
                      trigger="@"
                      markup="(@__display__)"
                      data={convertToMentionData(agents)}
                      style={{
                        padding: '15px',
                        height: '100%',
                        border: 'none',
                      }}
                      onAdd={(id, display) => {
                        setIdToNotify(id);
                      }}
                      displayTransform={(id, display) => {
                        return `@${display}`;
                      }}
                    />
                  </S.Mention>
                )}

                <Button
                  type="submit"
                  disabled={sendLoading}
                  size="large"
                  style={{ backgroundColor: '#F45D5B', color: 'white' }}
                  sx={{
                    display: { xs: 'none', lg: 'block' },
                  }}
                >
                  {t('chats.send')}
                </Button>
                <S.DarkIconButton
                  sx={{ display: { xs: 'block', lg: 'none' } }}
                  size={isTablet ? 'small' : 'large'}
                  onClick={handleSendMessage}
                >
                  <Icon name="message" />
                </S.DarkIconButton>
              </Stack>
            </form>
          </Stack>
        </Stack>
      ) : (
        <Box
          sx={{
            height: isTablet ? 'calc(100vh - 146px)' : 'calc(100vh - 236px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography align="center">{t('chats.description')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ChatsView;
