import { NEWS_ACTIONS } from '../action-types';
import { INews } from '../../interfaces';

export interface NewsReducerState {
  news: INews[] | null;
  loading: boolean;
}

const initialState: NewsReducerState = {
  news: [],
  loading: false,
};

const NewsReducer = (state: NewsReducerState = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case NEWS_ACTIONS.GET_NEWS_START:
      return {
        ...state,
        loading: true,
      };

    case NEWS_ACTIONS.GET_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        news: [],
      };

    case NEWS_ACTIONS.GET_NEWS_SUCCESS: {
      return {
        ...state,
        loading: false,
        news: payload,
      };
    }

    default:
      return state;
  }
};

export default NewsReducer;
