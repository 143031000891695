// Dependencies
import styled from 'styled-components';
import { FormControlLabel, Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Export styled components
export const PieChart = styled(Stack)`
  padding-top: 20px;

  .highcharts-container {
    margin: 0 auto;

    .centered-title {
      text-align: center;
      font-size: 34px;
      font-weight: 600;
      font-family: Exo, serif;
      line-height: 48px;
      color: ${(props) => props.theme.palette.text.primary};

      @media only screen and (max-width: 765px) {
        font-size: 23px;
        line-height: 33px;
      }

      p {
        margin: 0;
        &:last-child {
          font-size: 12px;
          font-weight: 400;
          line-height: 10px;
          color: ${(props) => props.theme.palette.text.secondary};

          @media only screen and (max-width: 765px) {
            font-size: 8.215px;
            line-height: 14px;
          }
        }
      }
    }

    .tooltip {
      box-shadow: 0 0 12px rgba(85, 101, 129, 0.1);
    }

    .pie-chart-tooltip {
      background: #fff;
      box-shadow: 0 0 12px rgba(85, 101, 129, 0.1);
      border-radius: 8px;
      padding: 12px 16px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

// padding: 0 20px;
export const LegendWrapper = styled(PerfectScrollbar)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 20px;

  .legend-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 4px;
    cursor: pointer;

    @media only screen and (max-width: 765px) {
      font-size: 10px;
      line-height: 12px;
    }

    .legend-symbol {
      min-width: 16px;
      height: 16px;
      margin-right: 8px;
      border-radius: 4px;

      @media only screen and (max-width: 765px) {
        width: 12px;
        height: 16px;
      }
    }

    &.disabled {
      color: ${(props) => props.theme.palette.text.secondary};

      .legend-symbol {
        background-color: ${(props) =>
          props.theme.palette.lightCyan} !important;
      }
    }
  }
`;

export const Checkbox = styled(FormControlLabel)`
  && {
    margin: 0 0 20px;

    .MuiCheckbox-root {
      padding: 4px;
      margin-right: 20px;
      border-radius: 5px;
      background: ${(props) => props.theme.palette.lightCyan};
    }
  }
`;
