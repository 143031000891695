// Export sort type
export enum Order {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface ISortBy {
  field: string;
  order: Order;
}
