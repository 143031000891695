// Dependencies
import React, { ChangeEvent, FC, ReactNode, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  PaginationItem,
  PaginationItemProps,
  Stack,
} from '@mui/material';
import { SxProps } from '@mui/system';

// Components
import { Input } from '../Input';
import { Icon } from '../Icon';

// Styles
import * as S from './styles';
import { Typography } from '../Typography';
import { useTranslation } from 'react-i18next';

// Interfaces
export interface ICardProps {
  title: ReactNode;
  subheader?: ReactNode;
  contentHeight?: string | number;
  contentPadding?: string | number;
  children: ReactNode;
  action?: ReactNode;
  sx?: SxProps;
  searchable?: boolean;
  searchPlaceholder?: string;
  search?: string;
  loading?: boolean;
  isEmpty?: boolean;
  emptyDataText?: string;
  pagination?: {
    total: number;
    current: number;
  };
  onPageChange?: (page: number) => void;
  onSearch?: (value: string) => void;
}

// Export Card component
export const Card: FC<ICardProps> = ({
  title,
  subheader,
  children,
  contentHeight,
  contentPadding,
  action,
  sx,
  searchable,
  searchPlaceholder,
  onSearch,
  search,
  loading,
  emptyDataText,
  pagination,
  onPageChange,
  isEmpty,
}) => {
  const { t } = useTranslation();

  // States
  const [isSearching, setIsSearching] = useState<boolean>(false);

  // Toggle search input handler
  const handleToggleSearchInput = () => {
    setIsSearching(!isSearching);
    if (isSearching && onSearch) {
      onSearch('');
    }
  };

  // Search change handler
  const handleSearchChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onSearch) {
      onSearch(e.target.value);
    }
  };

  const LoadingSpinner = () => (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );

  const EmptyContent = () => (
    <S.EmptyTextWrapper>
      <Typography
        variant="body1"
        sx={(theme) => ({ color: theme.palette['lightIndigo'] })}
      >
        {(emptyDataText || t('dashboard.no_data')) +
          (search ? ` ${t('dashboard.for_search_text')} "${search}"` : '')}
      </Typography>
    </S.EmptyTextWrapper>
  );

  // Render page item handler
  const handleRenderPageItem = (item: PaginationItemProps) => {
    switch (item.type) {
      case 'page': {
        if (
          pagination &&
          (item.page === 1 ||
            item.page === 2 ||
            item.page === pagination?.total - 1 ||
            item.page === pagination?.total ||
            item.selected)
        ) {
          return <PaginationItem {...item} />;
        }
        if (
          pagination &&
          ((item.page === 3 && pagination?.current === 4) ||
            (item.page === pagination?.total - 2 &&
              pagination?.current === pagination?.total - 3))
        ) {
          return <PaginationItem type="start-ellipsis" />;
        }
        break;
      }

      default: {
        return <PaginationItem {...item} />;
      }
    }
  };

  // Return Card component
  return (
    <S.Card sx={sx}>
      <S.CardHeader
        $headerDivider
        title={!isSearching && title}
        subheader={subheader}
        className={isSearching ? 'is-searching' : ''}
        action={
          <>
            {searchable && isSearching ? (
              <Stack direction="row" spacing={8}>
                <Input
                  value={search}
                  placeholder={searchPlaceholder}
                  onChange={handleSearchChange}
                  size="small"
                />
                <IconButton color="default" onClick={handleToggleSearchInput}>
                  <Icon name="x-lg" />
                </IconButton>
              </Stack>
            ) : (
              <Stack direction="row" spacing={8}>
                {searchable && (
                  <IconButton color="default" onClick={handleToggleSearchInput}>
                    <Icon name="search" />
                  </IconButton>
                )}
                {action}
              </Stack>
            )}
          </>
        }
      />
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <S.CardContent
          height={contentHeight}
          padding={contentPadding}
          $visiblePagination={Boolean(pagination && pagination.total > 0)}
        >
          {loading ? <LoadingSpinner /> : isEmpty ? <EmptyContent /> : children}
        </S.CardContent>
        {pagination && pagination.total > 0 && (
          <Stack
            alignItems="center"
            sx={(theme) => ({
              pt: theme.spacing(8),
              borderTop: `1px solid ${theme.palette.lightCyan}`,
            })}
          >
            <S.Pagination
              shape="rounded"
              count={pagination.total}
              page={pagination.current}
              onChange={(e, page: number) =>
                onPageChange ? onPageChange(page) : undefined
              }
              renderItem={handleRenderPageItem}
            />
          </Stack>
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' }, flex: 1 }}>
        {loading ? <LoadingSpinner /> : isEmpty ? <EmptyContent /> : children}
      </Box>
    </S.Card>
  );
};
