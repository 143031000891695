import { FORM_ACTIONS } from '../action-types';

export interface FormReducerState {
  profileForm: any;
  filesForm: any;
  newBrokerForm: any;
  calculationForm: any;
  contactForm: any;
  collectPaymentDataForm: any;
  enterEligiblePersonForm: any;
  emergencyContactPersonForm: any;
  calculationResultForm: any;
  recordOperatingCostsForm: any;
  addPayoutForm: any;
  acceptPrepaymentForm: any;
  newsForm: any;
  propertyObjectIdForm: any;
  loading: boolean;
}

const initialState: FormReducerState = {
  profileForm: {},
  filesForm: {},
  newBrokerForm: {},
  contactForm: {},
  calculationForm: {},
  collectPaymentDataForm: {},
  enterEligiblePersonForm: {},
  emergencyContactPersonForm: {},
  addPayoutForm: {},
  calculationResultForm: {},
  recordOperatingCostsForm: {},
  acceptPrepaymentForm: {},
  newsForm: {},
  propertyObjectIdForm: {},
  loading: false,
};

const FormReducer = (state: FormReducerState = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case FORM_ACTIONS.GET_PROFILE_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_PROFILE_FORM_ERROR:
      return {
        ...state,
        profileForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_PROFILE_FORM_SUCCESS:
      return {
        ...state,
        profileForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_NEW_BROKER_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_NEW_BROKER_FORM_ERROR:
      return {
        ...state,
        newBrokerForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_NEW_BROKER_FORM_SUCCESS:
      return {
        ...state,
        newBrokerForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_FILES_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_FILES_FORM_ERROR:
      return {
        ...state,
        filesForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_FILES_FORM_SUCCESS: {
      return {
        ...state,
        filesForm: payload,
        loading: false,
      };
    }

    case FORM_ACTIONS.GET_CONTACT_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_CALCULATION_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_CALCULATION_FORM_ERROR:
      return {
        ...state,
        calculationForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_CALCULATION_FORM_SUCCESS:
      return {
        ...state,
        calculationForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_CONTACT_FORM_ERROR:
      return {
        ...state,
        contactForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_ENTER_ELIGIBLE_PERSON_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_ENTER_ELIGIBLE_PERSON_FORM_ERROR:
      return {
        ...state,
        enterEligiblePersonForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_ENTER_ELIGIBLE_PERSON_FORM_SUCCESS:
      return {
        ...state,
        enterEligiblePersonForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM_ERROR:
      return {
        ...state,
        emergencyContactPersonForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM_SUCCESS:
      return {
        ...state,
        emergencyContactPersonForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_COLLECT_PAYMENT_DATA_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_COLLECT_PAYMENT_DATA_FORM_ERROR:
      return {
        ...state,
        collectPaymentDataForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_COLLECT_PAYMENT_DATA_FORM_SUCCESS:
      return {
        ...state,
        collectPaymentDataForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_ADD_PAYOUT_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_ADD_PAYOUT_FORM_ERROR:
      return {
        ...state,
        addPayoutForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_ADD_PAYOUT_FORM_SUCCESS:
      return {
        ...state,
        addPayoutForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_CALCULATION_RESULT_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_CALCULATION_RESULT_FORM_ERROR:
      return {
        ...state,
        calculationResultForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_CALCULATION_RESULT_FORM_SUCCESS:
      return {
        ...state,
        calculationResultForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_RECORD_OPERATING_COSTS_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_RECORD_OPERATING_COSTS_FORM_ERROR:
      return {
        ...state,
        recordOperatingCostsForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_RECORD_OPERATING_COSTS_FORM_SUCCESS:
      return {
        ...state,
        recordOperatingCostsForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_ACCEPT_PREPAYMENT_FORM_START:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_ACCEPT_PREPAYMENT_FORM_ERROR:
      return {
        ...state,
        acceptPrepaymentForm: null,
        loading: false,
      };

    case FORM_ACTIONS.GET_ACCEPT_PREPAYMENT_FORM_SUCCESS:
      return {
        ...state,
        acceptPrepaymentForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_NEWS_FORM:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_NEWS_FORM_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FORM_ACTIONS.GET_NEWS_FORM_SUCCESS:
      return {
        ...state,
        newsForm: payload,
        loading: false,
      };

    case FORM_ACTIONS.GET_PROPERTY_OBJECT_ID_FORM:
      return {
        ...state,
        loading: true,
      };

    case FORM_ACTIONS.GET_PROPERTY_OBJECT_ID_FORM_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FORM_ACTIONS.GET_PROPERTY_OBJECT_ID_FORM_SUCCESS:
      return {
        ...state,
        propertyObjectIdForm: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default FormReducer;
