// Dependencies
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  InputBase,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

// Components
import {
  Button,
  CreateNewBrokerModal,
  DataTable,
  ConfirmModal,
  IColumn,
  Icon,
  IconButton,
  Typography,
} from '../../components';

// Interfaces
import { RootState } from '../../redux/reducers';
import { ISortBy, IUserData, Order } from '../../interfaces';

// Global constants
import { ROUTES } from '../../constants';
import { getNewBrokerForm, getUsers } from '../../redux/actions';
import { deleteMultipleUser, deleteUser } from '../../services/user.service';

import * as S from './styles';

// Export brokers page
export const BrokersPage: FC = () => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // States
  const [brokers, setBrokers] = useState<IUserData[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<ISortBy>({
    field: 'date-created',
    order: Order.Desc,
  });
  const [visibleNewBrokerModal, setVisibleNewBrokerModal] =
    useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [confirmDeleteId, setConfirmDeleteId] = useState<string | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [visibleMultiDelConfirmModal, setVisibileMultiDelConfirmModal] =
    useState<boolean>(false);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const searchRef = useRef<HTMLDivElement>(null);

  // Theme
  const theme = useTheme();

  // Get navigate from hook
  const navigate = useNavigate();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get drawer status from store
  const isDrawerOpened = useSelector(
    ({ uiReducer: { isDrawerOpened } }: RootState) => isDrawerOpened
  );

  // Get users from store
  const { users, pagination, loading } = useSelector(
    ({ userReducer }: RootState) => userReducer
  );

  // Check platform
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSortBy(value);
  };

  // Page change handler
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
    setPage(1);
  };

  // Go to detail handler
  const handleGoToDetail = (id) => {
    navigate(ROUTES.BROKERS.DETAIL.replace(':id', id), {
      state: {
        rowsPerPage,
        page,
      },
    });
  };

  // Delete handler
  const handleDelete = (id) => {
    setConfirmDeleteId(id);
  };

  // Remove user
  const handleDeleteUser = () => {
    setDeleteLoading(true);
    deleteUser(confirmDeleteId)
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          dispatch(
            getUsers({
              page,
              perPage: rowsPerPage,
              sort: sortBy.field,
              'sort-order': sortBy.order,
              search: debouncedSearch,
            })
          );
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' });
        }
        setDeleteLoading(false);
        setConfirmDeleteId(null);
      })
      .catch((e: any) => {
        enqueueSnackbar(e?.response?.data?.message || 'Failed to delete', {
          variant: 'error',
        });
        setDeleteLoading(false);
        setConfirmDeleteId(null);
      });
  };

  // Remove multiple user
  const handleDeleteMultipleUser = () => {
    setDeleteLoading(true);
    const ids = brokers
      .filter((broker, index) => selected.includes(index))
      ?.map((item) => item.id);
    deleteMultipleUser(ids)
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          dispatch(
            getUsers({
              page,
              perPage: rowsPerPage,
              sort: sortBy.field,
              'sort-order': sortBy.order,
              search: debouncedSearch,
            })
          );
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' });
        }
        setDeleteLoading(false);
        setSelected([]);
        setVisibileMultiDelConfirmModal(false);
      })
      .catch((e: any) => {
        enqueueSnackbar(e?.response?.data?.message || 'Failed to delete', {
          variant: 'error',
        });
        setSelected([]);
        setDeleteLoading(false);
        setVisibileMultiDelConfirmModal(false);
      });
  };

  // Close delete confirm modal handler
  const handleCloseDeleteConfirmModal = () => {
    setConfirmDeleteId(null);
  };

  // Open multiple delete confirm modal
  const handleOpenMultiDeleteConfirmModal = () => {
    setVisibileMultiDelConfirmModal(true);
  };

  // Close multiple delete confirm modal
  const handleCloseMultiDeleteConfirmModal = () => {
    setVisibileMultiDelConfirmModal(false);
  };

  // Columns
  const columns: IColumn[] = [
    {
      field: 'photo',
      label: t('brokers.photo'),
      sortable: false,
      width: 56,
      render: (row: IUserData) => (
        <Avatar
          src={row?.image?.path}
          variant="square"
          sx={{ width: 40, height: 40 }}
        />
      ),
    },
    {
      field: 'name',
      label: t('brokers.name'),
      render: (row: IUserData) => (
        <Typography variant="body2" fontWeight={500}>
          {`${row.first_name} ${row.last_name}`}
        </Typography>
      ),
    },
    {
      field: 'company',
      label: t('brokers.company'),
    },
    {
      field: 'address',
      label: t('brokers.address'),
      render: (row: IUserData) => (
        <>
          {`${row.address?.street}, ${row.address?.zip} ${row.address?.city}`}
        </>
      ),
    },
  ];

  // Row actions
  const rowActions = [
    {
      icon: 'document',
      label: t('brokers.details'),
      onClick: handleGoToDetail,
    },
    {
      icon: 'trash',
      label: t('brokers.delete'),
      onClick: handleDelete,
    },
  ];

  // Open new broker modal handler
  const handleOpenNewBrokerModal = () => {
    setVisibleNewBrokerModal(true);
  };

  // Close new broker modal handler
  const handleCloseNewBrokerModal = () => {
    setVisibleNewBrokerModal(false);
  };

  // Change search handler
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleOpenSearchInput = () => {
    setVisibleSearch(true);
  };

  const handleCloseSearchInput = () => {
    setVisibleSearch(false);
  };

  const handleClearSearch = () => {
    setSearch('');
  };

  // Delayed search handler
  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  // Debounce search
  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
  }, [search, delayedChangeSearch]);

  // Get new broker form
  useEffect(() => {
    dispatch(getNewBrokerForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On page, sortBy and rowsPerPage changed
  useEffect(() => {
    dispatch(
      getUsers({
        page,
        perPage: rowsPerPage,
        sort: sortBy.field,
        'sort-order': sortBy.order,
        search: debouncedSearch,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, rowsPerPage, debouncedSearch]);

  // On users changed
  useEffect(() => {
    if (users) {
      setBrokers(users);
    }
  }, [users]);

  // On pagination changed
  useEffect(() => {
    if (pagination?.total || pagination?.total === 0) {
      setTotalPage(Math.ceil(pagination?.total / rowsPerPage));
    }
  }, [pagination, rowsPerPage]);

  useEffect(() => {
    if (Boolean(search) && !loading) {
      searchRef.current?.click();
    }
  }, [search, loading]);

  // Return brokers page

  return (
    <>
      <S.TableCard>
        <CardHeader
          sx={{
            pt: { sm: 22 },
            pb: { xs: 18, sm: 20 },
            px: { xs: 16, sm: 20 },
          }}
          title={
            <>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <InputBase
                  ref={searchRef}
                  autoFocus={Boolean(search) && !loading}
                  disabled={loading}
                  value={search}
                  placeholder={t('brokers.search')}
                  startAdornment={<Icon name="search" />}
                  endAdornment={
                    search ? (
                      <IconButton
                        sx={{ height: 20, width: 20 }}
                        size="small"
                        disabled={loading}
                        onClick={handleClearSearch}
                      >
                        <Icon name="x-lg" size={16} />
                      </IconButton>
                    ) : (
                      <Box sx={{ height: 20, width: 20 }} />
                    )
                  }
                  onChange={handleChangeSearch}
                />
              </Box>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                {visibleSearch ? (
                  <Stack spacing={10} direction="row">
                    <IconButton onClick={handleCloseSearchInput}>
                      <Icon name="x-lg" />
                    </IconButton>
                    <InputBase
                      disabled={loading}
                      startAdornment={<Icon name="search" />}
                      placeholder={t('brokers.search')}
                      value={search}
                      sx={{ flex: 1 }}
                      onChange={handleChangeSearch}
                    />
                  </Stack>
                ) : (
                  <Stack>
                    <IconButton
                      disabled={loading}
                      onClick={handleOpenSearchInput}
                    >
                      <Icon name="search" />
                    </IconButton>
                  </Stack>
                )}
              </Box>
            </>
          }
          action={
            !visibleSearch && (
              <Stack direction="row" spacing={{ xs: 10, md: 12 }}>
                {selected && selected.length > 0 && (
                  <Button
                    disabled={loading}
                    startIcon={!isMobile && <Icon name="trash" />}
                    onClick={handleOpenMultiDeleteConfirmModal}
                    sx={(theme) => ({ color: theme.palette.error.main })}
                  >
                    {t('brokers.delete')}
                  </Button>
                )}
                <Button
                  disabled={loading}
                  startIcon={!isMobile && <Icon name="plus-lg" />}
                  onClick={handleOpenNewBrokerModal}
                >
                  {t('brokers.new_broker')}
                </Button>
              </Stack>
            )
          }
        />
        <CardContent
          sx={(theme) => ({
            p: '0 !important',
            borderTop: `1px solid ${theme.palette['lightCyan']}`,
          })}
        >
          <DataTable
            paginated
            rowSelectable
            columnEditable
            page={page}
            data={brokers}
            columns={columns}
            isLoading={loading}
            totalPage={totalPage}
            rowActions={rowActions}
            rowsPerPage={rowsPerPage}
            selected={selected}
            stickyHeader
            contentSize={
              isMobile
                ? { width: 'calc(100vw - 32px)' }
                : {
                    width: isDrawerOpened
                      ? 'calc(100vw - 328px)'
                      : 'calc(100vw - 160px)',
                    height: 'calc(100vh - 320px)',
                  }
            }
            onChangeSelected={setSelected}
            onSort={handleSort}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onRowClick={handleGoToDetail}
          />
        </CardContent>
      </S.TableCard>
      <CreateNewBrokerModal
        open={visibleNewBrokerModal}
        onClose={handleCloseNewBrokerModal}
      />
      <ConfirmModal
        open={!!confirmDeleteId}
        onClose={handleCloseDeleteConfirmModal}
        onOk={handleDeleteUser}
        loading={deleteLoading}
      />
      <ConfirmModal
        open={visibleMultiDelConfirmModal}
        onClose={handleCloseMultiDeleteConfirmModal}
        onOk={handleDeleteMultipleUser}
        loading={deleteLoading}
      />
    </>
  );
};
