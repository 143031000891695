import * as Yup from 'yup';
import _ from 'lodash';
import i18n from '../i18n';
import { IForm } from '../interfaces';
import moment from 'moment';

const textType = [
  'date',
  'radio',
  'text',
  'float',
  'integer',
  'email',
  'textarea',
  'tel',
  'currency',
  'choice',
  'select',
];

export const getDeepKeys = (obj) => {
  let keys: any[] = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (Array.isArray(obj[key])) {
        keys.push(key);
      } else {
        const subkeys = getDeepKeys(obj[key]);
        keys = keys.concat(
          subkeys.map((subkey) => {
            return key + '.' + subkey;
          })
        );
      }
    } else {
      keys.push(key);
    }
  }
  return keys;
};

export const getInitialValuesInFormStructure = (value, structure) => {
  const keys = getDeepKeys(structure);

  return _.pick(value, keys);
};

export const getFormikTouchedValue = (value) => {
  const clone = _.cloneDeep(value);
  const keys = getDeepKeys(clone);

  for (const key of keys) {
    _.set(clone, key, true);
  }

  return clone;
};

export const buildFormInitialValues = (field): any => {
  if (!field) {
    return null;
  }

  if (field.type === 'object' || field.type === 'array') {
    const childForm = Object.entries(field.fields).reduce(
      (finalField, [fieldKey, fieldValue]: any) => {
        if (fieldValue.type === 'object' || fieldValue.type === 'array') {
          const mergedFieldValue = buildFormInitialValues(fieldValue);
          return { ...finalField, [fieldKey]: mergedFieldValue };
        } else if (fieldValue.type === 'headline') {
          return finalField;
        } else {
          if (fieldValue.type === 'checkbox') {
            return { ...finalField, [fieldKey]: [] };
          } else {
            return { ...finalField, [fieldKey]: '' };
          }
        }
      },
      {}
    );

    if (field.type === 'array' && field.allowAdd) {
      return [childForm];
    }

    return childForm;
  } else {
    return { [field.name]: '' };
  }
};

export const buildFormInitialData = (formData): any => {
  if (!formData) {
    return null;
  }

  return Object.entries(formData).reduce(
    (finalField, [fieldKey, fieldValue]: [string, any]) => {
      return { ...finalField, [fieldKey]: buildFormInitialValues(fieldValue) };
    },
    {}
  );
};

export const buildFormInitialDataWithValuesFromStructure = (
  formData,
  structure
): any => {
  const initialData = buildFormInitialData(structure);
  const data = _.merge(
    {},
    initialData,
    getInitialValuesInFormStructure(formData, initialData)
  );
  return data;
};

const makeFieldSchema = (fieldValue, fieldSchema, fieldLabel) => {
  let schema = fieldSchema;
  if (fieldValue.required) {
    if (fieldValue.type === 'checkbox') {
      schema = schema.min(1, i18n.t('validation.field_required'));
    } else if (fieldValue.type === 'select') {
      schema = schema.min(1, i18n.t('validation.field_required'));
    } else {
      schema = schema.required(i18n.t('validation.field_required'));
    }
  } else if (fieldValue.type === 'checkbox') {
    schema = schema.nullable();
  }

  if (
    fieldValue.type === 'password' &&
    fieldValue.name === 'confirmPlainPassword'
  ) {
    schema = schema.oneOf(
      [Yup.ref('plainPassword'), ''],
      i18n.t('validation.password_not_matched')
    );
  }

  const constraints = fieldValue.attributes.constraints;

  if (Array.isArray(constraints) && constraints.length > 0) {
    constraints.forEach(({ message, htmlPattern, match }) => {
      const regExp = new RegExp(htmlPattern);
      if (match) {
        schema = schema.matches(regExp, message);
      } else {
        schema = schema.test(
          'is-match',
          message,
          (value = '') => !regExp.test(value)
        );
      }
    });
  }

  return schema;
};

export const buildSingleFieldSchema = (field, parentKey = '') => {
  if (!field) {
    return null;
  }

  if (field.type === 'object' || field.type === 'array') {
    const objectShape = Yup.object().shape(
      Object.entries(field.fields).reduce(
        (finalField, [fieldKey, fieldValue]: any) => {
          if (fieldValue.type === 'object' || fieldValue.type === 'array') {
            const schema = buildSingleFieldSchema(fieldValue, fieldKey);
            return { ...finalField, [fieldKey]: schema };
          } else {
            let schema;

            if (fieldValue.type === 'file') {
              schema = null;
            } else {
              if (fieldValue.type === 'checkbox') {
                schema = Yup.array().of(Yup.string());
              } else if (fieldValue.type === 'file') {
                schema = Yup.mixed();
              } else {
                schema = Yup.string().nullable(true);
              }

              const dependencies = fieldValue.attributes.attr?.dependencies;

              let relatedKeys: any;
              let relatedValuesArray: any;

              if (dependencies) {
                relatedKeys = Object.keys(dependencies);
                relatedValuesArray = Object.values(dependencies);
              }

              if (relatedKeys && relatedKeys.length) {
                for (let i = 0; i < relatedKeys.length; i++) {
                  let relatedKey = relatedKeys[i];
                  const relatedValue = relatedValuesArray[i];

                  if (relatedValue.type === 'radio') {
                    relatedKey = relatedValue?.validator;
                  }

                  const checkValidation = (value) => {
                    if (relatedValue.type === 'array') {
                      const included = relatedValue?.validator?.includes(value);
                      if (relatedValue.reverse) {
                        if (
                          relatedValue.validator?.length === 1 &&
                          relatedValue.validator === 'non'
                        ) {
                          return value !== 'non' && !!value;
                        } else {
                          return !included;
                        }
                      } else {
                        return included;
                      }
                    } else if (relatedValue.type === 'radio') {
                      return value === 'true' || value === true;
                    } else if (relatedValue.type === 'min') {
                      return value >= relatedValue.validator;
                    }

                    return false;
                  };

                  schema = schema.when(relatedKey, (value, fieldSchema) => {
                    if (checkValidation(value)) {
                      return makeFieldSchema(fieldValue, fieldSchema, fieldKey);
                    } else if (fieldValue.type === 'checkbox') {
                      return fieldSchema.nullable();
                    } else {
                      return fieldSchema.notRequired();
                    }
                  });
                }
              } else {
                schema = makeFieldSchema(fieldValue, schema, fieldKey);
              }
            }

            return { ...finalField, [fieldKey]: schema };
          }
        },
        {}
      )
    );

    if (field.type === 'array' && field.allowAdd) {
      return Yup.array().of(objectShape).min(1, 'You need at least one');
    }

    return objectShape;
  } else {
    if (field.required) {
      if (field.type === 'file') {
        return Yup.mixed().required(i18n.t('validation.field_required'));
      } else {
        return Yup.string().required(i18n.t('validation.field_required'));
      }
    } else {
      return Yup.string();
    }
  }
};

export const buildFormSchema = (formData): any => {
  if (!formData) {
    return null;
  }

  return Yup.object().shape(
    Object.entries(formData).reduce((finalField, [fieldKey, fieldValue]) => {
      return {
        ...finalField,
        [fieldKey]: buildSingleFieldSchema(fieldValue, fieldKey),
      };
    }, {})
  );
};

export const buildFormRequestData = (data) => {
  let response = new FormData();
  const formData: any = Object.values(data).reduce(
    (total: any, field: any) => ({ ...total, ...field }),
    {}
  );
  Object.keys(formData).forEach((objectName) => {
    let obj = formData[objectName];

    if (typeof obj === 'object') {
      if (Array.isArray(obj)) {
        let isFilesArray = false;
        if (obj.find(() => true) instanceof File) {
          isFilesArray = true;
        }

        obj.forEach((item, index) => {
          if (isFilesArray) {
            if (item.name)
              response.append(
                `${objectName}[files][${index}][file][file]`,
                item
              );
          } else {
            if (typeof item === 'object') {
              Object.keys(item).forEach((itemKey) => {
                response.append(
                  `${objectName}[${index}][${itemKey}]`,
                  item[itemKey]
                );
              });
            } else {
              response.append(`${objectName}[${index}]`, item);
            }
          }
        });
      } else if (obj instanceof File) {
        if (obj.name) response.set(objectName + '[file][file]', obj);
      } else {
        if (obj === null) {
          response.set(objectName, '');
        } else {
          for (let key in obj) {
            const childObj = obj[key];
            if (childObj instanceof File) {
              if (obj.name)
                response.set(`${objectName}[${key}][file][file]`, childObj);
            } else if (Array.isArray(childObj)) {
              let isFilesArray = false;
              if (childObj.find(() => true) instanceof File) {
                isFilesArray = true;
              }
              childObj.forEach((item, index) => {
                if (isFilesArray) {
                  if (item.name)
                    response.set(
                      `${objectName}[${key}][files][${index}][file][file]`,
                      item
                    );
                } else {
                  response.set(`${objectName}[${key}][${index}]`, item);
                }
              });
            } else if (typeof childObj === 'object') {
              // throw "demo";
              for (let subKey in childObj) {
                response.set(
                  `${objectName}[${key}][${subKey}]`,
                  childObj[subKey]
                );
              }
            } else {
              response.set(`${objectName}[${key}]`, childObj);
            }
          }
        }
      }
    } else {
      response.set(objectName, obj);
    }
  });

  return response;
};

export const addToObject = (
  obj: Object,
  key: string,
  value: any,
  index: number
) => {
  // Create a temp object and index variable
  var temp: any = {};
  var i = 0;

  // Loop through the original object
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      //@ts-ignore Add the current item in the loop to the temp obj
      temp[prop] = obj[prop];

      // If the indexes match, add the new item
      if (i === index && key && value) {
        temp[key] = value;
      }

      // Increase the count
      i++;
    }
  }

  return temp;
};

export const getFields = (fields: IForm | null): IForm | null => {
  if (!fields) {
    return null;
  }
  Object.entries(fields).forEach(([fieldName, field]) => {
    field = { ...field };
    let defaultValue: any = null;
    if (textType.includes(field.type)) {
      defaultValue = '';
    } else if (field.type === 'checkbox' || field.type === 'file') {
      defaultValue = [];
    } else if (field.type === 'radioButton') {
      defaultValue = false;
    }
    field.state = {
      error: false,
      errorMessages: [],
      hidden:
        fields[fieldName].attributes?.attr?.hasOwnProperty('dependencies'),
      value: defaultValue,
    };
    if (field.fields) field.fields = getFields(field.fields);
    fields[fieldName] = { ...field };
  });
  return { ...fields };
};

export const duplicateStep = (form: IForm) => {
  let stepKeys = Object.keys(form);
  stepKeys = [stepKeys[stepKeys.length - 2], stepKeys[stepKeys.length - 1]];
  const newStepName =
    stepKeys[1] +
    '_' +
    Math.random().toString(36).substr(2, 9) +
    '_' +
    Math.random().toString(36).substr(2, 9);

  let count =
    Object.values(form).filter(
      (step: IForm) => step.objectName === form[stepKeys[1]].objectName
    ).length + 1;

  form = {
    ...addToObject(
      form,
      newStepName,
      {
        ...form[stepKeys[1]],
        isValidated: false,
        hasError: false,
        count,
        fields: {
          ...getFields({
            ...form[stepKeys[1]].fields,
          }),
        },
      },
      Object.keys(form).findIndex((key) => key === stepKeys[1])
    ),
  };

  return form;
};

export const checkAllRequiredFilesAttached = (values, createClientForms) => {
  const formValue = rebuildClientFormValue(values, createClientForms);
  const fileFormFiled = createClientForms?.propertyFiles;
  const fileFields: any[] = Object.values(fileFormFiled.fields)?.filter(
    (field: any) => field?.type === 'file'
  );
  const property = formValue?.property;

  return fileFields.filter((fieldField) => {
    const attr = fieldField.attributes.attr;
    const name = fieldField.name;
    const dependencies = attr?.dependencies;
    const dependencyOptions = attr?.dependencyOptions;
    let isMissingFile = false;

    if (fieldField.required) {
      isMissingFile =
        !values.propertyFiles[name] ||
        !values.propertyFiles[name]?.files?.length;
    } else if (dependencies) {
      isMissingFile = Object.keys(dependencies).some((key) => {
        const type = dependencies[key].type;
        const validator = dependencies[key].validator;

        if (type === 'array') {
          const isValidated = validator.includes(property[key]);
          if (isValidated && dependencyOptions.setRequired) {
            return (
              !values.propertyFiles[name] ||
              !values.propertyFiles[name]?.files?.length
            );
          } else {
            return false;
          }
        }

        return false;
      });
    }

    return isMissingFile;
  });
};

export const getMissingFileFields = (formValue, fileFormFiled) => {
  const fileFields: any[] = Object.values(fileFormFiled.fields)?.filter(
    (field: any) => field?.type === 'file'
  );
  const property = formValue?.property;

  return fileFields.filter((fieldField) => {
    const attr = fieldField.attributes.attr;
    const name = fieldField.name;
    const dependencies = attr?.dependencies;
    const dependencyOptions = attr?.dependencyOptions;
    let isMissingFile = false;

    if (fieldField.required) {
      isMissingFile = !property[name] || !property[name]?.files?.length;
    } else if (dependencies) {
      isMissingFile = Object.keys(dependencies).some((key) => {
        const type = dependencies[key].type;
        const validator = dependencies[key].validator;

        if (type === 'array') {
          const isValidated = validator.includes(property[key]);
          if (isValidated && dependencyOptions.setRequired) {
            return !property[name] || !property[name]?.files?.length;
          } else {
            return false;
          }
        }

        return false;
      });
    }

    return isMissingFile;
  });
};

export const rebuildClientFormValue: any = (values, createClientForms) => {
  const keys = Object.keys(values);
  let formValue = {};
  for (let key of keys) {
    const objectName = createClientForms[key].objectName;
    if (values[key] instanceof Date) {
      values[key] = moment(values[key].toISOString()).format('YYYY-MM-DD');
    }

    if (createClientForms[key].type === 'array') {
      if (Array.isArray(values[key])) {
        formValue = {
          ...formValue,
          [objectName]: values[key],
        };
      } else {
        formValue = {
          ...formValue,
          [objectName]: [...(formValue[objectName] || []), values[key]],
        };
      }
    } else if (createClientForms[key].type === 'object') {
      if (key === 'propertyFiles') {
        let propertyFiles: any = {};
        Object.keys(values[key]).forEach((subKey) => {
          if (values[key][subKey] && values[key][subKey].files) {
            propertyFiles[subKey] = values[key][subKey].files;
          }
        });
        formValue = {
          ...formValue,
          [objectName]: { ...formValue[objectName], ...propertyFiles },
        };
      } else {
        formValue = {
          ...formValue,
          [objectName]: { ...formValue[objectName], ...values[key] },
        };
      }
    }
  }

  return formValue;
};
