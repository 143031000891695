// Dependencies
import { FC, Fragment, useEffect, useState } from 'react';
import { List, Collapse, useMediaQuery, useTheme, Avatar } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

// Components
import { Icon } from '../../Common';

// Styles
import * as S from './styles';

// Global constants
import { NAV_LINKS, ROUTES } from '../../../constants';

// Hooks
import { logout } from '../../../redux/actions';
import { RootState } from '../../../redux/reducers';
import { isUserAllowed, isUserAllowedByIds } from '../../../utils';

interface IDrawerContentProps {
  isCollapsed?: boolean;
  onCloseMobileDrawer?: () => void;
}

// Export sidebar component
export const DrawerContent: FC<IDrawerContentProps> = ({
  isCollapsed = false,
  onCloseMobileDrawer,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [expandedList, setExpandedList] = useState<string[]>([]);
  const [expandedListTemp, setExpandedListTemp] = useState<string[]>([]);

  // Get user data from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  const handleLogout = async () => {
    const res: any = await dispatch(logout());
    if (res.value.success && res.value.message) {
      enqueueSnackbar(res.value.message, { variant: 'success' });
    }
  };

  const handleToggleExpandMenu = (path) => {
    if (expandedList.includes(path)) {
      setExpandedList(expandedList.filter((item) => item !== path));
    } else {
      setExpandedList([...expandedList, path]);
    }
  };

  const handleCloseMobileDrawer = () => {
    if (isMobile && onCloseMobileDrawer) {
      onCloseMobileDrawer();
    }
  };

  useEffect(() => {
    if (isCollapsed) {
      setExpandedListTemp(expandedList);
      setExpandedList([]);
    } else {
      setExpandedList(expandedListTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  return (
    <>
      <S.Menu>
        {NAV_LINKS.filter(({ role }) =>
          role ? isUserAllowed(account, role.min, role.max) : true
        )
          .filter(({ access }) =>
            access ? isUserAllowedByIds(account, access) : true
          )
          .map(({ label, icon, size, path, children }, index) => (
            <Fragment key={`menu-${index}`}>
              {children && path ? (
                <S.MenuItem
                  onClick={() => handleToggleExpandMenu(path)}
                  $isCollapsed={isCollapsed}
                  className={location.pathname.startsWith(path) && 'active'}
                >
                  <S.MenuItemIcon>
                    <Icon name={icon} size={size} />
                  </S.MenuItemIcon>
                  {!isCollapsed && (
                    <>
                      <S.MenuItemText variant="h4">
                        {t(`sidebar.${label}`)}
                      </S.MenuItemText>
                      <S.ArrowIcon expanded={expandedList.includes(path)}>
                        <Icon name="arrow-down" size={size} />
                      </S.ArrowIcon>
                    </>
                  )}
                </S.MenuItem>
              ) : (
                <S.MenuItem
                  component={NavLink}
                  end
                  to={path}
                  $isCollapsed={isCollapsed}
                  onClick={handleCloseMobileDrawer}
                >
                  <S.MenuItemIcon>
                    <Icon name={icon} size={size} />
                  </S.MenuItemIcon>
                  {!isCollapsed && (
                    <S.MenuItemText variant="h4">
                      {t(`sidebar.${label}`)}
                    </S.MenuItemText>
                  )}
                </S.MenuItem>
              )}
              {children && path && (
                <Collapse
                  in={!isCollapsed && expandedList.includes(path)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {children.map((subMenu, subIndex) => (
                      <S.SubMenuItem
                        key={`submenu-${subIndex}`}
                        component={NavLink}
                        to={subMenu.path}
                        onClick={handleCloseMobileDrawer}
                      >
                        <S.MenuItemText variant="h4">
                          {t(`sidebar.${subMenu.label}`)}
                        </S.MenuItemText>
                      </S.SubMenuItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </Fragment>
          ))}
      </S.Menu>
      <S.BottomMenu>
        {/* @ts-ignore */}
        <S.MenuItem
          component={NavLink}
          to={ROUTES.PROFILE}
          sx={{
            minHeight: '68px !important',
            p: '10px !important',
            height: 'auto !important',
          }}
        >
          <Avatar variant="circular" src={account?.image?.path} alt="avatar" />
          {!isCollapsed && (
            <S.ProfileItemText
              primary={
                <>
                  {account?.first_name}
                  <br />
                  {account?.last_name}
                </>
              }
              secondary={account?.company}
            />
          )}
        </S.MenuItem>
        <S.MenuItem onClick={handleLogout}>
          <S.MenuItemIcon>
            <Icon name="box-arrow-right" />
          </S.MenuItemIcon>
          {!isCollapsed && (
            <S.MenuItemText
              variant="h3"
              sx={(theme) => ({ color: theme.palette.primary.main })}
            >
              {t('sidebar.logout')}
            </S.MenuItemText>
          )}
        </S.MenuItem>
      </S.BottomMenu>
    </>
  );
};
