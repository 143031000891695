// Dependencies
import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { flatMap } from 'lodash';

// Components
import { Icon } from '../../Common';
import { DrawerContent } from '../DrawerContent';
import {
  ChatsModal,
  CreateNewClientModal,
  CustomizeDashboardModal,
} from '../../Modals';
import SearchPanel from './SearchPanel';

// Actions
import { getCreateClientForms } from '../../../redux/actions';

// Styles
import * as S from './styles';

// Constants
import { NAV_LINKS, ROUTES } from '../../../constants';

// Interfaces
import { RootState } from '../../../redux/reducers';

// Export header component
export const Header: FC = () => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get state from hook
  const { pathname } = useLocation();

  // Get user data from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // States
  const [openMobileDrawer, setOpenMobileDrawer] = useState<boolean>(false);
  const [openCustomizeDashboard, setOpenCustomizeDashboard] =
    useState<boolean>(false);
  const [openCreateClientModal, setOpenCreateClientModal] =
    useState<boolean>(false);
  const [openChatsModal, setOpenChatsModal] = useState<boolean>(false);
  const [visibleSearchBar, setVisibleSearchBar] = useState<boolean>(false);
  const [visibleLargeSearchBar, setVisibleLargeSearchBar] =
    useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [searchAnchorEl, setSearchAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [searchFocused, setSearchFocused] = useState<boolean>(false);

  const headerLabel =
    useMemo(() => {
      let resultLabel = (
        <>
          MEINE WOHNRENTE{' '}
          <Box component="span" fontWeight={500} mx={8}>
            MAKLER
          </Box>{' '}
          <Box component="span" fontWeight={500} color="red">
            PORTAL
          </Box>
        </>
      );

      if (pathname === ROUTES.DASHBOARD) {
        return resultLabel;
      }

      const label = flatMap(NAV_LINKS, (link) =>
        link.children ? link.children : link
      ).find(
        (link) => link.path !== '/' && pathname.startsWith(link.path)
      )?.label;

      if (label) resultLabel = t(`sidebar.${label}`);

      return resultLabel;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, account]) || '';

  useEffect(() => {
    dispatch(getCreateClientForms());
  }, [dispatch]);

  const handleCloseCustomizeDashboard = () => {
    setOpenCustomizeDashboard(false);
  };

  const handleOpenCustomizeDashboard = () => {
    setOpenCustomizeDashboard(true);
  };

  const handleOpenCreateClientModal = () => {
    setOpenCreateClientModal(true);
  };

  const handleCloseCreateClientModal = () => {
    setOpenCreateClientModal(false);
  };

  const handleOpenChatsModal = () => {
    setOpenChatsModal(true);
  };

  const handleCloseChatsModal = () => {
    setOpenChatsModal(false);
  };

  const handleSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(event.target.value);
  };

  const handleClearSearch = () => {
    setSearch('');
  };

  const handleOpenSearchPanel = (event: FocusEvent<HTMLInputElement>) => {
    setSearchFocused(true);
    setSearchAnchorEl(event.currentTarget?.parentElement);
  };

  const handleBlurSearch = () => {
    setSearchFocused(false);
  };

  const handleCloseSearchPanel = () => {
    if (!searchFocused) {
      setSearchAnchorEl(null);
    }
  };

  const handleEscKeyEvent = (event) => {
    if (event.key === 'Escape') {
      setSearchAnchorEl(null);
    }
  };

  const handleOpenSearchBar = () => {
    setVisibleSearchBar(true);
  };

  const handleCloseSearchBar = () => {
    setVisibleSearchBar(false);
    setSearchAnchorEl(null);
  };

  const handleToggleLargeSearchBar = () => {
    setVisibleLargeSearchBar(!visibleLargeSearchBar);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscKeyEvent, false);

    return () =>
      document.removeEventListener('keydown', handleEscKeyEvent, false);
  }, []);

  // Return header component
  return (
    <S.Header>
      <S.DesktopHeader sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: { md: 20, lg: 26, xl: 30 },
            display: {
              md: visibleLargeSearchBar ? 'none' : 'flex',
              lg: visibleLargeSearchBar ? 'none' : 'flex',
              xl: 'flex',
            },
          }}
        >
          {headerLabel}
        </Typography>
        <Stack
          direction="row"
          spacing={16}
          ml="auto"
          flexGrow={1}
          justifyContent="flex-end"
        >
          <S.SearchInput
            data-tour="tour-item-1"
            size="large"
            startAdornment={<Icon name="search" size={23} />}
            endAdornment={
              search ? (
                <IconButton
                  sx={{ height: 20, width: 20 }}
                  size="small"
                  style={{ marginRight: '15px' }}
                  onClick={handleClearSearch}
                >
                  <Icon name="x-lg" size={16} />
                </IconButton>
              ) : (
                <Box sx={{ height: 20, width: 20 }} />
              )
            }
            value={search}
            placeholder={t('header.search')}
            sx={{
              flexGrow: { md: 1, lg: 1, xl: 0 },
              width: { md: 'auto', lg: 'auto', xl: 370 },
              display: visibleLargeSearchBar
                ? 'flex'
                : { md: 'none', lg: 'none', xl: 'flex' },
            }}
            onChange={handleSearch}
            onFocus={handleOpenSearchPanel}
            onBlur={handleBlurSearch}
          />
          <IconButton
            sx={{ display: { lg: 'flex', xl: 'none' } }}
            size="large"
            color={visibleLargeSearchBar ? 'default' : 'primary'}
            onClick={handleToggleLargeSearchBar}
          >
            <Icon name={visibleLargeSearchBar ? 'x-lg' : 'search'} />
          </IconButton>
          <IconButton
            data-tour="tour-item-4"
            size="large"
            color="primary"
            onClick={handleOpenCustomizeDashboard}
          >
            <Icon name="checks-grid" />
          </IconButton>
          <IconButton
            data-tour="tour-item-2"
            size="large"
            color="primary"
            onClick={handleOpenChatsModal}
          >
            <Icon name="message" />
          </IconButton>
          <IconButton
            size="large"
            color="secondary"
            sx={{ display: { md: 'flex', lg: 'none' } }}
            onClick={handleOpenCreateClientModal}
          >
            <Icon name="plus-lg" />
          </IconButton>
          <Button
            data-tour="tour-item-3"
            sx={{ display: { md: 'none', lg: 'flex' } }}
            startIcon={<Icon name="plus-lg" />}
            size="large"
            color="primary"
            onClick={handleOpenCreateClientModal}
          >
            {t('header.new_client')}
          </Button>
        </Stack>
      </S.DesktopHeader>
      <S.MobileHeader sx={{ display: { sm: 'block', md: 'none' } }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 0 }}
        >
          {visibleSearchBar ? (
            <Stack width="100%" direction="row" spacing={16}>
              <S.SearchInput
                data-tour-sm="tour-item-1"
                size="small"
                startAdornment={<Icon name="search" size={23} />}
                endAdornment={
                  search ? (
                    <IconButton
                      sx={{ height: 20, width: 20 }}
                      size="small"
                      onClick={handleClearSearch}
                    >
                      <Icon name="x-lg" size={16} />
                    </IconButton>
                  ) : (
                    <Box sx={{ height: 20, width: 20 }} />
                  )
                }
                placeholder={t('header.search')}
                sx={{ flex: 1 }}
                onChange={handleSearch}
                onFocus={handleOpenSearchPanel}
                onBlur={handleBlurSearch}
              />
              <Button
                variant="text"
                color="primary"
                onClick={handleCloseSearchBar}
              >
                {t('header.cancel')}
              </Button>
            </Stack>
          ) : (
            <>
              <S.Brand>
                <IconButton
                  color="default"
                  onClick={() => setOpenMobileDrawer(!openMobileDrawer)}
                >
                  <Icon size={14} name={!openMobileDrawer ? 'menu' : 'x-lg'} />
                </IconButton>
                <S.Logo src="/assets/images/logo.svg" alt="logo" />
              </S.Brand>
              {!openMobileDrawer && (
                <Stack direction="row" spacing={{ sm: 16, xs: 8 }}>
                  <IconButton
                    color="primary"
                    onClick={handleOpenSearchBar}
                    data-tour-sm="tour-item-1"
                  >
                    <Icon name="search" />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={handleOpenCustomizeDashboard}
                    data-tour-sm="tour-item-4"
                  >
                    <Icon name="checks-grid" />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={handleOpenChatsModal}
                    data-tour-sm="tour-item-2"
                  >
                    <Icon name="message" />
                  </IconButton>
                  <S.DarkIconButton
                    onClick={handleOpenCreateClientModal}
                    data-tour-sm="tour-item-3"
                  >
                    <Icon name="plus-lg" color="secondary" />
                  </S.DarkIconButton>
                </Stack>
              )}
            </>
          )}
        </Stack>
        {!openMobileDrawer && (
          <Typography
            variant="h1"
            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
          >
            {headerLabel}
          </Typography>
        )}
        <S.MobileDrawer
          sx={{ display: { sm: 'block', md: 'none' } }}
          anchor="top"
          open={openMobileDrawer}
          onClose={() => setOpenMobileDrawer(false)}
        >
          <DrawerContent
            onCloseMobileDrawer={() => setOpenMobileDrawer(false)}
          />
        </S.MobileDrawer>
      </S.MobileHeader>
      {openCustomizeDashboard && (
        <CustomizeDashboardModal
          open={openCustomizeDashboard}
          onClose={handleCloseCustomizeDashboard}
        />
      )}
      {openCreateClientModal && (
        <CreateNewClientModal
          open={openCreateClientModal}
          onClose={handleCloseCreateClientModal}
        />
      )}
      {openChatsModal && (
        <ChatsModal open={openChatsModal} onClose={handleCloseChatsModal} />
      )}
      {searchAnchorEl && (
        <SearchPanel
          search={search}
          anchorEl={searchAnchorEl}
          onClose={handleCloseSearchPanel}
        />
      )}
    </S.Header>
  );
};
