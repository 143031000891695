import { ThunkDispatch } from 'redux-thunk';

import { ApiTypes } from '../../interfaces';
import { LEAD_ACTIONS } from '../action-types';
import { RootState } from '../store';

export const getLeads =
  (params) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getLeads = async () => {
      const res: any = await api.lead.getLeads(params);

      return {
        leads: res.data?.data || [],
        pagination: res.data?.pageInfo,
      };
    };

    return dispatch({
      type: LEAD_ACTIONS.GET_LEADS,
      payload: getLeads(),
    });
  };

export const getLeadDetail =
  (id: number) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    return dispatch({
      type: LEAD_ACTIONS.GET_LEAD_DETAIL,
      payload: api.lead.getLeadDetail(id),
    });
  };

export const setLeadDetail = (lead) => async (dispatch: ThunkDispatch<any, any, any>,) => {
  return dispatch({
    type: LEAD_ACTIONS.GET_LEAD_DETAIL_SUCCESS,
    payload: lead,
  });
};
