import { PROJECT_ACTIONS } from '../action-types';
import { IProjectData } from '../../interfaces';
import { IPagination } from '../../interfaces';

export interface ProjectReducerState {
  projects: {
    inProgress?: IProjectData[];
    declined?: IProjectData[];
    bought?: IProjectData[];
    sold?: IProjectData[];
    new?: IProjectData[];
    all?: IProjectData[];
    chat?: IProjectData[];
    pagination?: IPagination;
  };
  totalCustomerData: {
    this_year: any[];
    last_year: any[];
  };
  newCustomerData: {
    this_month: any[];
    last_month: any[];
  };
  shouldRefetch: any | null;
  loading: boolean;
  personalDataForm: any;
  modernizationsForm: any;
  objectPropertiesForm: any;
  registrationForm: any;
  formLoading: boolean;
  projectDetail: IProjectData | null;
  detailLoading: boolean;
  yearAnaliseLoading: boolean;
  monthAnaliseLoading: boolean;
  calculateLoading: boolean;
}

const initialState: ProjectReducerState = {
  projects: {},
  shouldRefetch: null,
  loading: false,
  personalDataForm: {},
  modernizationsForm: {},
  objectPropertiesForm: {},
  registrationForm: {},
  totalCustomerData: {
    this_year: [],
    last_year: [],
  },
  newCustomerData: {
    this_month: [],
    last_month: [],
  },
  formLoading: false,
  projectDetail: null,
  detailLoading: false,
  yearAnaliseLoading: false,
  monthAnaliseLoading: false,
  calculateLoading: false,
};

const ProjectReducer = (
  state: ProjectReducerState = initialState,
  action: any
) => {
  const { type, payload } = action;

  switch (type) {
    case PROJECT_ACTIONS.REFETCH_PROJECTS_START:
      return {
        ...state,
        shouldRefetch: { ...payload },
      };

    case PROJECT_ACTIONS.GET_PROJECTS_START:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_ACTIONS.GET_PROJECTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.GET_PROJECTS_SUCCESS: {
      return {
        ...state,
        projects: { ...state.projects, ...payload },
        loading: false,
      };
    }

    case PROJECT_ACTIONS.DELETE_PROJECT_START:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_ACTIONS.DELETE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.GET_PERSONAL_DATA_FORM_START:
      return {
        ...state,
        formLoading: true,
      };

    case PROJECT_ACTIONS.GET_PERSONAL_DATA_FORM_ERROR:
      return {
        ...state,
        formLoading: false,
      };

    case PROJECT_ACTIONS.GET_PERSONAL_DATA_FORM_SUCCESS:
      return {
        ...state,
        formLoading: false,
        personalDataForm: payload,
      };

    case PROJECT_ACTIONS.GET_OBJECT_PROPERTIES_FORM_START:
      return {
        ...state,
        formLoading: true,
      };

    case PROJECT_ACTIONS.GET_OBJECT_PROPERTIES_FORM_ERROR:
      return {
        ...state,
        formLoading: false,
      };

    case PROJECT_ACTIONS.GET_OBJECT_PROPERTIES_FORM_SUCCESS:
      return {
        ...state,
        formLoading: false,
        objectPropertiesForm: payload,
      };

    case PROJECT_ACTIONS.GET_MODERNIZATIONS_FORM_START:
      return {
        ...state,
        formLoading: true,
      };

    case PROJECT_ACTIONS.GET_MODERNIZATIONS_FORM_ERROR:
      return {
        ...state,
        formLoading: false,
      };

    case PROJECT_ACTIONS.GET_MODERNIZATIONS_FORM_SUCCESS:
      return {
        ...state,
        formLoading: false,
        modernizationsForm: payload,
      };

    case PROJECT_ACTIONS.UPDATE_CUSTOMER_DATA_START:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_ACTIONS.UPDATE_CUSTOMER_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.UPDATE_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.UPDATE_PROPERTY_DATA_START:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_ACTIONS.UPDATE_PROPERTY_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.UPDATE_PROPERTY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.UPDATE_PREFERRED_CALCULATION_DATA_START:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_ACTIONS.UPDATE_PREFERRED_CALCULATION_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.UPDATE_PREFERRED_CALCULATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.GET_PROJECT_DETAIL_START:
      return {
        ...state,
        detailLoading: true,
      };

    case PROJECT_ACTIONS.GET_PROJECT_DETAIL_ERROR:
      return {
        ...state,
        detailLoading: false,
        projectDetail: {},
      };

    case PROJECT_ACTIONS.GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        projectDetail: payload,
      };

    case PROJECT_ACTIONS.GET_REGISTRATION_FORM_START:
      return {
        ...state,
        formLoading: true,
      };

    case PROJECT_ACTIONS.GET_REGISTRATION_FORM_ERROR:
      return {
        ...state,
        formLoading: false,
      };

    case PROJECT_ACTIONS.GET_REGISTRATION_FORM_SUCCESS:
      return {
        ...state,
        formLoading: false,
        registrationForm: payload,
      };

    case PROJECT_ACTIONS.GET_YEAR_ANALYSIS_DATA_START:
      return {
        ...state,
        yearAnaliseLoading: true,
      };

    case PROJECT_ACTIONS.GET_YEAR_ANALYSIS_DATA_ERROR:
      return {
        ...state,
        yearAnaliseLoading: false,
      };

    case PROJECT_ACTIONS.GET_YEAR_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        yearAnaliseLoading: false,
        totalCustomerData: {
          ...state.totalCustomerData,
          [payload.type]: payload.data,
        },
      };

    case PROJECT_ACTIONS.GET_MONTH_ANALYSIS_DATA_START:
      return {
        ...state,
        monthAnaliseLoading: true,
      };

    case PROJECT_ACTIONS.GET_MONTH_ANALYSIS_DATA_ERROR:
      return {
        ...state,
        monthAnaliseLoading: false,
      };

    case PROJECT_ACTIONS.GET_MONTH_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        monthAnaliseLoading: false,
        newCustomerData: {
          ...state.newCustomerData,
          [payload.type]: payload.data,
        },
      };

    case PROJECT_ACTIONS.CALCULATE_PAYOUT_START:
      return {
        ...state,
        calculateLoading: true,
      };

    case PROJECT_ACTIONS.CALCULATE_PAYOUT_ERROR:
      return {
        ...state,
        calculateLoading: false,
      };

    case PROJECT_ACTIONS.CALCULATE_PAYOUT_SUCCESS:
      return {
        ...state,
        calculateLoading: false,
      };

    case PROJECT_ACTIONS.UPDATE_CALCULATION_START:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_ACTIONS.UPDATE_CALCULATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.UPDATE_CALCULATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.DELETE_CALCULATION_START:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_ACTIONS.DELETE_CALCULATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.DELETE_CALCULATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.ADD_PAYOUT_START:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_ACTIONS.ADD_PAYOUT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.ADD_PAYOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.DELETE_PAYOUT_START:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_ACTIONS.DELETE_PAYOUT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_ACTIONS.DELETE_PAYOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default ProjectReducer;
