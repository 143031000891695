// Dependencies
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import GridLayout from 'react-grid-layout';

// Components
import {
  CloseConfirmModal,
  CreateNewsModal,
  WelcomeModal,
  Tours,
} from '../../components';

// Store
import { RootState } from '../../redux/reducers';
// Styles
import * as S from './styles';

// Hooks
import { useLayoutMap } from '../../hooks';

const ROW_HEIGHTS = { xl: 500, lg: 500, md: 480, sm: 302 };
const COLS = { xl: 2, lg: 1, md: 1, sm: 1 };

// Export dashboard page
export const DashboardPage: FC = () => {
  // States
  const [visibleCreateNewsModal, setVisibleCreateNewModal] =
    useState<boolean>(false);
  const [visibleCloseConfirmModal, setVisibleCloseConfirmModal] =
    useState<boolean>(false);

  // Welcome Tours
  const [welcomeTourClosed, setWelcomeTourClosed] = useState<boolean>(false);
  const [visibleWelcomeModal, setVisibleWelcomeModal] = useState<boolean>(true);
  const [isTour, setIsTour] = useState<boolean>(false);

  const [layoutWidth, setLayoutWidth] = useState<number>(0);
  const [dialogLayouts, setDialogLayouts] = useState({});

  // Get layout states from store
  const { layouts, isDrawerOpened, breakpoint } = useSelector(
    ({ uiReducer: { layouts, isDrawerOpened, breakpoint } }: RootState) => ({
      layouts,
      isDrawerOpened,
      breakpoint,
    })
  );

  const contentRef = useRef<HTMLDivElement>(null);

  const currentLayout = useMemo(
    () => layouts && layouts[breakpoint],
    [breakpoint, layouts]
  );

  const LAYOUT_MAP = useLayoutMap();

  // Close create news modal handler
  const handleCloseCreateNewsModal = () => {
    setVisibleCloseConfirmModal(true);
  };
  // Close close confirm modal handler
  const handleCloseConfirmModal = () => {
    setVisibleCloseConfirmModal(false);
    setVisibleCreateNewModal(false);
  };

  // Cancel confirm modal handler
  const handleCancelConfirmModal = () => {
    setVisibleCloseConfirmModal(false);
  };

  // Close welcome modal handler
  const handleCloseWelcomeModal = () => {
    setVisibleWelcomeModal(false);
  };

  // Close welcome tours
  const handleCloseWelcomeTour = () => {
    setIsTour(false);
  };
  // Open welcome tours
  const handleOpenWelcomeTour = () => {
    setIsTour(true);
  };

  // On layouts and breakpoint changed
  useEffect(() => {
    setDialogLayouts(layouts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layouts, breakpoint]);

  // On mounted
  useEffect(() => {
    setLayoutWidth(contentRef.current?.clientWidth || 0);

    window.onresize = () => {
      setLayoutWidth(contentRef.current?.clientWidth || 0);
    };

    return () => {
      window.onresize = null;
    };
  }, []);

  useEffect(() => {
    setLayoutWidth(contentRef.current?.clientWidth || 0);
  }, [isDrawerOpened, contentRef.current?.clientWidth]);

  useEffect(() => {
    setWelcomeTourClosed(localStorage.getItem('welcomeTourClosed') === 'true');
  }, []);

  // Return dashboard page
  return (
    <S.Dashboard ref={contentRef}>
      <GridLayout
        isDraggable={false}
        isResizable={false}
        layout={dialogLayouts[breakpoint]}
        containerPadding={[0, 0]}
        margin={[28, 28]}
        cols={COLS[breakpoint]}
        rowHeight={ROW_HEIGHTS[breakpoint]}
        width={layoutWidth}
      >
        {currentLayout?.map((item: any) => {
          const layoutItem = LAYOUT_MAP.find(
            (gridItem) => gridItem.i === item.i
          );
          const Widget: FC | undefined = layoutItem?.component;

          return Widget && item?.visible ? (
            <div key={item.i}>
              <Widget />
            </div>
          ) : null;
        })}
      </GridLayout>

      {/* Open Welcome Modal Window */}
      {/* <WelcomeModal
        open={visibleWelcomeModal && !welcomeTourClosed}
        onClose={handleCloseWelcomeModal}
        onOpenTour={handleOpenWelcomeTour}
      /> */}

      {/* Turn on Tours */}
      {isTour && <Tours onClose={handleCloseWelcomeTour} />}
      <CreateNewsModal
        open={visibleCreateNewsModal}
        onClose={handleCloseCreateNewsModal}
      />
      <CloseConfirmModal
        open={visibleCloseConfirmModal}
        onClose={handleCloseConfirmModal}
        onCancel={handleCancelConfirmModal}
      />
    </S.Dashboard>
  );
};
