// Dependencies
import { Box, Button, Grid, Stack } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { AddPayoutModal, Typography } from '../../../../../../../components';
import PayoutVariantCard from './Card';

// Interfaces
import {
  ICalcType,
  IOfferCalculated,
  IOfferCalculation,
} from '../../../../../../../interfaces';
import { RootState } from '../../../../../../../redux/reducers';

// Utils
import {
  cleanSprengnetterValue,
  getCurrency,
  getPercent,
  isUserAllowed,
} from '../../../../../../../utils';

// Constants
import {
  CALCULATION_OPTIONS,
  isStatusAllowed,
  LABEL_MAPPING,
  Role,
  Status,
} from '../../../../../../../constants';

// Actions
import { Moment } from 'moment';
import { getProjectDetail } from '../../../../../../../redux/actions';
// Styles
import moment from 'moment';
import * as S from './styles';

// Create payout variant section
const PayoutVariantSection: FC = () => {
  // States
  const [visibleAddPayoutModal, setVisibleAddPayoutModal] =
    useState<boolean>(false);
  const [visibleOfferId, setVisibleOfferId] = useState<number>();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get translation from hook
  const { t } = useTranslation();

  // Get project detail from store
  const projectDetail = useSelector(
    ({ projectReducer: { projectDetail } }: RootState) => projectDetail
  );

  // Get user data from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  const BeforeComparison = (firstDate?: Moment, secondDate?: Moment) => {
    if (firstDate === undefined || secondDate === undefined) return -1;

    if (firstDate.isBefore(secondDate)) {
      return 1;
    }
    return -1;
  };
  // Get payout variant
  const payoutVariants = useMemo(() => {
    let variants: any[] = [];
    let itemLabels = {
      annuity_amount_lifelong_payment: 'Wunschrente',
      annuity_amount_time_rent: 'Wunschrente',
      annuity_amount_lifelong_payment_with_one_time_payment: 'Ihre Wunschrente',
      annuity_amount_time_rent_with_one_time_payment: 'Ihre Wunschrente',
      amount_one_time_payment: 'Wunschbetrag',
      amount_monthly_payment_with_one_time_payment: 'Wunschbetrag',
      amount_lifelong_payment_with_one_time_payment: 'Wunschbetrag',
      amount_time_rent_with_one_time_payment_payment_time: 'Wunschbetrag',
      time_rent_with_one_time_payment_payment_time: 'Zeitrente in Jahre',
      time_rent_payment_time: 'Zeitrente in Jahre',
    };

    const customer = projectDetail?.customer;
    const sprengnetter = projectDetail?.sprengnetter;

    projectDetail?.payout_variants.forEach((payoutVariant) => {
      let items: any[] = [];
      let calculations: any[] = [];
      let offers: any = [];
      let amount = 0;

      calculations = [
        ...calculations,
        ...payoutVariant.calculations.map((calculation) => {
          calculation.payoutVariant = payoutVariant;
          return calculation;
        }),
      ];
      if (
        isStatusAllowed(
          projectDetail?.status as string,
          Status.approvalOfNonBindingOfferByCustomer
        ) ||
        isUserAllowed(account, Role.ROLE_ADMIN)
      ) {
        calculations.forEach((calculation: IOfferCalculation) => {
          if (
            !calculation.hasOwnProperty('offerCalculated') ||
            calculation.offerCalculated?.binding ||
            !calculation.offerCalculated?.applied
          ) {
            return;
          }

          amount++;
          let items: any = [];
          let calculated: IOfferCalculated = {} as IOfferCalculated;
          let firstBorderFound: boolean = false;
          let border: number = 0;
          if (calculation?.offerCalculated) {
            calculated = calculation?.offerCalculated;
          }
          if (calculated) {
            items.push({
              label: 'Wert der Immobilie',
              value: `${getCurrency(calculation.propertyValue)} €`,
              createdAt: calculation.offerCalculated.created,
            });

            for (let propName in LABEL_MAPPING) {
              let label = '';
              if (
                calculated.hasOwnProperty(propName) ||
                LABEL_MAPPING[propName]?.alwaysShow
              ) {
                const mapping: {
                  label: string | Function;
                  border: number;
                  isSum?: boolean;
                  isCurrency: boolean;
                  isPercent: boolean;
                  role?: Role;
                  hide?: boolean | Function;
                  alwaysShow?: boolean;
                } = LABEL_MAPPING[propName];
                if (typeof mapping.label === 'function') {
                  label = mapping.label(calculation);
                } else {
                  label = mapping.label;
                }
                if (typeof mapping.hide === 'function' && mapping.hide) {
                  if (mapping.hide(calculation)) {
                    continue;
                  }
                } else if (typeof mapping.hide === 'boolean' && mapping.hide) {
                  continue;
                }

                let value: string = calculated[propName]?.toString();
                if (mapping.isCurrency) {
                  if (value) {
                    if (value.indexOf('.') !== -1) {
                      value = getCurrency(Math.round(parseFloat(value)));
                    } else {
                      value = getCurrency(value);
                    }
                  } else {
                    value = '0';
                  }
                }
                if (mapping.isPercent) {
                  if (value) {
                    value = getPercent(value, 1);
                  }
                }

                if (
                  (value !== '0' ||
                    mapping.alwaysShow ||
                    (calculation.calcType !== ICalcType.oneTimePayment &&
                      propName.toLowerCase().includes('annuity')) ||
                    (calculation.calcType === ICalcType.oneTimePayment &&
                      propName.toLowerCase().includes('onetimepayment'))) &&
                  isUserAllowed(
                    account,
                    mapping.role ? mapping.role : Role.ROLE_USER
                  )
                ) {
                  if (mapping.isCurrency) {
                    value += ' €';
                  }
                  if (mapping.isPercent) {
                    value += ' %';
                  }
                  border = mapping?.border;
                  if (firstBorderFound) {
                    if (border === 2) {
                      border = 1;
                    }
                  } else if (border === 2) {
                    firstBorderFound = true;
                  }

                  items.push({
                    label,
                    value,
                    border,
                    isSum: mapping?.isSum,
                    createdAt: calculation.offerCalculated.created,
                  });
                }
              }
            }

            let optionName =
              Object.keys(CALCULATION_OPTIONS).find((key) => {
                if (calculation.payoutVariant?.hasOwnProperty(key)) {
                  return calculation.payoutVariant[key] === true;
                }
                return false;
              }) || 'lifelong_payment';

            let optionType: {
              title: string;
              color: string;
              options: Array<string>;
              optionForTitle?: string;
            } = CALCULATION_OPTIONS[optionName];
            let title = optionType.title;
            let color = optionType.color || '#bbdcd0';
            if (optionType.optionForTitle) {
              title += optionType.optionForTitle.replace(
                '%rentTime%',
                calculation.rentTime
              );
            }

            offers.push({
              title,
              color,
              items,
              amount,
              calculated,
              calculation,
              isDelete: isUserAllowed(account, Role.ROLE_ADMIN),
              isDownload:
                isStatusAllowed(
                  projectDetail?.status as string,
                  null,
                  Status.approvalOfBindingOfferByCustomer
                ) && isUserAllowed(account, Role.ROLE_ADMIN),
              visibleInformation: true,
            });
          }
        });
      }

      let calcObj: IOfferCalculation = {
        payoutVariant: payoutVariant.id,
        birthDate: customer?.calculation_birth,
        gender: customer?.calculation_gender,
        livingSpace: projectDetail?.property.living_space,
        propertyConstructionYear: projectDetail?.property.year_of_construction,
        propertyValue: cleanSprengnetterValue(
          sprengnetter?.market_value || '0'
        ),
        rent: cleanSprengnetterValue(
          sprengnetter?.estimated_rental_value_sqm || '0'
        ),
        garageCount: projectDetail?.property.garage_amount,
        interestRate: 0,
        initialYield: 0,
        oneTimePayment: 0,
        remainingLife: '',
        rentTime: 0,
        remainingDept: projectDetail?.property.mortgage_amount ?? 0,
        offerCalculated: projectDetail?.accepted_calculation,
      };
      let optionName =
        Object.keys(CALCULATION_OPTIONS).find((key) => {
          if (payoutVariant.hasOwnProperty(key)) {
            //@ts-ignore
            return payoutVariant[key] === true;
          }
          return false;
        }) || 'lifelong_payment';

      let optionType: { title: string; options: Array<string> } =
        CALCULATION_OPTIONS[optionName];
      let optionKeys: Array<string> = optionType.options;
      let title = optionType.title;

      optionKeys.forEach((optionKey) => {
        if (payoutVariant.hasOwnProperty(optionKey)) {
          if (itemLabels.hasOwnProperty(optionKey)) {
            //@ts-ignore
            const value = payoutVariant[optionKey];

            //@ts-ignore
            let label = itemLabels[optionKey];
            if (
              [
                'amount_one_time_payment',
                'amount_time_rent_with_one_time_payment_payment_time',
                'amount_lifelong_payment_with_one_time_payment',
              ].includes(optionKey)
            ) {
              calcObj.oneTimePayment = value;
            }
            if (
              [
                'time_rent_payment_time',
                'time_rent_with_one_time_payment_payment_time',
              ].includes(optionKey)
            ) {
              calcObj.rentTime = value;
            }

            if (
              [
                'monthly_payment_time',
                'monthly_payment_time_with_one_time_payment',
              ].includes(optionKey)
            ) {
              calcObj.rentTime = value;
            }

            let renderValue;
            if (value) {
              renderValue = getCurrency(value.toString());
              if (value.toString().length > 2) {
                renderValue = renderValue + ' €';
              }
            } else {
              renderValue = '0';
            }

            items.push({
              label,
              value: renderValue,
              createdAt: calcObj?.offerCalculated?.created,
            });
          }
        }
      });

      let calcType = ICalcType.liveLong;
      if (payoutVariant.one_time_payment) {
        calcObj.oneTimePayment = 0;
        calcType = ICalcType.oneTimePayment;
      } else if (payoutVariant.time_rent) {
        calcType = ICalcType.timeRent;
      } else if (payoutVariant.time_rent_with_one_time_payment) {
        calcType = ICalcType.timeRentWithOneTimePayment;
      }

      const deleteAllowed =
        isStatusAllowed(
          projectDetail?.status,
          //TODO: check if this is correct
          null,
          Status.approvalOfBindingOfferByCustomer
        ) && isUserAllowed(account, Role.ROLE_ADMIN);

      const calculateAllowed =
        isStatusAllowed(
          projectDetail?.status,
          null,
          Status.approvalOfBindingOfferByCustomer
        ) && isUserAllowed(account, Role.ROLE_ADMIN);

      offers.sort((a: IOfferCalculated, b: IOfferCalculated) => {
        return BeforeComparison(moment(a?.created), moment(b?.created));
      });
      if (offers.length > 0)
        offers.forEach((offer: IOfferCalculated) => {
          variants.push({
            id: payoutVariant.id,
            title,
            items,
            calcObj,
            calcType,
            deleteAllowed,
            calculateAllowed,
            offers,
            offer,
          });
        });
      else
        variants.push({
          id: payoutVariant.id,
          title,
          items,
          calcObj,
          calcType,
          deleteAllowed,
          calculateAllowed,
          offers,
        });
    });

    variants = variants?.sort((a, b) => {
      return BeforeComparison(
        moment(a?.offer?.calculated?.created),
        moment(b?.offer?.calculated?.created)
      );
    });

    return variants;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail]);

  // Check add permission
  const addAllowed = useMemo(() => {
    if (projectDetail) {
      return isStatusAllowed(
        projectDetail?.status,
        null,
        Status.approvalOfBindingOfferByCustomer
      );
    } else {
      return false;
    }
  }, [projectDetail]);

  // Open add payout modal handler
  const handleOpenAddPayoutModal = () => {
    setVisibleAddPayoutModal(true);
  };

  // Close add payout modal handler
  const handleCloseAddPayoutModal = async (submitted?: boolean) => {
    setVisibleAddPayoutModal(false);

    if (submitted) {
      await dispatch(getProjectDetail(projectDetail?.id as number));
    }
  };

  // Toggle visible offer card handler
  const handleToggleVisible = (id: number) => {
    if (visibleOfferId === id) {
      setVisibleOfferId(undefined);
    } else {
      setVisibleOfferId(id);
    }
  };

  // Constants
  let cardIndex = 0;

  // On payout variant changed
  useEffect(() => {
    setVisibleOfferId(undefined);
  }, [payoutVariants]);

  // Return payout variant section
  return (
    <>
      <Box>
        <Stack
          mb={20}
          spacing={12}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
        >
          <Typography variant="h4" color="lightIndigo">
            {t('project_detail.calculate_payout_variant')} &{' '}
            {t('project_detail.indicative_offers')}
          </Typography>
          {addAllowed && (
            <Button
              variant="text"
              sx={{ px: 0, textDecoration: 'underline !important' }}
              onClick={handleOpenAddPayoutModal}
            >
              + {t('project_detail.add_pay_out_variant')}
            </Button>
          )}
        </Stack>
        <S.Cards>
          <Grid container columns={12} spacing={{ xs: 0, sm: 20 }}>
            {payoutVariants.map((variant) => {
              cardIndex++;
              return (
                <Grid item xs={12} md={6} xl={4}>
                  <PayoutVariantCard
                    {...variant}
                    offer={variant?.offer ? variant?.offer : variant?.offers}
                    index={cardIndex}
                    visibleOffer={visibleOfferId === cardIndex}
                    onToggleVisible={handleToggleVisible}
                  />
                </Grid>
              );
            })}
          </Grid>
        </S.Cards>
      </Box>
      {visibleAddPayoutModal && (
        <AddPayoutModal
          open={visibleAddPayoutModal}
          onClose={handleCloseAddPayoutModal}
        />
      )}
    </>
  );
};

// Export payout variant section
export default PayoutVariantSection;
