// Dependencies
import styled from 'styled-components';
import { Box, Tab as MuiTab, Typography } from '@mui/material';

// Export styled components
export const Page = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.down('md')} {
    height: auto;
  }
`;

export const Tab = styled(MuiTab)`
  && {
    ${(props) => props.theme.typography.h2};
    color: ${(props) => props.theme.palette.middleCyan};

    &.Mui-selected {
      color: ${(props) => props.theme.palette.lightIndigo};
    }
  }
`;

export const ChartInfoLabel = styled(Typography)`
  font-weight: 600 !important;
  font-size: 34px !important;
  line-height: 48px !important;

  @media only screen and (max-width: 600px) {
    font-size: 24px !important;
    line-height: 36px !important;
    margin-right: 12px !important;
  }
`;
