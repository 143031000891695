// Dependencies
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonBase,
  Stack,
} from '@mui/material';

export const Offer = styled(Accordion)<{ index: number; color: string }>`
  && {
    background-color: ${(props) => props.color};

    .MuiCollapse-root {
      width: calc(300% + 40px);
      transform: translateX(
        ${(props) => {
          switch (props.index % 3) {
            case 1: {
              return '0%';
            }

            case 2: {
              return 'calc(-100% / 3 - 20px / 3)';
            }

            case 0: {
              return 'calc(-200% / 3 - 40px / 3)';
            }
          }
        }}
      );

      ${(props) => props.theme.breakpoints.down('xl')} {
        width: calc(200% + 20px);
        transform: translateX(
          ${(props) => {
            switch (props.index % 2) {
              case 1: {
                return '0%';
              }

              case 0: {
                return 'calc(-50% - 10px)';
              }
            }
          }}
        );
      }

      ${(props) => props.theme.breakpoints.down('lg')} {
        width: 100%;
        transform: none;
      }
    }
  }
`;

export const OfferSummary = styled(AccordionSummary)<{ visibleOffer: boolean }>`
  && {
    .MuiAccordionSummary-content {
      margin: ${(props) => props.theme.spacing(6, 20, 20)};

      svg {
        margin-right: ${(props) => props.theme.spacing(8)};
      }

      .MuiTypography-root {
        text-decoration: underline;
      }

      .MuiTypography-root,
      svg {
        color: ${(props) =>
          props.visibleOffer
            ? props.theme.palette.error.main
            : props.theme.palette.gray};
      }
    }
  }
`;

export const OfferDetail = styled(AccordionDetails)<{ color: string }>`
  && {
    border-radius: 12px;
    padding: ${(props) => props.theme.spacing(20, 40, 30)};
    margin-top: ${(props) => props.theme.spacing(20)};
    background-color: ${(props) => props.color};

    ${(props) => props.theme.breakpoints.down('sm')} {
      padding: ${(props) => props.theme.spacing(20, 20, 30)};
    }
  }
`;

export const Actions = styled(Stack)`
  ${(props) => props.theme.breakpoints.down('xl')} {
    flex-wrap: wrap;
  }
`;

export const OfferButton = styled(ButtonBase)<{ active?: boolean }>`
  && {
    padding: ${(props) => props.theme.spacing(12, 20)};
    display: flex;
    flex-direction: column;
    max-width: 190px;
    border-radius: 12px;
    color: ${(props) =>
      props.active
        ? props.theme.palette.primary.main
        : props.theme.palette.lightIndigo};

    ${(props) => props.theme.breakpoints.down('xl')} {
      height: 100%;
      width: calc(50% - 6px);
      max-width: calc(50% - 6px);

      &:nth-of-type(2n + 1) {
        margin-right: ${(props) => props.theme.spacing(12)};
      }
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
      padding: ${(props) => props.theme.spacing(12, 8)};
    }

    &.Mui-disabled {
      cursor: not-allowed;
    }

    svg {
      width: 24px;
      margin-bottom: ${(props) => props.theme.spacing(12)};
    }

    .MuiTypography-root {
      color: ${(props) =>
        props.active
          ? props.theme.palette.primary.main
          : props.theme.palette.lightIndigo} !important;
    }
  }
`;
