// Dependencies
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setFeatureActive as setFeature } from '../../services/auth.service';
// Components
import {
  Button,
  ChangePasswordModal,
  DocumentPanel,
  FormPanel,
  FormType,
  Icon,
  IconButton,
  ProjectPanel,
  Typography,
} from '../../components';

// Styles
import * as S from './styles';

// Store
import { RootState } from '../../redux/reducers';
import { getAccount, getProfileForm, updateUser } from '../../redux/actions';
import { useSnackbar } from 'notistack';
import { LeadPanel } from '../../components/Panels/LeadPanel';
import { Switch } from '../../components/Common/Switch';
import { Role } from '../../constants';
import { getFeatureActive } from '../../services/auth.service';

// Interfaces
export interface IProfileForm {
  submit: () => void;
}

// Constants
enum TabPanels {
  Leads = 'leads',
  Detected = 'new',
  Submitted = 'inProgress',
  Rejected = 'declined',
  Bought = 'bought',
  Sold = 'sold',
  Profile = 'profile',
  Files = 'files',
}

// Export Profile page
export const ProfilePage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  // States
  const [activeTab, setActiveTab] = React.useState<TabPanels>(
    TabPanels.Profile
  );
  const [form, setForm] = useState<any>(null);

  // Get translation from hook
  const { t } = useTranslation();

  // Get dispatch from hook
  const dispatch = useDispatch();

  const [openChangePasswordModal, setOpenChangePasswordModal] =
    useState<boolean>(false);

  // Theme
  const theme = useTheme();

  // Check platform
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // Get form data from store
  const { profileForm, loading: formLoading } = useSelector(
    ({ formReducer }: RootState) => formReducer
  );

  // Get user data from store
  const { account, loading, features } = useSelector(
    ({ authReducer }: RootState) => authReducer
  );

  // File selector
  const fileSelector = useRef<any>();
  const [admin, setAdmin] = useState(
    account?.roles?.includes(Role.ROLE_ADMIN)!
  );

  const [featureActive, setFeatureActive] = useState(false);

  // Tab change handler
  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: TabPanels
  ) => {
    setActiveTab(newValue);
  };

  // Save handler
  const handleSave = () => {
    if (form) form.handleSubmit();
  };

  // Refetch handler
  const handleRefetch = () => {
    dispatch(getAccount());
  };

  // Open explorer to update image
  const handleUpdateImage = () => {
    fileSelector.current.click();
  };

  // Update profile image
  const handleUpdateProfileImage = async (e) => {
    const { files: uploadedFiles } = e.target;
    if (account) {
      try {
        const res: any = await dispatch(
          updateUser(account.id, { image: uploadedFiles[0] })
        );
        if (res?.value?.success && res?.value?.message) {
          handleRefetch();
          enqueueSnackbar(res.value?.message, { variant: 'success' });
        } else {
          enqueueSnackbar(res.value?.message, { variant: 'error' });
        }
      } catch (e: any) {
        enqueueSnackbar(
          e?.response?.data?.message || 'Failed to update image',
          { variant: 'error' }
        );
      }
    }
  };

  const handleOpenChangePasswordModal = () => {
    setOpenChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  };

  // On mounted
  useEffect(() => {
    dispatch(getProfileForm());
    if (account) {
      Object.values(account.roles).map((el) =>
        String(el) === 'ROLE_ADMIN' ? setAdmin(true) : null
      );
    }

    getFeatureActive('2fa').then((res) => {
      setFeatureActive(res.data?.data?.feature_active);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFeatureChange = async (e) => {
    try {
      setFeature({ featureKey: '2fa', featureActive: e.target.checked }).then(
        (res) => {
          setFeatureActive(!featureActive);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  // Return BrokersDetail page
  return (
    <>
      {isDesktop ? (
        <Card>
          <CardHeader
            sx={{ pb: 24 }}
            title={
              <Stack direction="row" spacing={16} alignItems="flex-start">
                <Badge
                  sx={{
                    '.MuiBadge-badge': {
                      padding: 8,
                      height: 'initial',
                      transform: 'initial',
                    },
                  }}
                  badgeContent={
                    !loading && (
                      <>
                        <IconButton onClick={handleUpdateImage}>
                          <Icon name="pencil" />
                        </IconButton>
                        <input
                          style={{ display: 'none' }}
                          type="file"
                          ref={fileSelector}
                          onChange={handleUpdateProfileImage}
                          multiple
                        />
                      </>
                    )
                  }
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  {!loading ? (
                    <Avatar
                      sx={{ width: 132, height: 132 }}
                      src={account?.image?.path}
                    >
                      <Icon name="image-fill" />
                    </Avatar>
                  ) : (
                    <Skeleton variant="rectangular" width={132} height={132} />
                  )}
                </Badge>
                <Stack spacing={20}>
                  <Stack spacing={4}>
                    <Typography variant="h3">
                      {!loading ? (
                        <>
                          {account?.first_name} {account?.last_name}
                        </>
                      ) : (
                        <Skeleton width={160} />
                      )}
                    </Typography>
                    <Typography>
                      {!loading ? account?.company : <Skeleton />}
                    </Typography>
                    <Typography>
                      {!loading ? (
                        <>
                          {account?.address.street}, {account?.address.zip},{' '}
                          {account?.address.city}
                        </>
                      ) : (
                        <Skeleton />
                      )}
                    </Typography>
                  </Stack>
                  <Typography variant="body2" color="lightIndigo">
                    {!loading ? account?.email : <Skeleton />}
                  </Typography>
                </Stack>
              </Stack>
            }
            action={
              <Stack
                flexDirection={'row'}
                alignItems="flex-start"
                justifyContent={'space-between'}
              >
                <Button
                  disabled={loading}
                  onClick={handleOpenChangePasswordModal}
                >
                  {t('profile.change_password')}
                </Button>

                {form !== null && (
                  <>
                    <Button
                      color="primary"
                      disabled={loading || formLoading}
                      onClick={handleSave}
                    >
                      {t('profile.save')}
                    </Button>

                    {admin && (
                      <FormControlLabel
                        sx={{ mb: 0 }}
                        labelPlacement="start"
                        label={
                          <Typography variant="body2" sx={{ width: '120px' }}>
                            {t('two_factor_auth.title')}
                          </Typography>
                        }
                        control={
                          <Switch
                            checked={featureActive}
                            onChange={handleFeatureChange}
                          />
                        }
                      />
                    )}
                  </>
                )}
              </Stack>
            }
          />
          <CardContent sx={{ p: 20, height: '100%' }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
            >
              <Tab value={TabPanels.Leads} label={t('brokers_detail.leads')} />
              <Tab
                value={TabPanels.Detected}
                label={t('brokers_detail.detected')}
              />
              <Tab
                value={TabPanels.Submitted}
                label={t('brokers_detail.submitted')}
              />
              <Tab
                value={TabPanels.Rejected}
                label={t('brokers_detail.rejected')}
              />
              <Tab
                value={TabPanels.Bought}
                label={t('brokers_detail.bought')}
              />
              <Tab value={TabPanels.Sold} label={t('brokers_detail.sold')} />
              <Tab
                value={TabPanels.Profile}
                label={t('brokers_detail.profile')}
              />
              <Tab value={TabPanels.Files} label={t('profile.files')} />
            </Tabs>
            <S.TabPanel>
              {activeTab === TabPanels.Leads && (
                <LeadPanel brokerId={account?.id} status={TabPanels.Leads} />
              )}
              {activeTab === TabPanels.Detected && (
                <ProjectPanel
                  brokerId={account?.id}
                  status={TabPanels.Detected}
                />
              )}
              {activeTab === TabPanels.Submitted && (
                <ProjectPanel
                  brokerId={account?.id}
                  status={TabPanels.Submitted}
                />
              )}
              {activeTab === TabPanels.Rejected && (
                <ProjectPanel
                  brokerId={account?.id}
                  status={TabPanels.Rejected}
                />
              )}
              {activeTab === TabPanels.Bought && (
                <ProjectPanel
                  brokerId={account?.id}
                  status={TabPanels.Bought}
                />
              )}
              {activeTab === TabPanels.Sold && (
                <ProjectPanel brokerId={account?.id} status={TabPanels.Sold} />
              )}
              {activeTab === TabPanels.Profile && (
                <FormPanel
                  setForm={setForm}
                  formData={profileForm}
                  formType={FormType.User}
                  isLoading={formLoading}
                  initialFormData={account}
                  refetchFormData={handleRefetch}
                  disableSelfRoleChange={true}
                />
              )}
              {activeTab === TabPanels.Files && <DocumentPanel />}
            </S.TabPanel>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardHeader
            sx={{ px: 16, pb: 4, pt: { xs: 18, sm: 20 } }}
            title={
              <Box>
                <Stack
                  direction="row"
                  spacing={10}
                  justifyContent="flex-end"
                  mb={12}
                >
                  <Button
                    disabled={loading}
                    onClick={handleOpenChangePasswordModal}
                  >
                    {t('profile.change_password')}
                  </Button>
                  {form !== null && (
                    <>
                      <Button
                        color="primary"
                        disabled={formLoading || loading}
                        onClick={handleSave}
                      >
                        {t('profile.save')}
                      </Button>
                      {admin && (
                        <FormControlLabel
                          sx={{ mb: 0 }}
                          labelPlacement="top"
                          label={t('two_factor_auth.title')}
                          checked={featureActive}
                          control={
                            <Switch
                              checked={featureActive}
                              onChange={handleFeatureChange}
                            />
                          }
                        />
                      )}
                    </>
                  )}
                </Stack>
                <Stack
                  mb={24}
                  direction="row"
                  spacing={8}
                  alignItems="flex-start"
                >
                  <Badge
                    sx={{
                      '.MuiBadge-badge': {
                        padding: { xs: 4, sm: 8 },
                        height: 'initial',
                        transform: 'initial',
                      },
                    }}
                    badgeContent={
                      !loading && (
                        <>
                          <IconButton onClick={handleUpdateImage}>
                            <Icon name="pencil" />
                          </IconButton>
                          <input
                            style={{ display: 'none' }}
                            type="file"
                            ref={fileSelector}
                            onChange={handleUpdateProfileImage}
                            multiple
                          />
                        </>
                      )
                    }
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    {!loading ? (
                      <Avatar
                        sx={{
                          width: { xs: 100, sm: 132 },
                          height: { xs: 100, sm: 132 },
                        }}
                      >
                        <Icon name="image-fill" />
                      </Avatar>
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          width: { xs: 78, sm: 132 },
                          height: { xs: 78, sm: 132 },
                        }}
                      />
                    )}
                  </Badge>
                  <Box>
                    <Typography
                      mb={2}
                      variant="h4"
                      height={28}
                      overflow="hidden"
                    >
                      {!loading ? (
                        <>
                          {account?.first_name} {account?.last_name}
                        </>
                      ) : (
                        <Skeleton width={160} />
                      )}
                    </Typography>
                    <Typography height={24} overflow="hidden">
                      {!loading ? account?.company : <Skeleton />}
                    </Typography>
                    <Typography height={24} overflow="hidden">
                      {!loading ? (
                        <>
                          {account?.address.street}, {account?.address.zip},{' '}
                          {account?.address.city}
                        </>
                      ) : (
                        <Skeleton />
                      )}
                    </Typography>
                    <Typography
                      mt={8}
                      variant="body2"
                      color="lightIndigo"
                      height={24}
                      overflow="hidden"
                    >
                      {!loading ? account?.email : <Skeleton />}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            }
          />
          <CardContent>
            <Tabs
              sx={{
                width: {
                  xs: 'calc(100vw - 64px)',
                  md: 'calc(100vw - 360px)',
                },
              }}
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons
            >
              <Tab value={TabPanels.Leads} label={t('brokers_detail.leads')} />
              <Tab
                value={TabPanels.Detected}
                label={t('brokers_detail.detected')}
              />
              <Tab
                value={TabPanels.Submitted}
                label={t('brokers_detail.submitted')}
              />
              <Tab
                value={TabPanels.Rejected}
                label={t('brokers_detail.rejected')}
              />
              <Tab
                value={TabPanels.Bought}
                label={t('brokers_detail.bought')}
              />
              <Tab value={TabPanels.Sold} label={t('brokers_detail.sold')} />
              <Tab
                value={TabPanels.Profile}
                label={t('brokers_detail.profile')}
              />
              <Tab value={TabPanels.Files} label={t('profile.files')} />
            </Tabs>
            <S.TabPanel>
              {activeTab === TabPanels.Leads && (
                <LeadPanel brokerId={account?.id} status={TabPanels.Leads} />
              )}
              {activeTab === TabPanels.Detected && (
                <ProjectPanel
                  brokerId={account?.id}
                  status={TabPanels.Detected}
                />
              )}
              {activeTab === TabPanels.Submitted && (
                <ProjectPanel
                  brokerId={account?.id}
                  status={TabPanels.Submitted}
                />
              )}
              {activeTab === TabPanels.Rejected && (
                <ProjectPanel
                  brokerId={account?.id}
                  status={TabPanels.Rejected}
                />
              )}
              {activeTab === TabPanels.Bought && (
                <ProjectPanel
                  brokerId={account?.id}
                  status={TabPanels.Bought}
                />
              )}
              {activeTab === TabPanels.Sold && (
                <ProjectPanel brokerId={account?.id} status={TabPanels.Sold} />
              )}
              {activeTab === TabPanels.Profile && (
                <FormPanel
                  setForm={setForm}
                  formData={profileForm}
                  formType={FormType.User}
                  isLoading={formLoading}
                  initialFormData={account}
                  refetchFormData={handleRefetch}
                  disableSelfRoleChange={true}
                />
              )}
              {activeTab === TabPanels.Files && <DocumentPanel />}
            </S.TabPanel>
          </CardContent>
        </Card>
      )}
      {/* Modals */}
      {openChangePasswordModal && (
        <ChangePasswordModal
          open={openChangePasswordModal}
          onClose={handleCloseChangePasswordModal}
          isDesktop={isDesktop}
        />
      )}
    </>
  );
};
