import { Role } from './role.constant';
import { ICalcType, IOfferCalculation } from '../interfaces';

export const PROJECT_GROUPS = [
  // 'project:change',
  // 'project:payoutVariant',
  // 'property:type',
  'project:all',
];
export const PROPERTY_NAMES = {
  condominium: 'Eigentumswohnung',
  singleFamilyHouse: 'Einfamilienhaus',
  singleFamilyHouseWithFlat: 'Einfamilienhaus mit Einliegerwohnung',
  twoFamilyHouse: 'Zweifamilienhaus',
  terracedCornerHouse: 'Reiheneckhaus',
  terracedMiddleHouse: 'Reihenmittelhaus',
  apartmentHouse: 'Mehrfamilienhaus',
  semiDetachedHouse: 'Doppelhaushälfte',
};

export const CALCULATION_COLORS = {
  [ICalcType.liveLong]: '#f7efea',
  [ICalcType.liveLongWithOneTimePayment]: '#fbf4e2',
  [ICalcType.timeRent]: '#eff4f8',
  [ICalcType.timeRentWithOneTimePayment]: '#fbf4e2',
  [ICalcType.oneTimePayment]: '#eff4f8',
};

export const CALCULATION_OPTIONS: {
  [key: string]: {
    title: string;
    color: string;
    options: Array<string>;
    optionForTitle?: string;
  };
} = {
  lifelong_payment: {
    color: '#f7efea',
    title: 'Lebenslange Allianz-Sofortrente',
    options: [],
  },
  time_rent: {
    color: '#b5c7e7', // TODO replace with correct one in the future
    title: 'Zeitrente',
    options: ['annuity_amount_time_rent', 'time_rent_payment_time'],
    optionForTitle: ' %rentTime% Jahre',
  },
  one_time_payment: {
    color: '#eff4f8',
    title: 'Einmalzahlung',
    options: ['amount_one_time_payment'],
  },
  lifelong_payment_with_one_time_payment: {
    color: '#fbf4e2',
    title: 'Kombi Einmalzahlung & lebenslange Allianz-Sofortrente',
    options: ['amount_lifelong_payment_with_one_time_payment'],
  },
  unknown: {
    color: '#bbdcd0', // TODO replace with correct one in the future
    title: 'Unbekannt',
    options: [],
  },
  time_rent_with_one_time_payment: {
    color: '#F9FBFC', // TODO replace with correct one in the future
    title: 'Kombi Einmalzahlung & Zeitrente',
    options: [
      'annuity_amount_time_rent_with_one_time_payment',
      'time_rent_with_one_time_payment_payment_time',
      'amount_time_rent_with_one_time_payment_payment_time',
    ],
    optionForTitle: ' %rentTime% Jahre',
  },
};

export const LABEL_MAPPING: { [key: string]: any } = {
  usufruct: {
    label: 'Wert des Wohnrechts',
    border: 0,
    isCurrency: true,
  },
  maintenanceCosts: {
    label: 'Instandhaltungskosten',
    border: 0,
    isCurrency: true,
  },
  interestOnEquity: {
    label: 'Kapitalverzinsung',
    border: 0,
    isCurrency: true,
  },
  restLifeExpectancy: {
    label: 'Restlebenserwartung in Jahre',
    border: 0,
  },
  totalAnnuity: {
    label: 'Bemessungsgrundlage',
    border: 0,
    isCurrency: true,
    hide: true,
  },
  // _oneTimePayment: {
  //   label: "Berechnete Einmalzahlung",
  //   border: 2,
  //   isSum: true,
  //   isCurrency: true,
  //   role: Role.ROLE_ADMIN,
  // },
  oneTimePayment: {
    label: 'Ergebnis',
    border: 2,
    isSum: true,
    isCurrency: true,
    role: Role.ROLE_ADMIN,
  },
  remainingDept: {
    label: 'Ablösung Restschulden',
    border: 2,
    isSum: true,
    isCurrency: true,
  },
  desireOneTimePayment: {
    label: 'Wunsch Einmalzahlung',
    border: 0,
    isCurrency: true,
    hide: true,
  },
  actualOneTimePayment: {
    label: 'Einmalzahlung für Angebot',
    border: 0,
    isSum: true,
    isCurrency: true,
  },
  monthlyAnnuity: {
    label: 'Berechnete Rente p.M.',
    border: 0,
    isSum: false,
    isCurrency: true,
    role: Role.ROLE_ADMIN,
    hide: (calculation: IOfferCalculation) =>
      calculation.calcType === ICalcType.liveLong ||
      calculation.calcType === ICalcType.liveLongWithOneTimePayment,
  },
  desireMonthlyAnnuity: {
    label: 'Wunschrente p.M.',
    border: 0,
    isCurrency: true,
    hide: true,
  },
  actualMonthlyAnnuity: {
    label: (calculation: IOfferCalculation) => {
      if (
        calculation.calcType === ICalcType.liveLong ||
        calculation.calcType === ICalcType.liveLongWithOneTimePayment
      ) {
        return 'Allianz Sofort-Rente p.M.';
      }
      return 'Rente für Indikatives Angebot p.M.';
    },
    border: 0,
    isSum: true,
    isCurrency: true,
  },
  initialYield: {
    label: 'Ankaufs-EK-Rendite p.a. in %',
    border: 0,
    isSum: false,
    isCurrency: false,
    isPercent: true,
    alwaysShow: true,
    role: Role.ROLE_ADMIN,
  },
};

export const COSTS_TYPE: { [key: number]: string } = {
  1: 'Grundsteuer',
  2: 'Sach- und Haftpflichtversicherung',
  3: 'Wasserversorgung',
  4: 'Zentrale Heizungsanlage',
  5: 'Personenaufzug',
  6: 'Straßenreinigung',
  7: 'Müllbeseitigung',
  8: 'Gebäudereinigung',
  9: 'Gartenpflege',
  10: 'Allgemeinstrom',
  11: 'Schornsteinreinigung',
  12: 'Hauswart',
  13: 'Breitbandnetz',
  14: 'Sonstige Betriebskosten',
  15: 'Winterdienst',
  16: 'Wartung Aufzug',
  17: 'Wartung RMW',
  18: 'Niederschlagswasser',
  19: 'Abrechnungsspitze',
};
