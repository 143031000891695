// Dependencies
import React, { FC, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Components
import { Dialog } from '../../Common';

// Utils
import {
  getCurrency,
  getDateString,
  getGender,
  getPercent,
} from '../../../utils';

// Interfaces
import { IOfferCalculation } from '../../../interfaces';

interface ICalculationInformationModal {
  open: boolean;
  onClose: () => void;
  calculation: IOfferCalculation;
}

// Export calculation information modal
export const CalculationInformationModal: FC<ICalculationInformationModal> = ({
  calculation,
  open,
  onClose,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  const namingTable = {
    birthDate: {
      title: t('calculation.date_of_birth'),
      transformer: getDateString,
    },
    gender: {
      title: t('calculation.gender'),
      transformer: getGender,
    },
    created: { title: t('calculation.created'), transformer: getDateString },

    remainingLife: {
      title: t('calculation.remaining_useful_life'),
      transformer: null,
    },
    livingSpace: {
      title: t('calculation.living_space_in_sqm'),
      transformer: null,
    },
    garageCount: { title: t('calculation.garage_number'), transformer: null },
    garageRent: {
      title: t('calculation.garage_rent_piece_month'),
      transformer: getCurrency,
      isCurrency: true,
    },
    rent: {
      title: t('calculation.rent_sqm_month'),
      transformer: getCurrency,
      isCurrency: true,
    },
    oneTimePayment: {
      title: t('calculation.one_time_payment'),
      transformer: getCurrency,
      isCurrency: true,
    },
    remainingDept: {
      title: t('calculation.residual_debt'),
      transformer: getCurrency,
      isCurrency: true,
    },
    propertyValue: {
      title: t('calculation.value_of_the_property'),
      transformer: getCurrency,
      isCurrency: true,
    },
    rentTime: { title: t('calculation.time_pension'), transformer: null },
    interestRate: { title: t('calculation.interest_yield'), transformer: null },
    initialYield: {
      title: t('calculation.purchase_yield'),
      transformer: getPercent,
      isPercent: true,
    },
  };

  const calculationFields = useMemo(() => {
    const calculationFields: any[] = [];
    Object.keys(calculation).forEach((fieldName) => {
      if (!Object.keys(namingTable).includes(fieldName)) {
        return;
      }

      const naming = namingTable[fieldName];
      let value = calculation[fieldName];
      if (naming.transformer) {
        value = naming.transformer(`${value}`);

        if (naming?.isCurrency) {
          value += ' €';
        }
        if (naming?.isPercent) {
          value += ' %';
        }
      }
      if (value === 0) {
        return;
      }
      const name = naming.title;

      calculationFields.push({
        name,
        value,
      });
    });
    return calculationFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation]);

  // Return calculation information modal
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('calculation_information.title')}
    >
      <Stack spacing={20} px={10}>
        {calculationFields.map(({ name, value }, index) => (
          <Stack key={index} direction="row" justifyContent="space-between">
            <Typography variant="body2">{name}</Typography>
            <Typography variant="body2">{value}</Typography>
          </Stack>
        ))}
      </Stack>
    </Dialog>
  );
};
