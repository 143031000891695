// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Box, Grid } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import { Button, Dialog } from '../../Common';

// Interfaces
import { RootState } from '../../../redux/reducers';

// Utils
import { buildFormInitialValues, buildSingleFieldSchema } from '../../../utils';

// Store
import {
  getEnterEligiblePersonForm,
  getProjectDetail,
} from '../../../redux/actions';
import {
  addEligiblePersonData,
  updateEligiblePersonData,
} from '../../../services/project.service';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

// Interfaces
interface IDeseacedModalProps {
  isEdit: boolean;
  open: boolean;
  onClose: () => void;
  id?: number;
  name?: string;
  handleDeceasedClick: (id: number, type: string) => void;
}

// Export enter eligible person modal
export const DeseacedModal: FC<IDeseacedModalProps> = ({
  isEdit,
  open,
  onClose,
  id,
  name,
  handleDeceasedClick,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get snack bar hook
  const { enqueueSnackbar } = useSnackbar();

  // Get dispatch from store
  const dispatch = useDispatch();

  // Get project id
  const params = useParams<{ id: string }>();

  // Get enter eligible person form from store
  const enterEligiblePersonForm = useSelector(
    ({ formReducer: { enterEligiblePersonForm } }: RootState) =>
      enterEligiblePersonForm
  );

  // Get project detail from store
  const { projectDetail } = useSelector(
    ({ projectReducer }: RootState) => projectReducer
  );

  const [loading, setLoading] = useState<boolean>(false);

  // Get initial value from form data
  const initialValue = useMemo(() => {
    return buildFormInitialValues(enterEligiblePersonForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get validation schema from form data
  const validationSchema = useMemo(() => {
    return buildSingleFieldSchema(enterEligiblePersonForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create formik
  const formik = useFormik<any>({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      if (projectDetail?.beneficiary_person?.id) {
        updateEligiblePersonData(
          projectDetail?.id,
          projectDetail.beneficiary_person.id,
          values
        )
          .then((res) => {
            setLoading(false);
            if (res.data?.success) {
              enqueueSnackbar(res.data?.message, { variant: 'success' });

              if (params.id) {
                dispatch(getProjectDetail(+params.id));
              }
            } else {
              enqueueSnackbar(res.data?.message || 'Failed', {
                variant: 'error',
              });
            }
            onClose();
          })
          .catch((err) => {
            enqueueSnackbar(err.response?.data?.message || 'Failed', {
              variant: 'error',
            });
            setLoading(false);
          });
      } else {
        addEligiblePersonData(projectDetail?.id, values)
          .then((res) => {
            setLoading(false);
            if (res.data?.success) {
              enqueueSnackbar(res.data?.message, { variant: 'success' });
            } else {
              enqueueSnackbar(res.data?.message || 'Failed', {
                variant: 'error',
              });
            }
            onClose();
          })
          .catch((err) => {
            enqueueSnackbar(err.response?.data?.message || 'Failed', {
              variant: 'error',
            });
            setLoading(false);
          });
      }
    },
  });

  // On mounted
  useEffect(() => {
    dispatch(getEnterEligiblePersonForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On project detail changed
  useEffect(() => {
    if (isEdit && projectDetail) {
      formik.setValues(projectDetail?.beneficiary_person);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, projectDetail]);

  // Return enter eligible person modal
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={`${name} ${t('deceased.client_marked')}`}
      actions={
        <>
          <Button
            loading={loading}
            color="primary"
            size="large"
            onClick={() => handleDeceasedClick(id || 0, 'customer')}
          >
            {t('deceased.client_confirm')}
          </Button>
          <Button
            loading={loading}
            color="secondary"
            size="large"
            onClick={() => handleDeceasedClick(id || 0, 'partner')}
          >
            {t('deceased.partner_confirm')}
          </Button>
        </>
      }
    >
      <Box component={PerfectScrollbar}>
        <Grid container columns={2} spacing={16}></Grid>
      </Box>
    </Dialog>
  );
};
