// Dependencies
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  ToggleButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// Components
import {
  AcceptPrepaymentModal,
  Dialog,
  ConfirmModal,
  Icon,
  RecordOperatingCostsModal,
} from '../../../../../../components';

// Interfaces
import { IAdditionalCosts } from '../../../../../../interfaces';

// Utils
import {
  getCurrency,
  getDate,
  getDateObj,
  getDateString,
  getLastAdditionalCosts,
  getPeriodInDays,
  getSecondToLastAdditionalCosts,
  isUserAllowed,
} from '../../../../../../utils';

// Store
import { RootState } from '../../../../../../redux/reducers';
import { getProjectDetail } from '../../../../../../redux/actions';

// Services
import {
  checkAdditionalCostsTask,
  deleteAdditionalCosts,
  finalizeAdditionalCosts,
  reopenAdditionalCosts,
} from '../../../../../../services/project.service';

// Constants
import { COSTS_TYPE, Role } from '../../../../../../constants';

const defaultTasks = {
  task_payment_adjusted: {
    id: 'task_payment_adjusted',
    title: 'Vorauszahlung / Rentenzahlung angepasst',
    value: false,
  },
  task_invoice_sent: {
    id: 'task_invoice_sent',
    title: 'Nebenkostenabrechnung verschickt',
    value: false,
  },
  task_payment_processed: {
    id: 'task_payment_processed',
    title: 'Guthaben ausbezahlt / Nachzahlung eingegangen',
    value: false,
  },
};

enum Confirm {
  Delete,
  Finalize,
  Reopen,
}

// Create additional costs calculator panel
const AdditionalCostsCalculatorPanel: FC = () => {
  // States
  const [filter, setFilter] = useState<string>('');
  const [ellipsisEl, setEllipsisEl] = useState<{
    element: HTMLElement;
    number: number;
  }>();
  const [additionalCostsData, setAdditionalCostsData] = useState<any>();
  const [
    visibleRecordOperatingCostsModal,
    setVisibleRecordOperatingCostsModal,
  ] = useState<boolean>(false);
  const [visibleAcceptPrepaymentModal, setVisibleAcceptPrepaymentModal] =
    useState<boolean>(false);
  const [prepaymentData, setPrepaymentData] = useState<any>();
  const [additionalCostsId, setAdditionalCostsId] = useState<number>();
  const [visibleConfirmModal, setVisibleConfirmModal] =
    useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [confirm, setConfirm] = useState<Confirm>(Confirm.Delete);
  const [visibleMoreInfoModal, setVisibleMoreInfoModal] =
    useState<boolean>(false);

  // Get translation from hook
  const { t } = useTranslation();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get theme from hook
  const theme = useTheme();

  // Check platform
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Get dispatch from store
  const dispatch = useDispatch();

  // Get user data from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Get project detail from store
  const { projectDetail } = useSelector(
    ({ projectReducer: { projectDetail } }: RootState) => ({
      projectDetail,
    })
  );

  // Get confirm text
  const confirmText = useMemo(() => {
    switch (confirm) {
      case Confirm.Finalize: {
        return t('confirm.finalize_confirm_text');
      }

      case Confirm.Reopen: {
        return t('confirm.reopen_confirm_text');
      }

      default: {
        return undefined;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm]);

  // Refetch project
  const refetchProjectDetail = async () => {
    await dispatch(getProjectDetail(projectDetail?.id as number));
  };

  // Calc additional costs item
  const calcAdditionalCostsItem = (
    additionalCostsItem: IAdditionalCosts | null,
    withHistory: boolean = false,
    itemsHistory: Array<{ label: string; value: string }> = []
  ) => {
    let calcYear: string = '';
    if (!additionalCostsItem) return null;
    if (additionalCostsItem.calc_period_start) {
      const datePeriodStart = getDate(additionalCostsItem.calc_period_start);
      if (datePeriodStart) {
        calcYear = String(moment(datePeriodStart).format('YYYY'));
      }
    }
    const items = [
      {
        label: 'Abrechnungszeitraum',
        value:
          getDateString(additionalCostsItem.calc_period_start) +
          ' - ' +
          getDateString(additionalCostsItem.calc_period_end),
        isSum: false,
      },
      {
        label: 'Ihr Zeitraum',
        value:
          additionalCostsItem.usage_period_start &&
          additionalCostsItem.usage_period_end
            ? getDateString(additionalCostsItem.usage_period_start) +
              ' - ' +
              getDateString(additionalCostsItem.usage_period_end)
            : '--.--.----',
        isSum: false,
      },
    ];

    if (
      additionalCostsItem.costs_elems &&
      Array.isArray(additionalCostsItem.costs_elems) &&
      additionalCostsItem.costs_elems.length > 0
    ) {
      let isHeatingCostsIncluded = false;
      for (let additionalCostsElem of additionalCostsItem.costs_elems) {
        const label = COSTS_TYPE[additionalCostsElem.additional_costs_type];
        const value = getCurrency(additionalCostsElem.total_calc_cost) + ' €';
        items.push({ label, value, isSum: false });
        if (additionalCostsElem.additional_costs_type === 4) {
          // 4 - heating
          isHeatingCostsIncluded = true;
        }
      }
      items.push({
        label: isHeatingCostsIncluded
          ? 'Summe Ihrer Betriebs- &Heizkosten'
          : 'Summe Ihrer Betriebskosten',
        value: getCurrency(additionalCostsItem.total_costs) + ' €',
        isSum: true,
      });
    }

    if (additionalCostsItem.is_finalized) {
      items.push({
        label: 'Summe Ihrer Vorauszahlungen',
        value:
          '- ' + getCurrency(additionalCostsItem.previous_prepayment) + ' €',
        isSum: false,
      });
      let label = 'Saldo';
      let value =
        getCurrency(Math.abs(additionalCostsItem.difference_prepayment)) + ' €';
      if (additionalCostsItem.difference_prepayment < 0) {
        label = 'Nachzahlung';
        value = '- ' + value;
      } else if (additionalCostsItem.difference_prepayment > 0) {
        label = 'Guthaben';
        value = '+ ' + value;
      }
      items.push({ label, value, isSum: true });
      items.push({
        label: 'Anpassung Vorauszahlungen p.M.',
        value: getCurrency(additionalCostsItem.prepayment) + ' €',
        isSum: false,
      });
      if (additionalCostsItem.is_accepted) {
        items.push({
          label: 'Vorauszahlungsansatz ab',
          value: getDateString(additionalCostsItem.finalized_date),
          isSum: false,
        });
      }
    }

    let headline = t('project_detail.operating_cost');
    if (!withHistory) {
      headline = headline + ' ' + calcYear;
    }

    const tasks = Object.entries(defaultTasks).map(([key, task]) => ({
      ...task,
      value: additionalCostsItem[key],
    }));

    return {
      headline,
      items,
      tasks,
      accepted: additionalCostsItem.is_accepted,
      finalized: additionalCostsItem.is_finalized,
      deleteAllowed: isUserAllowed(account, Role.ROLE_ADMIN),
      editAllowed: isUserAllowed(account, Role.ROLE_ADMIN),
      filter,
      filterItems: itemsHistory,
      withFilter: withHistory,
      additionalCostsItem,
    };
  };

  // Get additional cost from project detail
  const additionalCostsItems = useMemo(() => {
    if (
      !(
        projectDetail?.additional_costs &&
        Array.isArray(projectDetail?.additional_costs) &&
        projectDetail?.additional_costs.length > 0
      )
    ) {
      return;
    }
    const additionalCostsItem: IAdditionalCosts[] =
      projectDetail?.additional_costs;

    const additionalCostsItems: any[] = [];
    let itemMaxYear: IAdditionalCosts | null = null;
    let itemFilter: IAdditionalCosts | null = null;
    let maxYear = 0;
    const itemsHistory: Array<{ label: string; value: string }> = [];

    const sortedAdditionalCostsItem = additionalCostsItem.sort((a, b) =>
      String(a.calc_period_start).localeCompare(b.calc_period_end)
    );
    for (let additionalCostsItem of sortedAdditionalCostsItem) {
      // take from .calc_year when available
      let calcYear: string = '';
      if (additionalCostsItem.calc_period_start) {
        const datePeriodStart = getDate(additionalCostsItem.calc_period_start);
        if (datePeriodStart) {
          calcYear = moment(datePeriodStart).format('YYYY');
        }
      }
      itemsHistory.push({ label: calcYear, value: calcYear });
      if (maxYear < parseInt(calcYear)) {
        maxYear = parseInt(calcYear);
        itemMaxYear = additionalCostsItem;
      }
      if (filter === calcYear) {
        itemFilter = additionalCostsItem;
      }
    }
    if (itemFilter === null) {
      itemFilter = itemMaxYear;
    }
    additionalCostsItems.push(
      calcAdditionalCostsItem(itemFilter, true, itemsHistory)
    );
    additionalCostsItems.push(calcAdditionalCostsItem(itemMaxYear));

    return additionalCostsItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail, filter]);

  // Open record operating costs modal
  const handleOpenRecordOperatingCostsModal = (
    additionalCosts?: IAdditionalCosts
  ) => {
    let additionalCostsData: any;
    if (additionalCosts) {
      additionalCostsData = {
        additionalCosts: {
          id: additionalCosts.id,
          calc_date: additionalCosts.calc_date,
          calc_year: additionalCosts.calc_year,
          calc_period_start: additionalCosts.calc_period_start,
          calc_period_end: additionalCosts.calc_period_end,
          usage_period_start: additionalCosts.usage_period_start,
          usage_period_end: additionalCosts.usage_period_end,
        },
        additionalCostsElem: additionalCosts.costs_elems,
      };
    } else {
      if (projectDetail) {
        const { payment_detail } = projectDetail;
        const dateNow = new Date();
        const dateObj = getDateObj(dateNow);
        let calcYear;
        let useFirstPeriod = true;

        if (payment_detail?.usage_period_start) {
          calcYear = getDate(payment_detail?.usage_period_start)?.getFullYear();
          if (calcYear) {
            dateObj.year = calcYear;
          }
        }
        const additionalCostsLastYear = getLastAdditionalCosts(projectDetail);
        if (additionalCostsLastYear) {
          const tempDate = getDate(additionalCostsLastYear.calc_period_start);
          if (tempDate) {
            calcYear = tempDate.getFullYear() + 1;
            useFirstPeriod = false;
          }
        }

        additionalCostsData = {
          additionalCosts: {
            calc_date: dateNow.toISOString(),
            calc_year: calcYear,
            calc_period_start: `${calcYear}-01-01`,
            calc_period_end: `${calcYear}-12-31`,
            usage_period_start:
              useFirstPeriod &&
              payment_detail &&
              payment_detail.usage_period_start
                ? payment_detail.usage_period_start
                : `${calcYear}-01-01`,
            usage_period_end: `${calcYear}-12-31`,
          },
        };

        if (additionalCostsLastYear) {
          additionalCostsData.additionalCostsElem = [
            ...additionalCostsLastYear.costs_elems,
          ];
          for (let elem of additionalCostsData.additionalCostsElem) {
            if (elem.additional_costs_unit === 1) {
              elem.amount_total = getPeriodInDays(
                additionalCostsData.additionalCosts.calc_period_start,
                additionalCostsData.additionalCosts.calc_period_end
              );
              elem.amount_used = elem.amount_total;
            }
          }
        }
      }
    }
    setAdditionalCostsData(additionalCostsData);
    setVisibleRecordOperatingCostsModal(true);
  };

  // Close record operating costs modal
  const handleCloseRecordOperatingCostsModal = () => {
    setVisibleRecordOperatingCostsModal(false);
  };

  // Ellipsis click handler
  const handleEllipsisClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setEllipsisEl({ element: event.currentTarget, number: index });
  };

  // Close ellipsis menu handler
  const handleCloseEllipsisMenu = () => {
    setEllipsisEl(undefined);
  };

  // Change filter handler
  const handleChangeFilter = (event: SelectChangeEvent) => {
    setFilter(event.target.value);
  };

  // Finalize handler
  const handleFinalize = async () => {
    finalizeAdditionalCosts(projectDetail?.id, selectedId)
      .then(async (res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          handleCloseConfirmModal();
          await refetchProjectDetail();
        } else {
          enqueueSnackbar(res, { variant: 'error' });
          handleCloseConfirmModal();
        }
      })
      .catch((e) => {
        enqueueSnackbar(e, { variant: 'error' });
      });
  };

  // Open accept prepayment handler
  const handleOpenAcceptPrepaymentModal =
    (additionalCosts: IAdditionalCosts) => () => {
      let prepaymentData: any;
      if (additionalCosts) {
        prepaymentData = {
          prepayment: additionalCosts.prepayment,
        };
      } else {
        prepaymentData = {
          prepayment: 0,
        };
      }
      setPrepaymentData(prepaymentData);
      setAdditionalCostsId(additionalCosts.id);
      setVisibleAcceptPrepaymentModal(true);
    };

  // Close accept prepayment modal handler
  const handleCloseAcceptPrepaymentModal = async () => {
    setVisibleAcceptPrepaymentModal(false);
    await refetchProjectDetail();
  };

  // Reopen handler
  const handleReopen = async () => {
    try {
      const res: any = await reopenAdditionalCosts(
        projectDetail?.id,
        selectedId
      );
      if (res?.success) {
        enqueueSnackbar(res?.message, { variant: 'success' });
        await refetchProjectDetail();
      } else {
        enqueueSnackbar(res?.message, { variant: 'error' });
      }
    } catch (e: any) {
      enqueueSnackbar(e?.message || 'Failed to reopen', { variant: 'error' });
    } finally {
      handleCloseConfirmModal();
    }
  };

  // Delete handler
  const handleDelete = async () => {
    try {
      const res: any = await deleteAdditionalCosts(
        projectDetail?.id,
        selectedId
      );
      if (res?.success) {
        enqueueSnackbar(res?.message, { variant: 'success' });
        await refetchProjectDetail();
      } else {
        enqueueSnackbar(res?.message, { variant: 'error' });
      }
    } catch (e: any) {
      enqueueSnackbar(e?.message || 'Failed to delete', { variant: 'error' });
    } finally {
      handleCloseConfirmModal();
    }
  };

  // Check task handler
  const handleCheckTask = (additionalCostsId, id, checked) => async () => {
    try {
      const res: any = await checkAdditionalCostsTask(
        projectDetail?.id,
        additionalCostsId,
        { [id]: checked }
      );
      if (res?.success) {
        enqueueSnackbar(res?.message, { variant: 'success' });
        await refetchProjectDetail();
      } else {
        enqueueSnackbar(res?.message, { variant: 'error' });
      }
    } catch (e: any) {
      enqueueSnackbar(e?.message || 'Failed to reopen', { variant: 'error' });
    }
  };

  // Download handler
  const handleDownload = (additionalCostsId, type) => () => {
    let url = `export/additionalCosts/${additionalCostsId}`;
    if (type === 'pdf') url += '/pdf';
    window.open(process.env.REACT_APP_API_SERVER + '/v1/' + url, '_blank');
  };

  const handleOpenMoreInfoModal = (id) => () => {
    setSelectedId(id);
    setVisibleMoreInfoModal(true);
  };

  const handleCloseMoreInfoModal = () => {
    setSelectedId(undefined);
    setVisibleMoreInfoModal(false);
  };

  // Open confirm modal handler
  const handleOpenConfirmModal =
    (id, confirm: Confirm = Confirm.Delete) =>
    () => {
      setConfirm(confirm);
      setSelectedId(id);
      setVisibleConfirmModal(true);
    };

  // Close confirm modal handler
  const handleCloseConfirmModal = () => {
    setVisibleConfirmModal(false);
    setSelectedId(undefined);
    setConfirm(Confirm.Delete);
  };

  // Confirm handler
  const handleConfirm = async () => {
    switch (confirm) {
      case Confirm.Finalize: {
        await handleFinalize();
        break;
      }

      case Confirm.Reopen: {
        await handleReopen();
        break;
      }

      default: {
        await handleDelete();
      }
    }
  };

  // On project detail changed
  useEffect(() => {
    if (projectDetail) {
      const additionalCostsItem = getSecondToLastAdditionalCosts(projectDetail);
      let calcYear: string = '';
      if (additionalCostsItem && additionalCostsItem.calc_period_start) {
        const datePeriodStart = getDate(additionalCostsItem.calc_period_start);
        if (datePeriodStart) {
          calcYear = String(moment(datePeriodStart).format('YYYY'));
        }
      }

      setFilter(calcYear);
    }
  }, [projectDetail]);

  // Return additional costs calculator panel

  return (
    <>
      <Stack
        mb={20}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        justifyContent="space-between"
      >
        <Typography mb={20} variant="h3">
          {t('project_detail.additional_costs_calculator').replace('-', '')}
        </Typography>
        <Button
          size={isMobile ? 'small' : 'large'}
          fullWidth={isMobile}
          startIcon={<Icon name="plus-lg" />}
          onClick={() => handleOpenRecordOperatingCostsModal()}
        >
          {t('project_detail.add_new_calculator')}
        </Button>
      </Stack>
      <Grid container columns={2} spacing={20}>
        {additionalCostsItems &&
          additionalCostsItems.map(
            (
              {
                headline,
                items,
                deleteAllowed,
                editAllowed,
                finalized,
                withFilter,
                filterItems,
                additionalCostsItem,
                accepted,
                tasks,
              },
              index
            ) => (
              <Grid key={index} item xs={2} xl={1}>
                <Card variant="outlined">
                  <CardHeader
                    title={
                      <Stack direction="row" alignItems="center" spacing={20}>
                        <Typography variant="h3">{headline}</Typography>
                        {withFilter && !isMobile && (
                          <Select value={filter} onChange={handleChangeFilter}>
                            {filterItems.map(({ label, value }, index) => (
                              <MenuItem key={index} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Stack>
                    }
                    action={
                      <>
                        {isMobile
                          ? withFilter && (
                              <Select
                                value={filter}
                                onChange={handleChangeFilter}
                              >
                                {filterItems.map(({ label, value }, index) => (
                                  <MenuItem key={index} value={value}>
                                    {label}
                                  </MenuItem>
                                ))}
                              </Select>
                            )
                          : !withFilter && (
                              <ToggleButton
                                value=""
                                size="small"
                                color="secondary"
                                selected={
                                  ellipsisEl && ellipsisEl.number === index
                                }
                                onClick={(e) => handleEllipsisClick(e, index)}
                              >
                                <Icon name="ellipsis" />
                              </ToggleButton>
                            )}
                        <Menu
                          anchorEl={ellipsisEl?.element}
                          open={Boolean(ellipsisEl?.element)}
                          onClose={handleCloseEllipsisMenu}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          {finalized && deleteAllowed && (
                            <MenuItem
                              onClick={handleOpenConfirmModal(
                                additionalCostsItem.id,
                                Confirm.Reopen
                              )}
                            >
                              <Stack direction="row" spacing={12}>
                                <Icon name="reset" />
                                <Typography>
                                  {t('project_detail.reopen')}
                                </Typography>
                              </Stack>
                            </MenuItem>
                          )}
                          {editAllowed && (
                            <MenuItem
                              onClick={() =>
                                handleOpenRecordOperatingCostsModal(
                                  additionalCostsItem
                                )
                              }
                            >
                              <Stack direction="row" spacing={12}>
                                <Icon name="pencil" />
                                <Typography>
                                  {t('project_detail.edit')}
                                </Typography>
                              </Stack>
                            </MenuItem>
                          )}
                          {deleteAllowed && !finalized && (
                            <MenuItem
                              onClick={handleOpenConfirmModal(
                                additionalCostsItem.id
                              )}
                            >
                              <Stack direction="row" spacing={12}>
                                <Icon name="trash" />
                                <Typography color="error">
                                  {t('project_detail.delete')}
                                </Typography>
                              </Stack>
                            </MenuItem>
                          )}
                        </Menu>
                      </>
                    }
                  />
                  <CardContent>
                    {isMobile ? (
                      <Button
                        fullWidth
                        onClick={handleOpenMoreInfoModal(index)}
                      >
                        {t('project_detail.more_info')}
                      </Button>
                    ) : (
                      <>
                        <Stack spacing={20}>
                          {items.map(({ label, value, isSum }, index) => (
                            <Fragment key={index}>
                              {isSum && <Divider />}
                              <Stack
                                key={index}
                                spacing={20}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant={isSum ? 'h5' : 'body1'}
                                  color={isSum ? 'error' : 'primary'}
                                >
                                  {label}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  color={isSum ? 'error' : 'primary'}
                                >
                                  {value}
                                </Typography>
                              </Stack>
                            </Fragment>
                          ))}
                        </Stack>
                        {finalized && (
                          <>
                            <Divider sx={{ my: 20 }} />
                            <FormControlLabel
                              checked={Boolean(accepted)}
                              disabled={!finalized || accepted}
                              label={t('project_detail.accept_prepayment')}
                              control={<Checkbox />}
                              onChange={handleOpenAcceptPrepaymentModal(
                                additionalCostsItem
                              )}
                            />
                            {accepted &&
                              tasks.map((task) => (
                                <FormControlLabel
                                  name={task.id}
                                  checked={Boolean(task.value)}
                                  label={task.title}
                                  control={<Checkbox />}
                                  onChange={handleCheckTask(
                                    additionalCostsItem.id,
                                    task.id,
                                    !task.value
                                  )}
                                />
                              ))}
                            <Stack spacing={10} mt={20}>
                              <Button
                                onClick={handleDownload(
                                  additionalCostsItem.id,
                                  'excel'
                                )}
                              >
                                {t('project_detail.billing_download_excel')}
                              </Button>
                              <Button
                                onClick={handleDownload(
                                  additionalCostsItem.id,
                                  'pdf'
                                )}
                              >
                                {t('project_detail.billing_download_pdf')}
                              </Button>
                            </Stack>
                          </>
                        )}
                        {!finalized && (
                          <Button
                            fullWidth
                            size="large"
                            onClick={handleOpenConfirmModal(
                              additionalCostsItem.id,
                              Confirm.Finalize
                            )}
                            sx={{ mt: 20 }}
                          >
                            {t('project_detail.finalize')}
                          </Button>
                        )}
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )
          )}
      </Grid>
      {visibleRecordOperatingCostsModal && (
        <RecordOperatingCostsModal
          projectId={projectDetail?.id}
          operatingCosts={additionalCostsData}
          open={visibleRecordOperatingCostsModal}
          onClose={handleCloseRecordOperatingCostsModal}
        />
      )}
      <AcceptPrepaymentModal
        open={visibleAcceptPrepaymentModal}
        onClose={handleCloseAcceptPrepaymentModal}
        prepaymentData={prepaymentData}
        additionalCostsId={additionalCostsId}
      />
      <ConfirmModal
        open={visibleConfirmModal}
        onClose={handleCloseConfirmModal}
        text={confirmText}
        onOk={handleConfirm}
      />
      {visibleAcceptPrepaymentModal && (
        <AcceptPrepaymentModal
          open={visibleAcceptPrepaymentModal}
          onClose={handleCloseAcceptPrepaymentModal}
          prepaymentData={prepaymentData}
          additionalCostsId={additionalCostsId}
        />
      )}
      {visibleMoreInfoModal &&
        additionalCostsItems &&
        selectedId !== undefined && (
          <Dialog
            title={t('project_detail.operating_cost')}
            open={visibleMoreInfoModal}
            onClose={handleCloseMoreInfoModal}
          >
            <Stack mb={20} direction="row" justifyContent="space-between">
              {additionalCostsItems[selectedId].withFilter ? (
                <Select value={filter} onChange={handleChangeFilter}>
                  {additionalCostsItems[selectedId].filterItems.map(
                    ({ label, value }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    )
                  )}
                </Select>
              ) : (
                <span />
              )}
              <Stack direction="row" spacing={10}>
                {additionalCostsItems[selectedId].finalized &&
                  additionalCostsItems[selectedId].deleteAllowed && (
                    <IconButton onClick={handleReopen}>
                      <Icon name="" />
                    </IconButton>
                  )}
                {additionalCostsItems[selectedId].editAllowed && (
                  <IconButton
                    onClick={() =>
                      handleOpenRecordOperatingCostsModal(
                        additionalCostsItems[selectedId].additionalCostsItem
                      )
                    }
                  >
                    <Icon name="pencil" />
                  </IconButton>
                )}
                {additionalCostsItems[selectedId].deleteAllowed &&
                  !additionalCostsItems[selectedId].finalized && (
                    <IconButton onClick={handleDelete}>
                      <Icon name="trash" />
                    </IconButton>
                  )}
              </Stack>
            </Stack>
            <Stack spacing={20}>
              {additionalCostsItems[selectedId].items.map(
                ({ label, value, isSum }, index) => (
                  <Fragment key={index}>
                    {isSum && <Divider />}
                    <Stack
                      key={index}
                      spacing={20}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant={isSum ? 'h5' : 'body1'}
                        color={isSum ? 'error' : 'primary'}
                      >
                        {label}
                      </Typography>
                      <Typography
                        variant="h5"
                        color={isSum ? 'error' : 'primary'}
                      >
                        {value}
                      </Typography>
                    </Stack>
                  </Fragment>
                )
              )}
            </Stack>
            {additionalCostsItems[selectedId].finalized && (
              <>
                <Divider sx={{ my: 20 }} />
                <FormControlLabel
                  checked={Boolean(additionalCostsItems[selectedId].accepted)}
                  disabled={
                    !additionalCostsItems[selectedId].finalized ||
                    additionalCostsItems[selectedId].accepted
                  }
                  label={t('project_detail.accept_prepayment')}
                  control={<Checkbox />}
                  onChange={handleOpenAcceptPrepaymentModal(
                    additionalCostsItems[selectedId].additionalCostsItem
                  )}
                />
                {additionalCostsItems[selectedId].accepted &&
                  additionalCostsItems[selectedId].tasks.map((task) => (
                    <FormControlLabel
                      name={task.id}
                      checked={Boolean(task.value)}
                      label={task.title}
                      control={<Checkbox />}
                      onChange={handleCheckTask(
                        additionalCostsItems[selectedId].additionalCostsItem.id,
                        task.id,
                        !task.value
                      )}
                    />
                  ))}
                <Stack spacing={10} mt={20}>
                  <Button
                    onClick={handleDownload(
                      additionalCostsItems[selectedId].additionalCostsItem.id,
                      'excel'
                    )}
                  >
                    {t('project_detail.billing_download_excel')}
                  </Button>
                  <Button
                    onClick={handleDownload(
                      additionalCostsItems[selectedId].additionalCostsItem.id,
                      'pdf'
                    )}
                  >
                    {t('project_detail.billing_download_pdf')}
                  </Button>
                </Stack>
              </>
            )}
            {!additionalCostsItems[selectedId].finalized && (
              <Button
                fullWidth
                size="large"
                onClick={handleFinalize}
                sx={{ mt: 20 }}
              >
                {t('project_detail.finalize')}
              </Button>
            )}
          </Dialog>
        )}
    </>
  );
};

// Export additional costs calculator panel
export default AdditionalCostsCalculatorPanel;
