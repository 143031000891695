// Dependencies
import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Components
import { Card, Carousel, ChatsModal } from '../../../../components';
import { getProjects } from '../../../../services/project.service';
import { debounce } from 'lodash';
import { IProjectData, Order } from '../../../../interfaces';
import { getDateTime, getLastMessage, getProjectPrimaryImage } from '../../../../utils';

// Styles
import * as S from './styles';
import { ListItem } from '../../../../components/Common/ListItem';

// Constants
const PER_PAGE = 5;

// Export last-chats widget
export const LastChatsWidget: FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [projects, setProjects] = useState<IProjectData[]>([]);
  const [openChatsProjectId, setOpenChatsProjectId] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  // Theme
  const theme = useTheme();

  // Check platform
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleSearch = (value: string) => {
    setSearch(value);
  };

  // Close chat modal
  const handleCloseChatsModal = () => {
    setOpenChatsProjectId(null);
  };

  // Open chat modal
  const handleOpenChatModal = (id) => {
    setOpenChatsProjectId(id);
  };

  // Page change handler
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const fetchClients = () => {
    setLoading(true);
    getProjects({
      groups: 'project:messages',
      messages: 1,
      sort: 'date-message',
      'sort-order': Order.Desc,
      ...(isMobile ? { pagination: 0 } : {
        search: debouncedSearch,
        page,
        perPage: PER_PAGE
      }),
    }).then((res: any) => {
      setProjects(res.data?.data || []);
      setLoading(false);
      const { total } = res.data?.pageInfo;
      if (total) {
        setTotal(Math.ceil(total / PER_PAGE));
      }
    }).catch(() => {
      setLoading(false);
    });
  };

  // Delayed search handler
  // https://stackoverflow.com/a/54666498
  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  // On search changed
  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
  }, [search, delayedChangeSearch]);

  // Fetch clients
  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, page]);

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const carouselItems = useMemo(() => {
    return projects.reduce((page: any[][], chat, index) => {
      if (index % 3 === 0) {
        return [...page, [chat]];
      } else {
        return [
          ...page.slice(0, page.length - 1),
          [...page[page.length - 1], chat],
        ];
      }
    }, []);
  }, [projects]);

  // Return last-chats widget
  return (
    <Card
      title={t('dashboard.last_chats')}
      searchable
      searchPlaceholder={t('dashboard.search_last_chats')}
      onSearch={handleSearch}
      search={search}
      loading={loading}
      isEmpty={projects?.length === 0}
      pagination={{
        total,
        current: page
      }}
      onPageChange={handlePageChange}
    >
      <Box sx={{ pr: { md: 14 }, display: { xs: 'none', sm: 'block' } }}>
        {projects && projects.map((project, index) => (
          <S.ChatListItem
            key={index}
            avatar={getProjectPrimaryImage(project)}
            avatarShape="circular"
            title={`${project.customer?.first_name} ${project.customer?.last_name}`}
            subTitle={getLastMessage(project)?.message || ''}
            secondaryText={
              <Typography variant="body2" component="span" color="darkCyan">{getDateTime(getLastMessage(project)?.date || '')}</Typography>
            }
            notifyCount={0}
            onClick={() => handleOpenChatModal(project.id)}
          />)
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' }, flex: 1 }}>
        <Carousel length={carouselItems?.length}>
          {carouselItems.map((page, index) => {
            return (
              <S.ChartListItemWrapper key={index}>
                {page.map(
                  (project, index) => (
                    <ListItem
                      key={index}
                      className="last-chat-item"
                      sx={{
                        '.MuiTypography-subtitle1.MuiListItemText-primary': {
                          maxWidth: 'calc(100vw - 200px)'
                        },
                        '.MuiTypography-body2 .MuiListItemText-secondary': {
                          maxWidth: 'calc(100vw - 135px)'
                        }
                      }}
                      avatar={getProjectPrimaryImage(project)}
                      title={`${project.customer?.first_name} ${project.customer?.last_name}`}
                      subTitle={getLastMessage(project)?.message || ''}
                      secondaryText={(
                        <Typography variant="body2" component="span" color="darkCyan">{getDateTime(getLastMessage(project)?.date || '')}</Typography>
                      )}
                      notifyCount={0}
                      onClick={() => handleOpenChatModal(project.id)}
                    />
                  )
                )}
              </S.ChartListItemWrapper>
            );
          })}
        </Carousel>
      </Box>
      {
        !!openChatsProjectId && (
          <ChatsModal open={!!openChatsProjectId} onClose={handleCloseChatsModal} projectId={openChatsProjectId} />
        )
      }
    </Card>
  );
};
