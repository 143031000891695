// Dependencies
import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
// Theme
import theme from './theme';
// Styles
import 'react-grid-layout/css/styles.css';
import './App.css';

// Create app
const App: FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <CssBaseline />
        {children}
      </SnackbarProvider>
    </MuiThemeProvider>
  </ThemeProvider>
);

// Export app
export default App;
