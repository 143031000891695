// Dependencies
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';

// Components
import { Icon, IconButton } from '../../../../components';
import { Card } from '../../../../components';
import { ListItem } from '../../../../components/Common/ListItem';
import { Carousel } from '../../../../components';
import { Accordion } from '../../../../components/Common/Accordion';

// Styles
import * as StatusService from '../../../../constants';

// Constants
import { IProjectData } from '../../../../interfaces';
import { getProjectPrimaryImage } from '../../../../utils';
import { IChange } from '../../../../interfaces';
import {
  getMainStatusKey,
  getStatusRoute,
  PROJECT_GROUPS,
  ROUTES,
} from '../../../../constants';
import { getProjects } from '../../../../services/project.service';
import { Order } from '../../../../interfaces';

const PER_PAGE = 5;

// Create accordion page
const AccordionPage = ({ page, pageIndex, onDetail }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<any>(-1);

  const handleExpand =
    (value: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? value : false);
    };
  const isVisible = (index) => {
    if (expanded === -1 || expanded === false) {
      return true;
    }
    if (expanded === index + pageIndex * 3) {
      return true;
    } else {
      const nth = expanded - pageIndex * 3;
      return nth === 2 ? index === 0 : nth + (index % 3) < 2;
    }
  };

  return (
    <Box sx={{ pt: 0 }}>
      {page.map(
        (
          {
            id,
            image,
            name,
            submittedAt,
            modifiedAt,
            statusKey,
            status,
            comment,
            notify,
          },
          index
        ) =>
          isVisible(index) ? (
            <Accordion
              key={index}
              expanded={expanded === index + pageIndex * 3}
              avatar={image || ''}
              title={name}
              subTitle={`${t('dashboard.submitted')}: ${submittedAt}`}
              secondarySubTitle={`${t('dashboard.last_change')}: ${modifiedAt}`}
              comment={comment && `${t('dashboard.comment')}: ${comment}`}
              status={status}
              notify={notify}
              onChange={handleExpand(index + pageIndex * 3)}
              onDetail={() => onDetail({ id, statusKey })}
            />
          ) : (
            <></>
          )
      )}
    </Box>
  );
};

// Export last-updated-objects widget
export const LastUpdatedObjectsWidget: FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [projects, setProjects] = useState<IProjectData[]>([]);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<Order>(Order.Desc);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  // Get navigate from hook
  const navigate = useNavigate();

  // Fetch objects
  const fetchObjects = () => {
    getProjects({
      status: Object.keys(StatusService.getAvailableFilter('all')),
      groups: PROJECT_GROUPS,
      sort: 'date-change',
      'sort-order': sortOrder,
      search: debouncedSearch,
      ...(isMobile
        ? { pagination: 0 }
        : {
            page,
            perPage: PER_PAGE,
          }),
    })
      .then((res) => {
        setProjects(res.data.data);
        setLoading(false);
        const { total } = res.data?.pageInfo;
        if (total) {
          setTotal(Math.ceil(total / PER_PAGE));
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, sortOrder]);

  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
  }, [search, delayedChangeSearch]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  const sortedProjects = useMemo(() => {
    if (projects && projects.length > 0) {
      const temp = projects.map((project) => {
        if (project.changes && project.changes.length > 0) {
          project.changes?.sort((a, b) => {
            if (Date.parse(a.date) === Date.parse(b.date)) return 0;
            return Date.parse(a.date) > Date.parse(b.date) ? -1 : 1;
          });
        }
        return project;
      });
      temp?.sort((a: IProjectData, b: IProjectData) => {
        if (a.changes && b.changes) {
          if (a.changes?.length && b.changes?.length) {
            return Number(
              moment(a.changes[0].date ?? '').isSame(
                moment(b.changes[0].date ?? '')
              )
            );
          } else if (a.changes.length) {
            return -1;
          } else if (b.changes.length) {
            return 1;
          } else {
            return 0;
          }
        }
        return 0;
      });

      return temp;
    }
    return [];
  }, [projects]);

  const lastSubmittedObjects = useMemo(() => {
    if (sortedProjects && sortedProjects.length > 0) {
      return sortedProjects.map((project: IProjectData) => {
        const image = getProjectPrimaryImage(project);

        let modifiedAt = '';
        let submittedAt = '';
        let createdAt = '';
        let lastWorkflowChange: IChange | undefined;
        let lastUpdateOrWorkflowChange: IChange | undefined;
        let createdItem: IChange | undefined;
        let comment = '';
        if (project.changes && project.changes.length) {
          const changes = project.changes;
          lastWorkflowChange = changes.find(
            (change) => change.type === 'workflow'
          );
          createdItem = changes.find((change) => change.type === 'created');
          lastUpdateOrWorkflowChange = changes.find(() => true);
          const submittedDateItem = changes.find((change) => {
            return (
              change.type === 'workflow' &&
              JSON.parse(change.context)?.transitionName ===
                'requestApproveOfNonBindingOfferForCustomer'
            );
          });
          if (lastWorkflowChange) {
            comment = String(
              JSON.parse(lastWorkflowChange.context)?.message
            ).trim();
          }
          if (lastUpdateOrWorkflowChange) {
            modifiedAt = moment(lastUpdateOrWorkflowChange.date).format(
              'DD.MM.YYYY'
            );
          }
          if (submittedDateItem) {
            submittedAt = moment(submittedDateItem.date).format('DD.MM.YYYY');
          } else if (createdItem) {
            createdAt = moment(createdItem.date).format('DD.MM.YYYY');
          }
        }

        return {
          id: project.id,
          image: image || '',
          name: `${project.customer.first_name} ${project.customer.last_name}`,
          createdAt,
          submittedAt,
          modifiedAt,
          statusKey: project.status,
          status:
            StatusService.STATUS.customer[project.status] ||
            StatusService.STATUS.property[project.status] ||
            '',
          comment,
          notify: false,
        };
      });
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedProjects]);

  const carouselItems = lastSubmittedObjects.reduce(
    (page: any[][], project, index) => {
      if (index % 3 === 0) {
        return [...page, [project]];
      } else {
        return [
          ...page.slice(0, page.length - 1),
          [...page[page.length - 1], project],
        ];
      }
    },
    []
  );

  const handleChangeSortOrder = () => {
    setSortOrder(sortOrder === Order.Asc ? Order.Desc : Order.Asc);
  };

  const handleRedirectToClientsDetail = ({ id, statusKey }) => {
    const status = getMainStatusKey(statusKey);
    const statusRoute = getStatusRoute(status);
    if (status && statusRoute) {
      navigate(
        ROUTES[statusRoute].DETAIL.replace(':filter', status).replace(':id', id)
      );
    }
  };

  // Page change handler
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    fetchObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, isMobile]);

  // Return last-updated-objects widget
  return (
    <Card
      title={t('dashboard.last_updated_objects')}
      action={
        <IconButton color="default" onClick={handleChangeSortOrder}>
          <Icon name="arrow-down-up" />
        </IconButton>
      }
      searchable
      searchPlaceholder={t('dashboard.search_last_submitted_objects')}
      onSearch={handleSearchChange}
      search={search}
      loading={loading}
      isEmpty={lastSubmittedObjects?.length === 0}
      emptyDataText={t('dashboard.no_last_submitted_objects')}
      pagination={{
        total,
        current: page,
      }}
      onPageChange={handlePageChange}
    >
      <Box
        sx={{
          pr: { md: 14 },
          display: { xs: 'none', sm: 'block' },
          height: '100%',
        }}
      >
        {lastSubmittedObjects.map(
          (
            {
              id,
              image,
              name,
              createdAt,
              submittedAt,
              modifiedAt,
              statusKey,
              status,
              notify,
            },
            index
          ) => {
            return (
              <ListItem
                key={index}
                avatar={image}
                title={name}
                subTitle={
                  <>
                    {submittedAt ? (
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{ mr: 4 }}
                      >{`${t(
                        'dashboard.submitted'
                      )}: ${submittedAt} `}</Typography>
                    ) : (
                      createdAt && (
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ mr: 4 }}
                        >{`${t(
                          'project_detail.created_date'
                        )}: ${createdAt} `}</Typography>
                      )
                    )}
                    <Typography
                      variant="body2"
                      component="span"
                      color="darkCyan"
                    >{`${t(
                      'dashboard.last_change'
                    )}: ${modifiedAt}`}</Typography>
                  </>
                }
                status={status}
                notify={notify}
                onClick={() => handleRedirectToClientsDetail({ id, statusKey })}
              />
            );
          }
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <Carousel length={carouselItems?.length}>
          {carouselItems.map((page, pageIndex) => {
            return (
              <AccordionPage
                page={page}
                pageIndex={pageIndex}
                key={pageIndex}
                onDetail={handleRedirectToClientsDetail}
              />
            );
          })}
        </Carousel>
      </Box>
    </Card>
  );
};
