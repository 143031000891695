// Dependencies
import styled from 'styled-components';

// Components
import { Dialog } from '../../Common';

export const WelcomeModal = styled(Dialog)`
  ${(props) => props.theme.breakpoints.down('md')} {
    margin: 16px;
  }
  && {
    .MuiDialog-paper {
      max-width: 500px;
    }
    .MuiDialogTitle-root {
      border-bottom: none;
    }
    .MuiDialogContent-root {
      padding-top: 36px !important;
      padding-bottom: 28px !important;
    }
    .MuiDialogActions-root {
      padding-bottom: 40px !important;
      ${(props) => props.theme.breakpoints.down('md')} {
        display: flex;
        flex-direction: column;
      }
      & > :not(:first-of-type) {
        margin: 12px;
        ${(props) => props.theme.breakpoints.down('md')} {
          margin: 16px 0 0 0;
        }
      }
    }
  }
`;

export const Logo = styled.img`
  width: 104px;
`;
