// Dependencies
import React, { FC, memo } from 'react';

import { isEqual, omit, functions } from 'lodash';
import { Marker as GoogleMapsMarker } from 'google-maps-react';

// Interface
import { IMarker } from '../../../interfaces';

interface IMarkerProps {
  marker: IMarker;
  active: boolean;
  onChangeMarker: any;
}

const propsAreEqual = (props, nextProps) => {
  const [funcs, nextFuncs] = [functions(props), functions(nextProps)];
  const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs));
  const noFuncChange = funcs.length === nextFuncs.length &&
    funcs.every(fn => props[fn].toString() === nextProps[fn].toString());
  return noPropChange && noFuncChange;
};

export const MapMarker: FC<IMarkerProps> = memo(({
  marker,
  active,
  onChangeMarker,
  ...props
}: IMarkerProps) => {
  const {
    name,
    position,
  } = marker;

  const iconSizeWidth = active ? 34.5 : 27;
  const iconSizeHeight = active ? 46 : 36;

  return (
    <GoogleMapsMarker
      position={position}
      onClick={(prop, mapMarker) => {
        onChangeMarker(mapMarker, marker);
      }}
      title={name}
      icon={{
        url: '/assets/images/marker.svg',
        anchor: new google.maps.Point(iconSizeWidth / 2, iconSizeHeight),
        scaledSize: new google.maps.Size(iconSizeWidth, iconSizeHeight),
      }}
      {...props}
    />
  );
}, propsAreEqual);
