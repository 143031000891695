import { IAccessByRoleAndIds, Role } from '../constants';
import { IUserData } from '../interfaces';

export const isUserAllowed = (user: IUserData | null = null, minRole: Role, maxRole: Role | null = null) => {
  if (user && user.hasOwnProperty("roles") && user.roles.length > 0) {
    return user.roles.some((role) => {
      // @ts-ignore
      if (maxRole != null && maxRole >= Role[role]) {
        return true;
      }
      // @ts-ignore
      return minRole != null && minRole <= Role[role];
    });
  } else {
    return false;
  }
};

export const isUserAllowedForRoles = (user: IUserData | null = null, roles: Role[]) => {
  const rolesToCheck = [...roles, Role.ROLE_SUPER_ADMIN];
  if (user && user.roles && Array.isArray(user.roles) && user.roles.length > 0) {
    // @ts-ignore
    return user.roles.some((role: Role) => rolesToCheck.includes(Role[role]));
  } else {
    return false;
  }
};

export const isUserAllowedByIds = (user: IUserData | null = null, accessArray: IAccessByRoleAndIds[] | null = null) => {
  if (
    user &&
    user.hasOwnProperty("id") &&
    accessArray &&
    Array.isArray(accessArray) &&
    accessArray.length > 0
  ) {
    return accessArray.some((access) => {
      const { role, ids = null } = access;
      // @ts-ignore
      if (isUserAllowedForRoles(user, [role])) {
        if (ids === null) return true;
        return ids?.includes(user.id);
      }
      return false;
    });
  }
  return true;
};
