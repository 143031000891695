// Dependencies
import React, { FC, useState } from 'react';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { ConfirmModal } from '../../../../../../../components';

// Icons
import {
  CheckIcon,
  DownloadIcon,
  EyeIcon,
  GearIcon,
} from '../../../../../../../assets/icons';

// Interfaces
import {
  IOfferCalculated,
  IOfferCalculation,
} from '../../../../../../../interfaces';

// Store
import { getProjectDetail } from '../../../../../../../redux/actions';
import { RootState } from '../../../../../../../redux/reducers';

// Constants
import { isStatusAllowed, Status } from '../../../../../../../constants';

// Styles
import * as S from './styles';
import { acceptBindingOfferCalculation } from '../../../../../../../services/project.service';

// Interfaces
interface IIndicativeOfferProps {
  index: number;
  visibleOffer: boolean;
  onToggleVisibleOffer: (id: number) => void;
  onDownload: () => void;
  onDelete: (id) => () => void;
  onCalculate: () => void;
  title: string;
  color: string;
  amount: number;
  items: {
    label: string;
    value: string;
    isSum?: boolean;
    border: number;
  }[];
  calculated: IOfferCalculated;
  calculation: IOfferCalculation;
  isBinding?: boolean;
}

// Create indicative offer
const CardDetail: FC<IIndicativeOfferProps> = ({
  index,
  visibleOffer,
  onToggleVisibleOffer,
  onDownload,
  onDelete,
  onCalculate,
  title,
  color,
  amount,
  items,
  calculated,
  calculation,
  isBinding,
}) => {
  // States
  const [visibleMarkConfirmModal, setVisibleMarkConfirmModal] =
    useState<boolean>(false);

  // Get translation from hook
  const { t } = useTranslation();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get project detail from store
  const projectDetail = useSelector(
    ({ projectReducer: { projectDetail } }: RootState) => projectDetail
  );

  // Toggle visible offer handler
  const handleToggleVisibleOffer = () => {
    onToggleVisibleOffer(index);
  };

  // Open confirm modal to change preferred handler
  const handleOpenMarkConfirmModal = () => {
    setVisibleMarkConfirmModal(true);
  };

  // Confirm handler
  const handleOkConfirmModal = async () => {
    handleCloseConfirmModal();
    await acceptBindingOfferCalculation(projectDetail?.id, calculated.id);
    await dispatch(getProjectDetail(projectDetail?.id as number));
  };

  // Close confirm modal handler
  const handleCloseConfirmModal = () => {
    setVisibleMarkConfirmModal(false);
  };

  // Actions
  const actions = [
    ...(calculation.offerCalculated?.binding &&
    isStatusAllowed(
      projectDetail?.status as string,
      Status.approvalOfBindingOfferByCustomer
    )
      ? [
          {
            icon: <CheckIcon />,
            active: calculated.preferred,
            disabled: !isStatusAllowed(
              projectDetail?.status as string,
              null,
              Status.approvalOfBindingOfferByCustomer
            ),
            label: t('project_detail.marked_as_accepted'),
            handler: handleOpenMarkConfirmModal,
          },
        ]
      : []),
    {
      icon: <GearIcon />,
      disabled: !isStatusAllowed(
        projectDetail?.status,
        null,
        Status.approvalOfBindingOfferByCustomer
      ),
      label: t('project_detail.update_calculation'),
      handler: onCalculate,
    },
    {
      icon: <DownloadIcon />,
      label: t('project_detail.download_offer'),
      handler: onDownload,
    },
  ];

  // Return indicative offer
  return (
    <>
      <S.Offer
        color={color}
        index={index}
        expanded={visibleOffer}
        onChange={handleToggleVisibleOffer}
      >
        <S.OfferSummary visibleOffer={visibleOffer}>
          <EyeIcon width={20} />
          <Typography>
            {isBinding
              ? visibleOffer
                ? t('project_detail.close_binding_offer')
                : t('project_detail.open_binding_offer')
              : visibleOffer
              ? t('project_detail.close_offer')
              : t('project_detail.open_offer')}
          </Typography>
        </S.OfferSummary>
        <S.OfferDetail color={color}>
          <Grid container>
            <Grid item xs={12} xl={8}>
              <Typography variant="h3">
                {isBinding
                  ? t('project_detail.binding_offer')
                  : t('project_detail.indicative_offer')}{' '}
                {amount} | {title}
              </Typography>
              <Stack mt={20} spacing={16}>
                {items.map(({ label, value, border, isSum }, index) => (
                  <>
                    {border !== undefined && (
                      <Divider
                        sx={(theme) => ({
                          my: 8,
                          borderColor:
                            border === 2
                              ? theme.palette.primary.main
                              : '#dadee3',
                        })}
                      />
                    )}
                    <Stack
                      key={index}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography variant={isSum ? 'h4' : 'body1'}>
                        {label}
                      </Typography>
                      <Typography
                        variant={isSum ? 'h4' : 'h5'}
                        color={isSum ? 'darkGreen !important' : 'primary'}
                      >
                        {value}
                      </Typography>
                    </Stack>
                  </>
                ))}
              </Stack>
              {calculation.hasOwnProperty('rent_time') &&
                calculation?.rentTime !== 0 && (
                  <Typography
                    align="center"
                    variant="h3"
                    mx={-20}
                    py={20}
                    sx={(theme) => ({
                      borderTop: `1px solid ${theme.palette.secondary.main}`,
                    })}
                  >
                    Zeitrente auf {calculation.rentTime} Jahre
                  </Typography>
                )}
            </Grid>
            <Grid item xs={12} xl={4}>
              <S.Actions
                mt={12}
                direction={{ xs: 'row', xl: 'column' }}
                spacing={{ xs: 0, xl: 12 }}
                alignItems="center"
              >
                {actions.map(
                  ({ icon, label, active, disabled, handler }, index) => (
                    <S.OfferButton
                      key={index}
                      active={active}
                      disabled={disabled}
                      onClick={handler}
                    >
                      {icon}
                      <Typography>{label}</Typography>
                    </S.OfferButton>
                  )
                )}
              </S.Actions>
            </Grid>
          </Grid>
        </S.OfferDetail>
      </S.Offer>
      {visibleMarkConfirmModal && (
        <ConfirmModal
          open={visibleMarkConfirmModal}
          title={
            calculated.preferred
              ? t('project_detail.decline_binding_offer')
              : t('project_detail.accept_binding_offer')
          }
          text={
            calculated.preferred
              ? t('project_detail.decline_binding_offer_text')
              : t('project_detail.accept_binding_offer_text')
          }
          confirmLabel={
            calculated.preferred
              ? t('confirm.ok_decline_offer')
              : t('confirm.ok_accept_offer')
          }
          onClose={handleCloseConfirmModal}
          onOk={handleOkConfirmModal}
        />
      )}
    </>
  );
};

// Export indicative offer
export default CardDetail;
