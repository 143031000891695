// Dependencies
import React, { FC, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

// Components
import {
  Button,
  Icon,
  IconButton,
  Input,
  Select,
  Typography,
} from '../../../Common';

// Interfaces
import { IProjectData } from '../../../../interfaces';
import {
  getDateTime,
  getLastMessage,
  getProjectPrimaryImage,
} from '../../../../utils';

import * as S from './styles';
import * as StatusService from '../../../../constants';
import { ChatType } from '..';

interface IClientViewProps {
  visible: boolean;
  projects: IProjectData[] | any;
  selectedId: number | undefined;
  search: string;
  statusFilter: string;
  loading: boolean;
  onChangeSortOrder: () => void;
  setSearch: (value: string) => void;
  setStatusFilter: (value: string) => void;
  onStartNewChat: () => void;
  onClickClient: (id: number) => void;
  chatType: ChatType;
}

// Create clients view
const ClientsView: FC<IClientViewProps> = ({
  visible,
  projects,
  selectedId,
  onClickClient,
  onStartNewChat,
  search,
  statusFilter,
  loading,
  onChangeSortOrder,
  setSearch,
  setStatusFilter,
  chatType,
}) => {
  // const [visibleSearchInput, setVisibleSearchInput] = useState<boolean>(false);

  // Get translation from hook
  const { t } = useTranslation();

  // Theme
  const theme = useTheme();

  // Check platform
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('all');

  useEffect(() => {
    setSelectedStatusFilter(statusFilter);
  }, [statusFilter]);

  // Handle change search
  const handleSearchChange = (e) => {
    if (setSearch) {
      setSearch(e.target.value);
    }
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setSelectedStatusFilter(statusFilter);
    setAnchorEl(null);
  };

  const handleChangeStatusFilter = (e) => {
    setSelectedStatusFilter(e.target.value);
  };

  const handleApplyFilter = () => {
    setStatusFilter(selectedStatusFilter);
    setAnchorEl(null);
  };

  const handleCancelFilter = () => {
    setStatusFilter('all');
    handleFilterClose();
  };
  //
  // const handleToggleSearchInput = () => {
  //   setVisibleSearchInput(!visibleSearchInput);
  // };

  // Return Client view
  return (
    <Box
      sx={() => ({
        minWidth: { xs: '100%', lg: 380 },
        p: { xs: 16, lg: '16px 16px 20px 20px' },
        display: {
          xs: visible ? 'block' : 'none',
          lg: 'block',
        },
      })}
    >
      <Stack
        mb={{ xs: 16, sm: 12 }}
        spacing={{ xs: 8, sm: 12 }}
        direction="row"
      >
        {isTablet && chatType === ChatType.External ? (
          <Button fullWidth onClick={onStartNewChat}>
            {t('chats.start_new_chat')}
          </Button>
        ) : (
          <Input
            sx={{ flex: 1 }}
            placeholder={t('chats.search')}
            startAdornment={<Icon name="search" />}
            value={search}
            onChange={handleSearchChange}
          />
        )}

        {chatType === ChatType.External ? (
          <S.FilterIconButton onClick={handleFilterClick} active={!!anchorEl}>
            <Icon name="funnel-fill" />
          </S.FilterIconButton>
        ) : null}
        <S.Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <S.FilterPopover>
            <Stack direction="row" justifyContent="space-between" mb={12}>
              <Typography variant="h3">{t('dashboard.filter')}</Typography>
              <Stack direction="row">
                <Button sx={{ mr: 8 }} onClick={handleCancelFilter}>
                  {t('chats.clear_all')}
                </Button>
                <Button color="primary" onClick={handleApplyFilter}>
                  {t('chats.apply')}
                </Button>
              </Stack>
            </Stack>
            <S.SelectWrapper>
              <Typography variant="body2" mb={4}>
                {t('chats.by_status')}
              </Typography>
              <Select
                value={selectedStatusFilter}
                onChange={handleChangeStatusFilter}
              >
                <MenuItem value="all">All</MenuItem>
                {Object.values(StatusService.STATUS_MAPPING).map(
                  (filter, index) => (
                    <MenuItem key={index} value={filter.key}>
                      <ListItemText
                        primary={filter.name}
                        sx={{ fontSize: 8 }}
                      />
                    </MenuItem>
                  )
                )}
              </Select>
            </S.SelectWrapper>
          </S.FilterPopover>
        </S.Popover>
        <IconButton onClick={onChangeSortOrder}>
          <Icon name="arrow-down-up" />
        </IconButton>
      </Stack>
      {chatType === ChatType.External ? (
        <Button
          fullWidth
          sx={{ mb: 20, display: { xs: 'none', lg: 'block' } }}
          onClick={onStartNewChat}
          style={{ backgroundColor: '#DFFAEA' }}
        >
          {t('chats.start_new_chat')}
        </Button>
      ) : null}
      <PerfectScrollbar
        style={{
          height: isTablet ? 'calc(100vh - 230px)' : 'calc(100vh - 400px)',
          paddingRight: isTablet ? 0 : 13,
        }}
      >
        {loading ? (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {projects.map((project) => (
              <ListItem
                key={project.id}
                onClick={() => onClickClient(project.id)}
                sx={(theme: any) => ({
                  borderRadius: 2,
                  p: { xs: '10px 8px', sm: 12 },
                  mb: 8,
                  cursor: 'pointer',
                  background:
                    selectedId === project.id
                      ? theme.palette.whiteIndigo
                      : 'transparent',
                })}
              >
                <ListItemAvatar sx={{ minWidth: { xs: 64, sm: 80 } }}>
                  <Avatar
                    src={getProjectPrimaryImage(project)}
                    variant="rounded"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    0 ? (
                      // Todo: display unread message counts
                      <Stack direction="row" alignItems="center" spacing={8}>
                        <Typography variant="subtitle1">
                          {project?.customer?.first_name
                            ? project.customer?.first_name
                            : project?.first_name}{' '}
                          {project?.customer?.last_name
                            ? project?.customer?.last_name
                            : project?.last_name}
                        </Typography>
                        <Typography
                          variant="caption"
                          align="center"
                          sx={(theme) => ({
                            borderRadius: 1.5,
                            width: 20,
                            height: 20,
                            color: theme.palette.common.white,
                            bgcolor: theme.palette.error.main,
                          })}
                        >
                          {0}
                        </Typography>
                      </Stack>
                    ) : (
                      `${
                        project.customer?.first_name
                          ? project?.customer?.first_name
                          : project?.first_name
                      } ${
                        project.customer?.last_name
                          ? project.customer?.last_name
                          : project?.last_name
                      }`
                    )
                  }
                  secondary={getLastMessage(project)?.message || ''}
                />
                <Typography
                  variant={isTablet ? 'caption' : 'body2'}
                  mt={{ xs: -28, sm: -24 }}
                >
                  {getDateTime(getLastMessage(project)?.date || '')}
                </Typography>
              </ListItem>
            ))}
          </List>
        )}
      </PerfectScrollbar>
    </Box>
  );
};

export default ClientsView;
