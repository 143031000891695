// Dependencies
import { FC, useEffect } from 'react';
import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';

// Styles
import * as S from './styles';
import { darkIndigo } from '../../../theme/palette';
import { Icon } from '../Icon';

interface IDatePickerProps {
  label?: string;
  format?: string;
  disabled?: boolean;
  onChange: (date: string | Date | null) => void;
  value: any;
  disableFuture?: boolean;
}

const CalendarIcon = () => <Icon name="calendar" color={darkIndigo} />;

// Export datepicker
export const DatePicker: FC<IDatePickerProps> = ({
  label,
  format = 'dd.MM.yyyy',
  disabled,
  onChange,
  value,
  disableFuture,
}) => {
  const [date, setDate] = React.useState<Date | null>(
    value ? new Date(value) : null
  );
  const { i18n, t } = useTranslation();

  const locale = i18n.language === 'de' ? de : en;

  const handleChange = (newValue: Date | null) => {
    if (moment(newValue).isAfter(moment()) && disableFuture) {
      setDate(null);
      onChange('');
      return;
    }

    setDate(newValue);
    onChange(newValue ? moment(newValue).format('YYYY-MM-DD') : '');
  };

  useEffect(() => {
    if (value === null || value === '') {
      setDate(null);
    } else if (moment(date).format('YYYY-MM-DD') !== value) {
      setDate(new Date(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DesktopDatePicker
        label={label && label}
        inputFormat="dd.MM.yyyy"
        value={date}
        disabled={disabled}
        onChange={handleChange}
        disableFuture={disableFuture}
        renderInput={(params) => (
          <S.FormInput
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: t('general.date_format'),
            }}
          />
        )}
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
      />
    </LocalizationProvider>
  );
};
