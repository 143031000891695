// Dependencies
import { FC, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// MUI
import { FormControl, FormLabel, FormHelperText, Stack } from '@mui/material';

// Components
import { Dialog, Button, Typography, Input } from '../../Common';

// Form
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Store
import { useSnackbar } from 'notistack';
import { changePassword } from '../../../services/auth.service';
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/actions';

interface IChangePasswordProps {
  open: boolean;
  onClose: () => void;
  isDesktop?: boolean;
}

// Export Change Password Modal
export const ChangePasswordModal: FC<IChangePasswordProps> = ({
  open,
  onClose,
  isDesktop,
}) => {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Get translation from hook
  const { t } = useTranslation();

  // Get notification from hook
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // Validation schema
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(t('validation.password_required')),
    newPassword: Yup.string()
      .required(t('validation.password_required'))
      .min(8, t('validation.min_length_8'))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{};:\'\"\\|,.<>?]).{8,}$/,
        t('profile.choose_strong_password')
      ),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), ''], t('validation.password_not_matched'))
      .required(t('validation.confirm_password_required'))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{};:\'\"\\|,.<>?]).{8,}$/,
        t('profile.choose_strong_password')
      ),
  });

  // Get formik from hook
  const formik = useFormik<any>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const { currentPassword, newPassword, confirmNewPassword } = values;

      setIsLoading(true);
      await changePassword({
        currentPassword,
        plainPassword: newPassword,
        confirmPlainPassword: confirmNewPassword,
      })
        .then((res) => {
          if (res.data?.success) {
            enqueueSnackbar(res.data?.message, { variant: 'success' });
            dispatch(logout());
            onClose();
          } else {
            enqueueSnackbar(res.data?.message || 'Failed to change password', {
              variant: 'error',
            });
          }
        })
        .catch((e: any) => {
          enqueueSnackbar(
            e.response?.data?.message || 'Failed to change password',
            { variant: 'error' }
          );
        });
      setIsLoading(false);
    },
  });

  // Return Form Input
  const formInput = ({
    label = '',
    placeholder = '',
    path = '',
    underHelperText = '',
  }) => {
    const formikError = _.get(formik.errors, path);
    const formikTouched = _.get(formik.touched, path);
    return (
      <FormControl error={!!(formikError && formikTouched)}>
        <FormLabel>{label}</FormLabel>
        <Input
          size="medium"
          type="password"
          placeholder={placeholder}
          disabled={isLoading}
          {...formik.getFieldProps(path)}
        />
        <FormHelperText>
          {(!!(formikError && formikTouched) && formikError) || underHelperText}
        </FormHelperText>
      </FormControl>
    );
  };

  // Return Change Password Modal
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('profile.change_password')}
      actions={
        <Button
          type="submit"
          onClick={() => formik.handleSubmit()}
          color="primary"
          size="large"
          fullWidth={!isDesktop}
          loading={isLoading}
        >
          {t('profile.change_password')}
        </Button>
      }
    >
      <Typography variant="body1">
        {t('profile.choose_strong_password')}
      </Typography>
      <Typography variant="body1" mt={24}>
        {t('profile.change_password_description')}
      </Typography>
      <Stack spacing={28} mt={28}>
        {formInput({
          label: t('profile.current_password') + ' *',
          placeholder: t('profile.current_password'),
          path: 'currentPassword',
          underHelperText: t('profile.use_at_least_characters'),
        })}
        {formInput({
          label: t('profile.new_password') + ' *',
          placeholder: t('profile.new_password'),
          path: 'newPassword',
        })}
        {formInput({
          label: t('profile.confirm_new_password') + ' *',
          placeholder: t('profile.confirm_new_password'),
          path: 'confirmNewPassword',
        })}
      </Stack>
    </Dialog>
  );
};
