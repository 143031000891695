// Dependencies
import React, { FC, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  Box,
  Stack,
  useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';

// Components
import { Icon, IconButton, Typography } from '../../index';

// Utils
import { isUserAllowed } from '../../../utils';

// Constants
import { Role } from '../../../constants';

// Interfaces
import { RootState } from '../../../redux/store';

interface IContractCardProps {
  title: string;
  items: {
    label: string;
    value: string;
    hidden?: boolean;
  }[];
  onEdit: () => void;
}

// Export contract card
export const ContractCard: FC<IContractCardProps> = ({
  title,
  items,
  onEdit,
}) => {
  // States
  const [open, setOpen] = useState<boolean>(false);

  // Get theme from hook
  const theme = useTheme();

  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Toggle handler
  const handleToggle = () => {
    setOpen(!open);
  };

  // Return contract card
  return (
    <>
      <Accordion
        expanded={open}
        sx={{
          borderRadius: 3,
          border: `1px solid ${theme.palette.secondary.main}`,
          ':before': {
            height: 0,
          },
          '&.Mui-expanded': {
            '&:not(:first-of-type)': {
              marginTop: 20,
            },
          },
        }}
      >
        <Stack
          p={12}
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{
            borderBottom: open
              ? `1px solid ${theme.palette.secondary.main}`
              : 'none',
          }}
        >
          <Box>
            <Typography variant="h3">{title}</Typography>
          </Box>
          <Stack direction="row" spacing={10} alignItems="center">
            <IconButton
              disabled={!isUserAllowed(account, Role.ROLE_ADMIN)}
              onClick={onEdit}
            >
              <Icon name="pencil" />
            </IconButton>
            <IconButton color={'default'} onClick={handleToggle}>
              <Icon
                color={theme.palette['darkIndigo']}
                name={open ? 'arrow-up' : 'arrow-down'}
              />
            </IconButton>
          </Stack>
        </Stack>
        <AccordionDetails sx={{ mb: 20 }}>
          <Stack mt={20} spacing={20} px={10}>
            {items.map(
              ({ label, value, hidden }, index) =>
                !hidden && (
                  <Stack
                    key={index}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography>{label}</Typography>
                    <Typography variant="h4">{value}</Typography>
                  </Stack>
                )
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
