// Dependencies
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Components
import { Card, LineChart, Select, Typography } from '../../../../components';

// Store
import { RootState } from '../../../../redux/reducers';
import {
  getMonthAnalyseData,
  getYearAnalyseData,
} from '../../../../redux/actions';

// Styles
import * as S from '../styles';

// Constants
enum TotalCustomerChartType {
  thisYear = 'this_year',
  lastYear = 'last_year',
}

enum NewCustomerChartType {
  thisMonth = 'this_month',
  lastMonth = 'last_month',
}

// Create Analytics
const Analytics = () => {
  // States
  const [totalChartType, setTotalChartType] = useState<TotalCustomerChartType>(
    TotalCustomerChartType.thisYear
  );
  const [newChartType, setNewChartType] = useState<NewCustomerChartType>(
    NewCustomerChartType.thisMonth
  );
  const [totalClientCount, setTotalClientCount] = useState<string>('');
  const [increaseRate, setIncreaseRate] = useState<string>('');

  // Get translation from hook
  const { t } = useTranslation();

  // Get data from store
  const {
    totalCustomerData,
    newCustomerData,
    yearAnaliseLoading,
    monthAnaliseLoading,
  } = useSelector(
    ({
      projectReducer: {
        totalCustomerData,
        newCustomerData,
        yearAnaliseLoading,
        monthAnaliseLoading,
      },
    }: RootState) => ({
      totalCustomerData,
      newCustomerData,
      yearAnaliseLoading,
      monthAnaliseLoading,
    })
  );

  const newChartMonth = useMemo(() => {
    if (newChartType === NewCustomerChartType.thisMonth) {
      return moment().format('MM');
    } else {
      return moment().subtract(1, 'month').format('MM');
    }
  }, [newChartType]);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Change total chart type
  const handleChangeTotalChartType = (e) => {
    setTotalChartType(e.target.value);
  };

  // Change new chart type
  const handleChangeNewChartType = (e) => {
    setNewChartType(e.target.value);
  };

  // Fetch chart data
  useEffect(() => {
    dispatch(getYearAnalyseData(totalChartType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalChartType]);

  useEffect(() => {
    dispatch(getMonthAnalyseData(newChartType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChartType]);

  // Calculate increase rate
  useEffect(() => {
    const yearData = totalCustomerData[TotalCustomerChartType.thisYear];
    const lastYearData = totalCustomerData[TotalCustomerChartType.lastYear];

    if (yearData.length > 0) {
      let prevMonthValue;

      if (+newChartMonth === 1) {
        prevMonthValue = lastYearData[11]?.value;
      } else {
        prevMonthValue = yearData[+newChartMonth - 2]?.value;
      }
      const currentMonthValue = yearData[+newChartMonth - 1]?.value;

      if (prevMonthValue === 0) {
        setIncreaseRate('100');
      } else if (currentMonthValue === 0) {
        setIncreaseRate('-100');
      } else {
        setIncreaseRate(
          (
            ((currentMonthValue - prevMonthValue) / prevMonthValue) *
            100
          ).toFixed(1)
        );
      }
    }
  }, [newChartMonth, totalCustomerData]);

  useEffect(() => {
    setTotalClientCount(
      totalCustomerData[totalChartType].reduce(
        (totalCount, item) => totalCount + item.value,
        0
      )
    );
  }, [totalChartType, totalCustomerData]);

  // Return analytics
  return (
    <Grid container columns={2} spacing={12}>
      <Grid item xs={2} md={1}>
        <Card
          sx={{
            height: '332px',
            '.scrollbar-container': {
              height: yearAnaliseLoading ? '210px' : '100%',
            },
          }}
          title={t('projects.total_clients')}
          loading={yearAnaliseLoading}
          action={
            <Select
              value={totalChartType}
              onChange={handleChangeTotalChartType}
            >
              <MenuItem value={TotalCustomerChartType.lastYear}>
                {t('projects.last_year')}
              </MenuItem>
              <MenuItem value={TotalCustomerChartType.thisYear}>
                {t('projects.this_year')}
              </MenuItem>
            </Select>
          }
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            mb={12}
          >
            <Typography sx={{ mr: 12 }}>
              {t('projects.total_clients')}
            </Typography>
            <S.ChartInfoLabel color="#799FE5">
              {totalClientCount}
            </S.ChartInfoLabel>
          </Box>
          <LineChart
            title="Clients"
            data={totalCustomerData[totalChartType]}
            lineColor="#799FE5"
          />
        </Card>
      </Grid>
      <Grid item xs={2} md={1}>
        <Card
          sx={{
            height: '332px',
            display: 'flex',
            flexDirection: 'column',
            '.scrollbar-container': {
              height: monthAnaliseLoading ? '210px' : '100%',
            },
          }}
          title={t('projects.new_clients')}
          action={
            <Select value={newChartType} onChange={handleChangeNewChartType}>
              <MenuItem value={NewCustomerChartType.lastMonth}>
                {t('projects.last_month')}
              </MenuItem>
              <MenuItem value={NewCustomerChartType.thisMonth}>
                {t('projects.this_month')}
              </MenuItem>
            </Select>
          }
          loading={monthAnaliseLoading}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ mb: 12, height: 48 }}
          >
            {newChartType === NewCustomerChartType.thisMonth && (
              <>
                <Typography sx={{ mr: 12 }}>
                  {moment(newChartMonth, 'MM').format('MMMM')}
                </Typography>
                <S.ChartInfoLabel color="#4ED2A2">
                  {increaseRate}%
                </S.ChartInfoLabel>
              </>
            )}
          </Box>
          <LineChart
            title={t('projects.new_clients')}
            month={moment(newChartMonth, 'MM').format('MMMM')}
            data={newCustomerData[newChartType]}
            lineColor="#4ED2A2"
          />
        </Card>
      </Grid>
    </Grid>
  );
};

// Export analytics
export default Analytics;
