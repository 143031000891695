import { ThunkDispatch } from 'redux-thunk';

import { ApiTypes } from '../../interfaces';
import { NEWS_ACTIONS } from '../action-types';
import { RootState } from '../store';

export const getNews =
  (params?: any) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getNews = async () => {
      const res: any = await api.news.getNews(params);
      return res.data.data;
    };
    return dispatch({
      type: NEWS_ACTIONS.GET_NEWS,
      payload: getNews(),
    });
  };
