import { TASK_ACTIONS } from '../action-types';
import { ITask } from '../../interfaces';

export interface TaskReducerState {
  tasks: ITask[];
  loading: boolean;
}

const initialState: TaskReducerState = {
  tasks: [],
  loading: false,
};

const TaskReducer = (state: TaskReducerState = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case TASK_ACTIONS.GET_TASKS_START:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.GET_TASKS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case TASK_ACTIONS.GET_TASKS_SUCCESS: {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    }

    case TASK_ACTIONS.UPDATE_TASK_GROUP_START:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.UPDATE_TASK_GROUP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case TASK_ACTIONS.UPDATE_TASK_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case TASK_ACTIONS.UPDATE_TASK_START:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.UPDATE_TASK_ERROR:
      return {
        ...state,
        loading: false,
      };

    case TASK_ACTIONS.UPDATE_TASK_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default TaskReducer;
