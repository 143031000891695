import { ThunkDispatch } from 'redux-thunk';

import { ApiTypes } from '../../interfaces';
import { USER_ACTIONS } from '../action-types';
import { RootState } from '../store';

export const postUser =
  (user) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const postUser = async () => {
      const res = await api.user.postUser(user);
      return res.data;
    };

    return dispatch({
      type: USER_ACTIONS.POST_USER,
      payload: postUser(),
    });
  };

export const getUsers =
  (params) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getUsers = async () => {
      const res: any = await api.user.getUsers(params);

      return {
        users: res.data?.data || [],
        pagination: res.data?.pageInfo,
      };
    };

    return dispatch({
      type: USER_ACTIONS.GET_USERS,
      payload: getUsers(),
    });
  };

export const getUserDetail =
  (id: number) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    return dispatch({
      type: USER_ACTIONS.GET_USER_DETAIL,
      payload: api.user.getUserDetail(id),
    });
  };

export const updateUser =
  (id, forms) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const updateUser = async () => {
      const res = await api.user.updateUser(id, forms);
      return res.data;
    };

    return dispatch({
      type: USER_ACTIONS.UPDATE_USER,
      payload: updateUser(),
    });
  };

export const getIternalUsers =
  (params) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getIternalUsers = async () => {
      const res: any = await api.user.getIternalUsers(params);

      return {
        users: res.data?.data || [],
        pagination: res.data?.pageInfo,
      };
    };

    return dispatch({
      type: USER_ACTIONS.GET_ITERNAL_USER,
      payload: getIternalUsers(),
    });
  };
