import styled from 'styled-components';
import { ListItemText as MuiListItemText } from '@mui/material';

export const ListItemText = styled(MuiListItemText)`
  && {
    max-width: calc(100vw - 150px);
    
    .MuiListItemText-primary {
      white-space: nowrap;
      max-width: calc(100vw - 150px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .MuiListItemText-secondary {
      white-space: nowrap;
      max-width: calc(100vw - 150px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;