// Dependencies
import styled from 'styled-components';
import { IconButton } from '../../../Common';
import { MentionsInput } from 'react-mentions';

export const DarkIconButton = styled(IconButton)`
  background-color: ${(props) => props.theme.palette.darkIndigo} !important;
  color: #fff !important;
`;

export const Mention = styled(MentionsInput)`
  font-family: Exo, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4375em;
  color: #172844;
  /* box-sizing: border-box; */
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  background-color: #d7e2eb;
  min-height: 52px;
  border-radius: 12px;
  padding-left: 20px;
  -webkit-flex: 2;
  -ms-flex: 2;
  flex: 2;
  border: none;
  outline: none;
  padding: 15px;
`;
