import { APIS, buildFormRequestData, buildURLWithParams } from '../utils';
import { DELETE, GET, POST } from './axios.services';

export const getProjects = async (params) => {
  return await GET(`${APIS.project.projects}`, params, null, true);
};

export const getPersonalDataForm = async () => {
  return await GET(`${APIS.project.personalDataForm}`, null, null, true);
};

export const getModernizationsForm = async () => {
  return await GET(`${APIS.project.modernizationsForm}`, null, null, true);
};

export const getObjectPropertiesForm = async () => {
  return await GET(`${APIS.project.objectPropertiesForm}`, null, null, true);
};

export const updateCustomer = async (id, formData) => {
  const data = buildFormRequestData({ formData });
  return await POST(`${APIS.project.updateCustomer}/${id}`, data, null, true);
};

export const updateProperty = async (id, formData) => {
  const data = buildFormRequestData({ formData });
  return await POST(`${APIS.project.updateProperty}/${id}`, data, null, true);
};

export const updatePreferredCalculation = async (id, data) => {
  return await POST(
    APIS.project.updatePreferredCalculation.replace(':id', id),
    data,
    null,
    true
  );
};

export const getProjectDetail = async (id) => {
  const res = await GET(`${APIS.project.getProject}/${id}`, null, null, true);
  if (res.data.success) {
    return res.data.data;
  } else {
    throw res.data;
  }
};

export const getRegistrationForm = async () => {
  return await GET(`${APIS.project.registrationForm}`, null, null, true);
};

export const sendMessageToCustomer = async (id, message) => {
  const res = await POST(
    `${APIS.project.projects}/${id}/messages`,
    { message },
    null,
    true
  );
  return res.data;
};

export const getMessages = async (id) => {
  const res = await GET(
    `${APIS.project.projects}/${id}/messages`,
    null,
    null,
    true
  );
  return res.data.data;
};

export const updateWorkflow = async (id, payload) => {
  const res = await POST(
    `${APIS.project.projects}/${id}/workflow`,
    payload,
    null,
    true
  );
  return res.data;
};

export const deleteProject = async (id) => {
  const res = await DELETE(`${APIS.project.projects}/${id}`, null, null, true);
  return res.data;
};

export const deleteMultipleProject = async (ids) => {
  const res = await DELETE(
    APIS.project.deleteMultipleProjects,
    { ids },
    null,
    true
  );
  return res.data;
};

export const getAnalysisData = async (type, period) => {
  return await GET(`${APIS.project.analysis}/${type}`, { period }, null, true);
};

export const deleteCalculation = async (id) => {
  const res = await DELETE(
    APIS.project.deleteCalculation.replace(':id', id),
    null,
    null,
    true
  );
  return res.data;
};

export const addPayout = async (id, formData) => {
  const data = buildFormRequestData({ formData });
  return await POST(
    APIS.project.addPayout.replace(':id', id),
    data,
    null,
    true
  );
};

export const calculatePayout = async (formData) => {
  return await POST(APIS.project.calculatePayout, formData, null, true);
};

export const updateCalculation = async (id, data) => {
  return await POST(
    APIS.project.updateCalculation.replace(':id', id),
    data,
    null,
    true
  );
};

export const deletePayout = async (id) => {
  const res = await DELETE(
    APIS.project.deletePayout.replace(':id', id),
    null,
    null,
    true
  );
  return res.data;
};

export const addPaymentDetails = async (id, formData) => {
  const data = buildFormRequestData({ formData });
  return await POST(
    APIS.project.paymentDetails.replace(':id', id),
    data,
    null,
    true
  );
};

export const addEligiblePersonData = async (id, formData) => {
  const data = buildFormRequestData({ formData });
  return await POST(
    APIS.project.enterEligiblePerson.replace(':id', id),
    data,
    null,
    true
  );
};

export const updateEligiblePersonData = async (id, personId, formData) => {
  const data = buildFormRequestData({ formData });
  return await POST(
    APIS.project.enterEligiblePerson
      .replace(':id', id)
      .replace(':personId', personId),
    data,
    null,
    true
  );
};

export const addEmergencyContactPersonData = async (id, formData) => {
  const data = buildFormRequestData({ formData });
  return await POST(
    APIS.project.enterEmergencyContactPerson.replace(':id', id),
    data,
    null,
    true
  );
};

export const updateEmergencyContactPersonData = async (
  id,
  personId,
  formData
) => {
  const data = buildFormRequestData({ formData });
  return await POST(
    APIS.project.enterEmergencyContactPerson
      .replace(':id', id)
      .replace(':personId', personId),
    data,
    null,
    true
  );
};

export const assignBroker = async (id, creator) => {
  const res = await POST(
    APIS.project.assignBroker.replace(':id', id),
    { creator },
    null,
    true
  );
  return res.data;
};

export const deleteAdditionalCosts = async (projectId, additionalCostsId) => {
  const res = await DELETE(
    APIS.project.deleteAdditionalCosts
      .replace(':projectId', projectId)
      .replace(':additionalCostsId', additionalCostsId),
    null,
    null,
    true
  );
  return res.data;
};

export const finalizeAdditionalCosts = async (projectId, additionalCostsId) => {
  try {
    const res = await POST(
      APIS.project.finalizeAdditionalCosts
        .replace(':projectId', projectId)
        .replace(':additionalCostsId', additionalCostsId),
      null,
      null,
      true
    );
    return res.data;
  } catch (err: any) {
    return err.response.data.message;
  }
};

export const reopenAdditionalCosts = async (projectId, additionalCostsId) => {
  const res = await POST(
    APIS.project.reopenAdditionalCosts
      .replace(':projectId', projectId)
      .replace(':additionalCostsId', additionalCostsId),
    null,
    null,
    true
  );
  return res.data;
};

export const acceptPrepayment = async (
  projectId,
  additionalCostsId,
  paymentData
) => {
  const data = buildFormRequestData({ paymentData });
  const res = await POST(
    APIS.project.acceptPrepayment
      .replace(':projectId', projectId)
      .replace(':additionalCostsId', additionalCostsId),
    data,
    null,
    true
  );
  return res.data;
};

export const checkAdditionalCostsTask = async (
  projectId,
  additionalCostsId,
  formData
) => {
  try {
    const res = await POST(
      APIS.project.checkAdditionalCostsTask
        .replace(':projectId', projectId)
        .replace(':additionalCostsId', additionalCostsId),
      formData,
      null,
      true
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const addAdditionalCost = async (id, valueformData) => {
  const data = buildFormRequestData({ formData: valueformData });
  const res = await POST(
    APIS.project.additionalCosts.replace(':id', id),
    data,
    null,
    true
  );
  return res;
};

export const updateAdditionalCost = async (
  projectId,
  additionalCostsId,
  valueformData
) => {
  const data = buildFormRequestData({ formData: valueformData });

  const res = await POST(
    APIS.project.updateAdditionalCosts
      .replace(':projectId', projectId)
      .replace(':additionalCostsId', additionalCostsId),
    data,
    null,
    true
  );
  return res;
};

export const exportProjects = (params) => {
  const url = buildURLWithParams(
    process.env.REACT_APP_API_SERVER,
    APIS.project.exportProjects,
    params
  );
  window.open(url, '_blank');
};

export const acceptBindingOfferCalculation = async (
  projectId,
  calculationId
) => {
  const res = await POST(
    APIS.project.acceptBindingOffer
      .replace(':projectId', projectId)
      .replace(':calculationId', calculationId),
    null,
    null,
    true
  );
  return res;
};
