// Dependecies
import React, { FC } from 'react';

// Table
import {
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

// Components
import { Typography } from '../../../components';

// Hooks
import { useTranslation } from 'react-i18next';

// Styles
import * as S from './style';

interface ITableProps {
  array: any;
  d_index: number;
  prefix: string;
}

// Export Table
export const Table: FC<ITableProps> = ({ array, d_index, prefix }) => {
  // Get translation from hook
  const { t } = useTranslation();

  // Return Table
  return (
    <TableContainer>
      <MuiTable>
        <TableHead>
          <TableRow>
            {new Array(array[d_index].style.table.column)
              .fill(0)
              .map((_, column_index) => (
                <S.HeadCell
                  key={column_index}
                >
                  <Typography variant="h4">
                    {t(`faq.${prefix}_description_table_head_${column_index + 1}`)}
                  </Typography>
                </S.HeadCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {new Array(array[d_index].style.table.row)
            .fill(0)
            .map((_, row_index) => (
              <TableRow
                key={row_index}
              >
                {new Array(array[d_index].style.table.column)
                  .fill(0)
                  .map((_, column_index) => (
                    <S.BodyCell
                      key={column_index}
                      style={{
                        borderLeft:
                          +column_index + 1 === 1 ? 0 : '2px solid #ea504c',
                      }}
                    >
                      <Typography>
                        {t(`faq.${prefix}_description_table_body_${column_index + 1}_${row_index + 1}`)}
                      </Typography>
                    </S.BodyCell>
                  ))}
              </TableRow>
            ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
