// Dependencies
import { createTheme } from '@mui/material';
import { CSSProperties } from 'react';

// Configs
import breakpoints from './breakpoints';
import components from './components';
import palette from './palette';
import typography from './typography';
import shadows from './shadows';

declare module '@mui/material/styles' {
  interface Palette {
    cyan: CSSProperties['color'];
    lightCyan: CSSProperties['color'];
    darkCyan: CSSProperties['color'];
    whiteCyan: CSSProperties['color'];
    blackCyan: CSSProperties['color'];
    indigo: CSSProperties['color'];
    darkIndigo: CSSProperties['color'];
    lightIndigo: CSSProperties['color'];
    whiteIndigo: CSSProperties['color'];
    lightBlue: CSSProperties['color'];
    lightGreen: CSSProperties['color'];
    darkGreen: CSSProperties['color'];
    blue: CSSProperties['color'];
    lightRed: CSSProperties['color'];
    red: CSSProperties['color'];
    green: CSSProperties['color'];
    orange: CSSProperties['color'];
    gray: CSSProperties['color'];
  }
  interface PaletteOptions {
    cyan: CSSProperties['color'];
    lightCyan: CSSProperties['color'];
    darkCyan: CSSProperties['color'];
    whiteCyan: CSSProperties['color'];
    blackCyan: CSSProperties['color'];
    indigo: CSSProperties['color'];
    darkIndigo: CSSProperties['color'];
    lightIndigo: CSSProperties['color'];
    whiteIndigo: CSSProperties['color'];
    lightBlue: CSSProperties['color'];
    lightGreen: CSSProperties['color'];
    darkGreen: CSSProperties['color'];
    blue: CSSProperties['color'];
    lightRed: CSSProperties['color'];
    red: CSSProperties['color'];
    green: CSSProperties['color'];
    orange: CSSProperties['color'];
    gray: CSSProperties['color'];
  }
}

// Create theme
const theme = createTheme({
  spacing: 1,
  breakpoints,
  components,
  palette,
  typography,
  shadows,
});

// Export theme
export default theme;
