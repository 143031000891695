// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Card } from '../../../../components';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { BarChart } from '../../../../components/Common/Charts/BarChart';

// Services
import * as StatusService from '../../../../constants';
import { getProjects } from '../../../../services/project.service';
import { getFromCurrency } from '../../../../utils/project.utils';

// Types
import { IProjectData } from '../../../../interfaces';
import { PeriodMode } from '../../../../interfaces/period.type';
import { CardActionSelect } from '../../styles';

// Export object-overview widget
export const ObjectsValueWidget: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [period, setPeriod] = useState<PeriodMode>(PeriodMode.ThisMonth);
  const [projects, setProjects] = useState<IProjectData[]>([]);

  useEffect(() => {
    setLoading(true);
    getProjects({
      status: Object.keys(StatusService.getAvailableFilter('inProgress')),
      groups: 'project:marketdata',
      // period,
      period: period === 'all' ? null : period, // for demo
      pagination: 0,
    }).then((res) => {
      setProjects(res.data.data);
      setLoading(false);
    }).catch(() => {
      setProjects([]);
      setLoading(false);
    });
  }, [period]);

  const chartData = useMemo(() => {
    let tempCount = {};
    let tempAmount = {};

    if (projects && projects.length > 0) {
      projects.forEach((project) => {
        const type = project.status ?? 'unknown';
        if (!tempAmount.hasOwnProperty(type)) tempAmount[type] = 0;
        if (!tempCount.hasOwnProperty(type)) tempCount[type] = 0;
        tempCount[type]++;
        tempAmount[type] += +getFromCurrency(
          project?.sprengnetter?.market_value ?? ''
        );
      });
    }

    const keys = Object.keys(StatusService.STATUS.customer);
    const data: any = [];
    keys.forEach((statusKey) => {
      if (tempCount.hasOwnProperty(statusKey)) {
        const status = StatusService.STATUS.customer[statusKey];
        data.push({
          name: status?.name ?? 'Unbekannt',
          objects: tempCount[statusKey],
          value: tempAmount[statusKey],
        });
      }
    });

    return data;
  }, [projects]);

  // Date change handler
  const handleChangePeriod = (e: SelectChangeEvent) => {
    setPeriod(e.target.value as PeriodMode);
  };

  // Return object-overview widget
  return (
    <Card
      title={t('dashboard.objects_value')}
      action={
        <CardActionSelect value={period} onChange={handleChangePeriod}>
          <MenuItem value={PeriodMode.LastMonth}>{t('dashboard.last_month')}</MenuItem>
          <MenuItem value={PeriodMode.ThisMonth}>{t('dashboard.this_month')}</MenuItem>
          {/* next item for demo */}
          <MenuItem value={'all'}>{t('dashboard.all')}</MenuItem>
        </CardActionSelect>
      }
      loading={loading}
      isEmpty={chartData?.length === 0}
    >
      <BarChart data={chartData} />
    </Card>
  );
};
