// Dependencies
import styled from 'styled-components';
import { Card } from '@mui/material';

// Export styled components
export const TableCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: auto;
  .MuiCardContent-root {
    flex: 1;
  }
`;
