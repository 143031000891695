// Dependencies
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Stack } from '@mui/material';

// Sections
import MarketDataSection from './MarketData';
import MarketValuesSection from './MarketValues';
import PayoutVariantSection from './PayoutVariant';

// Utils
import { isUserAllowed } from '../../../../../../utils';

// Interfaces
import { RootState } from '../../../../../../redux/store';

// Constants
import { Role } from '../../../../../../constants';

// Create indicative offer panel
const IndicativeOfferPanel: FC = () => {
  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Return indicative offer panel
  return (
    <Stack spacing={{ xs: 20, sm: 40 }} divider={<Divider />}>
      {isUserAllowed(account, Role.ROLE_ADMIN) && <MarketDataSection />}
      {isUserAllowed(account, Role.ROLE_ADMIN) && <MarketValuesSection />}
      <PayoutVariantSection />
    </Stack>
  );
};

// Export indicative offer panel
export default IndicativeOfferPanel;
