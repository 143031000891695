// Dependencies
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from '@mui/material';
import differenceInYears from 'date-fns/differenceInYears';
import _ from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import { Checkbox, ImageGallery, Input, Select, Typography } from '../Common';
import { ImageList } from '../Common/ImageList';
//Assets
import { InfoIcon, Visibility, VisibilityOff } from '../../assets/icons/index';
// Constants
import { Role } from '../../constants';

// Store
import { RootState } from '../../redux/reducers';

// Interfaces
import ReactPhoneInput from 'react-phone-input-material-ui';
import 'react-phone-input-material-ui/lib/style.css';
import { IFileSection, IForm } from '../../interfaces';
import { POST } from '../../services/axios.services';
import { removeFile } from '../../services/file.service';
import {
  APIS,
  buildFormRequestData,
  extractImageSection,
  getCurrency,
  getFromCurrency,
  isUserAllowed,
  splitFilesByExtension,
} from '../../utils';
import { DatePicker } from '../Common/DatePicker';
import { FileList } from '../Common/FileList';
import { Switch } from '../Common/Switch';

export enum FormType {
  Customer = 'customer',
  Property = 'property',
  User = 'user',
}

interface IFormProps extends IForm {
  formik: any;
  formType?: FormType;
  path: string;
  parentFields?: any;
  layout?: any;
  required?: boolean;
  recursive?: boolean;
  disabled?: boolean;
  disableSelfRoleChange?: boolean;
  hiddenFields?: string[];
  refetchFormData?: () => void;
  onFieldChange?: (path: string, field: string, value: any) => void;
}

let imageGallery: IFileSection[] = [];

export const FormBuilder: FC<IFormProps> = ({
  type,
  formType,
  attributes,
  label,
  fields,
  formik,
  path,
  layout,
  parentFields,
  disabled,
  disableSelfRoleChange,
  required,
  recursive,
  hiddenFields,
  refetchFormData,
  onFieldChange,
}) => {
  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  const [isInitialSelectValueUpdated, setIsInitialSelectValueUpdated] =
    useState<boolean>(false);

  useEffect(() => {
    if (type === 'select' && formik.getFieldProps(path).value !== undefined) {
      if (formik.getFieldProps('passed_away').value) {
        formik.setFieldValue(
          'passed_away',
          formik.getFieldProps('passed_away').value.toString()
        );
      }
      setIsInitialSelectValueUpdated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, formik.getFieldProps(path).value]);
  const formikError = _.get(formik.errors, path);
  const formikTouched = _.get(formik.touched, path);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [visibleImageGallery, setVisibleImageGallery] =
    useState<boolean>(false);
  const [imageNumber, setImageNumber] = useState<number>(0);
  const [imageGroupNumber, setImageGroupNumber] = useState<number>(0);

  const [formDependencies, formDependencyOptions] = useMemo(() => {
    if (!attributes) {
      return [null, null];
    }
    const dependencies = attributes?.attr?.dependencies;
    const dependencyOptions = attributes?.attr?.dependencyOptions;
    if (!dependencies) {
      return [null, null];
    } else {
      return [dependencies, dependencyOptions];
    }
  }, [attributes]);

  const formAttr = useMemo(() => {
    if (!attributes) {
      return null;
    }

    const attr = attributes.attr;
    if (!attr) {
      return null;
    } else {
      return attr;
    }
  }, [attributes]);

  const [isVisible, isRequired] = useMemo(() => {
    let [visible, required] = [true, false];
    if (formDependencies && formik?.getFieldProps()?.value) {
      const terms = path ? path.split('.') : [];
      const parentPath = terms.slice(0, terms.length - 1).join('.');
      const setRequired =
        formDependencyOptions && formDependencyOptions.setRequired;
      for (let key in formDependencies) {
        let dependencyPath = key;
        if (key.includes('.')) {
          dependencyPath = key;
        } else {
          const fields = Object.entries(formik?.getFieldProps()?.value);
          for (const field of fields) {
            if (field[1] && typeof field[1] === 'object') {
              if (Object.keys(field[1]).includes(key)) {
                dependencyPath = field[0] + '.' + key;
              }
              // @ts-ignore
              if (field[1].length > 0 && parentPath) {
                dependencyPath = parentPath + '.' + key;
              }
            } else if (parentPath) {
              dependencyPath = parentPath + '.' + key;
            }
          }
        }

        const dependencyValue = formik.getFieldProps(dependencyPath).value;
        const dep = formDependencies[key];

        if (!dep) {
          return [visible, required];
        }
        if (dep.type === 'array') {
          let result;
          if (dep.reverse) {
            if (dep.validator.length === 1 && dep.validator[0] === 'non') {
              result = dependencyValue !== 'non' && !!dependencyValue;
            } else {
              result = !dep.validator.includes(dependencyValue);
            }
          } else {
            if (Array.isArray(dependencyValue)) {
              result = dep.validator.every((itemInValidator) => {
                return dependencyValue.includes(itemInValidator);
              });
            } else {
              result = dep.validator.includes(dependencyValue);
            }
          }
          if (setRequired) required = result;
          if (!setRequired) {
            visible = result;
            if (result) {
              required = formDependencyOptions?.required !== false;
            }
          }
          if (!visible) return [visible, required];
        } else if (dep.type === 'radio') {
          const validatorPath = parentPath
            ? parentPath + '.' + dep.validator
            : dep.validator;
          visible = formik.getFieldProps(validatorPath).value;
          required = false;
          if (!visible) return [visible, required];
        } else if (dep.type === 'age_min') {
          visible =
            differenceInYears(new Date(), new Date(dependencyValue)) >=
            dep.validator;
          required = false;
          if (!visible) return [visible, required];
        } else if (dep.type === 'age_max') {
          visible =
            differenceInYears(new Date(), new Date(dependencyValue)) <=
            dep.validator;
          required = false;
          if (!visible) return [visible, required];
        } else if (dep.type === 'min') {
          visible = +dependencyValue >= dep.validator;
          required = false;
          if (!visible) return [visible, required];
        } else {
          if (setRequired) required = false;
          if (!setRequired) visible = true;
        }
      }
    }
    return [visible, required];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDependencies, formDependencyOptions, formik, path, parentFields]);

  const isDisable = useMemo(() => formAttr?.disabled, [formAttr]);

  if (!recursive) {
    imageGallery = [];
  }

  const handleOpenImageGallery = (imageNumber: number, imageGroup?: string) => {
    setImageNumber(imageNumber);
    if (imageGroup) {
      const galleryNumber = imageGallery.findIndex(
        ({ label }) => imageGroup === label
      );
      setImageGroupNumber(galleryNumber);
    }
    setVisibleImageGallery(true);
  };

  const handleCloseImageGallery = () => {
    setVisibleImageGallery(false);
  };

  const handleFieldChange = (e) => {
    const terms = path.split('.');
    const currentKey = terms[terms.length - 1];
    const parentPath = terms.slice(0, terms.length - 1).join('.');

    if (
      path === 'registerUserData.mobile' ||
      path === 'registerUserData.phone' ||
      path === 'phone' ||
      path === 'mobile'
    ) {
      e = e[2] === '0' ? e.slice(0, 2) + e.slice(3) : e;
    }

    formik.setFieldValue(path, e.target ? e.target.value : e);

    onFieldChange &&
      onFieldChange(parentPath, currentKey, e.target ? e.target.value : e);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const renderHelp = (helpText?: string) => {
    if (!helpText) return null;
    return (
      <FormHelperText component="div">
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: `<span style="white-space: break-spaces;">${helpText}</span>`,
          }}
        />
      </FormHelperText>
    );
  };

  const handleKeyDown = (e) => {
    const cursorPosition = e?.target?.selectionStart;

    if ((cursorPosition === 3 || cursorPosition === 4) && e.keyCode === 48) {
      // Prevent entering zero at the third position
      e.preventDefault();
    }
  };

  const buildForm = (fieldType: string) => {
    let currentValue = formik.getFieldProps(path).value;

    if (
      currentValue &&
      typeof currentValue === 'object' &&
      !Array.isArray(currentValue) &&
      fieldType !== 'file'
    ) {
      currentValue = Object.keys(currentValue).map((key) => [
        key,
        currentValue[key],
      ]);
    }

    if (!isVisible && fieldType !== 'file') {
      return <></>;
    }

    if (hiddenFields && hiddenFields.includes(fieldType)) {
      return <></>;
    }

    const isBirth = path ? path.includes('birth') : false;
    
    const mobilePhoneMask = {
      de: '... ........',
      at: '... ........',
      ch: '... ........',
    };

    const landlinePhoneMask = {
      de: '.. .... ......',
      at: '.. .... ......',
      ch: '.. .... ......',
    };

    const phoneMasks = {
      'registerUserData.mobile': mobilePhoneMask,
      mobile: mobilePhoneMask,
      'personalData.mobile': mobilePhoneMask,
      'registerUserData.phone': landlinePhoneMask,
      phone: landlinePhoneMask,
      'personalData.phone': landlinePhoneMask,
    };


    switch (fieldType) {
      case 'date': {
        return (
          <Grid item xs={2} md={layout?.date ? layout?.date : 1}>
            <FormControl error={!!(formikError && formikTouched)}>
              <FormLabel>
                {label}
                {(required || isRequired) && '*'}
              </FormLabel>
              <DatePicker
                disableFuture={isBirth}
                onChange={(val: any) => handleFieldChange(val)}
                disabled={disabled}
                value={formik.getFieldProps(path).value}
              />

              <FormHelperText>
                {!!(formikError && formikTouched) && formikError}
              </FormHelperText>
              {renderHelp(formAttr?.help)}
            </FormControl>
          </Grid>
        );
      }
      case 'text':
        return (
          <Grid item xs={2} md={layout?.text ? layout?.text : 1}>
            <FormControl
              error={
                !!(formikError && formikTouched) ||
                !!(formikError && formik.submitCount)
              }
              disabled={disabled}
            >
              <FormLabel>
                {label}
                {(required || isRequired) && '*'}
              </FormLabel>
              <Input
                size="medium"
                disabled={disabled}
                {...formik.getFieldProps(path)}
                onChange={handleFieldChange}
              />
              <FormHelperText>
                {(!!(formikError && formikTouched) && formikError) ||
                  (!!(formikError && formik.submitCount) && formikError)}
              </FormHelperText>
              {renderHelp(formAttr?.help)}
            </FormControl>
          </Grid>
        );
      case 'choice':
        return (
          <Grid item xs={2} md={layout?.select ? layout?.select : 1}>
            <FormControl
              error={
                !!(formikError && formikTouched) ||
                !!(formikError && formik.submitCount)
              }
            >
              <FormLabel>
                {label}
                {(required || isRequired) && '*'}
              </FormLabel>
              <Select
                size="medium"
                sx={{ mt: '0 !important' }}
                disabled={disabled}
                {...formik.getFieldProps(path)}
                onChange={(e) => {
                  handleFieldChange(e);
                }}
              >
                {Object.keys(attributes.choices).map((item, index) => (
                  <MenuItem key={index} value={attributes.choices[item]}>
                    {item}
                  </MenuItem>
                ))}
              </Select>

              <FormHelperText>
                {(!!(formikError && formikTouched) && formikError) ||
                  (!!(formikError && formik.submitCount) && formikError)}
              </FormHelperText>
              {renderHelp(formAttr?.help)}
            </FormControl>
          </Grid>
        );
      case 'password':
        return (
          <Grid item xs={2} md={layout?.password ? layout?.password : 1}>
            <FormControl error={!!(formikError && formikTouched)}>
              <FormLabel>
                {label}
                {(required || isRequired) && '*'}
              </FormLabel>
              <Input
                size="medium"
                type={!showPassword ? 'password' : 'text'}
                disabled={disabled}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                {...formik.getFieldProps(path)}
              />
              <FormHelperText>
                {!!(formikError && formikTouched) && formikError}
              </FormHelperText>
              {renderHelp(formAttr?.help)}
            </FormControl>
          </Grid>
        );
      case 'currency':
        return (
          <Grid item xs={2} md={layout?.currency ? layout?.currency : 1}>
            {isDisable ? (
              <>
                {formAttr.isResult && <Divider sx={{ mb: 10 }} />}
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant={formAttr.isResult ? 'h5' : 'body2'}
                    color={formAttr.isResult ? 'error' : 'primary'}
                  >
                    {label}
                  </Typography>
                  <Typography
                    variant={formAttr.isResult ? 'h5' : 'h6'}
                    color={formAttr.isResult ? 'error' : 'primary'}
                  >
                    {getCurrency(currentValue)}{' '}
                    {label === 'Gesamtanteile' || label === 'Ihr Anteil'
                      ? ''
                      : '€'}
                  </Typography>
                </Stack>
              </>
            ) : (
              <FormControl error={!!(formikError && formikTouched)}>
                <FormLabel>
                  {label}
                  {(required || isRequired) && '*'}
                </FormLabel>
                <Input
                  size="medium"
                  endAdornment={
                    label === 'Gesamtanteile' || label === 'Ihr Anteil' ? (
                      ''
                    ) : (
                      <>€</>
                    )
                  }
                  {...formik.getFieldProps(path)}
                  value={
                    label === 'Gesamtbetrag, Euro'
                      ? getCurrency(currentValue, 2, true)
                      : getCurrency(currentValue, 2, false)
                  }
                  disabled={disabled}
                  onChange={(e) => {
                    handleFieldChange(e);
                    formik.setFieldValue(path, getFromCurrency(e.target.value));
                  }}
                />
                <FormHelperText>
                  {!!(formikError && formikTouched) && formikError}
                </FormHelperText>
                {renderHelp(formAttr?.help)}
              </FormControl>
            )}
          </Grid>
        );
      case 'textarea':
        return (
          <Grid item xs={2} md={layout?.textarea ? layout?.textarea : 1}>
            <FormControl error={!!(formikError && formikTouched)}>
              <FormLabel>
                {label}
                {(required || isRequired) && '*'}
              </FormLabel>
              <Input
                size="medium"
                multiline
                minRows={8}
                disabled={disabled}
                {...formik.getFieldProps(path)}
              />
              <FormHelperText>
                {!!(formikError && formikTouched) && formikError}
              </FormHelperText>
              {renderHelp(formAttr?.help)}
            </FormControl>
          </Grid>
        );
      case 'tel':
        return (
          <Grid item xs={2} md={layout?.tel ? layout?.tel : 1}>
            <FormControl error={!!(formikError && formikTouched)}>
              <FormLabel>
                {label}
                {(required || isRequired) && '*'}
              </FormLabel>
              <ReactPhoneInput
                onKeyDown={handleKeyDown}
                component={Input}
                inputProps={{
                  sx: {
                    padding: '10px 12px',
                    fontSize: '16px',
                    minHeight: '44px',
                  },
                }}
                copyNumbersOnly={false}
                size="medium"
                disabled={disabled}
                country={'de'}
                masks={phoneMasks[path] || undefined}
                onlyCountries={['de', 'ch', 'at']}
                {...formik.getFieldProps(path)}
                onChange={handleFieldChange}
              />
              <FormHelperText>
                {!!(formikError && formikTouched) && formikError}
              </FormHelperText>
              {renderHelp(formAttr?.help)}
            </FormControl>
          </Grid>
        );
      case 'integer':
        return (
          <Grid item xs={2} md={layout?.integer ? layout?.integer : 1}>
            <FormControl error={!!(formikError && formikTouched)}>
              <FormLabel>
                {label}
                {(required || isRequired) && '*'}
              </FormLabel>
              <Input
                size="medium"
                disabled={disabled}
                {...formik.getFieldProps(path)}
                onChange={handleFieldChange}
              />
              <FormHelperText>
                {!!(formikError && formikTouched) && formikError}
              </FormHelperText>
              {renderHelp(formAttr?.help)}
            </FormControl>
          </Grid>
        );
      case 'float':
        return (
          <Grid item xs={2} md={layout?.float ? layout?.float : 1}>
            {isDisable ? (
              <>
                {formAttr.isResult && <Divider sx={{ mb: 10 }} />}
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant={formAttr.isResult ? 'h5' : 'body2'}
                    color={formAttr.isResult ? 'error' : 'primary'}
                  >
                    {label}
                  </Typography>
                  <Typography
                    variant={formAttr.isResult ? 'h5' : 'h6'}
                    color={formAttr.isResult ? 'error' : 'primary'}
                  >
                    {currentValue}
                  </Typography>
                </Stack>
              </>
            ) : (
              <FormControl error={!!(formikError && formikTouched)}>
                <FormLabel>
                  {label}
                  {(required || isRequired) && '*'}
                </FormLabel>
                <Input
                  size="medium"
                  disabled={disabled}
                  {...formik.getFieldProps(path)}
                  onChange={handleFieldChange}
                />
                <FormHelperText>
                  {!!(formikError && formikTouched) && formikError}
                </FormHelperText>
                {renderHelp(formAttr?.help)}
              </FormControl>
            )}
          </Grid>
        );
      case 'checkbox':
        const entriesChoiches: [string, string][] = Object.entries(
          attributes.choices
        );

        const handleCheckboxChange = (value: any, event: any) => {
          let valueToProcess: string[] = [];
          if (currentValue) {
            valueToProcess = entriesChoiches
              .filter(([_, itemValue]) => currentValue?.includes(itemValue))
              .map(([_, itemValue]) => itemValue);
          }
          if (event.target.checked) {
            valueToProcess.push(value);
          } else {
            valueToProcess = valueToProcess.filter((v) => v !== value);
          }
          formik.setFieldValue(path, valueToProcess);
        };

        return (
          <Grid item xs={2} md={layout?.checkbox ? layout?.checkbox : 1}>
            <Typography variant="h3" sx={{ mb: 20, marginLeft: '12px' }}>
              {label}
              {(required || isRequired) && '*'}
            </Typography>
            <FormGroup>
              <Grid container columns={2} spacing={8}>
                {entriesChoiches.map(([key, value], index, keys) => {
                  return (
                    attributes?.choices && (
                      <Grid
                        key={index}
                        item
                        xs={2}
                        md={keys.length > 4 ? 1 : 2}
                      >
                        {currentValue && (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                value={attributes?.choices[key]}
                                disabled={disableSelfRoleChange}
                                style={{ marginLeft: '12px' }}
                              />
                            }
                            label={key}
                            disabled={disableSelfRoleChange}
                            checked={currentValue?.includes(
                              attributes?.choices[key]
                            )}
                            onChange={handleCheckboxChange.bind(this, value)}
                          />
                        )}
                      </Grid>
                    )
                  );
                })}
              </Grid>
              <FormControl error={!!(formikError && formikTouched)}>
                <FormHelperText>
                  {!!(formikError && formikTouched) && formikError}
                </FormHelperText>
              </FormControl>
              {renderHelp(formAttr?.help)}
            </FormGroup>
          </Grid>
        );
      case 'radio':
        return (
          <Grid item xs={2} md={layout?.radio ? layout?.radio : 1}>
            <Typography variant="h3" sx={{ mb: 20, marginLeft: '12px' }}>
              {label}
              {(required || isRequired) && '*'}
            </Typography>
            <FormGroup sx={{ mb: 20 }}>
              <RadioGroup>
                <Grid container columns={2} spacing={8}>
                  {Object.keys(attributes.choices).map((item, index, keys) => (
                    <Grid key={index} item xs={2} md={keys.length > 4 ? 1 : 2}>
                      <FormControlLabel
                        control={
                          <Radio
                            value={attributes.choices[item]}
                            disabled={disabled}
                            sx={{ marginLeft: '12px' }}
                          />
                        }
                        label={item}
                        checked={
                          String(currentValue) ===
                          String(attributes.choices[item])
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            formik.setFieldValue(
                              path,
                              attributes.choices[item]
                            );
                          }
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
              <FormControl error={!!(formikError && formikTouched)}>
                <FormHelperText>
                  {!!(formikError && formikTouched) && formikError}
                </FormHelperText>
              </FormControl>
              {renderHelp(formAttr?.help)}
            </FormGroup>
          </Grid>
        );
      case 'radioButton':
        const terms = path.split('.');
        const parentPath = terms.slice(0, terms.length - 1).join('.');
        const currentKey = terms[terms.length - 1];
        const radios = Object.entries(parentFields || {})
          .filter(
            ([key, value]: [string, any]) =>
              currentKey !== key && value && value.type === 'radioButton'
          )
          .map(([key]) => key);

        let error = false;
        return (
          <Grid item xs={2} md={2}>
            <FormGroup>
              <FormControlLabel
                value={currentValue === null ? '' : currentValue}
                sx={{ margin: 0, marginLeft: '12px' }}
                control={
                  <Switch
                    sx={{ mr: 8, marginLeft: '12px' }}
                    checked={Boolean(currentValue)}
                    disabled={disabled}
                    onChange={() => {
                      if (!currentValue) {
                        radios.forEach((radio) => {
                          formik.setFieldValue(
                            `${parentPath ? parentPath + '.' : ''}${radio}`,
                            false
                          );
                        });
                        error = !currentValue;
                      }
                      error = !currentValue;
                      formik.setFieldValue(path, !currentValue);
                    }}
                  />
                }
                label={label}
              />
              <FormControl error={!!(formikError && formikTouched) || error}>
                <FormHelperText>
                  {!!(formikError && formikTouched) && formikError}
                </FormHelperText>

                {renderHelp(formAttr?.help)}
              </FormControl>
            </FormGroup>
          </Grid>
        );
      case 'select': {
        return (
          <Grid item xs={2} md={layout?.select ? layout?.select : 1}>
            <FormControl error={!!(formikError && formikTouched)}>
              <FormLabel>
                {label}
                {(required || isRequired) && '*'}
              </FormLabel>
              {isInitialSelectValueUpdated && (
                <Select
                  size="medium"
                  sx={{ mt: '0 !important' }}
                  disabled={disabled}
                  {...formik.getFieldProps(path)}
                  onChange={handleFieldChange}
                >
                  {Object.keys(attributes.choices).map((item, index) => (
                    <MenuItem key={index} value={attributes.choices[item]}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {!isInitialSelectValueUpdated && (
                <Select
                  size="medium"
                  sx={{ mt: '0 !important' }}
                  disabled={disabled}
                  {...formik.getFieldProps(path)}
                  onChange={handleFieldChange}
                >
                  {Object.keys(attributes.choices).map((item, index) => (
                    <MenuItem key={index} value={attributes.choices[item]}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <FormHelperText>
                {!!(formikError && formikTouched) && formikError}
              </FormHelperText>
              {renderHelp(formAttr?.help)}
            </FormControl>
          </Grid>
        );
      }
      case 'file':
        const { images, documents } = splitFilesByExtension(
          currentValue?.files
        );
        if (currentValue?.files) {
          const gallerySection = extractImageSection(
            currentValue?.files,
            path,
            label
          );
          if (
            gallerySection &&
            !imageGallery.find(({ name }) => gallerySection.name === name)
          ) {
            imageGallery = [...imageGallery, { ...gallerySection }];
          }
        }

        const onFileUpload = async (files, fileType) => {
          const oldFiles =
            fileType === 'document' ? [...images] : [...documents];
          const newFiles = [
            ...oldFiles,
            ...files.filter((file) => !(file instanceof File)),
          ];

          const uploadFiles = files
            .filter((file) => file instanceof File)
            .map((file) => ({
              fileId: `${Date.now()}-${file.name}`,
              file,
            }));

          const onUploadProgress = (progressEvent, fileId) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            const files = [...newFiles, ...uploadFiles].map((file, index) => {
              if (file?.fileId === fileId) {
                return {
                  file: file.file,
                  fileId: file.fileId,
                  uploadPercent: percent,
                };
              } else if (
                file?.fileId &&
                [...newFiles, ...uploadFiles].findIndex(
                  (file) => file.fileId === fileId
                ) > index
              ) {
                return {
                  file: file.file,
                  fileId: file.fileId,
                  uploadPercent: 100,
                };
              } else {
                return file;
              }
            });

            formik.setFieldValue(path, {
              id: currentValue?.id,
              files,
            });
          };

          for (const file of uploadFiles) {
            const data = buildFormRequestData({
              formData: {
                [path]: [file.file],
              },
            });
            await POST(
              `${APIS.project.updateProperty}/${parentFields.id}`,
              data,
              null,
              true,
              (e: ProgressEvent) => onUploadProgress(e, file.fileId)
            );
          }
        };

        const onRemoveFile = async (id) => {
          removeFile(id)
            .then((res) => {
              if (res.data.success) {
                refetchFormData && refetchFormData();
              }
            })
            .catch((err) => {
              console.error(err);
            });
        };

        const handleClickImage = (imageNumber) => {
          handleOpenImageGallery(imageNumber, label);
        };

        const handleChangeDocuments = async (files) => {
          if (formType === FormType.Property) {
            formik.setSubmitting(true);
            await onFileUpload(files, 'document');
            formik.setSubmitting(false);
            if (refetchFormData) {
              await refetchFormData();
            }
          } else {
            formik.setFieldValue(path, {
              id: currentValue?.id,
              files: [...images, ...files],
            });
            formik.handleSubmit();
          }
        };

        const handleChangeImages = async (files) => {
          if (formType === FormType.Property) {
            formik.setSubmitting(true);
            await onFileUpload(files, 'image');
            formik.setSubmitting(false);
            if (refetchFormData) {
              await refetchFormData();
            }
          } else {
            formik.setFieldValue(path, {
              id: currentValue?.id,
              files: [...documents, ...files],
            });
            formik.handleSubmit();
          }
        };

        return (
          <Grid item xs={2} md={layout?.file ? layout?.file : 1}>
            <Typography variant="h3" mb={12}>
              {label}
              {(required || isRequired) && '*'}
            </Typography>
            <FileList
              loading={formik.isSubmitting}
              files={documents}
              hideSelectFileButton
              allowDelete={isUserAllowed(account, Role.ROLE_ADMIN)}
              onChangeFiles={handleChangeDocuments}
              onRemoveFile={onRemoveFile}
            />
            <Box my={8} />
            <ImageList
              loading={formik.isSubmitting}
              hasAddImageButton
              files={images || []}
              allowDelete={isUserAllowed(account, Role.ROLE_ADMIN)}
              onChangeFiles={handleChangeImages}
              onRemoveFile={onRemoveFile}
              onClickImage={handleClickImage}
            />
            <FormHelperText error={!!formikError}>
              {!!(formikError && formikTouched) && formikError}
            </FormHelperText>
            {renderHelp(formAttr?.help)}
          </Grid>
        );

      case 'headline':
        return (
          <Grid item xs={2} md={2} display="flex" alignItems="center">
            <Typography variant="h2" mt={16}>
              {label}
            </Typography>
            {formAttr?.tooltip && (
              <Tooltip
                arrow={true}
                title={formAttr?.tooltip}
                componentsProps={{
                  tooltip: { sx: { padding: '8px 16px', lineHeight: 2 } },
                }}
              >
                <Box pl={10} width={25} mt={0}>
                  <InfoIcon />
                </Box>
              </Tooltip>
            )}
          </Grid>
        );

      case 'object': {
        return Object.entries(fields as { [key: string]: IForm }).map(
          ([formKey, form]: [string, IForm], key) => (
            <FormBuilder
              key={key}
              formType={formType}
              path={path ? `${path}.${formKey}` : formKey}
              formik={formik}
              layout={layout}
              refetchFormData={refetchFormData}
              parentFields={parentFields}
              hiddenFields={hiddenFields}
              onFieldChange={onFieldChange}
              {...form}
              disabled={form.disabled || disabled}
              disableSelfRoleChange={disableSelfRoleChange}
              recursive
            />
          )
        );
      }
      case 'array':
        return Object.entries(fields as { [key: string]: IForm }).map(
          ([formKey, form]: [string, IForm], key) => (
            <FormBuilder
              key={key}
              formType={formType}
              path={path ? `${path}.${formKey}` : formKey}
              formik={formik}
              parentFields={fields}
              layout={layout}
              refetchFormData={refetchFormData}
              hiddenFields={hiddenFields}
              onFieldChange={onFieldChange}
              {...form}
              disabled={form.disabled || disabled}
              recursive
              disableSelfRoleChange={disableSelfRoleChange}
            />
          )
        );
      default:
        return (
          <Grid item xs={2} md={2}>
            {label}
          </Grid>
        );
    }
  };

  return (
    <>
      {formAttr?.newRow && (
        <Grid item xs={2} md={2} sx={{ padding: '0px !important' }} />
      )}

      {buildForm(type)}

      {imageGallery.length > 0 && (
        <ImageGallery
          open={visibleImageGallery}
          imageGallery={imageGallery}
          startImageNumber={imageNumber}
          imageGroupNumber={imageGroupNumber}
          onClose={handleCloseImageGallery}
        />
      )}
    </>
  );
};
