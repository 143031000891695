import { DELETE, GET, POST } from './axios.services';
import { APIS, buildFormRequestData } from '../utils';

export const getNews = async (params?: any) => {
  return await GET(`${APIS.news.news}`, params, null, true);
};

export const createNews = async (formData) => {
  const data = buildFormRequestData({ formData });
  return await POST(`${APIS.news.news}`, data, null, true);
};

export const deleteNews = async (id) => {
  const res = await DELETE(`${APIS.news.news}/${id}`, null, null, true);
  return res.data;
};
