// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';

// Styles
import * as S from './styles';

// Components
import { MapMarker } from '../MapMarker';

// Interfaces
import { IMarker } from '../../../interfaces';
import { MapInfoWindow } from '../MapInfoWindow';

const MapArea: FC = (props: any) => {
  const { google, markers } = props;
  const [activeMarker, setActiveMarker] = useState<IMarker | null>(null);
  const [bounds, setBounds] = useState();

  const infoVisible = Boolean(activeMarker);

  const handleChangeMarker = (mapMarker: google.maps.Marker, marker: IMarker) => {
    if (activeMarker && activeMarker.id === marker.id) {
      setActiveMarker(null);
    } else {
      setActiveMarker(marker);
    }
  };

  useEffect(() => {
    const newBounds = new google.maps.LatLngBounds();
    markers.forEach(marker => newBounds.extend(marker.position))
    setBounds(newBounds);
  }, [markers, google.maps.LatLngBounds])

  return (
    <S.Wrap>
      <Map
        google={google}
        bounds={bounds}
        maxZoom={16}
        gestureHandling="cooperative"
      >
        {markers.map((marker: IMarker, index) => (
          <MapMarker
            key={index}
            marker={marker}
            active={marker.id === activeMarker?.id}
            onChangeMarker={handleChangeMarker}
          />
        ))}
        <MapInfoWindow marker={activeMarker} visible={infoVisible} />
      </Map>
    </S.Wrap>
  );
};

export default GoogleApiWrapper(function () {
  return {
    apiKey: process.env.REACT_APP_MAP_API_KEY || '',
    libraries: ['visualization'],
    panControl: false,
    mapTypeControl: false,
    styles: [
      {
        stylers: [
          { saturation: -100 },
          { gamma: 0.8 },
          { lightness: 4 },
          { visibility: 'off' },
        ],
      },
    ],
  };
})(MapArea);
