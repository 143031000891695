// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import moment from 'moment';
import 'moment/locale/de';

// Components
import { Card, PieChart } from '../../../../components';

// Interfaces
import { IProjectData } from '../../../../interfaces';

// Services
import { getProjects } from '../../../../services/project.service';

// Styles
import * as S from './styles';

// Constants
import * as StatusService from '../../../../constants';
import { PROPERTY_NAMES } from '../../../../constants';
import { CardActionSelect } from '../../styles';

const currentYear = moment().format('YYYY');
const years = Array.from(
  { length: new Date().getFullYear() - 2020 },
  (_, index) => 2021 + index
);

const getPeriod = (year, month) => {
  if (month === 'all') return String(year);
  return `${year}-${String(month).padStart(2, '0')}`;
};

// Export object-overview widget
export const ObjectsOverviewWidget: FC = () => {
  // Get translation from hook
  const { t, i18n } = useTranslation();

  moment.locale(i18n.language);

  // States
  const [periodYear, setPeriodYear] = useState<string>(currentYear);
  const [periodMonth, setPeriodMonth] = useState<string>('all');
  const [projects, setProjects] = useState<IProjectData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDone, setShowDone] = useState<boolean>(false);

  // On changed
  useEffect(() => {
    const status = showDone
      ? ['complete']
      : [
          ...Object.keys(StatusService.getAvailableFilter('inProgress')),
          ...Object.keys(StatusService.getAvailableFilter('new')),
        ];
    setLoading(true);
    getProjects({
      status,
      groups: ['project:marketdata', 'property:type'],
      pagination: 0,
      period: getPeriod(periodYear, periodMonth),
    })
      .then((res) => {
        setProjects(res.data.data);
        setLoading(false);
      })
      .catch(() => {
        setProjects([]);
        setLoading(false);
      });
  }, [showDone, periodMonth, periodYear]);

  // Get chart data
  const pieChartData = useMemo(() => {
    const allProjects = projects || [];
    let tempTypes: any = {};
    allProjects
      .filter(({ changes, status }) => {
        if (status === 'complete')
          return (
            changes &&
            changes.filter(({ type, context, date }) => {
              return (
                type === 'workflow' &&
                moment(date).format('YYYY') === periodYear &&
                (periodMonth === 'all' ||
                  moment(date).format('MM') ===
                    String(periodMonth).padStart(2, '0'))
              );
            }).length > 0
          );

        return (
          status !== 'complete' &&
          changes &&
          changes.filter(({ type, context, date }) => {
            return (
              type === 'workflow' &&
              JSON.parse(context)?.transitionName ===
                'requestApproveOfNonBindingOfferForCustomer' &&
              moment(date).format('YYYY') === periodYear &&
              (periodMonth === 'all' ||
                moment(date).format('MM') ===
                  String(periodMonth).padStart(2, '0'))
            );
          }).length > 0
        );
      })
      .forEach((project) => {
        const type = project.property.property_type ?? 'unknown';

        const typeName = PROPERTY_NAMES[type] ?? 'Unbekannt';

        if (!tempTypes.hasOwnProperty(typeName)) tempTypes[typeName] = 0;
        tempTypes[typeName]++;
      });
    const sortKeys = Object.keys(tempTypes);
    sortKeys.sort((a: any, b: any) => {
      return tempTypes[a] > tempTypes[b] ? -1 : 1;
    });

    const types: any = {};
    sortKeys.forEach((type) => {
      types[type] = tempTypes[type];
    });
    return Object.keys(types).map((key) => ({ name: key, value: types[key] }));
  }, [projects, periodMonth, periodYear]);

  // Date change handler
  const handleChangePeriodYear = (e: SelectChangeEvent) => {
    setPeriodYear(e.target.value);
  };

  const handleChangePeriodMonth = (e: SelectChangeEvent) => {
    setPeriodMonth(e.target.value);
  };

  // Show done handler
  const handleShowDone = (value: boolean) => {
    setShowDone(value);
  };

  // Return object-overview widget

  return (
    <>
      <Card
        title={
          <S.OverviewCardTitle>
            {t('dashboard.objects_overview')}
          </S.OverviewCardTitle>
        }
        action={
          <>
            <Stack direction="row"></Stack>
            <CardActionSelect
              value={periodYear}
              onChange={handleChangePeriodYear}
            >
              {years.map((year) => (
                <MenuItem key={`year-${year}`} value={String(year)}>
                  {year}
                </MenuItem>
              ))}
            </CardActionSelect>
            <CardActionSelect
              value={periodMonth}
              onChange={handleChangePeriodMonth}
            >
              <MenuItem value={'all'}>{t('dashboard.all')}</MenuItem>
              {[
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
              ].map((month) => (
                <MenuItem key={`month-${month}`} value={month}>
                  {t('dashboard.month', {
                    month: moment.months('MMMM')[parseInt(month, 10) - 1],
                  })}
                </MenuItem>
              ))}
            </CardActionSelect>
          </>
        }
        loading={loading}
      >
        <PieChart
          data={pieChartData}
          showDone={showDone}
          onShowDone={handleShowDone}
        />
      </Card>
    </>
  );
};
