import { LeadStatus } from '../interfaces/lead-status.type';

export const LEAD_STATUSES: { value: LeadStatus, label: string }[] = [
  { value: LeadStatus.New, label: `leads_detail.${LeadStatus.New}` },
  { value: LeadStatus.Assumed, label: `leads_detail.${LeadStatus.Assumed}` },
  { value: LeadStatus.Rejected, label: `leads_detail.${LeadStatus.Rejected}` },
  { value: LeadStatus.NotPickedUp, label: `leads_detail.${LeadStatus.NotPickedUp}` },
  { value: LeadStatus.WillStillDecide, label: `leads_detail.${LeadStatus.WillStillDecide}` },
  { value: LeadStatus.WillGetBackToUs, label: `leads_detail.${LeadStatus.WillGetBackToUs}` },
];
