// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import { Button, Dialog } from '../../Common';
import { FormBuilder } from '../../FormBuilder';

// Store
import {
  getCalculationResultForm,
  updateCalculation,
} from '../../../redux/actions';

// Utils
import { buildFormInitialData, getFormikTouchedValue } from '../../../utils';

// Interfaces
import { RootState } from '../../../redux/store';
import { IForm, IOfferCalculationResult } from '../../../interfaces';

interface ICalculationResultModal {
  calculationResult: IOfferCalculationResult;
  open: boolean;
  onClose: () => void;
}

// Export calculation result modal
export const CalculationResultModal: FC<ICalculationResultModal> = ({
  calculationResult,
  open,
  onClose,
}) => {
  // States
  const [step, setStep] = useState(0);
  const [preStep, setPreStep] = useState(-1);
  const [formikTouched, setFormikTouched] = useState<any>({});

  // Get translation from hook
  const { t } = useTranslation();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get calculation result form from store
  const calculationResultForm = useSelector(
    ({ formReducer: { calculationResultForm } }: RootState) =>
      calculationResultForm
  );

  useEffect(() => {
    if (calculationResultForm) {
      const initData = buildFormInitialData(calculationResultForm);
      setFormikTouched(getFormikTouchedValue(initData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationResultForm]);

  // Create formik
  const formik = useFormik<any>({
    initialValues: buildFormInitialData(calculationResultForm),
    onSubmit: async (values) => {
      try {
        delete values.calculation.offerCalculated;
        const res: any = await dispatch(
          updateCalculation(calculationResult.formData.calculated.id, values)
        );
        if (res.value?.success) {
          enqueueSnackbar(res.value?.message, { variant: 'success' });
          onClose();
        } else {
          enqueueSnackbar(res.value?.message, { variant: 'error' });
        }
      } catch (e: any) {
        enqueueSnackbar(e.response?.data?.message || 'Failed to take over', {
          variant: 'error',
        });
      }
    },
  });

  const stepLength = useMemo(
    () =>
      calculationResultForm && Object.entries(calculationResultForm).length | 0,
    [calculationResultForm]
  );
  const currentStepContent: IForm = useMemo(
    () =>
      calculationResultForm &&
      (Object.values(calculationResultForm)[
        preStep > -1 ? preStep : step
      ] as IForm),
    [preStep, step, calculationResultForm]
  );
  const currentStepKey: string = useMemo(
    () =>
      calculationResultForm &&
      Object.keys(calculationResultForm)[preStep > -1 ? preStep : step],
    [preStep, step, calculationResultForm]
  );

  // Next step handler
  const handleNextStep = async () => {
    if (step === stepLength - 1) {
      formik?.handleSubmit();
      return;
    } else {
      await formik?.setTouched(
        { [currentStepKey]: formikTouched[currentStepKey] },
        true
      );
      setPreStep(step);
      setStep(step + 1);
    }
  };

  // Back step handler
  const handleBackStep = () => {
    setStep(step - 1);
  };

  // Submit handler
  const handleSubmit = () => {
    formik.handleSubmit();
  };

  // On calculation result changed
  useEffect(() => {
    (async () => {
      await dispatch(getCalculationResultForm(calculationResult.formId));
    })();

    const initData = buildFormInitialData(calculationResultForm);
    setFormikTouched(getFormikTouchedValue(initData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (step > preStep && preStep > -1) {
      const previousKey = Object.keys(calculationResultForm)[preStep];
      if (formik.errors[previousKey]) {
        setStep(preStep);
      }
      setPreStep(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preStep, formik.errors, step]);

  useEffect(() => {
    formik.setValues(calculationResult.formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationResult]);

  // Return calculation result modal
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('calculation_result.title')}
      actions={
        <>
          <Button size="large" disabled={step === 0} onClick={handleBackStep}>
            {t('calculation_result.return')}
          </Button>
          <Button
            size="large"
            disabled={step === stepLength - 1}
            onClick={handleNextStep}
          >
            {t('calculation_result.continue')}
          </Button>
          <Button
            size="large"
            color="primary"
            disabled={step !== stepLength - 1}
            onClick={handleSubmit}
          >
            {t('calculation_result.take_over')}
          </Button>
        </>
      }
    >
      <Box component={PerfectScrollbar} sx={{ maxHeight: '50vh' }}>
        <Grid container columns={1} spacing={16}>
          <FormBuilder
            type={currentStepContent?.type}
            attributes={currentStepContent?.attributes}
            label={currentStepContent?.label}
            fields={currentStepContent?.fields}
            formik={formik}
            path={currentStepKey}
          />
        </Grid>
      </Box>
    </Dialog>
  );
};
