// Dependencies
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Card, DocumentPanel } from '../../../../components';

const PER_PAGE = 5;

// Export consultation-documents widget
export const ConsultationDocumentsWidget: FC = () => {
  // Get translation from hook
  const { t } = useTranslation();

  // States
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(6);

  // Page change handler
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  // Return consultation-documents widget
  return (
    <Card
      title={t('dashboard.consultation_documents')}
      pagination={{
        total: Math.ceil(total / PER_PAGE),
        current: page,
      }}
      onPageChange={handlePageChange}
    >
      <DocumentPanel
        pagination={{ page: page, perPage: PER_PAGE }}
        onChangeTotal={setTotal}
      />
    </Card>
  );
};
