// Dependencies
import styled from 'styled-components';

// Components
import { Dialog } from '../../Common';

// Export styled components
export const ChatsModal = styled(Dialog)`
  && {
    .MuiDialog-paper {
      max-width: 1320px;
    }

    .MuiListItemText-primary {
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
      text-overflow: ellipsis;
      ${(props) => props.theme.breakpoints.down('lg')} {
        max-width: none;
      }
    }

    .MuiListItemText-secondary {
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
      text-overflow: ellipsis;
      ${(props) => props.theme.breakpoints.down('lg')} {
        max-width: none;
      }
    }

    .MuiButton-root {
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 12px;
      background: #eff4f8;
      color: #172844;
    }
  }
`;
