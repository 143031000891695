// Dependencies
import styled from 'styled-components';
import { Popper } from '@mui/material';

// Export styled components
export const SearchPanel = styled(Popper)`
  && {
    z-index: 1110;
    margin-top: 30px !important;

    ${(props) => props.theme.breakpoints.down('md')} {
      margin-top: 50px !important;
    }

    .MuiPaper-root {
      padding: 20px;

      ${(props) => props.theme.breakpoints.down('md')} {
        width: calc(100vw - 32px);
      }
    }
  }
`;
