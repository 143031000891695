export const STATUS_ACTIONS = {
  commissionExpertReport: {
    icon: 'tick',
  },
  approvedNonBindingOfferForCustomer: {
    icon: 'tick',
  },
  approvalNonBindingOffer: {
    icon: 'tick',
  },
  assessmentNonBindingOfferForInspection: {
    icon: 'tick',
  },
  approvalBindingOfferForCustomer: {
    icon: 'tick',
  },
  approvalBindingOffer: {
    icon: 'tick',
  },
  lock: {
    icon: 'tick',
  },
  declineNonBindingOffer: {
    icon: 'x-lg',
  },
  declineNonBindingOfferForCustomer: {
    icon: 'x-lg',
  },
  declineBindingOffer: {
    icon: 'x-lg',
  },
  returnLock: {
    icon: 'reset',
  },
  returnDeclineNonBindingOffer: {
    icon: 'reset',
  },
  returnApprovedNonBindingOfferForCustomer: {
    icon: 'reset',
  },
  returnDeclineNonBindingOfferForCustomer: {
    icon: 'reset',
  },
  returnApprovalNonBindingOffer: {
    icon: 'reset',
  },
  returnCommissionExpertReport: {
    icon: 'reset',
  },
  returnAssessmentNonBindingOfferForInspection: {
    icon: 'reset',
  },
  returnApprovalBindingOfferForCustomer: {
    icon: 'reset',
  },
  returnApprovalBindingOffer: {
    icon: 'reset',
  },
  returnDeclineBindingOffer: {
    icon: 'reset',
  },
  returnSell: {
    icon: 'reset',
  },
  requestApproveOfNonBindingOfferForCustomer: {
    icon: 'arrow-right',
  },
  sell: {
    icon: 'check-square',
  },
  approvalNonBindingOfferForCustomer: {
    icon: 'corner-up-left',
  },
  returnDeclineNonBindingOfferForCustomerByCustomer: {
    icon: 'reset',
  },
  returnDeclineNonBindingOfferByCustomer: {
    icon: 'reset',
  },
  returnDeclineBindingOfferByCustomer: {
    icon: 'reset',
  },
};

export const STATUS_ACTIONS_COLORS = {
  warning: 'orange',
  danger: 'red',
  success: 'green',
  blue: 'blue',
};
