// Dependencies
import React from 'react';
import { Components, darken, lighten } from '@mui/material';

// Components
import { Icon } from '../components';

// Palette
import {
  cyan,
  darkCyan,
  darkIndigo,
  lightCyan,
  white,
  whiteIndigo,
  middleCyan,
  whiteCyan,
  lightIndigo,
} from './palette';

// Shadows
import shadows from './shadows';
import breakpoints from './breakpoints';

// Icons
import {
  CheckboxBlankIcon,
  CheckboxIcon,
  CheckboxIndeterminateIcon,
} from '../assets/icons';

// Override interface
declare module '@mui/material/InputBase' {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

// Override components
const components: Components = {
  MuiFormControl: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: 14,
        lineHeight: '24px',
        marginBottom: 4,
        color: darkIndigo,
      },
    },
  },
  MuiInputBase: {
    defaultProps: {
      size: 'small',
    },
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          fontSize: 16,
          lineHeight: '24px',
          backgroundColor: theme.palette.cyan,
          '.MuiInputBase-input': {
            padding: theme.spacing(0),
          },
          '.MuiInputBase-inputAdornedStart': {
            paddingLeft: theme.spacing(8),
          },
          '.MuiInputBase-inputAdornedEnd': {
            paddingRight: 12,
          },
          '&.MuiSelect-root': {
            backgroundColor: 'transparent',
          },
          'label + &': {
            marginTop: '0 !important',
          },
          [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(2, 8),
            svg: {
              width: 16,
              height: 16,
            },
          },
        }),
      },
      {
        props: { size: 'small' },
        style: ({ theme }) => ({
          fontSize: 14,
          minHeight: 36,
          borderRadius: 8,
          padding: theme.spacing(6, 20),
        }),
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          minHeight: 44,
          borderRadius: 8,
          padding: theme.spacing(10, 12),
        }),
      },
      {
        props: { size: 'large' },
        style: ({ theme }) => ({
          minHeight: 52,
          borderRadius: 12,
          paddingLeft: theme.spacing(20),
        }),
      },
    ],
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: white,
            '& + .MuiSwitch-track': {
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {},
          '&.Mui-disabled + .MuiSwitch-track': {},
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
        },
        '& .MuiSwitch-track': {
          backgroundColor: cyan,
        },
      },
      sizeSmall: {
        width: 32,
        height: 20,
        '& .MuiSwitch-switchBase': {
          '&.Mui-checked': {
            transform: 'translateX(12px)',
          },
        },
        '& .MuiSwitch-thumb': {
          width: 16,
          height: 16,
        },
        '& .MuiSwitch-track': {
          borderRadius: 20 / 2,
        },
      },
      sizeMedium: {
        width: 40,
        height: 24,
        '& .MuiSwitch-switchBase': {
          '&.Mui-checked': {
            transform: 'translateX(16px)',
          },
        },
        '& .MuiSwitch-thumb': {
          width: 20,
          height: 20,
        },
        '& .MuiSwitch-track': {
          borderRadius: 24 / 2,
        },
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: 'small',
      color: 'primary',
      icon: <CheckboxBlankIcon />,
      checkedIcon: <CheckboxIcon />,
      indeterminateIcon: <CheckboxIndeterminateIcon />,
    },
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          padding: theme.spacing(11),
        }),
      },
      {
        props: { size: 'small' },
        style: () => ({
          svg: {
            width: 24,
            height: 24,
            border: '2px solid #697990',
            borderRadius: 6,
          },
        }),
      },
      {
        props: { size: 'medium' },
        style: () => ({
          svg: {
            width: 36,
            height: 36,
          },
        }),
      },
    ],
  },
  MuiSelect: {
    defaultProps: {
      disableUnderline: true,
      size: 'small',
      variant: 'standard',
      IconComponent: () => <Icon name="arrow-down" size={24} />,
    },
    variants: [
      {
        props: { variant: 'standard' },
        style: ({ theme }) => ({
          padding: '0',
          '.MuiSelect-select.MuiSelect-standard': {
            paddingLeft: theme.spacing(10),
            paddingRight: `${theme.spacing(40)} !important`,
            minHeight: '2.5rem',
            display: 'flex',
            alignItems: 'center',
            ':focus': {
              backgroundColor: 'transparent',
            },
          },
          svg: {
            position: 'absolute',
            right: '10px',
            pointerEvents: 'none',
          },
        }),
      },
    ],
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      size: 'small',
      color: 'secondary',
      variant: 'contained',
      disableElevation: true,
    },
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          textTransform: 'unset',
          fontWeight: 600,
          '.MuiButton-startIcon': {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(16),
          },
        }),
      },
      {
        props: { variant: 'contained', color: 'primary' },
        style: ({ theme }) => ({
          backgroundImage: [
            `linear-gradient(to right, ${theme.palette.lightRed}, ${theme.palette.red})`,
            `linear-gradient(135deg, ${theme.palette.lightRed}, ${theme.palette.red})`,
          ],
        }),
      },
      {
        props: { variant: 'contained', color: 'secondary' },
        style: ({ theme }) => ({
          ':hover': {
            backgroundColor: darken(theme.palette.lightCyan as string, 0.01),
          },
        }),
      },
      {
        props: { variant: 'outlined', color: 'secondary' },
        style: ({ theme }) => ({
          border: `1px solid ${theme.palette.lightIndigo}`,
          color: theme.palette.lightIndigo,
        }),
      },
      {
        props: { variant: 'text', color: 'primary' },
        style: ({ theme }) => ({
          color: theme.palette.darkIndigo,
        }),
      },
      {
        props: { variant: 'text', color: 'secondary' },
        style: ({ theme }) => ({
          color: theme.palette.lightIndigo,
        }),
      },
      {
        props: { size: 'small' },
        style: ({ theme }) => ({
          fontSize: 14,
          lineHeight: '24px',
          borderRadius: 8,
          padding: theme.spacing(6, 20),
        }),
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          fontSize: 16,
          lineHeight: '24px',
          borderRadius: 10,
          padding: theme.spacing(12, 20),
        }),
      },
      {
        props: { size: 'large' },
        style: ({ theme }) => ({
          fontSize: 16,
          lineHeight: '20px',
          borderRadius: 10,
          padding: theme.spacing(14, 32),
        }),
      },
    ],
  },
  MuiIconButton: {
    defaultProps: {
      size: 'small',
      color: 'default',
    },
    styleOverrides: {
      sizeMedium: {
        height: 48,
        width: 48,
      },
      sizeLarge: {
        height: 52,
        width: 52,
      },
    },
    variants: [
      {
        props: {},
        style: () => ({
          borderRadius: 10,
        }),
      },
      {
        props: { color: 'default' },
        style: ({ theme }) => ({
          color: theme.palette.lightIndigo,
          backgroundColor: 'transparent',
        }),
      },
      {
        props: { color: 'primary' },
        style: ({ theme }) => ({
          color: theme.palette.common.white,
          backgroundImage: [
            `linear-gradient(to right, ${theme.palette.indigo}, ${theme.palette.darkIndigo})`,
            `linear-gradient(135deg, ${theme.palette.indigo}, ${theme.palette.darkIndigo})`,
          ],
          '&.Mui-disabled': {
            color: darken(theme.palette.common.white, 0.2),
          },
        }),
      },
      {
        props: { color: 'warning' },
        style: ({ theme }) => ({
          color: theme.palette.common.white,
          backgroundColor: theme.palette.orange,
          ':hover': {
            backgroundColor: darken(theme.palette.orange as string, 0.1),
          },
          '&.Mui-disabled': {
            color: darken(theme.palette.common.white, 0.2),
            backgroundColor: darken(theme.palette.orange as string, 0.1),
          },
        }),
      },
      {
        props: { color: 'info' },
        style: ({ theme }) => ({
          color: theme.palette.common.white,
          backgroundColor: darken(theme.palette.blue as string, 0.1),
          ':hover': {
            backgroundColor: lighten(theme.palette.blue as string, 0.1),
          },
          '&.Mui-disabled': {
            color: darken(theme.palette.common.white, 0.2),
            backgroundColor: darken(theme.palette.blue as string, 0.2),
          },
        }),
      },
      {
        props: { color: 'success' },
        style: ({ theme }) => ({
          color: theme.palette.common.white,
          backgroundColor: darken(theme.palette.darkGreen as string, 0.1),
          ':hover': {
            backgroundColor: lighten(theme.palette.darkGreen as string, 0.1),
          },
          '&.Mui-disabled': {
            color: darken(theme.palette.common.white, 0.2),
            backgroundColor: darken(theme.palette.darkGreen as string, 0.2),
          },
        }),
      },
      {
        props: { color: 'error' },
        style: ({ theme }) => ({
          color: theme.palette.common.white,
          backgroundColor: theme.palette.red,
          ':hover': {
            backgroundColor: lighten(theme.palette.red as string, 0.1),
          },
          '&.Mui-disabled': {
            color: darken(theme.palette.common.white, 0.2),
            backgroundColor: darken(theme.palette.red as string, 0.1),
          },
        }),
      },
      {
        props: { color: 'secondary' },
        style: ({ theme }) => ({
          backgroundImage: [
            `linear-gradient(to right, ${theme.palette.lightRed}, ${theme.palette.red})`,
            `linear-gradient(135deg, ${theme.palette.lightRed}, ${theme.palette.red})`,
          ],
        }),
      },
      {
        props: { size: 'small' },
        style: () => ({
          width: 36,
          height: 36,
          svg: {
            width: 20,
            height: 20,
          },
        }),
      },
      {
        props: { size: 'medium' },
        style: () => ({
          width: 48,
          height: 48,
        }),
      },
      {
        props: { size: 'large' },
        style: () => ({
          width: 52,
          height: 52,
        }),
      },
    ],
  },
  MuiToggleButton: {
    defaultProps: {
      size: 'small',
    },
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          border: 'none',
          borderRadius: theme.spacing(8),
          '&.Mui-disabled': {
            border: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
        }),
      },
      {
        props: { color: 'standard' },
        style: ({ theme }) => ({
          '&.Mui-selected': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.darkIndigo,
          },
        }),
      },
      {
        props: { color: 'primary' },
        style: ({ theme }) => ({
          color: theme.palette.common.white,
          backgroundImage: [
            `linear-gradient(to right, ${theme.palette.indigo}, ${theme.palette.darkIndigo})`,
            `linear-gradient(135deg, ${theme.palette.indigo}, ${theme.palette.darkIndigo})`,
          ],
          '&.Mui-selected': {
            color: theme.palette.common.white,
          },
        }),
      },
      {
        props: { color: 'secondary' },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.cyan,
        }),
      },
      {
        props: { size: 'small' },
        style: () => ({
          width: 36,
          height: 36,
        }),
      },
      {
        props: { size: 'medium' },
        style: () => ({
          width: 42,
          height: 42,
        }),
      },
    ],
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 12,
      },
      rounded: {
        boxShadow: shadows[1],
      },
      outlined: {
        boxShadow: 'none !important',
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      root: {
        '.MuiPopover-paper': {
          boxShadow: '0px 0px 12px rgba(85, 101, 129, 0.1)',
        },
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h2',
      },
    },
    styleOverrides: {
      root: {
        padding: '20px 20px 16px',
      },
      action: {
        margin: 0,
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        ':hover': {
          backgroundColor: whiteIndigo,
        },
      },
    },
  },
  MuiListItemAvatar: {
    styleOverrides: {
      root: {
        minWidth: 80,
        [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
          minWidth: 60,
        },
      },
    },
  },
  MuiListItemText: {
    defaultProps: {
      primaryTypographyProps: {
        variant: 'subtitle1',
      },
    },
    styleOverrides: {
      root: {
        marginTop: 0,
        marginBottom: 0,
        '.MuiListItemText-secondary': {
          marginTop: 0,
        },
      },
      secondary: {
        marginTop: 2,
      },
    },
  },
  MuiAvatar: {
    defaultProps: {
      variant: 'rounded',
      children: <Icon name="home" />,
    },
    styleOverrides: {
      root: {
        backgroundColor: cyan,
        color: darkCyan,
      },
      rounded: {
        borderRadius: 10,
        width: 52,
        height: 40,
        [`@media (max-width: ${breakpoints.values.sm - 0.5}px)`]: {
          width: 52,
          height: 40,
          borderRadius: 8,
        },
      },
      circular: {
        width: 48,
        height: 48,
        border: `4px solid ${white}`,
        boxShadow: `0 0 6px ${cyan}`,
        [`@media (max-width: ${breakpoints.values.sm - 0.5}px)`]: {
          width: 40,
          height: 40,
        },
      },
      square: {
        borderRadius: 10,
        width: 40,
        height: 40,
        [`@media (max-width: ${breakpoints.values.sm - 0.5}px)`]: {
          width: 32,
          height: 32,
          borderRadius: 8,
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: 20,
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        '&.MuiTable-stickyHeader': {
          th: {
            borderBottom: `1px solid ${lightCyan}`,
          },
        },
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        borderTop: `1px solid ${cyan}`,
        borderBottom: `1px solid ${cyan}`,
      },
    },
  },
  MuiTableRow: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          '&.MuiTableRow-hover:hover': {
            backgroundColor: theme.palette.whiteIndigo,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.lightCyan,
            '&:hover': {
              backgroundColor: theme.palette.lightCyan,
            },
          },
        }),
      },
    ],
  },
  MuiTableCell: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          whiteSpace: 'nowrap',
          borderColor: lightCyan,
          '&.MuiTableCell-head': {
            color: theme.palette.lightIndigo,
          },
        }),
      },
    ],
  },
  MuiPagination: {
    styleOverrides: {
      root: {},
    },
  },
  MuiPaginationItem: {
    defaultProps: {
      shape: 'rounded',
    },
    styleOverrides: {
      root: {
        color: middleCyan,
        '&.Mui-selected': {
          fontWeight: 700,
          backgroundColor: 'transparent',
          color: darkIndigo,
        },
      },
      page: {
        [`@media (max-width: ${breakpoints.values.md - 1}px)`]: {
          display: 'none',
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontFamily: 'Exo, serif',
        padding: '8px 16px',
        [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
          minHeight: '24px',
          minWidth: '50px',
          padding: '4px 8px',
        },
      },
    },
  },
  MuiChip: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          height: 40,
          fontWeight: 600,
          borderRadius: 20,
          padding: theme.spacing(12, 10),
        }),
      },
    ],
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        [`&:not(.MuiBackdrop-invisible)`]: {
          backgroundColor: `${darkIndigo}50`,
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: cyan,
      },
    },
  },
  MuiTab: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          ...theme.typography.body2,
          minHeight: 48,
          maxWidth: 'unset',
          minWidth: 'unset',
          flexDirection: 'row',
          alignItems: 'flex-end',
          padding: theme.spacing(8, 0),
          textTransform: 'capitalize',
          textAlign: 'start',
          color: theme.palette.blackCyan,
          '&:not(last-of-type)': {
            marginRight: theme.spacing(20),
          },
          '.MuiTab-iconWrapper': {
            minWidth: 40,
            height: 30,
            marginBottom: theme.spacing(0),
            marginRight: theme.spacing(10),
            [theme.breakpoints.down('sm')]: {
              marginRight: theme.spacing(0),
            },
          },
        }),
      },
    ],
  },
  MuiTabs: {
    defaultProps: {
      scrollButtons: false,
    },
    variants: [
      {
        props: {},
        style: () => ({
          '.MuiTabs-indicator': {
            display: 'none',
          },
        }),
      },
    ],
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        ':first-of-type': {
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
        },
        ':last-of-type': {
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        fontWeight: 500,
        '&.Mui-expanded': {
          minHeight: 'unset',
        },
      },
      content: {
        margin: '19px 17px',
        '&.Mui-expanded': {
          margin: '19px 17px',
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: '1px 19px',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltipPlacementBottom: {
        marginTop: '10px !important',
      },
      tooltip: {
        color: lightIndigo,
        borderRadius: 16,
        backgroundColor: whiteCyan,
      },
      arrow: {
        color: whiteCyan,
      },
    },
  },
  MuiSkeleton: {
    variants: [
      {
        props: { variant: 'rectangular' },
        style: ({ theme }) => ({
          borderRadius: theme.spacing(4),
        }),
      },
    ],
  },
};

// Export components
export default components;
