import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

export const GET = async (
  api: string,
  params?: any,
  header?: any,
  withCredentials?: boolean
) => {
  try {
    return await apiClient.get(api, {
      headers: header,
      params,
      withCredentials,
    });
  } catch (e: any) {
    throw e?.message;
  }
};

export const POST = async (
  api: string,
  params?: any,
  header?: any,
  withCredentials?: boolean,
  onUploadProgress?: (e: ProgressEvent) => void
) => {
  return await apiClient.post(api, params, {
    headers: header,
    withCredentials,
    onUploadProgress,
  });
};

export const PUT = async (
  api: string,
  params?: any,
  header?: any,
  withCredentials?: boolean
) => {
  return await apiClient.put(api, params, { headers: header, withCredentials });
};

export const DELETE = async (
  api: string,
  params?: any,
  header?: any,
  withCredentials?: boolean
) => {
  return await apiClient.delete(api, {
    headers: header,
    params,
    withCredentials,
  });
};
