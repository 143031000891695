import { DELETE, GET, POST } from './axios.services';
import { APIS } from '../utils/urls';
import { buildFormRequestData } from '../utils';

export const postUser = async (formData: any) => {
  const data = buildFormRequestData({ formData });

  return await POST(`${APIS.user.users}`, data, null, true);
};

export const getBeforeIternalUsers = async (params) => {
  return await GET(`${APIS.user.users}/internal-agents`, params, null, true);
};

export const getIternalUsers = async (params) => {
  return await GET(`${APIS.user.users}/admins`, params, null, true);
};

export const getUsers = async (params) => {
  return await GET(`${APIS.user.users}`, params, null, true);
};

export const getUserDetail = async (id) => {
  const res = await GET(APIS.user.getUser.replace(':id', id), null, null, true);
  if (res.data.success) {
    return res.data.data;
  } else {
    throw res.data;
  }
};

export const updateUser = async (id, formData) => {
  const data = buildFormRequestData({ formData });
  return await POST(APIS.user.postUser.replace(':id', id), data, null, true);
};

export const deleteUser = async (id) => {
  const res = await DELETE(`${APIS.user.users}/${id}`, null, null, true);
  return res.data;
};

export const deleteMultipleUser = async (ids) => {
  const res = await DELETE(APIS.user.removeMultiple, { ids }, null, true);
  return res.data;
};
