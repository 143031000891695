// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// Components
import { Button, Dialog } from '../../Common';
import { FormBuilder } from '../../FormBuilder';

// Interfaces
import { RootState } from '../../../redux/store';

// Utils
import {
  buildFormInitialValues,
  buildFormSchema,
  getInitialValuesInFormStructure,
} from '../../../utils';

// Store
import { getAcceptPrepaymentForm } from '../../../redux/actions';

// Services
import { acceptPrepayment } from '../../../services/project.service';

// Interfaces
interface IAcceptPrepaymentModal {
  open: boolean;
  onClose: () => void;
  prepaymentData: any;
  additionalCostsId?: number;
}

// Export accept prepayment modal
export const AcceptPrepaymentModal: FC<IAcceptPrepaymentModal> = ({
  open,
  onClose,
  prepaymentData,
  additionalCostsId,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get project detail from store
  const { projectDetail } = useSelector(
    ({ projectReducer: { projectDetail } }: RootState) => ({
      projectDetail,
    })
  );

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get accept prepayment form from store
  const acceptPrepaymentForm = useSelector(
    ({ formReducer: { acceptPrepaymentForm } }: RootState) =>
      acceptPrepaymentForm
  );

  // Create formik
  const formik = useFormik<any>({
    initialValues: getInitialValuesInFormStructure(
      prepaymentData,
      buildFormInitialValues(acceptPrepaymentForm)
    ),
    validationSchema: buildFormSchema(acceptPrepaymentForm?.fields),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const res: any = await acceptPrepayment(
          projectDetail?.id,
          additionalCostsId,
          values
        );
        setLoading(false);
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          onClose();
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' });
        }
      } catch (e: any) {
        setLoading(false);
        enqueueSnackbar(e?.message || 'Fehler beim Hinzufügen', {
          variant: 'error',
        });
      }
    },
  });

  // Submit handler
  const handleSubmit = () => {
    formik.handleSubmit();
  };

  // On mounted
  useEffect(() => {
    (async () => {
      await dispatch(getAcceptPrepaymentForm());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On payment data & accept prepayment form data changed
  useEffect(() => {
    formik.setValues(
      getInitialValuesInFormStructure(
        prepaymentData,
        buildFormInitialValues(acceptPrepaymentForm)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepaymentData, acceptPrepaymentForm]);

  // Return accept prepayment modal
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('accept_prepayment.title')}
      actions={
        <Button
          loading={loading}
          color="primary"
          size="large"
          onClick={handleSubmit}
        >
          {t('accept_prepayment.save')}
        </Button>
      }
    >
      <Grid container columns={1} spacing={16}>
        <FormBuilder
          type={acceptPrepaymentForm?.type}
          attributes={acceptPrepaymentForm?.attributes}
          label={acceptPrepaymentForm?.label}
          fields={acceptPrepaymentForm?.fields}
          formik={formik}
          path=""
        />
      </Grid>
    </Dialog>
  );
};
