// Dependencies
import styled from 'styled-components';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
} from '@mui/material';
import { Button } from '../Button';

// Export styled components
export const Accordion = styled(MuiAccordion)`
  width: calc(100% - 57px);
  box-shadow: none !important;
  padding: 10px 12px;
  background-color: ${(props) =>
          props.expanded
                  ? props.theme.palette.whiteIndigo
                  : 'transparent'} !important;

  &:before {
    display: none;
  }

  & .MuiAccordionSummary-content {
    &.Mui-expanded {
      margin: 0;
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: calc(100% - 30px);
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  .MuiAccordionSummary-content {
    margin: 0;

    > div {
      display: flex;
      align-items: center;
    }

    .MuiAvatar-root {
      margin-right: 12px;
    }

    .MuiBox-root {
      .MuiTypography-root {
        color: ${(props) => props.theme.palette.text.primary} !important;
      }
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    color: ${(props) => props.theme.palette.text.primary} !important;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    .MuiTypography-h3 {
      font-size: 14px !important;
    }
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  margin-top: 8px;
  position: relative;
  ${(props) => props.theme.breakpoints.down('md')} {
    .MuiTypography-body2 {
      font-size: 12px !important;
      line-height: 20px !important;
    }
  }
`;

export const DetailButton = styled(Button)`
  position: absolute !important;
  bottom: 0;
  right: 2px;
  padding: 4px 6px !important;
`;

export const Status = styled(Typography)<{ color: string }>`
  && {
    background-color: ${props => props.color};
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 16px;
    padding: 0 10px;
    color: ${props => props.theme.palette.common.white};
    width: fit-content;

    ${(props) => props.theme.breakpoints.down('md')} {
      font-size: 9px !important;
      line-height: 18px !important;
    }
    
    ${(props) => props.theme.breakpoints.down('sm')} {
      white-space: nowrap;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
