// Dependencies
import styled from 'styled-components';

// Components
import { Dialog } from '../../Common';

// Export styled components
export const StateMessageModal = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 512px !important;
  }

  .MuiDialogTitle-root {
    border-bottom: none;
    padding-bottom: 16px !important;
  }

  .MuiDialogContent-root {
    padding: 0 20px 20px !important;
  }
`;
