import { IUserData } from './user.interface';
import { IPayoutVariant } from './payout-vaiant.interface';

export enum ICalcType {
  liveLong = 0,
  liveLongWithOneTimePayment = 1,
  timeRent = 2,
  timeRentWithOneTimePayment = 3,
  oneTimePayment = 4,
}

export interface IOfferCalculation {
  id?: number;
  calcType?: ICalcType;
  payoutVariant?: number | IPayoutVariant;
  birthDate: any;
  gender: any;
  offerCalculated?: IOfferCalculated;
  propertyConstructionYear: any;
  remainingLife: any;
  livingSpace: any;
  rent: any;
  oneTimePayment: any;
  propertyValue: any;
  rentTime: any;
  garageCount?: any;
  garageRent?: any;
  interestRate: any;
  initialYield: any;
  remainingDept?: number;
}

export interface IOfferCalculated {
  id: number;
  _one_time_payment: number;
  offerCalculation: IOfferCalculation;
  creator: IUserData;
  created: Date;
  binding?: boolean;
  usufruct: number;
  maintenance_costs: number;
  interest_on_equity: number;
  total_annuity: number;
  monthly_annuity?: number;
  preferred: boolean;
  applied: boolean;
  rest_life_expectancy: number;
  desire_monthly_annuity?: number;
  actual_monthly_annuity?: number;
  actual_one_time_payment?: number;
  desire_one_time_payment?: number;
  remaining_dept?: number;
  initial_yield?: number;
}

export interface IOfferCalculationResult {
  formId: string;
  message: string;
  formData: {
    calculated: IOfferCalculated,
    calculation: IOfferCalculation,
  }
}
