// Dependencies
import { FC, Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Collapse,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// Interfaces
import { RootState } from '../../../../../../../redux/reducers';

// Components
import { Icon } from '../../../../../../../components';

// Styles
import * as S from './styles';
import { RemoveIcon } from '../../../../../../../assets/icons';

// Create market data section
const MarketDataSection: FC = () => {
  // States
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  // Get theme from hook
  const theme = useTheme();

  // Check window size
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Get translation from hook
  const { t } = useTranslation();

  // Get project detail from store
  const projectDetail = useSelector(
    ({ projectReducer: { projectDetail } }: RootState) => projectDetail
  );

  // Constants
  const data = [
    {
      label: 'estimated_market_value',
      value: [
        {
          property: 'market_value',
          unit: '€',
        },
      ],
    },
    {
      label: 'estimated_market_value_sqm',
      value: [
        {
          property: 'market_value_sqm',
          unit: '€/m²',
        },
      ],
    },
    {
      label: 'estimated_rental_value',
      value: [
        {
          property: 'estimated_rental_value',
          unit: '€',
        },
      ],
    },
    {
      label: 'estimated_rental_value_sqm',
      value: [
        {
          property: 'estimated_rental_value_sqm',
          unit: '€/m²',
        },
      ],
    },
    {
      label: 'market_value_range',
      value: [
        {
          property: 'market_value_margin_start',
          unit: '€',
        },
        {
          property: 'market_value_margin_end',
          unit: '€',
        },
      ],
    },
  ];

  // Toggle collapse handler
  const handleToggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  // On window size changed
  useEffect(() => {
    setIsExpanded(!isMobile);
  }, [isMobile]);

  // Return market data section
  return (
    <Box>
      <Stack
        mb={isExpanded ? 12 : 0}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography mb={12} variant="h4" color="lightIndigo">
          {t('project_detail.market_data')}
        </Typography>
        <S.ExpandButton onClick={handleToggleCollapse}>
          {isExpanded ? <RemoveIcon /> : <Icon name="plus-lg" />}
        </S.ExpandButton>
      </Stack>
      <Collapse in={isExpanded}>
        <S.Data divider={<Divider orientation="vertical" flexItem />}>
          {data.map(({ label, value }, index) => (
            <S.Item key={index} spacing={12}>
              <Typography variant="body2" color="lightIndigo" maxWidth={121}>
                {t(`project_detail.${label}`)}
              </Typography>
              <Typography variant="h2">
                {value.map(({ property, unit }, vIndex) => (
                  <Fragment key={vIndex}>
                    {vIndex !== 0 && <> - </>}
                    {projectDetail && projectDetail.sprengnetter
                      ? `${projectDetail.sprengnetter[property]} ${unit}`
                      : ''}
                  </Fragment>
                ))}
              </Typography>
            </S.Item>
          ))}
        </S.Data>
      </Collapse>
    </Box>
  );
};

// Export market data section
export default MarketDataSection;
