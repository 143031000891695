// Dependencies
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, CircularProgress, FormControl, Stack } from '@mui/material';

// Components
import { Button, Icon, Input } from '../../Common';

// Styles
import * as S from './styles';
import { IUserData } from '../../../interfaces';
import { getUsers } from '../../../services/user.service';
import { CloseConfirmModal } from '../CloseConfirmModal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ListItem } from '../../Common/ListItem';
import { debounce } from 'lodash';

// Interfaces
interface IAssignBrokerModalProps {
  isLead?: boolean;
  target?: string;
  brokerId?: string | number | null;
  open: boolean;
  onClose: () => void;
  onAssign: (id: string) => void;
}

// Export CloseConfirm modal
export const AssignBrokerModal: FC<IAssignBrokerModalProps> = ({
  isLead,
  target,
  brokerId,
  open,
  onClose,
  onAssign,
}) => {
  // Get translations from hook
  const { t } = useTranslation();

  const [brokers, setBrokers] = useState<IUserData[]>([]);
  const [broker, setBroker] = useState<string | number>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [assignLoading, setAssignLoading] = useState<boolean>(false);
  const [visibleCloseConfirmModal, setVisibleCloseConfirmModal] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');

  const selectedBroker = useMemo(() => {
    if (brokers?.length > 0 && broker) {
      return brokers.find((item) => +broker === +item.id);
    }
  }, [broker, brokers]);

  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  const fetchClients = () => {
    setLoading(true);
    getUsers({
      messages: 1,
      search: debouncedSearch,
      pagination: 0,
    }).then((res: any) => {
      setBrokers(res.data?.data || []);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };

  const handleChangeBroker = (id) => {
    setBroker(id);
  };

  const handleCancelConfirmModal = () => {
    setVisibleCloseConfirmModal(false);
  };

  const handleConfirmAssign = () => {
    setVisibleCloseConfirmModal(true);
  };

  const handleAssign = async () => {
    setVisibleCloseConfirmModal(false);
    setAssignLoading(true);
    await onAssign(broker as string);
    setAssignLoading(false);
    handleClose();
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleClose = () => {
    setSearch('');
    onClose();
  };

  useEffect(() => {
    if (debouncedSearch) {
      fetchClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    setBroker(brokerId ? brokerId : '');
  }, [brokerId]);

  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
  }, [search, delayedChangeSearch]);

  // Return CloseConfirm modal
  return (
    <S.AssignBrokerModal
      title={brokerId ? t('assign_broker.change') : t('assign_broker.assign')}
      open={open}
      onClose={handleClose}
      actions={
        <Stack direction="row" spacing={10}>
          <Button
            size="large"
            color="primary"
            loading={assignLoading}
            disabled={!broker || broker === brokerId}
            onClick={handleConfirmAssign}
          >
            {t('assign_broker.assign')}
          </Button>
        </Stack>
      }
    >
      <FormControl>
        <Input
          size="medium"
          startAdornment={<Icon name="search" />}
          placeholder={t('new_chat.search')}
          value={search}
          onChange={handleSearchChange}
        />
      </FormControl>
      <Box component={PerfectScrollbar} sx={{ height: 350, mt: 8 }}>
        {
          loading ? (
            <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : brokers.length > 0 && brokers.map((brokerItem, index) => (
            <ListItem
              key={index}
              sx={{
                '.MuiListItemText-secondary': {
                  '.MuiBox-root': {
                    width: '100% !important'
                  }
                }
              }}
              selected={brokerItem.id === broker}
              avatar={brokerItem.image?.path}
              title={`${brokerItem.first_name} ${brokerItem.last_name}`}
              subTitle={brokerItem.company || ''}
              onClick={() => handleChangeBroker(brokerItem.id)}
            />
          ))
        }
      </Box>

      <CloseConfirmModal
        title={t('assign_broker.assign_title')}
        text={
          <>
            <Trans i18nKey={'assign_broker.assign_confirmation'}>
              Do you want to assign <b>{{target}}</b> to the broker <b>
                {{broker: selectedBroker && (selectedBroker.first_name + ' ' + selectedBroker.last_name)}}
              </b>?
            </Trans>
          </>
        }
        confirmLabel={t('close_confirm.yes')}
        open={visibleCloseConfirmModal}
        onClose={handleAssign}
        onCancel={handleCancelConfirmModal}
      />
    </S.AssignBrokerModal>
  );
};
