// Declare type
import breakpoints from './breakpoints';

declare module '@mui/material/styles' {}

declare module '@mui/material/Typography' {}

// Define typography
const typography = {
  fontFamily: 'Work Sans',
  h1: {
    fontFamily: 'Exo',
    fontWeight: 700,
    fontSize: 30,
    lineHeight: '36px',
  },
  h2: {
    fontFamily: 'Exo',
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '32px',
  },
  h3: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '32px',
  },
  h4: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '28px',
  },
  h5: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
  },
  h6: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
  },
  subtitle1: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
  },
  body1: {
    fontFamily: 'Exo, serif',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    [`@media (max-width: ${breakpoints.values.sm - 0.5}px)`]: {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  body2: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '18px',
  },
  caption: {
    fontSize: 12,
    lineHeight: '20px',
  },
};

// Export typography
export default typography;
