// Dependencies
import React, { FC, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

// Components
import { Carousel } from '../../Common';
import { ListItem } from '../../Common/ListItem';

// Store
import { RootState } from '../../../redux/store';

// Constants
const documents = [
  {
    title: 'Postbankatlas',
    description: `
      <br />
      <br />`,
    downloadLink:
      'https://s3.eu-central-1.amazonaws.com/meinewohnrente.de/cdn/assets/pdf/Postbank+Wohnatlas+2024.pdf',
    downloadLinkExternal:
      'https://s3.eu-central-1.amazonaws.com/meinewohnrente.de/cdn/assets/pdf/Postbank+Wohnatlas+2024.pdf',
  },
  {
    title: 'Ankaufsprofil',
    description: `
      <br />
      <br />`,
    downloadLink:
      'https://s3.eu-central-1.amazonaws.com/meinewohnrente.de/cdn/assets/pdf/Ankaufsprofil+-+Meine+Wohnrente.pdf',
    downloadLinkExternal:
      'https://s3.eu-central-1.amazonaws.com/meinewohnrente.de/cdn/assets/pdf/Ankaufsprofil+-+Meine+Wohnrente.pdf',
  },
  {
    title: 'Objektunterlagen Checkliste',
    description: `
      <br />
      <br />`,
    downloadLink:
      'https://cdn.meinewohnrente.de/cdn/assets/pdf/Objektunterlagen_Checkliste.pdf',
    downloadLinkExternal:
      'https://cdn.meinewohnrente.de/cdn/assets/pdf/Objektunterlagen_Checkliste.pdf',
  },
  {
    img: '/assets/images/beratungsbogen.jpg',
    imgExternal: '/assets/images/beratungsbogen_extern.jpg',
    title: 'Beratungsbogen',
    description: `Offline Beratungsbogen für die analoge Erfassung der
      Kundendaten`,
    downloadLink:
      'https://cdn.meinewohnrente.de/cdn/assets/pdf/MWR_Beratungsbogen_external.pdf',
    downloadLinkExternal:
      'https://cdn.meinewohnrente.de/cdn/assets/pdf/MWR_Beratungsbogen_external.pdf',
  },
  {
    img: 'https://cdn.meinewohnrente.de/cdn/assets/images/MWR_Imagebroschuere_Cover_Mockup.png',

    imgExternal:
      'https://cdn.meinewohnrente.de/cdn/assets/images/MWR_Imagebroschuere_Cover_Mockup.png',
    title: 'Infobroschüre',
    description: `
      <br />
      <br />`,
    downloadLink:
      'https://cdn.meinewohnrente.de/cdn/assets/pdf/MWR_Imagebroschuere.pdf',
    downloadLinkExternal:
      'https://cdn.meinewohnrente.de/cdn/assets/pdf/MWR_Imagebroschuere.pdf',
  },
  {
    img: '/assets/images/faq.jpg',
    imgExternal: '/assets/images/faq.jpg',
    title: 'Leitfaden / FAQ',
    description: `
      <br />
      <br />`,
    downloadLink:
      'https://cdn.meinewohnrente.de/cdn/assets/pdf/MWR_Beraterleitfaden_web.pdf',
    downloadLinkExternal:
      'https://cdn.meinewohnrente.de/cdn/assets/pdf/MWR_Beraterleitfaden_web.pdf',
  },
  {
    title: 'Aktuelle Sterbetafel',
    description: `
      <br />
      <br />`,
    downloadLink:
      "https://cdn.meinewohnrente.de/cdn/assets/pdf/Sterbetafel_2019_2021.pdf",
    downloadLinkExternal:
      "https://cdn.meinewohnrente.de/cdn/assets/pdf/Sterbetafel_2019_2021.pdf",
  },
  {
    // img: "/assets/images/faq.jpg",
    // imgExternal: "/assets/images/faq.jpg",
    title: 'Steuertabelle Ertragsanteil',
    description: `
      <br />
      <br />`,
    downloadLink:
      'https://cdn.meinewohnrente.de/cdn/assets/pdf/Ertragsanteil.pdf',
    downloadLinkExternal:
      'https://cdn.meinewohnrente.de/cdn/assets/pdf/Ertragsanteil.pdf',
  },
];

interface IDocumentPanelProps {
  pagination?: { page: number, perPage: number };
  search?: string;
  onChangeTotal?: (value: number) => void;
}

// Export document panel
export const DocumentPanel: FC<IDocumentPanelProps> = ({
  pagination,
  search,
  onChangeTotal,
}) => {
  // Get user from store
  const { userDetail } = useSelector(
    ({ userReducer }: RootState) => userReducer
  );

  let filteredDocuments = documents;
  let filteredDocumentsCount;

  if (search) {
    filteredDocuments = filteredDocuments.filter((item) =>
      item.title.toLowerCase().includes(search.toLowerCase())
    );
  }

  filteredDocumentsCount = filteredDocuments.length;

  if (pagination) {
    filteredDocuments = filteredDocuments.slice(
      (pagination.page - 1) * pagination.perPage,
      pagination.page * pagination.perPage
    );
  }

  useEffect(() => {
    onChangeTotal && onChangeTotal(filteredDocumentsCount);
  }, [filteredDocumentsCount, onChangeTotal]);

  // Calc carousel items
  const carouselItems = useMemo(() =>
    filteredDocuments
      .reduce((page: any[][], document, index) => {
        if (index % 3 === 0) {
          return [...page, [document]];
        } else {
          return [
            ...page.slice(0, page.length - 1),
            [...page[page.length - 1], document],
          ];
        }
      }, [])
  , [filteredDocuments]);

  const handleDownload = (link: string) => {
    window.open(link, '_blank');
  };

  // Return document panel
  return (
    <>
      <Box sx={{ pr: { md: 14 }, display: { xs: 'none', sm: 'block' } }}>
        {filteredDocuments.map(
          (
            { img, imgExternal, title, downloadLink, downloadLinkExternal },
            index
          ) => (
            <ListItem
              key={index}
              avatar={userDetail?.is_external_agent ? imgExternal : img}
              title={title}
              onDownload={() =>
                handleDownload(
                  userDetail?.is_external_agent
                    ? downloadLinkExternal
                    : downloadLink
                )
              }
            />
          )
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        {carouselItems.length > 0 && (
          <Carousel length={carouselItems?.length}>
            {carouselItems.map((documents, index) => (
              <Box key={index} sx={{ width: 'calc(100% - 40px)' }}>
                {documents.map(
                  (
                    {
                      img,
                      imgExternal,
                      title,
                      downloadLink,
                      downloadLinkExternal,
                    },
                    index
                  ) => (
                    <ListItem
                      key={index}
                      avatar={userDetail?.is_external_agent ? imgExternal : img}
                      title={title}
                      sx={{
                        '.MuiTypography-root.MuiTypography-subtitle1.MuiListItemText-primary': {
                          maxWidth: '120px'
                        }
                      }}
                      onDownload={() =>
                        handleDownload(
                          userDetail?.is_external_agent
                            ? downloadLinkExternal
                            : downloadLink
                        )
                      }
                    />
                  )
                )}
              </Box>
            ))}
          </Carousel>
        )}
      </Box>
    </>
  );
};
