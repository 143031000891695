// Dependencies
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getProjectDetail,
  getPropertyObjectIdForm,
} from '../../../redux/actions';
import { RootState } from '../../../redux/store';
import { updateProperty } from '../../../services/project.service';
import {
  buildFormInitialValues,
  buildSingleFieldSchema,
  getInitialValuesInFormStructure,
} from '../../../utils';
import { Button, Dialog } from '../../Common';
import { FormBuilder } from '../../FormBuilder';
import { CloseConfirmModal } from '../CloseConfirmModal';

interface IObjectIdModalProps {
  id: number | undefined;
  open: boolean;
  data?: any;
  onClose: () => void;
}

export const ObjectIdModal: FC<IObjectIdModalProps> = ({
  id,
  open,
  data,
  onClose,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get add payout form from store
  const propertyObjectIdForm = useSelector(
    ({ formReducer: { propertyObjectIdForm } }: RootState) =>
      propertyObjectIdForm
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [closeModal, setCloseModal] = useState(false);

  // Create formik
  const formik = useFormik<any>({
    initialValues: getInitialValuesInFormStructure(
      data,
      buildFormInitialValues(propertyObjectIdForm)
    ),
    validationSchema: buildSingleFieldSchema(propertyObjectIdForm),
    onSubmit: async (values) => {
      setLoading(true);
      if (id) {
        updateProperty(id, values)
          .then((res) => {
            setLoading(false);
            if (res.data?.success) {
              enqueueSnackbar(res.data?.message, { variant: 'success' });
              dispatch(getProjectDetail(id));
            } else {
              enqueueSnackbar(res.data?.message || 'Failed to save Object Id', {
                variant: 'error',
              });
            }

            onClose();
          })
          .catch((e) => {
            setLoading(false);
            onClose();
            enqueueSnackbar(
              e.response?.data?.message || 'Failed to save Object Id',
              { variant: 'error' }
            );
          });
      }
    },
  });

  const handleSaveObjectId = async () => {
    formik.handleSubmit();
  };

  const handleCloseConfirmModal = () => {
    setCloseModal(false);
    onClose();
  };

  const handleClose = () => {
    setCloseModal(true);
  };

  const handleCancelConfirmModal = () => {
    setCloseModal(false);
  };

  // On mounted
  useEffect(() => {
    (async () => {
      await dispatch(getPropertyObjectIdForm());
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t('project_detail.object_id')}
      actions={
        <>
          <Button
            color="secondary"
            size="large"
            onClick={handleSaveObjectId}
            loading={loading}
          >
            {t('project_detail.save')}
          </Button>
        </>
      }
    >
      <Grid container columns={1} spacing={16}>
        <FormBuilder
          type={propertyObjectIdForm?.type}
          attributes={propertyObjectIdForm?.attributes}
          label={propertyObjectIdForm?.label}
          fields={propertyObjectIdForm?.fields}
          parentFields={propertyObjectIdForm?.fields}
          formik={formik}
          layout={{ text: 2, textarea: 2 }}
          path=""
        />
      </Grid>

      {closeModal && (
        <CloseConfirmModal
          open={closeModal}
          onClose={handleCloseConfirmModal}
          onCancel={handleCancelConfirmModal}
        />
      )}
    </Dialog>
  );
};
