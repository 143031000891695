// Dependencies
import React, { FC } from 'react';

import { Box, Stack, Divider } from '@mui/material';
import { InfoWindow } from 'google-maps-react';

// Components
import { Typography } from '../../Common';

// Styles
import * as S from './styles';
import { IMarker } from '../../../interfaces';
import { ROUTES, getMainStatusKey } from '../../../constants';

interface IMapInfoWindowProps {
  marker: IMarker | null;
  visible: boolean;
}

export const MapInfoWindow: FC<IMapInfoWindowProps> = ({
  marker,
  visible,
  ...props
}: IMapInfoWindowProps) => {

  const link = ROUTES.CLIENTS.DETAIL.replace(':id', `${marker?.id}`).replace(':filter', marker?.status ? getMainStatusKey(marker?.status) : 'new');

  return (
    /* @ts-ignore */
    <InfoWindow position={marker?.position} visible={visible} {...props}>
      <Stack direction="row">
        <a href={link} className="link-map">
          <S.MenuItemAvatar src={marker?.image} variant="rounded" />
          <Box
            justifyContent="space-between"
            flexDirection="column"
            display="flex"
          >
            <Stack>
              <Typography variant="subtitle1">{marker?.name}</Typography>
              <Typography variant="caption">
                {marker?.city} {marker?.street} {marker?.zip}
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              divider={<Divider color="#a3b2c5" orientation="vertical" />}
              direction="row"
            >
              <Typography variant="subtitle2">{marker?.plot_size} sqm</Typography>
              <Typography variant="subtitle2">{marker?.living_space} sqm</Typography>
              <Typography variant="subtitle2">{marker?.room_amount}</Typography>
            </Stack>
          </Box>
        </a>
      </Stack>
    </InfoWindow>
  );
};
