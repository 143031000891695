// Dependencies
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button, Typography } from '../../Common';

// Styles
import * as S from './styles';
import { Stack } from '@mui/material';

export type CloseConfirmReason = 'escapeKeyDown' | 'backdropClick' | 'cancelClick' | 'xClick';

// Interfaces
interface ICloseConfirmModalProps {
  open: boolean;
  title?: string;
  text?: string | ReactNode;
  confirmLabel?: string;
  hideCancel?: boolean;
  onClose: () => void;
  onCancel: (event?: {}, reason?: CloseConfirmReason) => void;
}

// Export CloseConfirm modal
export const CloseConfirmModal: FC<ICloseConfirmModalProps> = ({
  open,
  title,
  confirmLabel,
  text,
  hideCancel,
  onClose,
  onCancel,
}) => {
  // Get translations from hook
  const { t } = useTranslation();

  const handleClose = (event?: any, reason?: "escapeKeyDown" | "backdropClick") => {
    let closeReason: CloseConfirmReason | undefined = reason;
    if (!reason) closeReason = 'xClick';
    onCancel(event, closeReason);
  };

  // Return CloseConfirm modal
  return (
    <S.CloseConfirmModal
      title={title || t('close_confirm.title')}
      open={open}
      onClose={handleClose}
      actions={
        <Stack direction="row" spacing={10}>
          {!hideCancel && (
            <Button size="large" onClick={(event) => onCancel(event, "cancelClick")}>
              {t('close_confirm.close')}
            </Button>
          )}
          <Button size="large" color="primary" onClick={onClose}>
            { confirmLabel || t('close_confirm.yes') }
          </Button>
        </Stack>
      }
    >
      <Typography>{text || t('close_confirm.description')}</Typography>
    </S.CloseConfirmModal>
  );
};
