import { CLIENT_ACTIONS } from '../action-types';

export interface ClientReducerState {
  clients: any;
  createClientForms: any;
  loading: boolean;
  drafts: any[];
  draft: any;
}

const initialState: ClientReducerState = {
  clients: null,
  createClientForms: null,
  loading: false,
  drafts: [],
  draft: [],
};

const ClientReducer = (
  state: ClientReducerState = initialState,
  action: any
) => {
  const { type, payload } = action;
  switch (type) {
    case CLIENT_ACTIONS.GET_CREATE_CLIENT_FORMS_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLIENT_ACTIONS.GET_CREATE_CLIENT_FORMS_ERROR: {
      return {
        ...state,
        createClientForms: null,
        loading: true,
      };
    }

    case CLIENT_ACTIONS.CREATE_DRAFT: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLIENT_ACTIONS.CREATE_DRAFT_SUCCESS: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLIENT_ACTIONS.GET_CREATE_CLIENT_FORMS_SUCCESS: {
      return {
        ...state,
        createClientForms: payload,
        loading: false,
      };
    }

    case CLIENT_ACTIONS.CREATE_DRAFT_ERROR:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_ACTIONS.CREATE_CLIENT_START:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_ACTIONS.CREATE_CLIENT_ERROR:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_ACTIONS.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_ACTIONS.GET_DRAFTS_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLIENT_ACTIONS.GET_DRAFTS_ERROR:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_ACTIONS.GET_DRAFTS_SUCCESS: {
      return {
        ...state,
        drafts: payload,
        loading: false,
      };
    }

    case CLIENT_ACTIONS.GET_CLIENTS_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLIENT_ACTIONS.GET_CLIENTS_ERROR:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_ACTIONS.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: payload,
        loading: false,
      };

    case CLIENT_ACTIONS.DELETE_DRAFT_START:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_ACTIONS.DELETE_DRAFT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case CLIENT_ACTIONS.DELETE_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CLIENT_ACTIONS.GET_DRAFT_START:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_ACTIONS.GET_DRAFT_ERROR:
      return {
        ...state,
        loading: false,
        draft: {},
      };

    case CLIENT_ACTIONS.GET_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        draft: payload,
      };

    case CLIENT_ACTIONS.UPDATE_DRAFT: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLIENT_ACTIONS.UPDATE_DRAFT_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLIENT_ACTIONS.UPDATE_DRAFT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLIENT_ACTIONS.UPDATE_DRAFT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default ClientReducer;
