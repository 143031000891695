import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ChatType, IClient, VisibleSection } from '..';
import { RootState } from '../../../../redux/store';
import ChatsView from '../ChatsView';
import ClientsView from '../ClientsView';

import {
  ROUTES,
  getMainStatusKey,
  getStatusRoute,
} from '../../../../constants';
import { IProjectData, Order } from '../../../../interfaces';

import { debounce } from 'lodash';
import { getClientById } from '../../../../services/client.service';
import { getIternalUsers } from '../../../../services/user.service';

const PER_PAGE = 10;
interface InternalChatProps {
  onClose: () => void;
  projectId?: number;
  chatType: ChatType;
  setVisibleNewChatModal: (visible: boolean) => void;
  setClientSearch: (search: string) => void;
  clientSearch: string;
  handleClickClient: (id: number) => void;
  setStatusFilter: (status: string) => void;
  statusFilter: string;
  setSortOrder: (order: Order) => void;
  sortOrder: Order;
  fetchClients: () => void;
  handleGoBack?: () => void;
  handleOpenInformation: () => void;
  handleCloseInformation: () => void;
  visibleInformation?: boolean;
  setVisibleSection: (section: VisibleSection) => void;
  visibleSection?: VisibleSection;
  visiblePhotoViewer?: boolean;
  handleClosePhotoViewer: () => void;
  handleOpenPhotos: () => void;
  setDebouncedSearch: (search: string) => void;
  debouncedSearch: string;
  page?: number;
  setPage?: (page: number) => void;
  setTotal: (total: number) => void;
  openFromWidget?: boolean;
}

function InternalChat({
  onClose,
  projectId,
  chatType,
  setVisibleNewChatModal,
  openFromWidget,
  clientSearch,
  setClientSearch,
  handleClickClient,
  setStatusFilter,
  statusFilter,
  setSortOrder,
  sortOrder,
  fetchClients,
  handleGoBack,
  handleOpenInformation,
  handleCloseInformation,
  visibleInformation,
  setVisibleSection,
  visibleSection,
  handleClosePhotoViewer,
  handleOpenPhotos,
  setDebouncedSearch,
  debouncedSearch,
  setPage,
  setTotal,
  page,
}: InternalChatProps) {
  // Get navigate from hook
  const navigate = useNavigate();

  // Get snackbar
  const { enqueueSnackbar } = useSnackbar();

  const { clients: projects, loading: listLoading } = useSelector(
    ({ clientReducer: { clients, loading } }: RootState) => ({
      clients,
      loading,
    })
  );

  const [iternalUsers, setIternalUsers] = useState<any>([]);

  useEffect(() => {
    const fetchIternalUser = async () =>
      await getIternalUsers({}).then((res) => {
        setIternalUsers(res?.data?.data);
      });

    fetchIternalUser();
  }, []);

  // States
  const [client, setClient] = useState<IClient>();

  const [project, setProject] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState<IProjectData[]>([]);

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  // Theme
  const theme = useTheme();

  // Check platform
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  // Go back handler
  // Open new chat modal handler
  const handleOpenNewChat = () => {
    setVisibleNewChatModal(true);
  };

  // Handle sort change
  const handleChangeSort = () => {
    setSortOrder(sortOrder === Order.Asc ? Order.Desc : Order.Asc);
  };

  const handleResetProjectList = async (updatedProject) => {
    if (projectList.find((item) => item.id === updatedProject.id)) {
      setProjectList([
        updatedProject,
        ...projectList.filter((item) => item.id !== updatedProject.id),
      ]);
    } else {
      await fetchClients();
    }
  };

  // Delayed search handler
  const delayedChangeSearch = useRef(
    debounce((clientSearch) => {
      setDebouncedSearch(clientSearch);
    }, 500)
  );

  // Set list
  useEffect(() => {
    if (projects?.data) {
      setProjectList(projects?.data);
    }
  }, [projects]);

  // On search changed
  useEffect(() => {
    delayedChangeSearch.current(clientSearch);
    return delayedChangeSearch.current.cancel;
  }, [clientSearch, delayedChangeSearch]);

  useEffect(() => {
    fetchClients();
    setTotal(Math.ceil(projects?.pagination?.total / PER_PAGE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, sortOrder, statusFilter, page]);

  // On change platform
  useEffect(() => {
    setVisibleSection(VisibleSection.Clients);
  }, [isTablet, setVisibleSection]);

  useEffect(() => {
    if (projectId) {
      setLoading(true);
      getClientById(projectId)
        .then((res) => {
          setProject(res?.data?.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

      setClient(client);
      if (isTablet) {
        setVisibleSection(VisibleSection.Detail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  // Return chats modal

  return (
    <>
      <PerfectScrollbar style={{ margin: -20 }}>
        <Stack direction="row">
          <ClientsView
            visible={visibleSection === VisibleSection.Clients}
            onClickClient={handleClickClient}
            projects={projectList || []}
            selectedId={project?.id}
            search={clientSearch}
            statusFilter={statusFilter}
            loading={listLoading}
            setSearch={setClientSearch}
            setStatusFilter={setStatusFilter}
            onChangeSortOrder={handleChangeSort}
            onStartNewChat={handleOpenNewChat}
            chatType={chatType}
          />
          <ChatsView
            project={project}
            visible={visibleSection === VisibleSection.Detail}
            loading={loading}
            fetchClients={fetchClients}
            resetProjectList={handleResetProjectList}
            onClickInformation={handleOpenInformation}
            chatType={chatType}
            agents={iternalUsers}
          />

          {/* TODO: FIX No issue */}
          {/* {visibleInformation &&
            (visibleSection === VisibleSection.Photos ? (
              <PhotosView
                visible={visibleSection === VisibleSection.Photos}
                files={project?.property?.property_images?.files}
                onClose={handleClosePhotoViewer}
              />
            ) : (
              <InfoView
                project={project}
                visible={visibleSection === VisibleSection.Info}
                deleteLoading={deleteLoading}
                onDeleteObject={handleDeleteObject}
                onCloseInformation={handleCloseInformation}
                onPhotoClick={handleOpenPhotos}
                onShowDetail={handleGoToDetail}
              />
            ))} */}
        </Stack>
      </PerfectScrollbar>
    </>
  );
}

export default InternalChat;
