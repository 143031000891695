// Dependencies
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import ListTable from './Table';

// Styles
import * as S from './styles';

// Constants
import { ROUTES } from '../../../constants';

enum ListTab {
    Table = 'table',
    Analytics = 'analytics',
}

// Export project list page
export const DraftListPage: FC = () => {
    // States
    const [tab, setTab] = useState<ListTab>(ListTab.Table);
    // Get path from hook
    const { pathname } = useLocation();
    // Check list type
    const isClientType = useMemo(() => {

        return pathname.includes(ROUTES.CLIENTS.INDEX);

    }, [pathname]);
    // On list type changed

    useEffect(() => {
        if (!isClientType) {
            setTab(ListTab.Table);
        }
    }, [isClientType]);

    // Return project list page
    return <S.Page>{tab === ListTab.Table && <ListTable />}</S.Page>;
};