// Dependencies
import { FC, useMemo, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CollectPaymentDataModal,
  ContractCard,
  EnterEligiblePersonModal,
  ObjectIdModal,
  Icon,
  EmergencyContactPersonModal,
} from '../../../../../../components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/reducers';
import {
  getCurrency,
  getDate,
  getDateString,
  getEndDate,
  getFirstAdditionalCosts,
  getFirstYearFromAdditionalCosts,
  getProjectAdditionalCosts,
} from '../../../../../../utils';

// Create contract data panel
const ContractDataPanel: FC = () => {
  // States
  const [visibleCollectPaymentDataModal, setVisibleCollectPaymentDataModal] =
    useState<boolean>(false);
  const [
    visibleEmergencyContactPersonModal,
    setVisibleEmergencyContactPersonModal,
  ] = useState<boolean>(false);
  const [visibleEnterEligiblePersonModal, setVisibleEnterEligiblePersonModal] =
    useState<boolean>(false);
  const [visibleObjectIdModal, setVisibleObjectIdModal] = useState(false);

  // Get translation from hook
  const { t } = useTranslation();

  // Get project detail from store
  const { projectDetail } = useSelector(
    ({ projectReducer }: RootState) => projectReducer
  );

  // Get payment details
  const paymentDetails = useMemo(() => {
    const paymentDetail = projectDetail?.payment_detail;

    if (paymentDetail) {
      const calculation = projectDetail?.accepted_calculation?.offerCalculation;
      const startOfPayment = paymentDetail?.start_of_payment;

      let payment = 'oneTime';
      if (
        projectDetail?.accepted_calculation?.actual_monthly_annuity &&
        projectDetail?.accepted_calculation?.actual_one_time_payment
      ) {
        payment = 'combination';
      } else if (projectDetail?.accepted_calculation?.actual_monthly_annuity) {
        payment = 'rent';
      }

      const items = [
        {
          label: 'Kontoinhaber',
          value: `${projectDetail.customer.first_name} ${projectDetail.customer.last_name}`,
        },
        {
          label: 'IBAN',
          value: paymentDetail.iban,
        },
        {
          label: 'Verstorben am',
          value: getDateString(paymentDetail.passed_away_date),
          hidden: !paymentDetail.passed_away,
        },
        {
          label: 'Innerhalb Rentengarantiezeit verstorben',
          value: `${
            paymentDetail.passed_away_within_rent_warranty ? 'Ja' : 'Nein'
          }`,
          hidden: !paymentDetail.passed_away,
        },
        {
          label: 'Einmalzahlung',
          value: getCurrency(paymentDetail.rent_warranty_final_payment) + ' €',
          hidden:
            !paymentDetail.passed_away ||
            !paymentDetail.passed_away_within_rent_warranty,
        },
        {
          label: 'Zahlungsbeginn',
          value: getDateString(startOfPayment),
          hidden: payment === 'oneTime',
        },
        {
          label: 'Auszahlungsdatum',
          value: getDateString(paymentDetail.day_of_payment),
          hidden: !paymentDetail.day_of_payment,
        },
        {
          label: 'Ende der Zeitrente',
          value: getEndDate(startOfPayment, calculation?.rentTime),
          hidden: !calculation?.rentTime || payment === 'oneTime',
        },
        {
          label: 'Ende der Rentengarantiezeit',
          value: getEndDate(startOfPayment, 5),
          hidden: payment === 'oneTime',
        },
        {
          label: 'Startdatum Nebenkostenvorauszahlung',
          value: getDateString(paymentDetail.usage_period_start),
          hidden: !paymentDetail.usage_period_start,
        },
      ];

      let firstYear = '';
      let date1;
      const additionalCostsItem = getFirstAdditionalCosts(projectDetail, true);
      if (additionalCostsItem) {
        firstYear = getFirstYearFromAdditionalCosts(additionalCostsItem);
        if (additionalCostsItem.usage_period_start) {
          date1 = getDateString(additionalCostsItem.usage_period_start);
        }
      }
      items.push({
        label:
          firstYear !== ''
            ? `NK Vorauszahlung p.M. : für ${firstYear} ab ${date1}`
            : 'Monatliche Nebenkostenvorauszahlung',
        value:
          getCurrency(paymentDetail.advance_payment_of_additional_costs) + ' €',
      });
      items.push({
        label: 'Zu überweisender Betrag abzgl. NK',
        value:
          getCurrency(
            paymentDetail.amount_to_be_paid_minus_advance_payment_of_additional_costs
          ) + ' €',
        hidden: payment === 'oneTime',
      });

      // add other years
      const additionalCosts = getProjectAdditionalCosts(projectDetail, true);
      if (additionalCosts) {
        for (let additionalCostsItem of additionalCosts) {
          let yearUsagePeriodStartString = '';
          if (
            additionalCostsItem.usage_period_start &&
            additionalCostsItem.usage_period_end
          ) {
            let usagePeriodStart = getDate(
              additionalCostsItem.usage_period_start
            );
            let usagePeriodEnd = getDate(additionalCostsItem.usage_period_end);
            let yearUsagePeriodStart = getDate(
              additionalCostsItem.usage_period_start
            )?.getFullYear();
            if (yearUsagePeriodStart) yearUsagePeriodStart += 1;
            const date1 = getDateString(additionalCostsItem.finalized_date);
            yearUsagePeriodStartString = ` : für ${yearUsagePeriodStart} ab ${date1}`;
            if (usagePeriodStart && usagePeriodEnd) {
              const fullYearPrepayment = additionalCostsItem.prepayment;
              items.push({
                label: `NK Vorauszahlung p.M.${yearUsagePeriodStartString}`,
                value: getCurrency(fullYearPrepayment) + ' €',
              });
            }
          }
          items.push({
            label: `Zu überweisender Betrag abzgl. NK (${additionalCostsItem.calc_year})`,
            value:
              getCurrency(additionalCostsItem.difference_prepayment) + ' €',
            hidden: payment === 'oneTime',
          });
        }
      }

      return {
        title: t('project_detail.payment_details'),
        items,
      };
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail]);

  // Get eligible person
  const eligiblePerson = useMemo(() => {
    const beneficiaryPerson = projectDetail?.beneficiary_person;
    if (beneficiaryPerson) {
      const items = [
        {
          label: 'Kontoinhaber',
          value: `${beneficiaryPerson.first_name} ${beneficiaryPerson.last_name}`,
        },
        {
          label: 'IBAN',
          value: beneficiaryPerson.iban,
        },
        {
          label: 'Straße',
          value: beneficiaryPerson?.address?.street,
        },
        {
          label: 'PLZ / Ort',
          value:
            beneficiaryPerson?.address?.zip +
            ' ' +
            beneficiaryPerson?.address?.city,
        },

        {
          label: 'E-Mail',
          value: beneficiaryPerson.email,
        },

        {
          label: 'Telefon',
          value: beneficiaryPerson.phone,
        },

        {
          label: 'Mobil',
          value: beneficiaryPerson.mobile,
        },
      ];

      return {
        title: t('project_detail.eligible_person'),
        items,
      };
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail]);

  // Get emergency contact person
  const emergencyContactPerson = useMemo(() => {
    const emergencyContactPerson = projectDetail?.emergency_contact_person;
    if (emergencyContactPerson) {
      const items = [
        {
          label: 'Kontoinhaber',
          value: `${emergencyContactPerson.first_name} ${emergencyContactPerson.last_name}`,
        },

        {
          label: 'Straße',
          value: emergencyContactPerson?.address?.street,
        },
        {
          label: 'PLZ / Ort',
          value:
            emergencyContactPerson?.address?.zip +
            ' ' +
            emergencyContactPerson?.address?.city,
        },

        {
          label: 'E-Mail',
          value: emergencyContactPerson.email,
        },

        {
          label: 'Telefon',
          value: emergencyContactPerson.phone,
        },

        {
          label: 'Mobil',
          value: emergencyContactPerson.mobile,
        },
      ];

      return {
        title: t('project_detail.notfallkontakt'),
        items,
      };
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail]);

  // Open collect payment data modal handler
  const handleOpenCollectPaymentDataModal = () => {
    setVisibleCollectPaymentDataModal(true);
  };

  // Close collect payment data modal handler
  const handleCloseCollectPaymentDataModal = () => {
    setVisibleCollectPaymentDataModal(false);
  };

  // Open enter eligible person modal handler
  const handleOpenEnterEligiblePersonModal = () => {
    setVisibleEnterEligiblePersonModal(true);
  };

  // Close enter eligible person modal handler
  const handleCloseEmergencyContactPersonModal = () => {
    setVisibleEmergencyContactPersonModal(false);
  };

  // Close enter eligible person modal handler
  const handleCloseEnterEligiblePersonModal = () => {
    setVisibleEnterEligiblePersonModal(false);
  };

  // Open object id modal
  const handleOpenObjectIdModal = () => {
    setVisibleObjectIdModal(true);
  };

  // Open emergency contact modal
  const handleOpenEmergencyContactModal = () => {
    setVisibleEmergencyContactPersonModal(true);
  };

  // Close object id modal
  const handleCloseObjectIdModal = () => {
    setVisibleObjectIdModal(false);
  };

  // Return contract data panel
  return (
    <>
      <Stack
        mb={20}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3">
          {t('project_detail.contract_data').replace('-', '')}
        </Typography>
        <Stack direction="row" paddingLeft={12} spacing={12}>
          {!emergencyContactPerson && (
            <Button onClick={handleOpenEmergencyContactModal}>
              {t('project_detail.notfallkontakt')}
            </Button>
          )}
          <Button
            startIcon={<Icon name="home" />}
            onClick={handleOpenObjectIdModal}
          >
            {t('project_detail.object_id')}
          </Button>
          {!paymentDetails && (
            <Button
              startIcon={<Icon name="currency-euro" />}
              onClick={handleOpenCollectPaymentDataModal}
            >
              {t('project_detail.deposit_payment_data')}
            </Button>
          )}
          {!eligiblePerson && (
            <Button
              startIcon={<Icon name="user" />}
              onClick={handleOpenEnterEligiblePersonModal}
            >
              {t('project_detail.deposit_authorized_person')}
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack spacing={20}>
        {emergencyContactPerson && (
          <ContractCard
            {...emergencyContactPerson}
            onEdit={handleOpenEmergencyContactModal}
          />
        )}
        {paymentDetails && (
          <ContractCard
            {...paymentDetails}
            onEdit={handleOpenCollectPaymentDataModal}
          />
        )}
        {eligiblePerson && (
          <ContractCard
            {...eligiblePerson}
            onEdit={handleOpenEnterEligiblePersonModal}
          />
        )}
      </Stack>
      <CollectPaymentDataModal
        isEdit={!!paymentDetails}
        open={Boolean(visibleCollectPaymentDataModal && projectDetail)}
        onClose={handleCloseCollectPaymentDataModal}
      />
      <EnterEligiblePersonModal
        isEdit={!!eligiblePerson}
        open={visibleEnterEligiblePersonModal}
        onClose={handleCloseEnterEligiblePersonModal}
      />
      <EmergencyContactPersonModal
        isEdit={!!emergencyContactPerson}
        open={visibleEmergencyContactPersonModal}
        onClose={handleCloseEmergencyContactPersonModal}
      />
      {visibleObjectIdModal && (
        <ObjectIdModal
          id={projectDetail?.id}
          open={visibleObjectIdModal}
          data={projectDetail?.property}
          onClose={handleCloseObjectIdModal}
        />
      )}
    </>
  );
};

// Export contact data panel
export default ContractDataPanel;
