// Dependencies
import styled from 'styled-components';
import { ButtonBase, ListItemText, Typography } from '@mui/material';

export const Menu = styled.div``;

export const MenuItem: any = styled(ButtonBase)<{ $isCollapsed: boolean }>`
  && {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    padding: 12px ${props => props.$isCollapsed ? 0 : 20}px;
    justify-content: ${props => props.$isCollapsed ? 'cetner' : 'flex-start'};
    color: ${(props) => props.theme.palette.lightIndigo};

    & + & {
      margin-top: 12px;
    }
    
    &.active {
      color: ${(props) => props.theme.palette.darkIndigo};
      background-image: linear-gradient(to right, ${(props) => props.theme.palette.lightCyan}, ${(props) => props.theme.palette.cyan});

      .MuiListItemText-primary, .MuiListItemText-secondary {
        color: ${(props) => props.theme.palette.darkIndigo} !important;
      }
    }
  }
`;

export const ProfileItemText = styled(ListItemText)`
  && {
    margin: 0 12px;
    
    .MuiListItemText-primary {
      color: ${props => props.theme.palette.primary.main};
      line-height: 20px;
    }
  }
`;

export const SubMenuItem: any = styled(ButtonBase)`
  && {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    padding: 6px 20px 6px 40px;
    justify-content: flex-start;
    color: ${(props) => props.theme.palette.lightIndigo};
    margin-top: 12px;
    
    &:last-of-type {
      margin-bottom: 12px;
    }

    &.active {
      color: ${(props) => props.theme.palette.primary.main};

      .MuiListItemText-primary, .MuiListItemText-secondary {
        color: ${(props) => props.theme.palette.primary.main} !important;
      }
    }
  }
`;

export const MenuItemIcon = styled.div`
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.theme.palette.lightIndigo};

    ${MenuItem}.active & {
      color: ${(props) => props.theme.palette.darkIndigo};
    }
  }
`;

export const ArrowIcon = styled.div<{ expanded?: boolean }>`
  min-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  transform: rotate(${(props) => props.expanded ? '0' : '-90deg'});
  transition: transform .1s ease-in-out;

  svg {
    color: ${(props) => props.theme.palette.lightIndigo};

    ${MenuItem}.active & {
      color: ${(props) => props.theme.palette.darkIndigo};
    }
  }
`;

export const MenuItemText = styled(Typography)`
  && {
    margin-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px !important;
  }
`;

export const BottomMenu = styled.div`
  margin-top: auto;
`;
