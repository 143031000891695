import { USER_ACTIONS } from '../action-types';
import { IPagination, IUserData } from '../../interfaces';

export interface UserReducerState {
  users: IUserData[];
  userDetail: IUserData | null;
  pagination?: IPagination;
  iternalUsers?: IUserData[];
  loading: boolean;
  detailLoading: boolean;
}

const initialState: UserReducerState = {
  users: [],
  iternalUsers: [],
  userDetail: null,
  loading: false,
  detailLoading: false,
};

const UserReducer = (state: UserReducerState = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTIONS.POST_USER_START:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.POST_USER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case USER_ACTIONS.POST_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case USER_ACTIONS.GET_USERS_START:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case USER_ACTIONS.GET_USERS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };

    case USER_ACTIONS.GET_USER_DETAIL_START:
      return {
        ...state,
        detailLoading: true,
      };

    case USER_ACTIONS.GET_USER_DETAIL_ERROR:
      return {
        ...state,
        detailLoading: false,
        userDetail: {},
      };

    case USER_ACTIONS.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        userDetail: payload,
      };

    case USER_ACTIONS.GET_ITERNAL_USER_START:
      return {
        ...state,
        loading: true,
        iternalUsers: [],
      };

    case USER_ACTIONS.GET_ITERNAL_USER_ERROR:
      return {
        ...state,
        loading: false,
        iternalUsers: [],
      };

    case USER_ACTIONS.GET_ITERNAL_USER_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        iternalUsers: payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
