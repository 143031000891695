// Dependencies
import styled from 'styled-components';
import { alpha, Card, CardContent, CardHeader, Chip } from '@mui/material';

// Styled components
export const PayoutVariantCard = styled(Card)<{
  color: string;
  visibleOffer: boolean;
}>`
  && {
    box-shadow: none;
    overflow: visible;
    background: ${(props) => props.color};

    .MuiIconButton-root,
    .MuiTypography-root {
      color: ${(props) =>
        props.visibleOffer
          ? props.theme.palette.darkIndigo
          : props.theme.palette.gray};
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
      box-shadow: 0 -5px 10px ${(props) => alpha(props.theme.palette.darkIndigo, 0.1)};
      border-radius: 0;
      z-index: 1;
    }
  }
`;

export const Header = styled(CardHeader)`
  && {
    height: 180px;
    align-items: flex-start;
    padding: ${(props) => props.theme.spacing(20)};

    .MuiCardHeader-content {
      height: 100%;

      .MuiCardHeader-title {
        height: 100%;
        overflow: auto;
      }
    }
  }
`;

export const PreferChip = styled(Chip)`
  && {
    margin-top: ${(props) => props.theme.spacing(20)};
    background-color: ${(props) => props.theme.palette.common.white};
    color: ${(props) => props.theme.palette.lightIndigo};
  }
`;

export const Content = styled(CardContent)`
  && {
    min-height: 50px;
    position: relative;
    padding: ${(props) => props.theme.spacing(0)} !important;

    & > .MuiIconButton-root {
      position: absolute;
      z-index: 1;
      right: 20px;
    }
  }
`;
