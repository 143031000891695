import { ThunkDispatch } from 'redux-thunk';

import { RootState } from '../store';
import { ApiTypes } from '../../interfaces';
import { FORM_ACTIONS } from '../action-types';

export const getProfileForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getProfileForm();
      const data = res.data.data;
      let fields = data.fields;
      let { image, ...restFields } = fields;
      data.fields = restFields;

      return data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_PROFILE_FORM,
      payload: getForm(),
    });
  };

export const getNewBrokerForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getNewBrokerForm();
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_NEW_BROKER_FORM,
      payload: getForm(),
    });
  };

export const getFilesForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getFilesForm();
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_FILES_FORM,
      payload: getForm(),
    });
  };

export const getCalculationForm =
  (formName: string) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getCalculationForm(formName);
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_CALCULATION_FORM,
      payload: getForm(),
    });
  };

export const getContactForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getContactForm();
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_CONTACT_FORM,
      payload: getForm(),
    });
  };

export const getCollectPaymentDataForm =
  (formName: string) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getCollectPaymentDataForm(formName);
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_COLLECT_PAYMENT_DATA_FORM,
      payload: getForm(),
    });
  };

export const getEnterEligiblePersonForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getEnterEligiblePersonForm();
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_ENTER_ELIGIBLE_PERSON_FORM,
      payload: getForm(),
    });
  };

export const getEmergencyContactPersonForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getEmergencyContactPersonForm();
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_ENTER_EMERGENCY_CONTACT_PERSON_FORM,
      payload: getForm(),
    });
  };

export const getAddPayoutForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getAddPayoutForm();
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_ADD_PAYOUT_FORM,
      payload: getForm(),
    });
  };

export const getCalculationResultForm =
  (formName: string) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getCalculationResultForm(formName);
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_CALCULATION_RESULT_FORM,
      payload: getForm(),
    });
  };

export const getRecordOperatingCostsForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getRecordOperatingCostsForm();
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_RECORD_OPERATING_COSTS_FORM,
      payload: getForm(),
    });
  };

export const getAcceptPrepaymentForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getAcceptPrepaymentForm();
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_ACCEPT_PREPAYMENT_FORM,
      payload: getForm(),
    });
  };

export const getNewsForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getNewsForm();
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_NEWS_FORM,
      payload: getForm(),
    });
  };

export const getPropertyObjectIdForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForm = async () => {
      const res = await api.form.getPropertyObjectIdForm();
      return res.data.data;
    };

    return dispatch({
      type: FORM_ACTIONS.GET_PROPERTY_OBJECT_ID_FORM,
      payload: getForm(),
    });
  };
