import { ThunkDispatch } from 'redux-thunk';
import { ApiTypes } from '../../interfaces';
import { CLIENT_ACTIONS } from '../action-types';
import { RootState } from '../store';

export const getClients =
  (params) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getClients = async () => {
      const res = await api.client.getClients(params);

      return { data: res.data.data, pagination: res.data?.pageInfo };
    };

    return dispatch({
      type: CLIENT_ACTIONS.GET_CLIENTS,
      payload: getClients(),
    });
  };

export const deleteDraft =
  (id) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const deleteDraft = async () => {
      const res = await api.client.deleteDraft(id);
      return res.data;
    };

    return dispatch({
      type: CLIENT_ACTIONS.DELETE_DRAFT,
      payload: deleteDraft(),
    });
  };

export const getDrafts =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getDrafts = async () => {
      const res = await api.client.getDrafts();
      return res.data.data;
    };

    return dispatch({
      type: CLIENT_ACTIONS.GET_DRAFTS,
      payload: getDrafts(),
    });
  };

export const getCreateClientForms =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getCreateClientForms = async () => {
      const res = await api.client.getCreateClientForms();
      return res.data.data;
    };

    return dispatch({
      type: CLIENT_ACTIONS.GET_CREATE_CLIENT_FORMS,
      payload: getCreateClientForms(),
    });
  };

export const createNewClient =
  (forms) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const createClient = async () => {
      const res = await api.client.createNewClient(forms);
      return res.data;
    };

    return dispatch({
      type: CLIENT_ACTIONS.CREATE_CLIENT,
      payload: createClient(),
    });
  };

export const getDraft =
  (id: number) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    return dispatch({
      type: CLIENT_ACTIONS.GET_DRAFT,
      payload: api.client.getDraft(id),
    });
  };

export const createDraft =
  (forms) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const createDraft = async () => {
      const res = await api.client.createDraft(forms);
      return res.data;
    };

    return dispatch({
      type: CLIENT_ACTIONS.CREATE_DRAFT,
      payload: createDraft(),
    });
  };

export const updateDraft =
  (id, forms) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const updateDraft = async () => {
      const res = await api.client.updateDraft(id, forms);
      const getNewDraftValues = await api.client.getDraft(id);
      return { message: res.data, changedDraftValues: getNewDraftValues };
    };

    return dispatch({
      type: CLIENT_ACTIONS.UPDATE_DRAFT,
      payload: updateDraft(),
    });
  };
