// Dependencies
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, CardHeader as MuiCardHeader, Card as MuiCard, Pagination as MuiPagination } from '@mui/material';

// Export styled components
export const Card = styled(MuiCard)`
  height: 100%;
  ${(props) => props.theme.breakpoints.down('md')} {
    width: calc(100vw - 36px) !important;
    display: flex;
    flex-direction: column;
  }
`;

export const CardHeader = styled(MuiCardHeader)<{ $headerDivider?: boolean }>`
  && {
    border-bottom-color: ${(props) => props.theme.palette.lightCyan};
    border-bottom-style: solid;
    border-bottom-width: ${(props) => (props.$headerDivider ? 1 : 0)}px;

    .MuiCardHeader-title {
      font-weight: 700;
    }

    &.is-searching {
      .MuiCardHeader-action {
        width: 100%;
      }

      .MuiInputBase-root {
        flex: 1;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 12px !important;

    .MuiInputBase-root {
      height: 36px;
    }

    .MuiCardHeader-title {
      font-size: 18px;
      line-height: 28px;
      white-space: nowrap;
      max-width: calc(100vw - 145px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

// @ts-ignore
export const CardContent = styled(PerfectScrollbar)<{
  height?: string | number;
  padding?: string | number;
  $visiblePagination: boolean;
}>`
  margin: ${(props) =>
          props.padding === undefined
                  ? '0 40px'
                  : typeof props.padding === 'string'
                          ? props.padding
                          : `${props.padding}px`};
  height: ${(props) =>
          props.height === undefined
                  ? (props.$visiblePagination ? '364px' : '440px')
                  : typeof props.height === 'string'
                          ? props.height
                          : `${props.height}px`};

  ${(props) => props.theme.breakpoints.down('md')} {
    height: ${(props) =>
            props.height === undefined
                    ? props.$visiblePagination ? '344px' : '418px'
                    : typeof props.height === 'string'
                            ? props.height
                            : `${props.height}px`};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: ${(props) =>
            props.height === undefined
                    ? '224px'
                    : typeof props.height === 'string'
                            ? props.height
                            : `${props.height}px`};
  }
`;

export const Pagination = styled(MuiPagination)`
  && {    
    .MuiPaginationItem-root {
      ${(props) => props.theme.breakpoints.down('md')} {
        display: list-item;
      }
    }
  }
`;

export const EmptyTextWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-right: 12px;
  padding-left: 12px;
`;
