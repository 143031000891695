// Dependencies
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button, Checkbox, Input } from '../../Common';

// Styles
import * as S from './styles';
import { ListItemText, Stack } from '@mui/material';

// Interfaces
interface ICloseConfirmModalProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onApply: (message: string) => void;
  onDeclinedByClient: (event: React.ChangeEvent<HTMLInputElement>) => any;
  loading?: boolean;
  isItDecline?: boolean;
}

// Export CloseConfirm modal
export const StateMessageModal: FC<ICloseConfirmModalProps> = ({
  title,
  open,
  loading,
  isItDecline,
  onClose,
  onDeclinedByClient,
  onApply,
}) => {
  // Message
  const [message, setMessage] = useState<string>('');

  const [isDeclinedByMWR, setIsDeclinedByMWR] = useState<boolean>(false);
  const [isDeclinedByClient, setIsDeclinedByClient] = useState<boolean>(false);

  // Get translations from hook
  const { t } = useTranslation();

  // Change message handler
  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const updateIsDeclinedByMWR = () => {
    setIsDeclinedByMWR(!isDeclinedByMWR);
  };

  const updateIsDeclinedByClient = (e) => {
    setIsDeclinedByClient(!isDeclinedByClient);
    onDeclinedByClient(e);
  };

  // Return CloseConfirm modal
  return (
    <S.StateMessageModal
      title={title}
      open={open}
      onClose={onClose}
      actions={
        <Stack direction="row" spacing={10} marginTop={34}>
          <Button size="large" onClick={onClose}>
            {t('close_confirm.close')}
          </Button>
          <Button
            size="large"
            color="primary"
            disabled={isItDecline && !isDeclinedByMWR && !isDeclinedByClient}
            onClick={() => onApply(message)}
            loading={loading}
          >
            {t('close_confirm.ok')}
          </Button>
        </Stack>
      }
    >
      <Input
        sx={{ width: '100%' }}
        size="medium"
        multiline
        minRows={4}
        value={message}
        onChange={handleChange}
      />
      {isItDecline && (
        <>
          <Stack
            direction="row"
            spacing={24}
            style={{ marginTop: 24, alignItems: 'center' }}
          >
            <Checkbox
              checked={isDeclinedByClient}
              disabled={isDeclinedByMWR}
              onChange={(e) => updateIsDeclinedByClient(e)}
              size="medium"
              style={{ padding: 0 }}
              sx={{
                '&:hover': { backgroundColor: 'transparent' },
              }}
            />
            <ListItemText
              primary={'Abgelehnt von Kunden'}
              sx={{
                color: '#172844',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '300',
                lineHeight: 'normal',
              }}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={24}
            style={{ marginTop: 24, alignItems: 'center' }}
          >
            <Checkbox
              checked={isDeclinedByMWR}
              disabled={isDeclinedByClient}
              onChange={updateIsDeclinedByMWR}
              size="medium"
              style={{ padding: 0 }}
              sx={{
                '&:hover': { backgroundColor: 'transparent' },
              }}
            />
            <ListItemText primary={'Abgelehnt MW'} sx={{ fontSize: 8 }} />
          </Stack>
        </>
      )}
    </S.StateMessageModal>
  );
};
