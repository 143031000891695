// Dependencies
import { FC, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Icon, IconButton, ImageGallery, Typography } from '../../../Common';
import { IFile } from '../../../../interfaces';

// Interfaces
interface IPhotosViewProps {
  visible: boolean;
  files?: IFile[];
  onClose: () => void;
}

// Create Photos view
const PhotosView: FC<IPhotosViewProps> = ({ visible, onClose, files }) => {
  // States
  const [visibleImageGallery, setVisibleImageGallery] =
    useState<boolean>(false);
  const [imageNumber, setImageNumber] = useState<number>(0);

  // Theme
  const theme = useTheme();

  // Check platform
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Photo click handler
  const handleOpenImageGallery = (imageNumber: number) => () => {
    setImageNumber(imageNumber);
    setVisibleImageGallery(true);
  };

  // Close image gallery handler
  const handleCloseImageGallery = () => {
    setVisibleImageGallery(false);
  };

  // Return Info view
  return (
    <>
      <Box
        sx={(theme) => ({
          p: 20,
          width: { xs: '100%', lg: 310 },
          display: {
            xs: visible ? 'block' : 'none',
          },
          borderLeft: isTablet
            ? 'none'
            : `1px solid ${theme.palette['lightCyan']}`,
        })}
      >
        <Stack direction="row" alignItems="center" mb={4}>
          <IconButton onClick={onClose} color="default" sx={{ mr: 4 }}>
            <Icon name="arrow-left" />
          </IconButton>
          <Typography variant="h4">Photos</Typography>
        </Stack>
        <PerfectScrollbar
          style={{
            height: isMobile
              ? 'calc(100vh - 230px)'
              : isTablet
              ? 'calc(100vh - 212px)'
              : '620px',
          }}
        >
          <Grid container columns={3} spacing={10}>
            {files &&
              files.map((file, index) => (
                <Grid key={index} item xs={1}>
                  <Avatar
                    src={file.path}
                    sx={{
                      width: '100% !important',
                      height: '83px !important',
                      cursor: 'pointer',
                    }}
                    onClick={handleOpenImageGallery(index)}
                  />
                </Grid>
              ))}
          </Grid>
        </PerfectScrollbar>
      </Box>
      <ImageGallery
        open={visibleImageGallery}
        onClose={handleCloseImageGallery}
        startImageNumber={imageNumber}
        files={files}
      />
    </>
  );
};

// Export Info view
export default PhotosView;
