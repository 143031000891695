// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTheme, useMediaQuery, alpha } from '@mui/material';
import { useSelector } from 'react-redux';
import ReactHighcharts from 'react-highcharts';

// Interfaces
import { RootState } from '../../../../redux/reducers';

// Styles
import * as S from './styles';

// Interface
export interface IBarChartProps {
  data: any[];
}

// Colors
const Colors = [
  '#799FE5',
  '#4ED2A2',
  '#FFA67B',
  '#B67FEF',
  '#67E2E2',
  '#D7D7D7',
];
const size = { xl: 374, lg: 382, md: 360, sm: 196 };

// Export bar chart component
export const BarChart: FC<IBarChartProps> = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const breakpoint = useSelector(
    ({ uiReducer: { breakpoint } }: RootState) => breakpoint
  );

  const [chartData, setChartData] = useState(data);

  useEffect(() => {
    setChartData(
      data.map((item, index) => ({
        ...item,
        y: item.value,
        color: alpha(Colors[index % Colors.length], 0.5),
      }))
    );
  }, [data]);

  const chartOptions: any = useMemo(
    () => {
      let series: any[] = chartData.filter((item) => !item.disabled);

      if (isMobile && series.length > 2) {
        series = [null, ...series];
      }

      if (series.length > 3) {
        series = [null, ...series];
      }

      return {
        chart: {
          type: 'column',
          height: size[breakpoint],
          marginLeft: 0,
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            pointWidth: breakpoint === 'sm' ? 30 : 48,
          },
          column: {
            borderRadius: 8,
            dataLabels: {
              enabled: false,
            },
            showInLegend: false,
          },
        },
        tooltip: {
          useHTML: true,
          backgroundColor: 'transparent',
          borderRadius: 8,
          borderColor: 'transparent',
          borderWidth: 0,
          animation: true,
          padding: 12,
          className: 'pie-chart-tooltip',
          shadow: false,
          style: {
            color: '#333333',
            cursor: 'default',
            fontSize: '14px',
            lineHeight: '24px',
            whiteSpace: 'nowrap',
          },
          shape: 'square',
          formatter() {
            // @ts-ignore
            const { point } = this;
            var value = point.y
              .toString()
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
            return `
              <div class="bar-chart-tooltip">
                <div>${value}</div>
              </div>
            `;
          },
        },
        xAxis: {
          visible: false,
          labels: {
            enabled: false,
          },
          categories: ['Comdominium', 'Delivery Indicative Offer', 'Assessment'],
          gridLineColor: '#00fff0',
          startOnTick: false,
          endOnTick: false,
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
          gridLineDashStyle: 'Dash',
          plotLines: [
            {
              value: 10,
              zIndex: 2,
              width: 2,
              dashStyle: 'Dash',
            },
          ],
          labels: {
            align: 'left',
            x: 0,
            y: -10,
            style: {
              color: '#697990',
            },
            formatter: function () {
              // @ts-ignore
              return this.value;
            },
          },
        },
        series: [
          {
            name: 'object overview',
            data: series,
          },
        ],
      }
    },
    [chartData, breakpoint, isMobile]
  );

  const handleClickLegendItem = (seriesName: string) => {
    setChartData(
      chartData.map((item: any) => {
        if (item.name === seriesName) {
          item = {
            ...item,
            disabled: !item.disabled,
          };
        }
        return item;
      })
    );
  };

  return (
    <S.BarChart>
      <S.LegendWrapper>
        {chartData.map((item, index) => (
          <div
            key={`item-${index}`}
            className={`legend-item ${item.disabled ? 'disabled' : ''}`}
            onClick={() => handleClickLegendItem(item.name)}
          >
            <div className="legend-symbol" style={{ background: item.color }} />
            <div className="legend-label">
              <p>{item.name}</p>
            </div>
          </div>
        ))}
      </S.LegendWrapper>
      <ReactHighcharts config={chartOptions} />
    </S.BarChart>
  );
};
