// Dependencies
import React, { FC, useMemo } from 'react';
import { Tab, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

// Utils
import { getTabLabel, isUserAllowed } from '../../../../../utils';

// Constants
import {
  isStatusAllowed,
  Role,
  ROUTES,
  Status,
  STATUS_MAPPING,
} from '../../../../../constants';

// Icons
import {
  CalculatorIcon,
  CalendarIcon,
  FileContractIcon,
  FileSignatureIcon,
  FolderOpenIcon,
  HammerIcon,
  IdCardIcon,
  LayerGroupIcon,
  ListCheckIcon,
} from '../../../../../assets/icons';

// Store
import { RootState } from '../../../../../redux/reducers';

// Styles
import * as S from './styles';

// Constants
export enum DetailTab {
  PersonalData = 'PersonalData',
  RegistrationForm = 'RegistrationForm',
  Modernizations = 'Modernizations',
  IndicativeOffer = 'IndicativeOffer',
  BindingOffer = 'BindingOffer',
  ContractData = 'ContractData',
  AdditionalCostsCalculator = 'AdditionalCostsCalculator',
  Files = 'Files',
  Tasks = 'Tasks',
}

// Interfaces
interface ITabsProps {
  activeTab: DetailTab;
  onTabChange: (tab: DetailTab) => void;
}

// Create Tabs
const Tabs: FC<ITabsProps> = ({ activeTab, onTabChange }) => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get params from hook
  const params = useParams<{ filter: string }>();

  // Get path from hook
  const { pathname } = useLocation();

  // Check detail type
  const isObjectType = useMemo(() => {
    return pathname.includes(ROUTES.OBJECTS.INDEX);
  }, [pathname]);

  // Theme
  const theme = useTheme();

  // Check platform
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Get project detail from store
  const { projectDetail } = useSelector(
    ({ projectReducer: { projectDetail } }: RootState) => ({
      projectDetail,
    })
  );

  // Tab change handler
  const handleTabChange = (_: React.SyntheticEvent, tab: DetailTab) => {
    onTabChange(tab);
  };

  // Return Tabs
  return (
    <S.Tabs
      value={activeTab}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons={false}
    >
      {(isUserAllowed(account, Role.ROLE_ADMIN) ||
        isUserAllowed(account, Role.ROLE_AGENTS)) && (
        <Tab
          icon={<IdCardIcon />}
          label={
            !isMobile ? getTabLabel(isObjectType, t('project_detail.personal_data'), ' ') : <></>
          }
          value={DetailTab.PersonalData}
        />
      )}
      {(isUserAllowed(account, Role.ROLE_ADMIN) ||
        isUserAllowed(account, Role.ROLE_AGENTS)) &&
        <Tab
          icon={<LayerGroupIcon />}
          label={!isMobile ? getTabLabel(isObjectType, t('project_detail.registration_form'), '-') : <></>}
          value={DetailTab.RegistrationForm}
        />
      }
      {(isUserAllowed(account, Role.ROLE_ADMIN) ||
        isUserAllowed(account, Role.ROLE_AGENTS)) && (
        <Tab
          icon={<HammerIcon />}
          label={!isMobile ? getTabLabel(isObjectType, t('project_detail.modernizations'), '-') : <></>}
          value={DetailTab.Modernizations}
        />
      )}
      {(isUserAllowed(account, Role.ROLE_ADMIN) ||
        isUserAllowed(account, Role.ROLE_AGENTS)) &&
        isStatusAllowed(
          projectDetail?.status,
          Status.approvalOfNonBindingOfferForCustomerByMwr
        ) && (
          <Tab
            icon={<CalculatorIcon />}
            label={
              !isMobile ? (
                getTabLabel(isObjectType, t('project_detail.indicative_offer'), ' ')
              ) : (
                <></>
              )
            }
            value={DetailTab.IndicativeOffer}
          />
        )}
      {(isUserAllowed(account, Role.ROLE_ADMIN) ||
        isUserAllowed(account, Role.ROLE_AGENTS)) &&
        isStatusAllowed(
          projectDetail?.status,
          Status.assessmentOfNonBindingOfferByTüv
        ) && (
          <Tab
            icon={<FileSignatureIcon />}
            label={
              !isMobile ? getTabLabel(isObjectType, t('project_detail.binding_offer'), ' ') : <></>
            }
            value={DetailTab.BindingOffer}
          />
        )}
      {(isUserAllowed(account, Role.ROLE_ADMIN) ||
        isUserAllowed(account, Role.ROLE_AGENTS)) &&
        isObjectType && (
          <Tab
            icon={<FileContractIcon />}
            value={DetailTab.ContractData}
            label={!isMobile ? getTabLabel(isObjectType, t('project_detail.contract_data'), '-') : <></>}
          />
        )}
      {(isUserAllowed(account, Role.ROLE_ADMIN) ||
        isUserAllowed(account, Role.ROLE_AGENTS)) &&
        (params.filter === STATUS_MAPPING.bought.key ||
          isUserAllowed(account, Role.ROLE_ADMIN)) &&
        isObjectType && (
          <Tab
            icon={<CalendarIcon />}
            value={DetailTab.AdditionalCostsCalculator}
            label={
              !isMobile ? (
                getTabLabel(isObjectType, t('project_detail.additional_costs_calculator'), '-')
              ) : (
                <></>
              )
            }
          />
        )}
      {(isUserAllowed(account, Role.ROLE_ADMIN) ||
        isUserAllowed(account, Role.ROLE_AGENTS)) && (
        <Tab
          icon={<FolderOpenIcon />}
          label={!isMobile ? getTabLabel(isObjectType, t('project_detail.files'), '&') : <></>}
          value={DetailTab.Files}
        />
      )}
      <Tab
        icon={<ListCheckIcon />}
        label={!isMobile ? t('project_detail.tasks') : <></>}
        value={DetailTab.Tasks}
      />
    </S.Tabs>
  );
};

// Export Tabs
export default Tabs;
