// Dependencies
import styled from 'styled-components';
import { Box } from '@mui/material';

export const OverviewCardTitle = styled(Box)`
  @media only screen and (max-width: 400px) {
    max-width: 120px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// Export styled components
