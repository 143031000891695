// Dependencies
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// Components
import { Dialog, Icon } from '../../../../../components';

// Store
import { RootState } from '../../../../../redux/reducers';

// Utils
import {
  getAddress,
  getFullName,
  getProjectPrimaryImage,
} from '../../../../../utils';

// Constants
import { STATUS } from '../../../../../constants';

// Styles
import * as S from './styles';
import ProjectDate from '../Date';
import * as StatusService from '../../../../../constants';
import Actions from '../Actions';

// Create information
const Information = ({ form, gotoFileTab, fileUploading, unsavedData }) => {
  // States
  const [visibleExtraInformationModal, setVisibleExtraInformationModal] =
    useState<boolean>(false);

  // Get translation from hook
  const { t } = useTranslation();

  // Theme
  const theme = useTheme();

  // Check platform
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // Get project detail from store
  const { projectDetail, detailLoading } = useSelector(
    ({ projectReducer: { projectDetail, detailLoading } }: RootState) => ({
      projectDetail,
      detailLoading,
    })
  );

  // Open extra information modal handler
  const handleOpenExtraInformationModal = () => {
    setVisibleExtraInformationModal(true);
  };

  // Close extra information modal handler
  const handleCloseExtraInformationModal = () => {
    setVisibleExtraInformationModal(false);
  };

  // On browser size changed
  useEffect(() => {
    if (!isMobile) {
      setVisibleExtraInformationModal(false);
    }
  }, [isMobile]);

  let status =
    // @ts-ignore
    StatusService.STATUS.customer[projectDetail?.status] ||
    // @ts-ignore
    StatusService.STATUS.property[projectDetail?.status] ||
    '';

  // Return information
  return (
    <>
      <S.Information
        direction="row"
        spacing={{ xs: 8, md: 18, xl: 32 }}
        p={{ xs: 20, lg: 0 }}
      >
        <Badge
          sx={{
            '.MuiBadge-badge': {
              padding: 8,
              height: 'initial',
              transform: 'initial',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          {detailLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{ width: { xs: 142, lg: 186 }, height: { xs: 142, lg: 186 } }}
            />
          ) : (
            <Avatar
              src={getProjectPrimaryImage(projectDetail)}
              sx={{ width: { xs: 142, lg: 186 }, height: { xs: 142, lg: 186 } }}
            >
              <Icon name="image-fill" />
            </Avatar>
          )}
        </Badge>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
          width={'100%'}
        >
          <Box display="flex" width="100%" justifyContent="space-between">
            <Box display="flex">
              <Box display="flex" flexDirection="column">
                <Box mb={detailLoading ? 6 : 2}>
                  {detailLoading ? (
                    <Skeleton height={32} variant="rectangular" />
                  ) : (
                    <Typography variant="h2">
                      {getFullName(projectDetail)}
                    </Typography>
                  )}
                </Box>
                <Box mb={30}>
                  {detailLoading ? (
                    <Skeleton height={24} width={250} variant="rectangular" />
                  ) : (
                    <Typography variant="subtitle1">
                      {getAddress(projectDetail)}
                    </Typography>
                  )}
                </Box>
              </Box>
              {!isDesktop ? null : detailLoading ? (
                <Skeleton height={32} variant="rectangular" />
              ) : (
                <Box px={{ xs: 10, lg: 20 }}>
                  <Stack alignItems="flex-end" position="relative">
                    <S.Status color={status.color} align="center">
                      {status.name}
                    </S.Status>
                  </Stack>
                </Box>
              )}
            </Box>
            {isDesktop && (
              <Stack>
                <Actions
                  form={form}
                  fileUploading={fileUploading}
                  gotoFileTab={gotoFileTab}
                />
              </Stack>
            )}
          </Box>
          <Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {detailLoading ? (
                  <Skeleton variant="rectangular" height={52} width={250} />
                ) : (
                  <Stack
                    direction="row"
                    spacing={16}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <Box>
                      <Typography
                        mb={4}
                        variant="body2"
                        color="lightIndigo"
                        height={24}
                        overflow="hidden"
                      >
                        {t('project_detail.plot')}
                      </Typography>
                      <Typography variant="h2" overflow="hidden">
                        {projectDetail?.property?.plot_size || ''} m²
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        mb={4}
                        variant="body2"
                        color="lightIndigo"
                        height={24}
                        overflow="hidden"
                      >
                        {t('project_detail.living_space')}
                      </Typography>
                      <Typography variant="h2" overflow="hidden">
                        {projectDetail?.property?.living_space || ''} m²
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        mb={4}
                        variant="body2"
                        color="lightIndigo"
                        height={24}
                        overflow="hidden"
                      >
                        {t('project_detail.rooms')}
                      </Typography>
                      <Typography variant="h2" overflow="hidden">
                        {projectDetail?.property?.room_amount}
                      </Typography>
                    </Box>
                    {projectDetail?.property?.object_id && (
                      <Box>
                        <Typography
                          mb={4}
                          variant="body2"
                          color="lightIndigo"
                          height={24}
                          overflow="hidden"
                        >
                          {t('project_detail.object_nr')}
                        </Typography>
                        <Typography variant="h2" overflow="hidden">
                          {projectDetail?.property?.object_id}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                )}
              </Box>
              {isDesktop && <ProjectDate hasUnsavedData={unsavedData} />}
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <Button
                size="medium"
                variant="outlined"
                onClick={handleOpenExtraInformationModal}
              >
                {t('project_detail.details')}
              </Button>
            </Box>
          </Box>
        </Box>
      </S.Information>
      {visibleExtraInformationModal && (
        <Dialog
          title={t('project_detail.details')}
          open={visibleExtraInformationModal}
          onClose={handleCloseExtraInformationModal}
        >
          <S.DialogContent spacing={30} divider={<Divider />}>
            <Stack
              px={20}
              direction="row"
              justifyContent="space-between"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Box>
                <Typography mb={4} variant="body2">
                  {t('project_detail.plot')}
                </Typography>
                <Typography variant="subtitle1">
                  {projectDetail?.property?.plot_size || ''} m²
                </Typography>
              </Box>
              <Box>
                <Typography mb={4} variant="body2">
                  {t('project_detail.living_space')}
                </Typography>
                <Typography variant="subtitle1">
                  {projectDetail?.property?.living_space || ''} m²
                </Typography>
              </Box>
              <Box>
                <Typography mb={4} variant="body2">
                  {t('project_detail.rooms')}
                </Typography>
                <Typography variant="subtitle1">
                  {projectDetail?.property?.room_amount}
                </Typography>
              </Box>
              {projectDetail?.property?.object_id && (
                <Box>
                  <Typography mb={4} variant="body2">
                    {t('project_detail.object_id')}
                  </Typography>
                  <Typography variant="subtitle1">
                    {projectDetail?.property?.object_id}
                  </Typography>
                </Box>
              )}
            </Stack>
            <ProjectDate />
            <Typography
              component="span"
              align="center"
              color="common.white"
              sx={(theme) => ({
                p: theme.spacing(8.5, 12),
                height: 40,
                backgroundColor: projectDetail
                  ? STATUS.customer[projectDetail.status]?.color ||
                    STATUS.property[projectDetail.status]?.color
                  : '',
                borderRadius: 20,
                mx: 'auto !important',
              })}
            >
              {projectDetail
                ? STATUS.customer[projectDetail.status]?.name ||
                  STATUS.property[projectDetail.status]?.name
                : ''}
            </Typography>
          </S.DialogContent>
        </Dialog>
      )}
    </>
  );
};

// Export information
export default Information;
