// Dependencies
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
  Box,
  CircularProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import { Button, Checkbox, Dialog, Icon, Input } from '../../Common';

// Utils
import {
  getAddress,
  getDateTime,
  getLastMessage,
  getProjectPrimaryImage,
} from '../../../utils';

// Services
import { getProjects } from '../../../services/project.service';

// Constants
import { STATUS_MAPPING } from '../../../constants';

// Styles
import * as S from './styles';

// Interfaces
import { IProjectData } from '../../../interfaces';

interface IStartNewNewModalProps {
  open: boolean;
  onClose: () => void;
  onClickClient: (id: number) => void;
}

// Export start new chat modal
export const StartNewChatModal: FC<IStartNewNewModalProps> = ({
  open,
  onClose,
  onClickClient,
}) => {
  // States
  const [filterBy, setFilterBy] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [projects, setProjects] = useState<IProjectData[]>([]);
  const [clientId, setClientId] = useState<number>();

  // Get translation from hook
  const { t } = useTranslation();

  // Delayed search handler
  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  // Calc main status
  const mainStatus = useMemo(() => {
    return Object.values(STATUS_MAPPING)?.map((mainStatus) => {
      return mainStatus;
    });
  }, []);

  // Fetch clients
  const fetchClients = () => {
    const statusToFilter = filterBy.reduce(
      (allFilters: string[], mainStatus: string) => {
        return [...allFilters, ...STATUS_MAPPING[mainStatus].status];
      },
      []
    );

    setLoading(true);
    getProjects({
      search: debouncedSearch,
      status: statusToFilter,
      pagination: 0,
    })
      .then((res: any) => {
        setProjects(res.data?.data || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // Change filter by handler
  const handleSelectStatus = (e) => {
    setFilterBy(e.target.value);
  };

  // Search change handler
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // Click client handler
  const handleClickClient = (id: number) => () => {
    setClientId(id);
  };

  // Start chat handler
  const handleStartChat = useCallback(() => {
    if (clientId) {
      onClickClient(clientId);
      onClose();
      setClientId(undefined);
    }
  }, [clientId, onClickClient, setClientId, onClose]);

  // On filterBy and debouncedSearch changed
  useEffect(() => {
    if (debouncedSearch || filterBy.length > 0) {
      fetchClients();
    } else {
      setProjects([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy, debouncedSearch]);

  // On search and delayedChangeSearch changed
  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
  }, [search, delayedChangeSearch]);

  // Return start new chat modal
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('new_chat.title')}
      actions={
        <Button
          color="primary"
          size="large"
          disabled={loading || !clientId}
          onClick={handleStartChat}
        >
          {t('new_chat.start_chat')}
        </Button>
      }
    >
      <Stack spacing={30}>
        <Input
          size="medium"
          placeholder={t('new_chat.search')}
          startAdornment={<Icon name="search" />}
          onChange={handleSearchChange}
        />
        <Select
          size="medium"
          multiple
          value={filterBy}
          onChange={handleSelectStatus}
          renderValue={() => t('projects.filter_by_status')}
        >
          {mainStatus.map((filter, index) => (
            <MenuItem key={index} value={filter.key}>
              <Checkbox checked={filterBy.indexOf(filter.key) > -1} />
              <ListItemText primary={filter.name} sx={{ fontSize: 8 }} />
            </MenuItem>
          ))}
        </Select>
        <Box component={PerfectScrollbar} sx={{ height: 350 }}>
          {loading ? (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {projects.length > 0 ? (
                projects.map((project, index) => (
                  <S.ChatListItem
                    key={index}
                    sx={{
                      '.MuiListItemText-primary': {
                        '.MuiBox-root': {
                          width: '100% !important',
                        },
                        maxWidth: 'calc(100vw - 150px) !important',
                      },
                      '.MuiListItemText-secondary': {
                        '.MuiBox-root': {
                          width: '100% !important',
                        },
                        maxWidth: 'calc(100vw - 150px) !important',
                      },
                    }}
                    selected={project.id === clientId}
                    avatar={getProjectPrimaryImage(project)}
                    title={`${project.customer?.first_name} ${project.customer?.last_name}`}
                    subTitle={getAddress(project) || ''}
                    secondaryText={getDateTime(
                      getLastMessage(project)?.date || ''
                    )}
                    notifyCount={0}
                    onClick={handleClickClient(project.id)}
                  />
                ))
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      </Stack>
    </Dialog>
  );
};
