// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import { Button, Dialog } from '../../Common';
import { FormBuilder } from '../../FormBuilder';

// Interfaces
import { RootState } from '../../../redux/store';
import {
  ICalcType,
  IOfferCalculation,
  IOfferCalculationResult,
} from '../../../interfaces';

// Utils
import {
  buildFormInitialValues,
  buildFormSchema,
  getInitialValuesInFormStructure,
} from '../../../utils';

// Store
import { getCalculationForm, calculatePayout } from '../../../redux/actions';

// Interfaces
interface ICalculationModal {
  open: boolean;
  binding?: boolean;
  payoutVariantId?: number;
  onClose: (calculationResult?: IOfferCalculationResult) => void;
  calculationType?: ICalcType;
  calculation?: IOfferCalculation;
}

// Export calculation modal
export const CalculationModal: FC<ICalculationModal> = ({
  open,
  binding,
  payoutVariantId,
  calculationType,
  calculation,
  onClose,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get form name from calculation type
  const formName = useMemo(() => {
    let name = 'single_calculationOffer';
    if (calculationType === ICalcType.oneTimePayment) {
      name = 'single_calculationOfferOneTime';
    } else if (
      calculationType === ICalcType.timeRent ||
      calculationType === ICalcType.timeRentWithOneTimePayment
    ) {
      name = 'single_calculationOfferTimeRent';
    }

    return name;
  }, [calculationType]);

  // Get calculation form from store
  const calculationForm = useSelector(
    ({ formReducer: { calculationForm } }: RootState) => calculationForm
  );

  // Create formik
  const formik = useFormik<any>({
    initialValues: getInitialValuesInFormStructure(
      calculation,
      buildFormInitialValues(calculationForm)
    ),
    validationSchema: buildFormSchema(calculationForm?.fields),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const res: any = await dispatch(
          calculatePayout({
            ...values,
            ...(binding && { binding }),
            ...(payoutVariantId && { payoutVariant: payoutVariantId }),
          })
        );
        setLoading(false);
        if (res.value?.success) {
          enqueueSnackbar(res.value?.data.message, { variant: 'success' });
          onClose(res.value?.data);
        } else {
          enqueueSnackbar(res.value?.data.message, { variant: 'error' });
        }
      } catch (e: any) {
        setLoading(false);
        enqueueSnackbar(e.response?.data?.message || 'Failed to calculate', {
          variant: 'error',
        });
      }
    },
  });

  // Submit handler
  const handleSubmit = () => {
    formik.handleSubmit();
  };

  // Close modal
  const handleCloseModal = () => {
    onClose();
  };

  // On mounted
  useEffect(() => {
    (async () => {
      await dispatch(getCalculationForm(formName));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formName]);

  // On calculation & calculation form data changed
  useEffect(() => {
    formik.setValues(
      getInitialValuesInFormStructure(
        calculation,
        buildFormInitialValues(calculationForm)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation, calculationForm]);

  // Return calculation modal
  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      title={t('calculation.title')}
      actions={
        <Button
          loading={loading}
          color="primary"
          size="large"
          onClick={handleSubmit}
        >
          {t('calculation.calculate')}
        </Button>
      }
    >
      <Box component={PerfectScrollbar} sx={{ height: '50vh' }}>
        <Grid container columns={2}>
          <FormBuilder
            type={calculationForm?.type}
            attributes={calculationForm?.attributes}
            label={calculationForm?.label}
            fields={calculationForm?.fields}
            formik={formik}
            layout={{
              password: 2,
              date: 2,
              text: 2,
              currency: 2,
              select: 2,
              float: 2,
              integer: 2,
            }}
            path=""
          />
        </Grid>
      </Box>
    </Dialog>
  );
};
