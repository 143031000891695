// Dependencies
import React, { FC, useEffect, useRef, useState } from 'react';
import { MenuItem, Pagination, SelectChangeEvent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Components
import { Select } from '../../Select';
import { Typography } from '../../Typography';

// Interfaces
export interface ITablePaginationProps {
  page?: number;
  isLoading?: boolean;
  totalPage?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onPageChange?: (page: number) => void;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
}

// Default values
const defaultPage = 1;
export const defaultRowsPerPage = 10;
const defaultRowsPerPageOptions = [5, 10, 20];

// Export TablePagination component
export const TablePagination: FC<ITablePaginationProps> = ({
  isLoading,
  page = defaultPage,
  totalPage,
  rowsPerPage = defaultRowsPerPage,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  onPageChange,
  onRowsPerPageChange,
}) => {
  // States
  const [isMobile, setIsMobile] = useState<boolean>();

  // Get translation from hook
  const { t } = useTranslation();

  // Ref
  const paginationRef = useRef<any>();

  // Rows per page change handler
  const handleRowsPerPageChange = (event: SelectChangeEvent<unknown>) => {
    if (onRowsPerPageChange) {
      onRowsPerPageChange(event.target.value as number);
    }
  };

  // Page change handler
  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    if (onPageChange) {
      onPageChange(value);
    }
  };

  // Responsive handler
  const handleResponsive = () => {
    const pagination = paginationRef.current;
    if (pagination) {
      if (pagination.clientWidth > 516) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }
  };

  // On mounted
  useEffect(() => {
    handleResponsive();
    window.addEventListener('resize', handleResponsive);

    return () => {
      window.removeEventListener('resize', handleResponsive);
    };
  }, []);

  // Return TablePagination component
  return (
    <Stack
      ref={paginationRef}
      p={{ xs: '20px 16px', sm: 20 }}
      sx={(theme) => ({
        marginTop: 'auto',
        borderTop: `1px solid ${theme.palette['lightCyan']}`,
      })}
      direction="row"
      justifyContent={isMobile ? 'center' : 'space-between'}
      alignItems="center"
    >
      {!isMobile && (
        <Stack spacing={12} direction="row" alignItems="center">
          <Typography
            variant="body2"
            sx={{ display: { xs: 'none', sm: isMobile ? 'none' : 'block' } }}
          >
            {t('table.rows_per_page')}:
          </Typography>
          <Select
            value={rowsPerPage}
            disabled={isLoading}
            onChange={handleRowsPerPageChange}
            sx={{
              padding: { xs: 8, md: 12 },
              minHeight: 32,
              height: 32,
              ml: { xs: 0, sm: 12 },
            }}
          >
            {rowsPerPageOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
      <Pagination
        page={page}
        shape="rounded"
        siblingCount={0}
        count={totalPage}
        disabled={isLoading}
        sx={{
          '.MuiPagination-ul .MuiPaginationItem-ellipsis': {
            display: { xs: 'none', sm: isMobile ? 'none' : 'block' },
          },
          '.MuiPagination-ul .Mui-selected': {
            display: 'block',
          },
        }}
        onChange={handlePageChange}
      />
    </Stack>
  );
};
