// Dependencies
import styled from 'styled-components';
import {Stack, Typography} from '@mui/material';

// Export styled components
export const Information = styled(Stack)`
  ${(props) => props.theme.breakpoints.down('lg')} {
    padding: ${(props) => props.theme.spacing(20)};
    border-top: 1px solid ${(props) => props.theme.palette.lightCyan};
    border-bottom: 1px solid ${(props) => props.theme.palette.lightCyan};
  }
`;
export const Status = styled(Typography)<{ color: string }>`
  && {
    min-width: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: ${props => props.color};
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    border-radius: 24px;
    padding: 12px 10px;
    height: 40px;
    white-space: nowrap;
    color: ${props => props.theme.palette.common.white};
  }
`;
export const DialogContent = styled(Stack)`
  margin: ${(props) => props.theme.spacing(10, -20)};
`;
