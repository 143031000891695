// Dependencies
import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  ListItem as MuiListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  ToggleButton,
  Link,
} from '@mui/material';

// Styles
import * as S from './styles';

// Components
import {
  Typography,
  Card,
  Icon,
  Carousel,
  IconButton,
  Dialog,
  CreateNewsModal,
  ConfirmModal,
} from '../../../../components';

// Constants
import { Role } from '../../../../constants';

// Actions
import { getNews } from '../../../../redux/actions';
import { RootState } from '../../../../redux/reducers';

// Utils
import { getNewsDate, isUserAllowed } from '../../../../utils';
import { CardActionSelect } from '../../styles';
import { deleteNews } from '../../../../services/news.service';

// Constants
const data = [
  {
    avatar: '',
    name: 'Meine Wohnrente',
    createdAt: '1 month ago',
    note: 'Meine Wohnrente is growing fast!\n\nWe are pleased that we have the DEGIV -  the company for real estate pensions - as a new',
    image: '',
    like: 128,
    comments: 19,
    feedback: 6,
  },
  {
    avatar: '',
    name: 'Meine Wohnrente',
    createdAt: '1 month ago',
    note: 'Meine Wohnrente is growing fast!\n\nWe are pleased that we have the DEGIV -  the company for real estate pensions - as a new',
    image: '',
    like: 128,
    comments: 19,
    feedback: 6,
  },
  {
    avatar: '',
    name: 'Meine Wohnrente',
    createdAt: '1 month ago',
    note: 'Meine Wohnrente is growing fast!\n\nWe are pleased that we have the DEGIV -  the company for real estate pensions - as a new',
    image: '',
    like: 128,
    comments: 19,
    feedback: 6,
  },
];

enum Views {
  LinkedIn = 'linkedin',
  PortalNews = 'portalNews',
}

// Export linked-in-feed widget
export const LinkedInFeedWidget: FC = () => {
  // States
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(18);
  const [view, setView] = useState(Views.PortalNews);
  const [openWindow, setOpenWindow] = useState<boolean>(false);
  const [visibleNewsModal, setVisibleNewsModal] = useState<boolean>(false);
  const [visibleConfirmModal, setVisibleConfirmModal] =
    useState<boolean>(false);
  const [deletingNews, setDeletingNews] = useState<boolean>(false);

  // Get translation from hook
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);
  // Get account from store
  const { loading, news } = useSelector(
    ({ newsReducer }: RootState) => newsReducer
  );

  const breakpoint = useSelector(
    ({ uiReducer: { breakpoint } }: RootState) => breakpoint
  );

  // View change handler
  const handleChangeView = (e) => {
    setView(e.target.value);
    if (e.target.value === Views.PortalNews) {
      if (news && Array.isArray(news)) {
        setTotal(news.length);
      }
    }
  };

  // View change mobile handler
  const handleChangeMobileView = (_, value) => {
    setView(value);
  };

  // Close Select Modal Window
  const handleCloseModalWindow = () => {
    setOpenWindow(false);
  };

  // Open Select Modal Window
  const handleOpenModalWindow = () => {
    setOpenWindow(true);
  };

  // Close News Modal
  const handleCloseNewsModal = () => {
    setVisibleNewsModal(false);
  };

  // Close News Modal
  const handleOpenNewsModal = () => {
    setVisibleNewsModal(true);
  };

  // Page change handler
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOpenConfirmModal = () => {
    setVisibleConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setVisibleConfirmModal(false);
  };

  const handleDeleteNews = () => {
    if (news && news.length > 0) {
      setDeletingNews(true);
      deleteNews(news[page - 1].id)
        .then(() => {
          handleCloseConfirmModal();
          dispatch(getNews());
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setDeletingNews(false);
        });
    }
  };

  // On mounted
  useEffect(() => {
    dispatch(getNews());
    // setTotal(news.length);
  }, [dispatch]);

  useEffect(() => {
    if (news && Array.isArray(news)) {
      setTotal(news.length);
    }
  }, [news]);

  // Linkedin Content
  const FeedContent = ({ feed }) => {
    const { image, name, note, createdAt, like, comments, feedback } = feed;
    return (
      <Box sx={{ px: { xs: 12, sm: 0 } }}>
        <MuiListItem>
          <ListItemAvatar sx={{ minWidth: 60 }}>
            <Avatar
              variant="circular"
              src={image}
              sx={{ width: 48, height: 48 }}
            />
          </ListItemAvatar>
          <ListItemText primary={name} secondary={createdAt} />
        </MuiListItem>
        <S.TextContent>
          <Typography variant="body1">
            {note.slice(0, breakpoint === 'sm' ? 56 : 125)}
          </Typography>
          <S.SeeMoreButton variant="subtitle1">
            ..{t('dashboard.see_more')}
          </S.SeeMoreButton>
        </S.TextContent>
        <S.FeedImage>
          {image ? (
            <img src={image} alt="linkedln-feed" />
          ) : (
            <S.NoImageContent>
              <Typography variant="h2">{t('dashboard.no_image')}</Typography>
            </S.NoImageContent>
          )}
        </S.FeedImage>
        <Stack direction="row">
          <S.FeedInfo>
            <Icon name="hand" />
            <Typography variant="body1">{like}</Typography>
          </S.FeedInfo>
          <S.FeedInfo>
            <Icon name="chat-square-dots-fill" />
            <Typography>{comments}</Typography>
          </S.FeedInfo>
          <S.FeedInfo>
            <Icon name="back" />
            <Typography>{feedback}</Typography>
          </S.FeedInfo>
        </Stack>
      </Box>
    );
  };

  const maxTextLength = breakpoint === 'sm' ? 56 : 400;

  // Portal News Content
  const PortalNews = ({ post }) => {
    return (
      <S.PortalNews direction="row" spacing={40}>
        {post?.image && (
          <S.FeedImage>
            <img src={post?.image.original} alt="linkedln-feed" />
          </S.FeedImage>
        )}
        <S.PortalNewsContent spacing={{ xs: 0, sm: 12 }} flex={1}>
          <S.PortalTitle variant="h2">{post?.title}</S.PortalTitle>
          <Typography variant="body1" color="text.secondary">
              {post?.message?.length <= (post?.image ? maxTextLength : maxTextLength+maxTextLength)
                ? post?.message
                : `${post?.message.slice(0, (post?.image ? maxTextLength : maxTextLength+maxTextLength))}...`
              }
          </Typography>
          {post.link && (
            <Link
              sx={{ cursor: 'pointer' }}
              color="red"
              href={post.link}
              target="_blank"
            >
              mehr lesen
            </Link>
          )}
          <Stack direction="row" spacing={10} sx={{ fontSize: 14 }}>
            <Typography color="middleCyan" variant="body2">
              By Admin
            </Typography>
            <Typography color="middleCyan" variant="body2">
              {getNewsDate(post?.date)}
            </Typography>
          </Stack>
        </S.PortalNewsContent>
      </S.PortalNews>
    );
  };

  // Return linked-in-feed widget
  return (
    <Card
      title={
        <S.HeaderWrapper direction="row" alignItems="center" spacing={15}>
          {view === Views.PortalNews
            ? t('dashboard.portal_news')
            : t('dashboard.linkedin_feed')}
        </S.HeaderWrapper>
      }
      action={
        <>
          <Stack
            direction="row"
            spacing={8}
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          >
            <CardActionSelect value={view} onChange={handleChangeView}>
              {/* <MenuItem value={Views.LinkedIn}>
                {t('dashboard.linkedin_feed')}
              </MenuItem> */}
              <MenuItem value={Views.PortalNews}>
                {t('dashboard.portal_news')}
              </MenuItem>
            </CardActionSelect>
            {view === Views.PortalNews &&
              isUserAllowed(account, Role.ROLE_ADMIN) && (
                <Stack spacing={8} direction="row">
                  <IconButton color="primary" onClick={handleOpenConfirmModal}>
                    <Icon name="trash" />
                  </IconButton>
                  <IconButton color="secondary" onClick={handleOpenNewsModal}>
                    <Icon name="plus-lg" />
                  </IconButton>
                </Stack>
              )}
          </Stack>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <IconButton sx={{ maxHeight: 36 }} onClick={handleOpenModalWindow}>
              <Icon name={'arrow-down'} />
            </IconButton>
            <Dialog
              open={openWindow}
              onClose={handleCloseModalWindow}
              title={
                <Typography variant="h2">
                  {view === Views.PortalNews
                    ? t('dashboard.portal_news')
                    : t('dashboard.linkedin_feed')}
                </Typography>
              }
            >
              <S.ToggleButtonGroup
                orientation="vertical"
                value={view}
                exclusive
                onChange={handleChangeMobileView}
              >
                <ToggleButton value="linkedin">
                  {/* <Stack direction="row">
                    <Typography variant="subtitle1">
                      {t('dashboard.linkedin_feed')}
                    </Typography>
                    {view === Views.LinkedIn && <Icon name="tick" />}
                  </Stack> */}
                </ToggleButton>
                <ToggleButton value="portalNews">
                  <Stack direction="row">
                    <Typography variant="subtitle1">
                      {t('dashboard.portal_news')}
                    </Typography>
                    {view === Views.PortalNews && <Icon name="tick" />}
                  </Stack>
                </ToggleButton>
              </S.ToggleButtonGroup>
            </Dialog>
          </Box>
        </>
      }
      loading={Views.PortalNews && loading}
      isEmpty={Views.PortalNews && news?.length === 0}
      pagination={{
        total,
        current: page,
      }}
      onPageChange={handlePageChange}
    >
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <S.ContentWrapper>
          {view === Views.LinkedIn
            ? data.map((feed, index) => <FeedContent feed={feed} key={index} />)
            : news && <PortalNews post={news[page - 1]} />}
        </S.ContentWrapper>
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <Carousel length={view === Views.LinkedIn ? data.length : news?.length}>
          {view === Views.LinkedIn
            ? data.map((feed, index) => <FeedContent feed={feed} key={index} />)
            : news?.map((post, index) => (
                <PortalNews post={post} key={index} />
              ))}
        </Carousel>
      </Box>

      {visibleNewsModal && (
        <CreateNewsModal
          open={visibleNewsModal}
          onClose={handleCloseNewsModal}
        />
      )}

      {visibleConfirmModal && (
        <ConfirmModal
          loading={deletingNews}
          open={visibleConfirmModal}
          onClose={handleCloseConfirmModal}
          onOk={handleDeleteNews}
        />
      )}
    </Card>
  );
};
