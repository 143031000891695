// Dependencies
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Checkbox,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

// Components
import {
  DataTable,
  IColumn,
  Icon,
  IconButton,
  Input,
  Typography,
} from '../../../../components';

// Interfaces
import { IProjectData, Order } from '../../../../interfaces';

// Utils
import { getAddress, getProjectPrimaryImage } from '../../../../utils';

// Store
import { RootState } from '../../../../redux/reducers';

// Constants
import { ROUTES, STATUS } from '../../../../constants';
import { getProjects } from '../../../../redux/actions';
import * as StatusService from '../../../../constants';

// Create detail list
const DetailList = () => {
  // States
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  const [sortOrder, setSortOrder] = useState<Order>(Order.Desc);

  // Get translation from hook
  const { t } = useTranslation();

  // Get navigate from hook
  const navigate = useNavigate();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get params from hook
  const params = useParams<{ id: string; filter: string }>();

  const { filter } = params;

  const [filterBy, setFilterBy] = useState<string[]>(Object.keys(StatusService.getAvailableFilter(filter as string)) || []);

  // Get state from hook
  const { state, pathname } = useLocation();

  // Check detail type
  const isObjectType = useMemo(() => {
    return pathname.includes(ROUTES.OBJECTS.INDEX);
  }, [pathname]);

  // Get projects from store
  const { projects, loading } = useSelector(
    ({ projectReducer: { projects, loading } }: RootState) => ({
      projects,
      loading,
    })
  );

  // Calc projects
  const { allProjects, pagination } = useMemo(
    () => ({
      allProjects:
        projects && filter ? projects[filter as any] : [],
      pagination: projects?.pagination,
    }),
    [projects, filter]
  );

  const filters = useMemo(() => Object.keys(StatusService.getAvailableFilter(filter as string)), [filter]);

  // Delayed search handler
  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  // Fetch projects
  const fetchProjects = () => {
    dispatch(
      getProjects(filter, {
        page,
        perPage: rowsPerPage,
        status: filterBy,
        sort: 'date-submit',
        'sort-order': sortOrder,
        search: debouncedSearch,
      })
    );
  };

  // Columns
  const columns: IColumn[] = [
    {
      field: 'photo',
      label: t('project_detail.photo'),
      sortable: false,
      width: 68,
      render: (row: IProjectData) => (
        <Avatar src={getProjectPrimaryImage(row)} />
      ),
    },
    {
      field: 'first_name',
      label: t('project_detail.name_and_address'),
      render: (row: IProjectData, isSelected) => (
        <>
          <Typography
            variant="subtitle1"
            color={isSelected ? 'darkIndigo' : 'lightIndigo'}
          >
            {`${row.customer?.first_name} ${row.customer?.last_name}`}
          </Typography>
          <Typography
            variant="body2"
            color={isSelected ? 'darkIndigo' : 'lightIndigo'}
          >
            {getAddress(row)}
          </Typography>
        </>
      ),
    },
    {
      field: 'status',
      label: '',
      render: (row) => (
        <Box
          sx={{
            width: 20,
            height: 20,
            backgroundColor:
              STATUS.customer[row.status]?.color ||
              STATUS.property[row.status]?.color ||
              '',
            borderRadius: 10,
          }}
        />
      ),
    },
  ];

  // Go back handler
  const handleGoBack = () => {
    navigate(
      ROUTES[isObjectType ? 'OBJECTS' : 'CLIENTS'].INDEX +
        `/${params.filter || ''}`
    );
  };

  // Row click handler
  const handleRowClick = (id) => {
    navigate(
      ROUTES[isObjectType ? 'OBJECTS' : 'CLIENTS'].DETAIL.replace(
        ':filter',
        params.filter || ''
      ).replace(':id', id)
    );
  };

  // Page change handler
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
    setPage(1);
  };

  // Change search text handler
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // Toggle search handler
  const handleToggleSearch = () => {
    setVisibleSearch(!visibleSearch);
  };

  // Filter click handler
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterEl(event.currentTarget);
  };

  // Close filter menu handler
  const handleCloseFilter = () => {
    setFilterEl(null);
  };

  // Update filter handler
  const handleFilterChange = (value) => {
    if (filterBy.indexOf(value) === -1) {
      setFilterBy([...filterBy, value]);
    } else {
      setFilterBy(filterBy.filter((item) => item !== value));
    }
  };

  // Sort order change handler
  const handleChangeSortOrder = () => {
    setSortOrder(sortOrder === Order.Asc ? Order.Desc : Order.Asc);
  };

  // Debounced search
  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
  }, [search, delayedChangeSearch]);

  // On pagination changed
  useEffect(() => {
    if (pagination?.total || pagination?.total === 0) {
      setTotalPage(Math.ceil(pagination?.total / rowsPerPage));
    }
  }, [pagination, rowsPerPage]);

  // On filterBy, sortBy, rowsPerPage, page changed
  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder, rowsPerPage, page, debouncedSearch, filterBy]);

  useEffect(() => {
    setFilterBy(
      Object.keys(StatusService.getAvailableFilter(filter as string))
    );
  }, [filter]);

  // On state of location changed
  useEffect(() => {
    if (state) {
      const { page, rowsPerPage } = state as any;
      if (page) {
        setPage(page);
      }
      if (rowsPerPage) {
        setRowsPerPage(rowsPerPage);
      }
    }
  }, [state]);

  // Return project list
  return (
    <>
      <Card>
        <CardHeader
          sx={(theme) => ({
            // @ts-ignore
            borderBottom: `1px solid ${theme.palette.lightCyan}`,
          })}
          title={
            <Stack direction="row" spacing={8}>
              <IconButton disabled={loading} onClick={handleGoBack}>
                <Icon name="arrow-back" />
              </IconButton>
              <IconButton disabled={loading} onClick={handleChangeSortOrder}>
                <Icon name="arrow-down-up" />
              </IconButton>
              {isObjectType === false && (
                <IconButton disabled={loading} onClick={handleFilterClick}>
                  <Icon name="funnel-fill" />
                </IconButton>
              )}
              <IconButton disabled={loading} onClick={handleToggleSearch}>
                <Icon name={visibleSearch ? 'x-lg' : 'search'} />
              </IconButton>
              {visibleSearch && (
                <Input
                  fullWidth
                  startAdornment={<Icon name="search" />}
                  placeholder="Search"
                  value={search}
                  disabled={loading}
                  onChange={handleSearchChange}
                />
              )}
            </Stack>
          }
        />
        <DataTable
          isList
          paginated
          page={page}
          isLoading={loading}
          totalPage={totalPage}
          rowsPerPage={rowsPerPage}
          data={allProjects || []}
          columns={columns}
          selectedId={Number(params?.id)}
          contentSize={{
            height: 'calc(100vh - 400px)',
          }}
          onRowClick={handleRowClick}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Card>
      {Boolean(filterEl) && (
        <Menu
          anchorEl={filterEl}
          open={Boolean(filterEl)}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {filters.map((option, index) => (
            <MenuItem
              key={index}
              value={option}
              disabled={loading}
              onClick={() => handleFilterChange(option)}
            >
              <Checkbox checked={filterBy.indexOf(option) > -1} />
              <ListItemText primary={StatusService.STATUS.customer[option]?.name} sx={{ fontSize: 8 }} />
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

// Export detail list
export default DetailList;
