// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';

// Components
import { Button, Dialog } from '../../Common';
import { FormBuilder } from '../../FormBuilder';

// Interfaces
import { RootState } from '../../../redux/reducers';

// Utils
import { buildFormInitialValues, buildSingleFieldSchema } from '../../../utils';

// Store
import {
  getCollectPaymentDataForm,
  getProjectDetail,
} from '../../../redux/actions';
import { addPaymentDetails } from '../../../services/project.service';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

// Interfaces
interface ICollectPaymentDataModal {
  isEdit: boolean;
  open: boolean;
  onClose: () => void;
}

// Export collect payment data modal
export const CollectPaymentDataModal: FC<ICollectPaymentDataModal> = ({
  isEdit,
  open,
  onClose,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get snack bar hook
  const { enqueueSnackbar } = useSnackbar();

  // Get dispatch from store
  const dispatch = useDispatch();

  // Get project id
  const params = useParams<{ id: string }>();

  // Get collect payment form from store
  const collectPaymentDataForm = useSelector(
    ({ formReducer: { collectPaymentDataForm } }: RootState) =>
      collectPaymentDataForm
  );

  // Get project detail from store
  const { projectDetail } = useSelector(
    ({ projectReducer }: RootState) => projectReducer
  );

  // Get from name from project detail
  const formName = useMemo(() => {
    let formName = 'single_paymentOneTimeDetails';

    if (
      projectDetail?.accepted_calculation?.actual_monthly_annuity &&
      projectDetail?.accepted_calculation?.actual_one_time_payment
    ) {
      formName = 'single_paymentCombinationDetails';
    } else if (projectDetail?.accepted_calculation?.actual_monthly_annuity) {
      formName = 'single_paymentDetails';
    }

    return formName;
  }, [projectDetail]);

  const [formikIsSet, setFormikIsSet] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // Get initial value from form data
  const initialValue = useMemo(() => {
    const builtForm = buildFormInitialValues(collectPaymentDataForm);
    return builtForm;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildFormInitialValues(collectPaymentDataForm)]);

  // Get validation schema from form data
  const validationSchema = useMemo(() => {
    return buildSingleFieldSchema(collectPaymentDataForm);
  }, [collectPaymentDataForm]);

  // Create formik
  const formik = useFormik<any>({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      addPaymentDetails(projectDetail?.id, values)
        .then((res) => {
          setLoading(false);
          if (res.data?.success) {
            enqueueSnackbar(res.data?.message, { variant: 'success' });
            if (params.id) {
              dispatch(getProjectDetail(+params.id));
            }
          } else {
            enqueueSnackbar(res.data?.message || 'Failed', {
              variant: 'error',
            });
          }
          onClose();
        })
        .catch((err) => {
          enqueueSnackbar(err.response?.data?.message || 'Failed', {
            variant: 'error',
          });
          setLoading(false);
        });
    },
  });

  // Handle submit
  const handleSubmit = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    if (isEdit && projectDetail && open) {
      formik.resetForm();
      formik.setValues(projectDetail?.payment_detail);
      setFormikIsSet(true);
    }
    if (!isEdit && open) {
      formik.resetForm();
      formik.setValues(initialValue);
      setFormikIsSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, projectDetail, open]);

  // On mounted
  useEffect(() => {
    dispatch(getCollectPaymentDataForm(formName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formName]);

  // Return collect payment data modal
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setFormikIsSet(false);
      }}
      title={t('collect_payment_data.title')}
      actions={
        <Button
          loading={loading}
          color="primary"
          size="large"
          onClick={handleSubmit}
        >
          {t('collect_payment_data.save')}
        </Button>
      }
    >
      {formikIsSet && (
        <Grid container columns={2} spacing={16}>
          <FormBuilder
            type={collectPaymentDataForm?.type}
            attributes={collectPaymentDataForm?.attributes}
            label={collectPaymentDataForm?.label}
            fields={collectPaymentDataForm?.fields}
            formik={formik}
            layout={{
              password: 2,
              date: 2,
              text: 2,
              currency: 2,
              select: 2,
            }}
            path=""
          />
        </Grid>
      )}
    </Dialog>
  );
};
