// Dependencies
import styled from 'styled-components';
import { Avatar } from '@mui/material';

// Export styled components
export const MenuItemAvatar = styled(Avatar)`
  && {
    width: 82px;
    height: 82px;
    margin-right: 13px;
    border-radius: 8px;
  }
`;
