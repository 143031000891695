// Dependencies
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Export styled components
export const TabPanel = styled(PerfectScrollbar)`
  margin: ${(props) => props.theme.spacing(24, 20, 0)};

  ${(props) => props.theme.breakpoints.up('lg')} {
    height: calc(100vh - 610px);
  }
`;
