// Dependencies
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  ListItemAvatar,
  ListItemText,
  Select,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

// Components
import {
  AssignBrokerModal,
  Button,
  ChatsModal,
  ConfirmModal,
  DataTable,
  IColumn,
  Icon,
  IconButton,
  Input,
} from '../../../../components';

// Utils
import {
  getProjectCreatedDate,
  getDateString,
  getFullName,
  getLastTask,
  getProjectPrimaryImage,
  isUserAllowed,
  getProjectSubmittedDate,
} from '../../../../utils';

// Constants
import {
  getAvailableFilter,
  Role,
  ROUTES,
  STATUS,
  STATUS_MAPPING,
} from '../../../../constants';

// Store
import { RootState } from '../../../../redux/reducers';
import { getProjects } from '../../../../redux/actions';

// Interfaces
import { IProjectData, ISortBy, Order } from '../../../../interfaces';

// Services
import {
  assignBroker,
  deleteMultipleProject,
  deleteProject,
  exportProjects,
} from '../../../../services/project.service';

// Constants
import * as StatusService from '../../../../constants';

// Styles
import * as S from './styles';

// Create list table
export const ListTable = () => {
  // States
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<ISortBy>({
    field: 'date-submit',
    order: Order.Desc,
  });
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<IProjectData | null>();
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [selectedId, setSelectedId] = useState<number>();
  const [openChatsProjectId, setOpenChatsProjectId] = useState<number | null>(
    null
  );
  const [visibleDeleteConfirmModal, setVisibleDeleteConfirmModal] =
    useState<boolean>(false);
  const [visibleAssignBrokerModal, setVisibleAssignBrokerModal] = useState<
    string | null
  >(null);
  const [visibleMultiDeleteConfirmModal, setVisibleMultiDeleteConfirmModal] =
    useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  // Get filter param
  const { filter } = useParams();

  const [filterBy, setFilterBy] = useState<string[]>(
    Object.keys(StatusService.getAvailableFilter(filter as string)) || []
  );

  const filters = useMemo(
    () => Object.keys(StatusService.getAvailableFilter(filter as string)),
    [filter]
  );

  // Theme
  const theme = useTheme();

  // Get translation from hook
  const { t } = useTranslation();

  // Get navigate from hook
  const navigate = useNavigate();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get path from hook
  const { pathname } = useLocation();

  // Check platform
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Get status from store
  const isDrawerOpened = useSelector(
    ({ uiReducer: { isDrawerOpened } }: RootState) => isDrawerOpened
  );

  // Check list type
  const isObjectType = useMemo(() => {
    return pathname.includes(ROUTES.OBJECTS.INDEX);
  }, [pathname]);

  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Get projects from store
  const { projects, loading, shouldRefetch } = useSelector(
    ({ projectReducer: { projects, loading, shouldRefetch } }: RootState) => ({
      projects,
      loading,
      shouldRefetch,
    })
  );

  // Get projects
  const { allProjects, pagination } = useMemo(
    () => ({
      allProjects: projects && filter ? projects[filter as any] : [],
      pagination: projects?.pagination,
    }),
    [projects, filter]
  );

  // Delayed search handler
  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  // Fetch projects
  const fetchProjects = () => {
    dispatch(
      getProjects(filter, {
        page,
        perPage: rowsPerPage,
        status: filterBy,
        sort: sortBy.field,
        'sort-order': sortBy.order,
        search: debouncedSearch,
      })
    );
  };

  // Open search input handler
  const handleOpenSearchInput = () => {
    setVisibleSearch(true);
  };

  // Close search input handler
  const handleCloseSearchInput = () => {
    setVisibleSearch(false);
  };

  // Filter change handler
  const handleFilterChange = (e) => {
    const value = e.target.value;
    if (filterBy.indexOf(value) === -1) {
      setFilterBy([...value]);
    } else {
      setFilterBy(filterBy.filter((filter) => filter === value));
    }
  };

  // Export handler
  const handleExport = () => {
    const query = {
      status: Object.keys(getAvailableFilter(filter as string)),
    };
    exportProjects(query);
  };

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSortBy(value);
  };

  // Page change handler
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
  };

  // Search change handler
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // Open assign broker modal
  const handleOpenAssignBrokerModal = (id) => {
    setVisibleAssignBrokerModal(id);
    setSelectedProject(allProjects.find((client) => client.id === id));
  };

  // Go to detail handler
  const handleGoToDetail = (id) => {
    navigate(
      ROUTES[isObjectType ? 'OBJECTS' : 'CLIENTS'].DETAIL.replace(
        ':filter',
        filter || ''
      ).replace(':id', id),
      {
        state: {
          rowsPerPage,
          page,
        },
      }
    );
  };

  // Open chat modal handler
  const handleOpenChatModal = (id) => {
    setOpenChatsProjectId(id);
  };

  // Close chat modal
  const handleCloseChatsModal = () => {
    setOpenChatsProjectId(null);
  };

  // Open close confirm modal
  const handleOpenDeleteConfirmModal = (id: number) => {
    setSelectedId(id);
    setVisibleDeleteConfirmModal(true);
  };

  // Close delete confirm modal
  const handleCloseDeleteConfirmModal = () => {
    setVisibleDeleteConfirmModal(false);
  };

  // Delete handler
  const handleDelete = async () => {
    setDeleteLoading(true);
    deleteProject(selectedId)
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          dispatch(
            getProjects(filter, {
              page,
              perPage: rowsPerPage,
              status: filterBy,
              sort: sortBy.field,
              'sort-order': sortBy.order,
              search: search,
            })
          );
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' });
        }
        setDeleteLoading(false);
        setVisibleDeleteConfirmModal(false);
      })
      .catch(() => {
        setDeleteLoading(false);
        setVisibleDeleteConfirmModal(false);
      });
  };

  // Close assign broker modal
  const handleCloseAssignBrokerModal = () => {
    setVisibleAssignBrokerModal(null);
  };

  // Assign broker
  const handleAssignBroker = (brokerId: string) => {
    if (selectedProject) {
      assignBroker(selectedProject.id, +brokerId).then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          fetchProjects();
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' });
        }
      });
    }
  };

  // Open multiple delete confirm modal
  const handleOpenMultiDeleteConfirmModal = () => {
    setVisibleMultiDeleteConfirmModal(true);
  };

  // Close multiple delete confirm modal
  const handleCloseMultiDeleteConfirmModal = () => {
    setVisibleMultiDeleteConfirmModal(false);
  };

  // Remove multiple object
  const handleDeleteMultipleObject = () => {
    setDeleteLoading(true);
    const ids = allProjects
      .filter((object, index) => selected.includes(index))
      ?.map((item) => item.id);
    deleteMultipleProject(ids)
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          dispatch(
            getProjects(filter, {
              page,
              perPage: rowsPerPage,
              status: filterBy,
              sort: sortBy.field,
              'sort-order': sortBy.order,
              search: search,
            })
          );
        } else {
          enqueueSnackbar(res?.message || 'Failed to delete', {
            variant: 'error',
          });
        }
        setDeleteLoading(false);
        setSelected([]);
        setVisibleMultiDeleteConfirmModal(false);
      })
      .catch((e: any) => {
        enqueueSnackbar(e?.response?.data?.message || 'Failed to delete', {
          variant: 'error',
        });
        setSelected([]);
        setDeleteLoading(false);
        setVisibleMultiDeleteConfirmModal(false);
      });
  };

  // Columns
  const columns: IColumn[] = [
    {
      field: 'name',
      label: t('projects.clients'),
      render: (row: IProjectData, isSelected) => (
        <Stack direction="row" alignItems="center">
          <ListItemAvatar>
            <Avatar src={getProjectPrimaryImage(row)} />
          </ListItemAvatar>
          <ListItemText
            primary={`${row.customer.first_name} ${row.customer.last_name}`}
            secondary={`${row.customer.address.street} ${row.customer.address.zip} ${row.customer.address.city}`}
            secondaryTypographyProps={{
              color: isSelected
                ? theme.palette.darkIndigo
                : theme.palette.lightIndigo,
            }}
          />
        </Stack>
      ),
    },
    {
      field: filter === STATUS_MAPPING.new.key ? 'created' : 'submitted',
      label:
        filter === STATUS_MAPPING.new.key
          ? t('projects.created_date')
          : t('projects.submitted_date'),
      render: (row: IProjectData, isSelected) => (
        <Box color={isSelected ? 'darkIndigo' : 'middleCyan'}>
          {row.status === STATUS_MAPPING.new.key
            ? t('projects.created')
            : getProjectSubmittedDate(row)
            ? t('projects.submitted')
            : t('projects.created')}
          <br />{' '}
          {row.status === STATUS_MAPPING.new.key
            ? getProjectCreatedDate(row)
            : getProjectSubmittedDate(row) || getProjectCreatedDate(row)}
        </Box>
      ),
    },
    {
      field: 'status',
      label: t('projects.status'),
      render: (row: IProjectData) => (
        <S.Status
          color={
            STATUS.customer[row.status]?.color ||
            STATUS.property[row.status]?.color ||
            ''
          }
        >
          {STATUS.customer[row.status]?.name ||
            STATUS.property[row.status]?.name ||
            ''}
        </S.Status>
      ),
      sortable: false,
    },
    {
      field: 'project',
      label: t('projects.tasks'),
      visible: isUserAllowed(account, Role.ROLE_ADMIN),
      render: (row: IProjectData, isSelected) => {
        const lastTask = getLastTask(row);
        return (
          lastTask && (
            <Stack direction="row" alignItems="center">
              <ListItemAvatar sx={{ minWidth: 60 }}>
                <Avatar
                  variant="circular"
                  src={lastTask?.closedUser.image?.path}
                />
              </ListItemAvatar>
              <ListItemText
                primary={lastTask?.task.message}
                secondary={`${lastTask?.closedUser.first_name} ${
                  lastTask?.closedUser.last_name
                }, ${getDateString(lastTask?.closedDate as string)}`}
                primaryTypographyProps={{
                  variant: 'subtitle1',
                  color: isSelected ? 'darkIndigo' : 'lightIndigo',
                }}
                secondaryTypographyProps={{
                  color: isSelected ? 'darkIndigo' : 'middleCyan',
                }}
              />
            </Stack>
          )
        );
      },
    },
  ];

  // Row actions
  const rowActions = [
    {
      icon: 'user',
      label: t('leads.assign_broker'),
      hidden: !isUserAllowed(account, Role.ROLE_ADMIN) || isObjectType,
      onClick: handleOpenAssignBrokerModal,
    },
    {
      icon: 'document',
      label: t('projects.details'),
      onClick: handleGoToDetail,
    },
    {
      icon: 'message',
      label: t('projects.send_message'),
      onClick: handleOpenChatModal,
    },
    {
      icon: 'trash',
      label: t('projects.delete'),
      hidden: !isUserAllowed(account, Role.ROLE_ADMIN),
      onClick: handleOpenDeleteConfirmModal,
    },
  ];

  // On search changed
  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
  }, [search, delayedChangeSearch]);

  // On pagination changed
  useEffect(() => {
    if (pagination?.total) {
      setTotalPage(Math.ceil(pagination?.total / rowsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, rowsPerPage]);

  // On filterBy, page, rowsPerPage changed
  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    page,
    rowsPerPage,
    sortBy,
    debouncedSearch,
    filterBy,
    shouldRefetch,
  ]);

  // On filter changed
  useEffect(() => {
    setFilterBy(
      Object.keys(StatusService.getAvailableFilter(filter as string))
    );
  }, [filter]);

  // Return list table
  return (
    <>
      <Card>
        <CardHeader
          sx={{ pt: 22, pb: 20 }}
          title={
            visibleSearch ? (
              <Stack spacing={10} direction="row">
                <IconButton onClick={handleCloseSearchInput}>
                  <Icon name="x-lg" />
                </IconButton>
                <Input
                  disabled={loading}
                  startAdornment={<Icon name="search" />}
                  placeholder={t('projects.search')}
                  value={search}
                  sx={{
                    flex: { xs: 1, md: 'unset' },
                    width: { xs: '100%', md: 300 },
                  }}
                  onChange={handleSearchChange}
                />
              </Stack>
            ) : (
              <Stack>
                <IconButton disabled={loading} onClick={handleOpenSearchInput}>
                  <Icon name="search" />
                </IconButton>
              </Stack>
            )
          }
          action={
            isMobile ? (
              !visibleSearch && (
                <Stack direction="row" spacing={12}>
                  {isUserAllowed(account, Role.ROLE_AGENTS) && (
                    <IconButton disabled={loading} onClick={handleExport}>
                      <Icon name="box-arrow-up" />
                    </IconButton>
                  )}
                  {selected && selected.length > 0 && (
                    <IconButton
                      disabled={loading}
                      onClick={handleOpenMultiDeleteConfirmModal}
                    >
                      <Icon name="trash" />
                    </IconButton>
                  )}
                </Stack>
              )
            ) : (
              <Stack direction="row" spacing={12}>
                {!isObjectType && (
                  <Select
                    sx={(theme) => ({
                      display: { xs: 'none', sm: 'flex' },
                      background: 'transparent',
                      fontFamily: 'Work Sans',
                      fontWeight: '600',
                      color: theme.palette.lightIndigo,
                    })}
                    value={filterBy}
                    displayEmpty
                    multiple
                    onChange={handleFilterChange}
                    renderValue={() => t('projects.filter_by_status')}
                  >
                    {filters.map((option, index) => (
                      <S.MenuItem key={index} value={option}>
                        <Checkbox checked={filterBy.indexOf(option) > -1} />
                        <ListItemText
                          primary={StatusService.STATUS.customer[option]?.name}
                          sx={{ fontSize: 10 }}
                        />
                      </S.MenuItem>
                    ))}
                  </Select>
                )}
                {isUserAllowed(account, Role.ROLE_AGENTS) && (
                  <Button
                    disabled={loading}
                    variant="text"
                    startIcon={<Icon name="box-arrow-up" />}
                    onClick={handleExport}
                  >
                    {t(
                      isObjectType
                        ? 'projects.export_objects'
                        : 'projects.export_clients'
                    )}
                  </Button>
                )}
                {selected && selected.length > 0 && (
                  <Button
                    disabled={loading}
                    variant="text"
                    startIcon={!isMobile && <Icon name="trash" />}
                    onClick={handleOpenMultiDeleteConfirmModal}
                  >
                    {t('projects.delete')}
                  </Button>
                )}
              </Stack>
            )
          }
        />
        <CardContent
          sx={(theme) => ({
            p: '0 !important',
            // @ts-ignore
            borderTop: `1px solid ${theme.palette.lightCyan}`,
          })}
        >
          <DataTable
            sx={{ flex: 1 }}
            paginated
            stickyHeader
            rowSelectable
            columnEditable
            columns={columns}
            rowActions={rowActions}
            data={allProjects || []}
            page={page}
            selected={selected}
            isLoading={loading}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            contentSize={
              isMobile
                ? {
                    width: 'calc(100vw - 32px)',
                    height: isObjectType
                      ? 'calc(100vh - 252px)'
                      : 'calc(100vh - 330px)',
                  }
                : {
                    width: isDrawerOpened
                      ? 'calc(100vw - 328px)'
                      : 'calc(100vw - 160px)',
                    height: isObjectType
                      ? 'calc(100vh - 316px)'
                      : 'calc(100vh - 406px)',
                  }
            }
            onSort={handleSort}
            onRowClick={handleGoToDetail}
            onChangeSelected={setSelected}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </CardContent>
      </Card>
      {visibleAssignBrokerModal && (
        <AssignBrokerModal
          target={getFullName(selectedProject as IProjectData)}
          brokerId={selectedProject?.creator?.id}
          open={!!visibleAssignBrokerModal}
          onClose={handleCloseAssignBrokerModal}
          onAssign={handleAssignBroker}
        />
      )}
      {visibleDeleteConfirmModal && (
        <ConfirmModal
          open={visibleDeleteConfirmModal}
          onClose={handleCloseDeleteConfirmModal}
          onOk={handleDelete}
          loading={deleteLoading}
        />
      )}
      {visibleMultiDeleteConfirmModal && (
        <ConfirmModal
          open={visibleMultiDeleteConfirmModal}
          onClose={handleCloseMultiDeleteConfirmModal}
          onOk={handleDeleteMultipleObject}
          loading={deleteLoading}
        />
      )}
      {!!openChatsProjectId && (
        <ChatsModal
          open={!!openChatsProjectId}
          onClose={handleCloseChatsModal}
          projectId={openChatsProjectId}
        />
      )}
    </>
  );
};

// Export list table
export default ListTable;
