// Dependecies
import React, { FC } from 'react';
import ReactHighcharts from 'react-highcharts';

// Styles
import * as S from './style';

export const Chart: FC = () => {
  const config = {
    chart: {
      type: 'column',
      marginLeft: 100,
      backgroundColor: '#002647',
    },
    title: {
      align: 'right',
      text: 'Residual value right of residence',
      style: {
        color: '#fff',
        fontSize: '20px',
        fontWeight: 'bold',
      },
      x: -20,
      y: 30,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      visible: true,
      type: 'category',
      labels: {
        enabled: true,
        style: {
          color: '#fff',
          fontSize: '16px',
          fontWeight: 'bold',
        },
      },
      lineColor: '#fff',
      startOnTick: false,
      endOnTick: false,
      title: {
        text: 'End of year',
        align: 'low',
        offset: 0,
        x: -95,
        y: -12,
        margin: 30,
        style: {
          color: '#fff',
          fontSize: '16px',
          fontWeight: 'bold',
        },
      },
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          style: {
            color: '#fff',
            fontSize: '16px',
            fontWeight: 'bold',
          },
          format: '{point.y:.3f} €',
        },
        color: '#EAD01B',
        pointWidth: 50,
        pointStart: 1,
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        data: [180.0, 160.0, 140.0, 120.0, 100.0, 80.0, 60.0, 40.0, 20.0, 0],
      },
    ],
  };
  return (
    <S.Wrap>
      <ReactHighcharts config={config} />
    </S.Wrap>
  );
};
