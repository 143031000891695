export const IMAGE_TYPES = [
  'jpeg',
  'jpg',
  'png',
  'tiff',
  'bmp',
  'gif',
  'svg',
  'webp',
];

export const VIDEO_TYPES = [
  'mp4 ',
  'mkv ',
  'mov ',
];
