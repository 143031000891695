// Dependencies
import { FC, useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import { Icon } from '../../../../../../../components';

// Interfaces
import { RootState } from '../../../../../../../redux/reducers';

// Icons
import {
  CoinsIcon,
  RemoveIcon,
  StarIcon,
} from '../../../../../../../assets/icons';

// Utils
import { getCurrency } from '../../../../../../../utils';

// Styles
import * as S from './styles';

// Constants
const marketValues = [
  {
    star: 1,
    step: 'market_value_simple',
    value: 'object_standard_simple_value',
    sqm_value: 'object_standard_simple_value_sqm',
  },
  {
    star: 2,
    step: 'market_value_medium',
    value: 'object_standard_middle_value',
    sqm_value: 'object_standard_middle_value_sqm',
  },
  {
    star: 3,
    step: 'market_value_high',
    value: 'object_standard_high_value',
    sqm_value: 'object_standard_high_value_sqm',
  },
  {
    star: 4,
    step: 'market_value_very_high',
    value: 'object_standard_very_high_value',
    sqm_value: 'object_standard_very_high_value_sqm',
  },
];

// Create market values section
const MarketValuesSection: FC = () => {
  // States
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  // Get translation from hook
  const { t } = useTranslation();

  // Get theme from hook
  const theme = useTheme();

  // Check window size
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Get project detail from store
  const projectDetail = useSelector(
    ({ projectReducer: { projectDetail } }: RootState) => projectDetail
  );

  // Toggle collapse handler
  const handleToggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  // On window size changed
  useEffect(() => {
    setIsExpanded(!isMobile);
  }, [isMobile]);

  // Return market values section
  return (
    <Stack direction={{ xs: 'column', xl: 'row' }} spacing={{ xs: 0, xl: 40 }}>
      <Box>
        <Stack
          mb={isExpanded ? 12 : 0}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography mb={12} variant="h4" color="lightIndigo">
            {t('project_detail.market_values_for_different_equipment')}
          </Typography>
          <S.ExpandButton onClick={handleToggleCollapse}>
            {isExpanded ? <RemoveIcon /> : <Icon name="plus-lg" />}
          </S.ExpandButton>
        </Stack>
        <Collapse in={isExpanded}>
          <S.Values
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >
            {marketValues.map(({ star, step, value, sqm_value }, index) => (
              <S.Item alignItems="center" key={index}>
                <Stack direction="row">
                  {new Array(star).fill('').map(() => (
                    <Box color="#f7be42">
                      <StarIcon width={24} />
                    </Box>
                  ))}
                  {new Array(4 - star).fill('').map(() => (
                    <Box color="cyan">
                      <StarIcon width={24} />
                    </Box>
                  ))}
                </Stack>
                <Typography variant="body2" mb={10}>
                  {t(`project_detail.${step}`)}
                </Typography>
                <Typography variant="h2">
                  {projectDetail && projectDetail.sprengnetter
                    ? `${projectDetail.sprengnetter[value]} €`
                    : ''}
                </Typography>
                <Typography variant="h3" color="lightIndigo">
                  {projectDetail && projectDetail.sprengnetter
                    ? `${projectDetail.sprengnetter[sqm_value]} €/m²`
                    : ''}
                </Typography>
              </S.Item>
            ))}
          </S.Values>
        </Collapse>
      </Box>
      <S.Card>
        <CoinsIcon />
        <Typography variant="h5" align="center" mb={-40}>
          {t('project_detail.remaining_debts_to_be_paid_off')
            .split(' ')
            .map((s, index) => {
              if (index === 0) {
                return <Typography variant="body2">{s}</Typography>;
              } else {
                return s;
              }
            })}
        </Typography>
        <Typography variant="h2" mt={34}>
          {projectDetail?.property.mortgage
            ? `${getCurrency(projectDetail?.property.mortgage_amount)} €`
            : 'Keine'}
        </Typography>
      </S.Card>
    </Stack>
  );
};

// Export market values section
export default MarketValuesSection;
