// Dependencies
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';

// Components
import { Icon } from '../../Common';
import { DrawerContent } from '../DrawerContent';

// Styles
import * as S from './styles';

// Global constants
import { setIsDrawerOpened } from '../../../redux/actions';
import { RootState } from '../../../redux/reducers';

// Export sidebar component
export const Sidebar: FC = () => {
  const dispatch = useDispatch();

  const isDrawerOpened = useSelector(
    ({ uiReducer: { isDrawerOpened } }: RootState) => isDrawerOpened
  );

  // States
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isCollapsedTemp, setIsCollapsedTemp] = useState<boolean>(false);

  useEffect(() => {
    setIsCollapsed(!isDrawerOpened);
  }, [isDrawerOpened]);

  // Toggle sidebar handler
  const handleToggleSidebar = () => {
    setTimeout(() => {
      dispatch(setIsDrawerOpened(!isDrawerOpened));
      setIsCollapsedTemp(!isCollapsed);
    }, 100);
  };

  // Mouse enter handler
  const handleMouseEnter = () => {
    if (isCollapsed) {
      setIsCollapsedTemp(false);
    }
  };

  // Mouse leave handler
  const handleMouseLeave = () => {
    if (isCollapsed) {
      setIsCollapsedTemp(true);
    }
  };

  const MenuCollapseAndPin = () => {
    return (
      <>
        {!isCollapsedTemp && (
          <S.Logo src="/assets/images/logo.svg" alt="logo" />
        )}
        {!isCollapsedTemp ? (
          <IconButton color="default" onClick={handleToggleSidebar}>
            <Icon
              size={14}
              name={isCollapsed ? 'pushpin' : 'indent-decrease'}
            />
          </IconButton>
        ) : (
          <IconButton color="default" onClick={handleToggleSidebar}>
            <Icon size={14} name="indent-increase" />
          </IconButton>
        )}
      </>
    );
  };

  // Return sidebar component
  return (
    <S.Sidebar
      $isCollapsed={isCollapsedTemp}
      $isFixed={isCollapsed}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!isCollapsedTemp ? (
        <S.Brand>
          <MenuCollapseAndPin />
        </S.Brand>
      ) : (
        <S.CollapsedBrand>
          {!isCollapsedTemp && (
            <S.Logo src="/assets/images/logo.svg" alt="logo" />
          )}
          <MenuCollapseAndPin />
        </S.CollapsedBrand>
      )}
      <DrawerContent isCollapsed={isCollapsedTemp} />
    </S.Sidebar>
  );
};
