import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Button, Typography } from '../../components';
import { forgotPassword } from '../../redux/actions';
import { RootState } from '../../redux/reducers';
import { ROUTES } from '../../constants';
import * as S from './styles';

export const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const loading = useSelector(({ authReducer: { loading } }: RootState) => loading);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('validation.email_invalid'))
      .max(80, t('validation.max_length'))
      .required(t('validation.email_required')),
  });

  const formik = useFormik<any>({
    initialValues: {
      email: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      if (loading) {
        return;
      }

      try {
        const res: any = await dispatch(forgotPassword(values));
        if (res.value.success && res.value.message) {
          enqueueSnackbar(res.value.message, { variant: 'success' });
        } else {
          enqueueSnackbar(res.value.message, { variant: 'error' });
        }
      } catch (e: any) {
        enqueueSnackbar(
          e.response?.data?.message || 'Fehler bei Serverkommunikation',
          { variant: 'error' }
        );
      }
    },
  });

  return (
    <S.Container>
      <S.Logo src="/assets/images/logo.svg" alt="logo" />

      <form onSubmit={formik.handleSubmit}>
        <S.Card>
          <S.Title mb={24}>
            <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
              {t('forgot_password.forgot_password')}
            </Typography>
          </S.Title>

          <S.FormWrapper mb={24}>
            <S.FormInput
              error={!!(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              placeholder={t('forgot_password.email')}
              {...formik.getFieldProps('email')}
            />
          </S.FormWrapper>

          <Box mt={16}>
            <Button size="large" color="primary" type="submit" loading={loading}>
              {t('forgot_password.send_password')}
            </Button>
          </Box>
        </S.Card>
      </form>

      <Box display="flex" mt={16}>
        <Typography variant="body1" mr={8}>
          {t('forgot_password.question')}
        </Typography>
        <Box component={Link} to={ROUTES.LOGIN} color="primary.main">
          <Typography variant="h4">{t('forgot_password.sign_in')}</Typography>
        </Box>
      </Box>
    </S.Container>
  );
};
