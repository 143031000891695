import { ROUTES } from './routes';
import { Role } from './role.constant';

export interface IAccessByRoleAndIds {
  role: Role;
  ids?: number[];
}

interface INavLink {
  icon: string;
  label: string;
  size?: number;
  path: string;
  roles?: Role[];
  role: {
    min: Role;
    max?: Role;
  };
  access?: IAccessByRoleAndIds[];
  children?: {
    label: string;
    path: string;
  }[];
}

export interface ILayoutItem {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  visible: boolean;
}

const LAYOUTS_IN_XL_SCREENS: ILayoutItem[] = [
  { i: 'a', x: 0, y: 0, w: 1, h: 1, visible: true },
  {
    i: 'b',
    x: 1,
    y: 0,
    w: 1,
    h: 1,
    visible: true,
  },
  { i: 'c', x: 0, y: 1, w: 1, h: 1, visible: true },
  {
    i: 'd',
    x: 1,
    y: 1,
    w: 1,
    h: 1,
    visible: true,
  },
  {
    i: 'e',
    x: 0,
    y: 2,
    w: 1,
    h: 1,
    visible: true,
  },
  { i: 'f', x: 1, y: 2, w: 1, h: 1, visible: true },
];

const LAYOUTS_IN_LG_SCREENS: ILayoutItem[] = [
  { i: 'a', x: 0, y: 0, w: 1, h: 1, visible: true },
  {
    i: 'b',
    x: 0,
    y: 0,
    w: 1,
    h: 1,
    visible: true,
  },
  { i: 'c', x: 0, y: 1, w: 1, h: 1, visible: true },
  {
    i: 'd',
    x: 1,
    y: 1,
    w: 1,
    h: 1,
    visible: true,
  },
  {
    i: 'e',
    x: 0,
    y: 2,
    w: 1,
    h: 1,
    visible: true,
  },
  { i: 'f', x: 1, y: 2, w: 1, h: 1, visible: true },
];

const LAYOUTS_IN_MD_SCREENS: ILayoutItem[] = [
  { i: 'a', x: 0, y: 0, w: 1, h: 1, visible: true },
  {
    i: 'b',
    x: 0,
    y: 1,
    w: 1,
    h: 1,
    visible: true,
  },
  { i: 'c', x: 0, y: 2, w: 1, h: 1, visible: true },
  {
    i: 'd',
    x: 0,
    y: 3,
    w: 1,
    h: 1,
    visible: true,
  },
  {
    i: 'e',
    x: 0,
    y: 4,
    w: 1,
    h: 1,
    visible: true,
  },
  { i: 'f', x: 0, y: 5, w: 1, h: 1, visible: true },
];

const LAYOUTS_IN_SM_SCREENS: ILayoutItem[] = [
  { i: 'a', x: 0, y: 0, w: 1, h: 1, visible: true },
  {
    i: 'b',
    x: 0,
    y: 1,
    w: 1,
    h: 1,
    visible: true,
  },
  { i: 'c', x: 0, y: 2, w: 1, h: 1, visible: true },
  {
    i: 'd',
    x: 0,
    y: 3,
    w: 1,
    h: 1,
    visible: true,
  },
  {
    i: 'e',
    x: 0,
    y: 4,
    w: 1,
    h: 1,
    visible: true,
  },
  { i: 'f', x: 0, y: 5, w: 1, h: 1, visible: true },
];

export const INITIAL_DASHBOARD_LAYOUTS = {
  xl: LAYOUTS_IN_XL_SCREENS,
  lg: LAYOUTS_IN_LG_SCREENS,
  md: LAYOUTS_IN_MD_SCREENS,
  sm: LAYOUTS_IN_SM_SCREENS,
};

export const NAV_LINKS: INavLink[] = [
  {
    label: 'dashboard',
    icon: 'panel',
    path: ROUTES.DASHBOARD,
    role: {
      min: Role.ROLE_USER,
    },
  },
  {
    label: 'leads',
    icon: 'add',
    path: ROUTES.LEADS.PREFIX,
    role: {
      min: Role.ROLE_USER,
      // min: Role.ROLE_ADMIN, // temporarily show for admin only
    },
    access: [
      {
        role: Role.ROLE_ADMIN,
      },
      {
        role: Role.ROLE_USER,
        ids: [207, 208, 211, 231, 159, 294],
      },
    ],
  },
  {
    label: 'clients',
    icon: 'users',
    path: ROUTES.CLIENTS.INDEX,
    role: {
      min: Role.ROLE_USER,
    },
    children: [
      {
        label: 'draft',
        path: ROUTES.CLIENTS.DRAFTS,
      },
      {
        label: 'detected',
        path: ROUTES.CLIENTS.INDEX + '/new',
      },
      {
        label: 'submitted',
        path: ROUTES.CLIENTS.INDEX + '/inProgress',
      },
      {
        label: 'rejected',
        path: ROUTES.CLIENTS.INDEX + '/declined',
      },
    ],
  },
  {
    label: 'objects',
    icon: 'home',
    size: 18,
    path: ROUTES.OBJECTS.INDEX,
    role: {
      min: Role.ROLE_USER,
    },
    children: [
      {
        label: 'bought',
        path: ROUTES.OBJECTS.INDEX + '/bought',
      },
      {
        label: 'sold',
        path: ROUTES.OBJECTS.INDEX + '/sold',
      },
    ],
  },
  {
    label: 'brokers',
    icon: 'user',
    size: 16,
    path: ROUTES.BROKERS.INDEX,
    role: {
      min: Role.ROLE_MANAGER,
    },
  },
  {
    label: 'faq',
    icon: 'question',
    path: ROUTES.FAQ,
    role: {
      min: Role.ROLE_USER,
    },
  },
];

export const TOURS_CONFIG = [
  {
    tourId: 'tour-item-1',
  },
  {
    tourId: 'tour-item-2',
  },
  {
    tourId: 'tour-item-3',
  },
  {
    tourId: 'tour-item-4',
  },
];
