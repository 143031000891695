import { LEAD_ACTIONS } from '../action-types';
import { IPagination, ILeadData } from '../../interfaces';

export interface LeadReducerState {
  leads: ILeadData[];
  leadDetail: ILeadData | null;
  pagination?: IPagination;
  loading: boolean;
  detailLoading: boolean;
}

const initialState: LeadReducerState = {
  leads: [],
  leadDetail: null,
  loading: false,
  detailLoading: false,
};

const LeadReducer = (state: LeadReducerState = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case LEAD_ACTIONS.GET_LEADS_START:
      return {
        ...state,
        loading: true,
      };

    case LEAD_ACTIONS.GET_LEADS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case LEAD_ACTIONS.GET_LEADS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };

    case LEAD_ACTIONS.GET_LEAD_DETAIL_START:
      return {
        ...state,
        detailLoading: true,
      };

    case LEAD_ACTIONS.GET_LEAD_DETAIL_ERROR:
      return {
        ...state,
        detailLoading: false,
        leadDetail: {},
      };

    case LEAD_ACTIONS.GET_LEAD_DETAIL_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        leadDetail: payload,
      };

    default:
      return state;
  }
};

export default LeadReducer;
