// Dependencies
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

// Hooks
import { useAccountState } from '../hooks';

// Component
import { Layout } from '../components';
import { LoadingContainer } from '../components/LoadingContainer';

// Constants
import { MAIN_ROUTES, PUBLIC_ROUTES, ROUTES } from '../constants';

// Actions
import { getAccount } from '../redux/actions';
import { RootState } from '../redux/reducers';
import { isUserAllowed } from '../utils';

// Create app router
const AppRouter = () => {
  const dispatch = useDispatch();

  const { authorized, loading } = useAccountState();

  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  useEffect(() => {
    dispatch(getAccount());
  }, [dispatch]);

  // Return app router
  return (
    <Router>
      <Routes>
        {authorized && loading ? (
          <Route path="*" element={<LoadingContainer />} />
        ) : (
          <>
            {authorized &&
              MAIN_ROUTES.map(({ path, element: E, role }) =>
                isUserAllowed(account, role.min, role?.max) ? (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <Layout>
                        <E />
                      </Layout>
                    }
                  />
                ) : null
              )}
            {!authorized &&
              PUBLIC_ROUTES.map(({ path, element: E }) => (
                <Route key={path} path={path} element={<E />} />
              ))}
            <Route
              path="*"
              element={
                <Navigate to={authorized ? ROUTES.DASHBOARD : ROUTES.LOGIN} />
              }
            />
          </>
        )}
      </Routes>
    </Router>
  );
};

// Export app routes
export default AppRouter;
