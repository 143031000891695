import moment from 'moment';

import { Gender01, ILeadData, PropertyType } from '../interfaces';

export const getGengerLabel = (gender: Gender01) => {
  return gender === Gender01.Female
    ? 'leads.female'
    : gender === Gender01.Male
    ? 'leads.male'
    : '';
};

export const getLeadPropertyTypeLabel = (lead: ILeadData) => {
  return lead.property_type === PropertyType.House ||
    lead.property_type === PropertyType.DuplexHouse
    ? 'leads_detail.house'
    : lead.property_type === PropertyType.Apartment
    ? 'leads_detail.apartment'
    : 'leads_detail.unknown_property';
};

export const getLeadStatusLabel = (lead: ILeadData) => {
  return lead.status ? `leads_detail.${lead.status}` : 'leads_detail.new';
};

export const getLeadCreateDate = (lead: ILeadData | null) => {
  return lead && lead.createdAt
    ? moment(lead.createdAt).format('DD.MM.YYYY')
    : '';
};

export const getLeadName = (lead: ILeadData | null) => {
  if (!lead) return '';
  let name = lead.first_name || '';
  if (lead.last_name) {
    if (name !== '') name += ' ';
    name += lead.last_name;
  }
  return name;
};

export const getLeadAgentName = (lead: ILeadData | null) => {
  if (!lead || !lead?.assignedAgent) return '';
  let name = lead.assignedAgent.first_name || '';
  if (lead.assignedAgent.last_name) {
    if (name !== '') name += ' ';
    name += lead.assignedAgent.last_name;
  }
  return name;
};

export const getLeadPaymentType = (lead: ILeadData | null) => {
  if (!lead) return '';
  switch (lead.payment_type) {
    case 0:
      return 'payment_type_one_time';
    case 1:
      return 'payment_type_time_rent';
    case 2:
      return 'payment_type_combi';
    default:
      return '';
  }
};
