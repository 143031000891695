// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// Components
import { Button } from '../../Common';
import { Stepper } from '../../Common/Stepper';
import { FormBuilder } from '../../FormBuilder';
import { CloseConfirmModal } from '../CloseConfirmModal';

// Actions
import { postUser } from '../../../redux/actions';

// Utils
import {
  buildFormInitialData,
  buildFormSchema,
  getFormikTouchedValue,
} from '../../../utils';

// Styles
import * as S from './styles';

// interfaces
import { IForm } from '../../../interfaces';
import { RootState } from '../../../redux/reducers';

interface ICreateNewClientModal {
  open: boolean;
  onClose: () => void;
}

// Create New Broker Modal
export const CreateNewBrokerModal: FC<ICreateNewClientModal> = ({
  open,
  onClose,
}) => {
  // States
  const [step, setStep] = useState(0);
  const [preStep, setPreStep] = useState(-1);
  const [visibleCloseConfirmModal, setVisibleCloseConfirmModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formikTouched, setFormikTouched] = useState<any>({});

  // Get translation from hook
  const { t } = useTranslation();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get new broker form from store
  const { newBrokerForm } = useSelector(
    ({ formReducer }: RootState) => formReducer
  );

  // Get formik from hook
  const formik = useFormik<any>({
    initialValues: buildFormInitialData(newBrokerForm),
    validationSchema: buildFormSchema(newBrokerForm),
    onSubmit: async (values) => {
      const keys = Object.keys(values);
      let formValue = {};
      for (let key of keys) {
        const objectName = newBrokerForm[key].objectName;
        if (values[key] instanceof Date) {
          values[key] = moment(values[key].toISOString()).format('YYYY-MM-DD');
        }

        if (newBrokerForm[key].type === 'array') {
          formValue = {
            ...formValue,
            [objectName]: [...(formValue[objectName] || []), values[key]],
          };
        } else if (newBrokerForm[key].type === 'object') {
          if (values[key].image && Array.isArray(values[key].image)) {
            values[key].image = values[key].image[0];
          }
          formValue = {
            ...formValue,
            [objectName]: { ...formValue[objectName], ...values[key] },
          };
        }
      }

      setIsLoading(true);
      try {
        const res: any = await dispatch(postUser(formValue['user']));
        setIsLoading(false);
        if (res.value?.success) {
          enqueueSnackbar(res.value?.message, { variant: 'success' });
          onClose();
        } else {
          enqueueSnackbar(res.value?.message, { variant: 'error' });
        }
      } catch (e: any) {
        setIsLoading(false);
        enqueueSnackbar(
          e.response?.data?.message || 'Failed to create client',
          { variant: 'error' }
        );
      }
    },
  });

  const stepLength = useMemo(
    () => newBrokerForm && Object.entries(newBrokerForm)?.length,
    [newBrokerForm]
  );
  const currentStepContent: IForm = useMemo(
    () =>
      newBrokerForm &&
      (Object.values(newBrokerForm)[preStep > -1 ? preStep : step] as IForm),
    [preStep, step, newBrokerForm]
  );
  const currentStepKey: string = useMemo(
    () =>
      newBrokerForm &&
      Object.keys(newBrokerForm)[preStep > -1 ? preStep : step],
    [preStep, step, newBrokerForm]
  );

  useEffect(() => {
    const initData = buildFormInitialData(newBrokerForm);
    setFormikTouched(getFormikTouchedValue(initData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (step > preStep && preStep > -1) {
      const previousKey = Object.keys(newBrokerForm)[preStep];
      if (formik.errors[previousKey]) {
        setStep(preStep);
      }
      setPreStep(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preStep, formik.errors, step]);

  // Next step handler
  const handleNextStep = async () => {
    if (step === stepLength - 1) {
      formik?.handleSubmit();
      return;
    } else {
      await formik?.setTouched(
        { [currentStepKey]: formikTouched[currentStepKey] },
        true
      );
      setPreStep(step);
      setStep(step + 1);
    }
  };

  // Back step handler
  const handleBackStep = () => {
    setStep(step - 1);
  };

  // Close handler
  const handleClose = () => {
    setVisibleCloseConfirmModal(true);
  };

  // Close confirm modal handler
  const handleCloseConfirmModal = () => {
    setVisibleCloseConfirmModal(false);
    onClose();
  };

  // Cancel confirm modal handler
  const handleCancelConfirmModal = () => {
    setVisibleCloseConfirmModal(false);
  };

  // Return create new broker modal
  return (
    <S.CrateNewBrokerDialog
      open={open}
      onClose={handleClose}
      title={t('new_broker.create_new_broker')}
      actions={
        <>
          <Button
            sx={{ flexGrow: { xs: 1, sm: 0 } }}
            size="large"
            onClick={handleBackStep}
            disabled={step === 0}
          >
            {t('new_broker.back')}
          </Button>
          <Button
            sx={{ ml: '16px !important', flexGrow: { xs: 1, sm: 0 } }}
            color="primary"
            size="large"
            onClick={handleNextStep}
            loading={isLoading}
          >
            {step === stepLength - 1
              ? t('new_broker.save')
              : t('new_broker.next')}
          </Button>
        </>
      }
      headerChild={
        <Stepper
          step={preStep > -1 ? preStep : step}
          onChangeStep={setStep}
          length={stepLength}
        />
      }
    >
      <Grid container columns={2} columnSpacing={42} rowSpacing={28}>
        <FormBuilder
          type={currentStepContent?.type}
          attributes={currentStepContent?.attributes}
          label={currentStepContent?.label}
          fields={currentStepContent?.fields}
          formik={formik}
          path={currentStepKey}
        />
      </Grid>

      <CloseConfirmModal
        open={visibleCloseConfirmModal}
        onClose={handleCloseConfirmModal}
        onCancel={handleCancelConfirmModal}
      />
    </S.CrateNewBrokerDialog>
  );
};
