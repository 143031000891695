import { APIS, buildURLWithParams } from '../utils/urls';
import { DELETE, GET, POST } from './axios.services';

export const getLeads = async (params) => {
  return await GET(`${APIS.lead.leads}`, params, null, true);
};

export const getLeadDetail = async (id) => {
  const res = await GET(`${APIS.lead.getLead}/${id}`, null, null, true);
  if (res.data.success) {
    return res.data.data;
  } else {
    throw res.data;
  }
};

export const deleteLead = async (id) => {
  const res = await DELETE(`${APIS.lead.leads}/${id}`, null, null, true);
  return res.data;
};

export const deleteMultipleLeads = async (ids) => {
  const res = await DELETE(APIS.lead.leads, { ids }, null, true);
  return res.data;
};

export const updateLead = async (id, data) => {
  const res = await POST(`${APIS.lead.leads}/${id}`, data, null, true);
  return res.data;
};

export const assignBroker = async (id, brokerId) => {
  const res = await POST(`${APIS.lead.leads}/${id}/assign`, { agent_id: brokerId }, null, true);
  return res.data;
};

export const exportLeads = (params) => {
  const url = buildURLWithParams(process.env.REACT_APP_API_SERVER, APIS.lead.exportLeads, params);
  window.open(url, '_blank');
};
