// Dependencies
import styled from 'styled-components';
import { Card, MenuItem as MuiMenuItem, Typography } from '@mui/material';

// Export styled components
export const TableCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: auto;
  .MuiCardContent-root {
    flex: 1;
  }
`;

export const Status = styled(Typography)<{ color: string }>`
  && {
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: ${(props) => props.color};
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    border-radius: 24px;
    padding: 12px 10px;
    height: 40px;
    white-space: nowrap;
    display: inline-block;
    text-align: center;
    min-width: 60px;
    color: ${(props) => props.theme.palette.common.white};
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  &&.Mui-selected {
    background: transparent;
  }
  &&.Mui-selected.Mui-focusVisible {
    background: transparent;
  }
`;
