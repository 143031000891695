// Dependencies
import { FC } from 'react';
import {
  ButtonProps,
  Button as MuiButton,
  CircularProgress,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsSizeOverrides } from '@mui/material/Button/Button';
import React from 'react';

// Interfaces
export interface IButtonProps extends ButtonProps {
  /**
   * The size of the component.
   * `small` is equivalent to the dense button styling.
   * @default 'small'
   */
  size?: OverridableStringUnion<
    'small' | 'medium' | 'large',
    ButtonPropsSizeOverrides
  >;
  loading?: boolean;
}

// Export Button component
export const Button: FC<IButtonProps> = React.forwardRef(
  ({ loading, children, ...props }, ref) => (
    <MuiButton {...props} ref={ref}>
      {loading ? <CircularProgress color="inherit" size={20} /> : children}
    </MuiButton>
  )
);
