// Dependencies
import { FC, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

// Interfaces
import { RootState } from '../../../redux/reducers';

interface IDetailViewLayoutProps {
  items: ReactNode;
  detail: ReactNode;
}

// Export detail view layout
export const DetailViewLayout: FC<IDetailViewLayoutProps> = ({
  items,
  detail,
}) => {
  // Get layout states from store
  const isDrawerOpened = useSelector(
    ({ uiReducer: { isDrawerOpened } }: RootState) => isDrawerOpened
  );

  // Return detail view layout
  return (
    <Stack direction="row" spacing={20}>
      <Box sx={{ width: 390 }}>{items}</Box>
      <Box sx={{ width: `calc(100vw - ${isDrawerOpened ? 738 : 570}px)` }}>
        {detail}
      </Box>
    </Stack>
  );
};
