// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// Components
import { Button, Dialog } from '../../Common';
import { FormBuilder } from '../../FormBuilder';

// Interfaces
import { RootState } from '../../../redux/store';

// Utils
import {
  buildFormInitialValues,
  buildFormSchema,
} from '../../../utils';

// Store
import { addPayout, getAddPayoutForm } from '../../../redux/actions';

// Interfaces
interface IAddPayoutModal {
  open: boolean;
  onClose: (submitted?: boolean) => void;
}

// Export add payout modal
export const AddPayoutModal: FC<IAddPayoutModal> = ({ open, onClose }) => {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Get translation from hook
  const { t } = useTranslation();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get add payout form from store
  const addPayoutForm = useSelector(
    ({ formReducer: { addPayoutForm } }: RootState) => ({ payout: addPayoutForm })
  );

  // Get project detail from store
  const projectDetail = useSelector(
    ({ projectReducer: { projectDetail } }: RootState) => projectDetail
  );

  // Create formik
  const formik = useFormik<any>({
    initialValues: { payout: buildFormInitialValues(addPayoutForm.payout) },
    validationSchema: buildFormSchema(addPayoutForm),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const res: any = await dispatch(addPayout(projectDetail?.id, values.payout));
        setIsLoading(false);

        if (res.value?.success) {
          enqueueSnackbar(res.value?.message, { variant: 'success' });
          onClose(true);
        } else {
          enqueueSnackbar(res.value?.message, { variant: 'error' });
        }
      } catch (e: any) {
        setIsLoading(false);
        enqueueSnackbar(
          e.response?.data?.message || 'Failed to create client',
          { variant: 'error' }
        );
      }
    },
  });

  // Submit handler
  const handleSubmit = () => {
    formik.handleSubmit();
  };

  // On mounted
  useEffect(() => {
    (async () => {
      await dispatch(getAddPayoutForm());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Return add payout modal
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('calculation.title')}
      actions={
        <Button loading={isLoading} color="primary" size="large" disabled={isLoading} onClick={handleSubmit}>
          {t('calculation.save')}
        </Button>
      }
    >
      <Grid container columns={1} spacing={16}>
        <FormBuilder
          type={addPayoutForm?.payout?.type}
          attributes={addPayoutForm?.payout?.attributes}
          label={addPayoutForm?.payout?.label}
          fields={addPayoutForm?.payout?.fields}
          parentFields={addPayoutForm?.payout?.fields}
          formik={formik}
          path="payout"
        />
      </Grid>
    </Dialog>
  );
};
