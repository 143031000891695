// Dependencies
import styled from 'styled-components';

// Export styled components
export const Wrap = styled.div`
  /* Wrap */
  .gm-style-iw,
  .gm-style-iw-c {
    background-color: ${(props) => props.theme.palette.darkIndigo} !important;
    color: ${(props) => props.theme.palette.common.white} !important;
    padding: 16px !important;
    margin: 0 !important;
    border-radius: 10px !important;
    box-shadow: none !important;
  }
  .gm-style-iw-t {
    bottom: 57px !important;
  }
  /* Content */
  .gm-style-iw-d {
    background-color: ${(props) => props.theme.palette.darkIndigo} !important;
    color: ${(props) => props.theme.palette.common.white} !important;
    overflow: auto !important;
  }
  /* Tringle */
  .gm-style-iw-tc::after {
    background: ${(props) => props.theme.palette.darkIndigo} !important;
  }
  /* CLose Button */
  .gm-ui-hover-effect {
    display: none !important;
  }
  /* other */
  /* Wrap other */
  .poi-info-window div,
  .poi-info-window a {
    background-color: ${(props) => props.theme.palette.darkIndigo} !important;
    width: auto !important;
    color: ${(props) => props.theme.palette.common.white} !important;
  }
  /* Header */
  .poi-info-window .title {
    ${(props) => props.theme.typography.body1}
  }
  /* Content */
  .poi-info-window .address div {
    ${(props) => props.theme.typography.caption}
  }
  /* Link */
  .poi-info-window a {
    ${(props) => props.theme.typography.caption}
  }

  // Disable text in bottom
  /* Logo */
  a[href^="http://maps.google.com/maps"]
  {
    display: none !important;
  }
  /* Logo */
  a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }
  /* Text */
  .gmnoprint a,
  .gmnoprint span,
  .gm-style-cc {
    display: none;
  }
  /* Buttons */
  /* .gmnoprint div {
        display: none !important;
    } */
`;
