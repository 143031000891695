import { APIS } from '../utils/urls';
import { GET, POST } from './axios.services';
import { buildFormRequestData } from '../utils';

export const getClients = async (params) => {
  return await GET(`${APIS.client.getClients}`, params, null, true);
};

export const getDrafts = async (params) => {
  return await GET(`${APIS.client.getDrafts}`, params, null, true);
};

export const sendNoteToCustomer = async (id, message) => {
  const res = await POST(
    `${APIS.client.getClients}/${id}/internal-messages`,
    { message },
    null,
    true
  );
  return res.data;
};

export const getNotes = async (id) => {
  const res = await GET(
    `${APIS.client.getClients}/${id}/internal-messages`,
    null,
    null,
    true
  );
  return res.data.data;
};

export const notifyAgent = async (customerId, agentId) => {
  const res = await GET(
    `${APIS.client.getClients}/${customerId}/internal-message-email/${agentId}`,
    null,
    null,
    true
  );
  return res.data.data;
};

export const getClientById = async (id: number) => {
  return await GET(`${APIS.client.getClientById}/${id}`, null, null, true);
};

export const getCreateClientForms = async () => {
  return await GET(`${APIS.client.getCreateClientForms}`, null, null, true);
};

export const createNewClient = async (formData: any) => {
  const data = buildFormRequestData({ formData });

  return await POST(`${APIS.client.createNewClient}`, data, null, true);
};

export const createDraft = async (formContent: any) => {
  return await POST(
    `${APIS.client.createDraft}`,
    {
      formContent,
    },
    null,
    true
  );
};

export const updateDraft = async (id: number, formContent: any) => {
  const res = await POST(
    `${APIS.client.updateDraft}/${id}`,
    {
      formContent,
    },
    null,
    true
  );
  return res.data;
};

export const deleteDraft = async (id) => {
  const res = await POST(`${APIS.client.deleteDraft}/${id}`, null, null, true);
  return res.data;
};

export const markCustomerDeceased = async (id: number, type: string) => {
  return await POST(
    `${APIS.client.markClientDeceased}/${id}`,
    { deceased: type },
    null,
    true
  );
};

export const getDraft = async (id) => {
  const res = await GET(`${APIS.client.getDraft}/${id}`, null, null, true);
  if (res.data.success) {
    return res.data.data;
  } else {
    throw res.data;
  }
};
