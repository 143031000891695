// Dependencies
import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Divider,
  MenuItem,
  MenuList,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

// Components
import { Icon, IconButton, Typography } from '../../../Common';

// Constants
import { Role } from '../../../../constants';

// Store
import { RootState } from '../../../../redux/reducers';

// Interfaces
import { IProjectData } from '../../../../interfaces';
import {
  getAddress,
  getProjectFilesByExtension,
  getProjectPrimaryImage,
} from '../../../../utils';
import { ConfirmModal } from '../../ConfirmModal';
import { isUserAllowed } from '../../../../utils';

interface IInfoViewProps {
  visible: boolean;
  project?: IProjectData | any;
  deleteLoading: boolean;
  onCloseInformation: () => void;
  onShowDetail: () => void;
  onPhotoClick: () => void;
  onDeleteObject: () => Promise<void>;
}

// Create Info view
const InfoView: FC<IInfoViewProps> = ({
  visible,
  deleteLoading,
  onCloseInformation,
  onShowDetail,
  onPhotoClick,
  onDeleteObject,
  project,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  // Theme
  const theme = useTheme();

  // Check platform
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  // Get user data from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  const [visibleDeleteConfirmModal, setVisibleDeleteConfirmModal] =
    useState<boolean>(false);

  const projectFiles = useMemo(() => {
    if (project) {
      return getProjectFilesByExtension(project);
    }
    return null;
  }, [project]);

  // Close Delete modal
  const handleCloseDeleteConfirmModal = () => {
    setVisibleDeleteConfirmModal(false);
  };

  // Delete click handler
  const handleDeleteObjectClick = () => {
    setVisibleDeleteConfirmModal(true);
  };

  // Delete handler
  const handleDeleteObject = () => {
    onDeleteObject().then(() => {
      setVisibleDeleteConfirmModal(false);
    });
  };

  // Return Info view
  return (
    <Box
      sx={(theme) => ({
        p: 20,
        width: { xs: '100%', lg: 310 },
        display: {
          xs: visible ? 'block' : 'none',
          lg: 'block',
        },
        borderLeft: isTablet
          ? 'none'
          : `1px solid ${theme.palette['lightCyan']}`,
      })}
    >
      <Stack
        mb={16}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ display: { xs: 'none', lg: 'flex' } }}
      >
        <Typography variant="h5">{t('chats.user_info')}</Typography>
        <IconButton color="default" onClick={onCloseInformation}>
          <Icon name="x-lg" />
        </IconButton>
      </Stack>
      <Stack mb={25} direction="row" alignItems="center" spacing={16}>
        <Avatar src={project ? getProjectPrimaryImage(project) : undefined} />
        <Typography variant="subtitle1">
          {project?.customer?.first_name} {project?.customer?.last_name}
        </Typography>
      </Stack>
      <PerfectScrollbar
        style={{
          height: isTablet ? 'calc(100vh - 251px)' : 'fit-content',
        }}
      >
        <MenuList>
          <MenuItem sx={{ height: 60, borderRadius: 2 }}>
            <Box display="flex">
              <Icon color={theme.palette['lightIndigo']} name="location" />
            </Box>
            <Typography ml={12} whiteSpace="break-spaces">
              {project && getAddress(project)}
            </Typography>
          </MenuItem>
          <MenuItem sx={{ height: 60, borderRadius: 2 }}>
            <Box display="flex">
              <Icon color={theme.palette['lightIndigo']} name="phone" />
            </Box>
            <Typography ml={12}>{project?.customer?.phone}</Typography>
          </MenuItem>
          <Divider />
          <MenuItem sx={{ height: 60, borderRadius: 2 }}>
            <Box display="flex">
              <Icon color={theme.palette['lightIndigo']} name="document" />
            </Box>
            <Typography ml={12}>
              {projectFiles?.documents?.length} {t('chats.documents')}
            </Typography>
          </MenuItem>
          <MenuItem sx={{ height: 60, borderRadius: 2 }} onClick={onPhotoClick}>
            <Box display="flex">
              <Icon color={theme.palette['lightIndigo']} name="image-fill" />
            </Box>
            <Typography ml={12}>
              {project?.property?.property_images?.files?.length}{' '}
              {t('chats.photos')}
            </Typography>
          </MenuItem>
          <MenuItem sx={{ height: 60, borderRadius: 2 }}>
            <Box display="flex">
              <Icon color={theme.palette['lightIndigo']} name="camera" />
            </Box>
            <Typography ml={12}>
              {projectFiles?.videos?.length} {t('chats.videos')}
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem sx={{ height: 60, borderRadius: 2 }} onClick={onShowDetail}>
            <Box display="flex">
              <Icon color={theme.palette['lightIndigo']} name="eye" />
            </Box>
            <Typography ml={12}>{t('chats.show_object_details')}</Typography>
          </MenuItem>
          {isUserAllowed(account, Role.ROLE_ADMIN) && (
            <MenuItem
              sx={{ height: 60, borderRadius: 2 }}
              onClick={handleDeleteObjectClick}
            >
              <Box display="flex">
                <Icon name="document" color={theme.palette.error.main} />
              </Box>
              <Typography ml={12} color={theme.palette.error.main}>
                {t('chats.delete_client')}
              </Typography>
            </MenuItem>
          )}
        </MenuList>
      </PerfectScrollbar>

      <ConfirmModal
        open={visibleDeleteConfirmModal}
        onClose={handleCloseDeleteConfirmModal}
        onOk={handleDeleteObject}
        loading={deleteLoading}
      />
    </Box>
  );
};

// Export Info view
export default InfoView;
