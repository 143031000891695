// Dependencies
import styled from 'styled-components';
import { Box } from '@mui/material';

// Export styled components
export const CarouselWrapper = styled(Box)`
  .owl-stage-outer {
    min-height: 190px !important;
    margin: 0 30px;
    ${(props) => props.theme.breakpoints.down('md')} {
      margin: 0 15px;
      .last-chat-item {
        width: calc(100% - 20px);
      }
    }
  }

  .owl-dots {
    border-top: 1px solid ${props => props.theme.palette.lightCyan};
    margin-top: 0 !important;
    padding-top: 4px;
    
    .owl-dot {
      span {
        width: 8px !important;
        height: 8px !important;
        background-color: ${(props) => props.theme.palette.cyan} !important;
      }

      &.active {
        span {
          background-color: ${(props) =>
            props.theme.palette.lightIndigo} !important;
        }
      }
    }
  }
`;
