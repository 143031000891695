import React, { FC, useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import * as S from './styles';
import { Button, Typography } from '../../components';
import { login } from '../../redux/actions';
import * as Yup from 'yup';
import { RootState } from '../../redux/reducers';
import { ROUTES } from '../../constants';
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '../../assets/icons';
import ValidateCode from '../../components/ValidateCode/ValidateCodeComponent';
import { getFeatureActive } from '../../services/auth.service';

export const LoginPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const loginLoading = useSelector(
    ({ authReducer: { loginLoading } }: RootState) => loginLoading
  );

  const [validateCode, setValidateCode] = useState(false);
  const [feature, setFeature] = useState(false);
  const [tries, setTries] = useState(0);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getFeatureActive('2fa').then((res) => {
      setFeature(res.data?.data?.feature_active);
    });
  }, []);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('validation.email_invalid'))
      .max(80, t('validation.max_length'))
      .required(t('validation.email_required')),
    plainPassword: Yup.string().required(t('validation.password_required')),
    // authCode: Yup.string().required(t('validation.auth_code_required')),
  });

  const formik = useFormik<any>({
    initialValues: {
      email: '',
      plainPassword: '',
      authCode: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      if (loginLoading) {
        return;
      }

      try {
        const res: any = await dispatch(login(values));

        if (res.value.success && res.value.message) {
          if (
            values.authCode === '' &&
            feature &&
            res?.value?.message === 'Authentifizierungscode gesendet'
          )
            setValidateCode(true);

          enqueueSnackbar(res?.value?.message, { variant: 'success' });
        } else {
          validateCode && setTries(tries + 1);
          enqueueSnackbar(res.value.message, { variant: 'error' });
        }
      } catch (e: any) {
        validateCode && setTries(tries + 1);
        enqueueSnackbar(e.response?.data?.message || 'Failed to log in', {
          variant: 'error',
        });
      }
    },
  });

  useEffect(() => {
    if (tries >= 3) {
      setDisabled(true);
    }
  }, [tries]);

  return (
    <S.Container>
      <S.Logo src="/assets/images/logo.svg" alt="logo" />

      {!validateCode ? (
        <form onSubmit={formik.handleSubmit}>
          <S.Card>
            <S.Title mb={24}>
              <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
                {t('login.sign_in')}
              </Typography>
            </S.Title>

            <S.FormWrapper mb={24}>
              <S.FormInput
                error={!!(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                placeholder={t('login.email')}
                {...formik.getFieldProps('email')}
              />
            </S.FormWrapper>
            <S.FormWrapper>
              <S.FormInput
                error={
                  !!(
                    formik.touched.plainPassword && formik.errors.plainPassword
                  )
                }
                helperText={
                  formik.touched.plainPassword && formik.errors.plainPassword
                }
                placeholder={t('login.password')}
                type={!showPassword ? 'password' : 'text'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps('plainPassword')}
              />
            </S.FormWrapper>
            <Box
              component={Link}
              mb={16}
              textAlign="right"
              width="100%"
              to={ROUTES.FORGOT_PASSWORD}
              color="primary.main"
              sx={{ textDecoration: 'none' }}
            >
              <Typography variant="body2">
                {t('login.forgot_password')}
              </Typography>
            </Box>

            <Box sx={{ width: { sm: 'auto', xs: '100%' } }} mt={16}>
              <Button
                fullWidth
                size="large"
                color="primary"
                type="submit"
                loading={loginLoading}
              >
                {t('login.submit')}
              </Button>
            </Box>
          </S.Card>
        </form>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <ValidateCode
            formik={formik}
            disabled={disabled}
            setDisabled={setDisabled}
            setTries={setTries}
          />
        </form>
      )}

      {!validateCode ? (
        <Box
          sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
          display="flex"
          mt={16}
        >
          <Typography variant="body1" mr={8}>
            {t('login.question')}
          </Typography>
          <Box component={Link} to={ROUTES.REGISTER} color="primary.main">
            <Typography variant="h4">{t('login.sign_up')}</Typography>
          </Box>
        </Box>
      ) : null}
    </S.Container>
  );
};
