// Dependencies
import styled from 'styled-components';
import { Box, Stack, IconButton } from '@mui/material';

// Export styled components
export const ExpandButton = styled(IconButton)`
  && {
    display: none;
    border: 1px solid ${(props) => props.theme.palette.blackCyan};

    ${(props) => props.theme.breakpoints.down('sm')} {
      display: inline-flex;
    }
  }
`;


// Export styled components
export const Values = styled(Stack)`
  && {
    flex-wrap: wrap;

    & > :not(style) + :not(style) {
      margin-left: ${(props) => props.theme.spacing(50)};

      ${(props) => props.theme.breakpoints.down(1900)} {
        margin-left: ${(props) => props.theme.spacing(40)};
      }

      ${(props) => props.theme.breakpoints.down(1800)} {
        margin-left: ${(props) => props.theme.spacing(20)};
      }

      ${(props) => props.theme.breakpoints.down(1700)} {
        margin-left: ${(props) => props.theme.spacing(10)};
      }

      ${(props) => props.theme.breakpoints.down('xl')} {
        margin-left: ${(props) => props.theme.spacing(0)};
      }
    }

    .MuiDivider-root {
      ${(props) => props.theme.breakpoints.down('xl')} {
        display: none;
      }
    }
  }
`;

export const Item = styled(Stack)`
  ${(props) => props.theme.breakpoints.down('xl')} {
    width: 50%;
    border-bottom: 1px solid ${(props) => props.theme.palette.lightCyan};
    padding: ${(props) => props.theme.spacing(16)};

    &:nth-of-type(2n + 1) {
      border-right: 1px solid ${(props) => props.theme.palette.lightCyan};
    }

    &:last-of-type,
    &:nth-of-type(3) {
      border-bottom: none;
    }
  }
`;

export const Card = styled(Box)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme.palette.lightCyan};
    border-radius: 8px;
    flex: 1;
    margin: ${(props) => props.theme.spacing(-10, 0, -10, 40)};

    svg {
      width: 24px;
      color: ${(props) => props.theme.palette.cyan};
      margin-bottom: ${(props) => props.theme.spacing(12)};
    }

    ${(props) => props.theme.breakpoints.down('xl')} {
      margin: ${(props) => props.theme.spacing(40, 0, 0, 0)};
      border: none;
      flex-direction: row;
      justify-content: space-between;
      border-top: 1px solid ${(props) => props.theme.palette.cyan};
      border-radius: 0;

      svg {
        display: none;
      }

      .MuiTypography-body2 {
        display: none;
      }

      .MuiTypography-h5 {
        ${(props) => props.theme.typography.h4};
        color: ${(props) => props.theme.palette.lightIndigo};
      }
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
      margin: ${(props) => props.theme.spacing(20, 0, 0, 0)};
      padding-top: ${(props) => props.theme.spacing(14)};
    }
  }
`;
