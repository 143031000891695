// Dependencies
import styled from 'styled-components';
import { Box, Divider } from '@mui/material';

export const Table = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled(Box)<{
  $isList?: boolean;
  $contentSize?: { width?: string | number; height?: string | number };
}>`
  overflow-y: auto;
  width: ${(props) => props.$contentSize?.width || 'auto'};
  max-height: ${(props) => props.$contentSize?.height || 'auto'};
  padding: ${(props) =>
    props.$isList ? props.theme.spacing(0) : props.theme.spacing(0, 20)};

  .MuiTableBody-root {
    border: none;

    .MuiTableRow-root {
      &:last-of-type {
        .MuiTableCell-root {
          border-bottom: none;
        }
      }
    }
  }

  .MuiTableCell-root {
    padding: ${(props) =>
      props.$isList ? props.theme.spacing(8, 16) : props.theme.spacing(8, 12)};

    &.MuiTableCell-head {
      padding: ${(props) => props.theme.spacing(18, 12)};
      border-bottom: none;
    }

    .MuiTypography-subtitle1 {
      white-space: nowrap;
      max-width: ${(props) => (props.$isList ? '186px' : 'auto')};
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .MuiTypography-body2 {
      white-space: nowrap;
      max-width: ${(props) => (props.$isList ? '186px' : 'auto')};
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  ::-webkit-scrollbar-track {
    margin-top: ${(props) =>
      props.$isList ? props.theme.spacing(0) : props.theme.spacing(72)};
    margin-bottom: 8px;
  }
`;

export const HeaderDivider = styled(Divider)`
  position: absolute;
  top: ${(props) => props.theme.spacing(72)};
  width: 100%;
  z-index: 99;
  border-color: ${(props) => props.theme.palette.lightCyan} !important;
`;
