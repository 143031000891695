import { GET, POST } from './axios.services';
import { APIS } from '../utils/urls';
import { buildFormRequestData } from '../utils';

export const getTasks = async () => {
  return await GET(`${APIS.task.tasks}`, null, null, true);
};

export const updateTaskGroup = async (projectId, taskGroup) => {
  const data = buildFormRequestData({ taskGroup });
  return await POST(
    APIS.task.updateTaskGroup.replace(':projectId', projectId),
    data,
    null,
    true
  );
};

export const updateTask = async (projectId, taskId, task) => {
  const data = buildFormRequestData({ task });
  return await POST(
    APIS.task.updateTask
      .replace(':projectId', projectId)
      .replace(':taskId', taskId),
    data,
    null,
    true
  );
};

export const updateProjectTask = async (projectId, task) => {
  return await POST(
    APIS.task.updateTask
      .replace(':projectId', projectId)
      .replace(':taskId', task.id),
    task,
    null,
    true
  );
};

export const setProjectTask = async (projectId, task) => {
  return await POST(
    APIS.task.setTask
      .replace(':projectId', projectId)
      .replace(':taskId', task.id),
    task,
    null,
    true
  );
};
