// Dependencies
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { debounce } from 'lodash';

// Components
import {
  Button,
  DataTable,
  ConfirmModal,
  DetailViewLayout,
  FormPanel,
  FormType,
  IColumn,
  Icon,
  IconButton,
  Input,
  ProjectPanel,
  Typography,
} from '../../components';

// Styles
import * as S from './styles';

// Store
import { RootState } from '../../redux/reducers';
import {
  getProfileForm,
  getUserDetail,
  getUsers,
  updateUser,
} from '../../redux/actions';

// Global constants
import { Role, ROUTES } from '../../constants';

// Interfaces
import { ISortBy, IUserData, Order } from '../../interfaces';
import { deleteUser } from '../../services/user.service';
import { useSnackbar } from 'notistack';
import { isUserAllowed } from '../../utils';
import { LeadPanel } from '../../components/Panels/LeadPanel';

export interface IForm {
  handleSubmit: () => void;
}

// Constants
enum TabPanels {
  Leads = 'leads',
  Detected = 'new',
  Submitted = 'inProgress',
  Rejected = 'declined',
  Bought = 'bought',
  Sold = 'sold',
  Profile = 'profile',
}

// Export BrokersDetail page
export const BrokersDetailPage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  // Get translation from hook
  const { t } = useTranslation();

  // States
  const [activeTab, setActiveTab] = React.useState<TabPanels>(
    TabPanels.Profile
  );
  const [form, setForm] = useState<IForm | null>(null);
  const [brokers, setBrokers] = useState<IUserData[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<ISortBy>({
    field: 'date-created',
    order: Order.Desc,
  });
  const [visibleDeleteConfirmModal, setVisibleDeleteConfirmModal] =
    useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Get state from hook
  const { state } = useLocation();

  // Get params from hook
  const params = useParams();

  // Get user data from store
  const { users, userDetail, loading, detailLoading, pagination } = useSelector(
    ({ userReducer }: RootState) => userReducer
  );

  // Get form data from store
  const { profileForm, loading: formLoading } = useSelector(
    ({ formReducer }: RootState) => formReducer
  );

  // Theme
  const theme = useTheme();

  // Check platform
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // File selector
  const fileSelector = useRef<any>();

  // Tab change handler
  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: TabPanels
  ) => {
    setActiveTab(newValue);
  };

  // Get navigate from hook
  const navigate = useNavigate();

  // Go back handler
  const handleGoBack = () => {
    navigate(ROUTES.BROKERS.INDEX);
  };

  // Save handler
  const handleSave = () => {
    if (form) form.handleSubmit();
  };

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSortBy(value);
  };

  // Row click handler
  const handleRowClick = (id) => {
    navigate(ROUTES.BROKERS.DETAIL.replace(':id', id));
  };

  // Page change handler
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
    setPage(1);
  };

  // Refetch handler
  const handleRefetch = () => {
    if (params.id) {
      dispatch(getUserDetail(+params.id));
    }
  };

  // Fetch list
  const handleFetchList = () => {
    dispatch(
      getUsers({
        page,
        perPage: rowsPerPage,
        sort: sortBy.field,
        'sort-order': sortBy.order,
        search: debouncedSearch,
      })
    );
  };

  // Open delete confirm modal handler
  const handleOpenDeleteConfirmModal = () => {
    setVisibleDeleteConfirmModal(true);
  };

  // Close delete confirm modal handler
  const handleCloseDeleteConfirmModal = () => {
    setVisibleDeleteConfirmModal(false);
  };

  // Delete broker handler
  const handleDeleteBroker = () => {
    setDeleteLoading(true);
    deleteUser(userDetail?.id)
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          navigate(ROUTES.BROKERS.INDEX);
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' });
        }
        setDeleteLoading(false);
      })
      .catch((e: any) => {
        enqueueSnackbar(e?.response?.data?.message || 'Failed to delete', {
          variant: 'error',
        });
        setDeleteLoading(false);
      });
  };

  // Change search handler
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  // Open explorer to update image
  const handleUpdateImage = () => {
    fileSelector.current.click();
  };

  // Update profile image
  const handleUpdateProfileImage = async (e) => {
    const { files: uploadedFiles } = e.target;
    if (userDetail) {
      try {
        const res: any = await dispatch(
          updateUser(userDetail.id, { image: uploadedFiles[0] })
        );
        if (res?.value?.success && res?.value?.message) {
          handleRefetch();

          if (users.some((user) => userDetail.id === user.id)) {
            handleFetchList();
          }

          enqueueSnackbar(res.value?.message, { variant: 'success' });
        } else {
          enqueueSnackbar(res.value?.message, { variant: 'error' });
        }
      } catch (e: any) {
        enqueueSnackbar(
          e?.response?.data?.message || 'Failed to update image',
          { variant: 'error' }
        );
      }
    }
  };

  // Columns
  const columns: IColumn[] = [
    {
      field: 'photo',
      label: 'Photo',
      sortable: false,
      width: 56,
      render: (row: IUserData) => (
        <Avatar
          src={row?.image?.path}
          variant="square"
          sx={{ width: 40, height: 40 }}
        >
          <Icon name="user" size={16} />
        </Avatar>
      ),
    },
    {
      field: 'name',
      label: 'Name and Company',
      render: (row) => (
        <>
          <Typography variant="body2" fontWeight={500}>
            {row.first_name} {row.last_name}
          </Typography>
          <Typography variant="body2" fontWeight={400}>
            {row.company}
          </Typography>
        </>
      ),
    },
  ];

  // Delayed search handler
  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  // Debounce search hook
  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, delayedChangeSearch]);

  // On users changed
  useEffect(() => {
    if (users) {
      setBrokers(users);
    }
  }, [users]);

  // On pagination changed
  useEffect(() => {
    if (pagination?.total || pagination?.total === 0) {
      setTotalPage(Math.ceil(pagination?.total / rowsPerPage));
    }
  }, [pagination, rowsPerPage]);

  // On state of location changed
  useEffect(() => {
    if (state) {
      const { page, rowsPerPage } = state as any;
      if (page) {
        setPage(page);
      }
      if (rowsPerPage) {
        setRowsPerPage(rowsPerPage);
      }
    }
  }, [state]);

  // On page, sortBy and rowsPerPage changed
  useEffect(() => {
    handleFetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, rowsPerPage, debouncedSearch]);

  // On params changed
  useEffect(() => {
    if (params?.id) {
      dispatch(getUserDetail(+params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  // On mounted
  useEffect(() => {
    dispatch(getProfileForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Return BrokersDetail page

  return (
    <>
      {isDesktop ? (
        <DetailViewLayout
          items={
            <Card>
              <CardHeader
                sx={(theme) => ({
                  pt: 25,
                  pb: 22,
                  borderBottom: `1px solid ${theme.palette['lightCyan']}`,
                })}
                title={
                  <Stack direction="row" spacing={8}>
                    <IconButton onClick={handleGoBack}>
                      <Icon name="arrow-back" />
                    </IconButton>
                    <Input
                      fullWidth
                      value={search}
                      disabled={loading}
                      startAdornment={<Icon name="search" />}
                      placeholder={t('brokers_detail.search')}
                      onChange={handleChangeSearch}
                    />
                  </Stack>
                }
              />
              <DataTable
                isList
                paginated
                page={page}
                stickyHeader
                data={brokers}
                columns={columns}
                isLoading={loading}
                totalPage={totalPage}
                rowsPerPage={rowsPerPage}
                selectedId={Number(params?.id)}
                contentSize={{
                  height: 'calc(100vh - 321px)',
                }}
                onSort={handleSort}
                onRowClick={handleRowClick}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Card>
          }
          detail={
            <Card>
              <CardHeader
                sx={{ pb: 24 }}
                title={
                  <Stack direction="row" spacing={16} alignItems="flex-start">
                    <Badge
                      sx={{
                        '.MuiBadge-badge': {
                          padding: 8,
                          height: 'initial',
                          transform: 'initial',
                        },
                      }}
                      badgeContent={
                        !detailLoading && (
                          <>
                            <IconButton onClick={handleUpdateImage}>
                              <Icon name="pencil" />
                            </IconButton>
                            <input
                              style={{ display: 'none' }}
                              type="file"
                              ref={fileSelector}
                              onChange={handleUpdateProfileImage}
                              multiple
                            />
                          </>
                        )
                      }
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      {detailLoading ? (
                        <Skeleton
                          variant="rectangular"
                          width={132}
                          height={132}
                        />
                      ) : (
                        <Avatar
                          src={userDetail?.image?.path || ''}
                          sx={{ width: 132, height: 132 }}
                        >
                          <Icon name="image-fill" />
                        </Avatar>
                      )}
                    </Badge>
                    <Stack spacing={20}>
                      <Stack spacing={4}>
                        <Typography variant="h3" height={32} overflow="hidden">
                          {detailLoading ? (
                            <Skeleton />
                          ) : (
                            <>
                              {userDetail?.first_name} {userDetail?.last_name}
                            </>
                          )}
                        </Typography>
                        <Typography height={24} overflow="hidden">
                          {detailLoading ? <Skeleton /> : userDetail?.company}
                        </Typography>
                        <Typography height={24} overflow="hidden">
                          {detailLoading ? (
                            <Skeleton />
                          ) : (
                            <>
                              {userDetail?.address?.street},{' '}
                              {userDetail?.address?.zip},{' '}
                              {userDetail?.address?.city}
                            </>
                          )}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="body2"
                        color="lightIndigo"
                        height={24}
                        overflow="hidden"
                      >
                        {detailLoading ? (
                          <Skeleton width={200} />
                        ) : (
                          <>
                            {t('brokers_detail.last_active')}:{' '}
                            {moment(userDetail?.last_login).format(
                              'DD.MM.YYYY'
                            )}
                          </>
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                }
                action={
                  <Stack spacing={60} alignItems="flex-end">
                    <IconButton
                      disabled={detailLoading || deleteLoading}
                      onClick={handleOpenDeleteConfirmModal}
                    >
                      <Icon name="trash" />
                    </IconButton>
                    {form !== null && (
                      <Button
                        color="primary"
                        disabled={detailLoading || formLoading}
                        onClick={handleSave}
                      >
                        {t('brokers_detail.save')}
                      </Button>
                    )}
                  </Stack>
                }
              />
              <CardContent sx={{ p: 20, height: '100%' }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  variant="scrollable"
                >
                  <Tab
                    value={TabPanels.Leads}
                    label={t('brokers_detail.leads')}
                  />
                  <Tab
                    value={TabPanels.Detected}
                    label={t('brokers_detail.detected')}
                  />
                  <Tab
                    value={TabPanels.Submitted}
                    label={t('brokers_detail.submitted')}
                  />
                  <Tab
                    value={TabPanels.Rejected}
                    label={t('brokers_detail.rejected')}
                  />
                  <Tab
                    value={TabPanels.Bought}
                    label={t('brokers_detail.bought')}
                  />
                  <Tab
                    value={TabPanels.Sold}
                    label={t('brokers_detail.sold')}
                  />
                  <Tab
                    value={TabPanels.Profile}
                    label={t('brokers_detail.profile')}
                  />
                </Tabs>
                <S.TabPanel>
                  {activeTab === TabPanels.Leads && (
                    <LeadPanel brokerId={params.id} status={TabPanels.Leads} />
                  )}
                  {activeTab === TabPanels.Detected && (
                    <ProjectPanel
                      brokerId={params.id}
                      status={TabPanels.Detected}
                    />
                  )}
                  {activeTab === TabPanels.Submitted && (
                    <ProjectPanel
                      brokerId={params.id}
                      status={TabPanels.Submitted}
                    />
                  )}
                  {activeTab === TabPanels.Rejected && (
                    <ProjectPanel
                      brokerId={params.id}
                      status={TabPanels.Rejected}
                    />
                  )}
                  {activeTab === TabPanels.Bought && (
                    <ProjectPanel
                      brokerId={params.id}
                      status={TabPanels.Bought}
                    />
                  )}
                  {activeTab === TabPanels.Sold && (
                    <ProjectPanel
                      brokerId={params.id}
                      status={TabPanels.Sold}
                    />
                  )}
                  {activeTab === TabPanels.Profile && (
                    <FormPanel
                      isLoading={formLoading || detailLoading}
                      setForm={setForm}
                      formData={profileForm}
                      formType={FormType.User}
                      disabled={!isUserAllowed(account, Role.ROLE_ADMIN)}
                      initialFormData={userDetail}
                      disableSelfRoleChange={Boolean(
                        account?.id === userDetail?.id
                      )}
                      refetchFormData={handleRefetch}
                    />
                  )}
                </S.TabPanel>
              </CardContent>
            </Card>
          }
        />
      ) : (
        <Card>
          <CardHeader
            sx={{ px: 16, pb: 4, pt: { xs: 18, sm: 20 } }}
            title={
              <IconButton onClick={handleGoBack}>
                <Icon name="arrow-back" />
              </IconButton>
            }
            action={
              <Stack direction="row" spacing={8}>
                <IconButton
                  disabled={detailLoading || deleteLoading}
                  onClick={handleOpenDeleteConfirmModal}
                >
                  <Icon name="trash" />
                </IconButton>
                {form !== null && (
                  <Button
                    color="primary"
                    disabled={detailLoading}
                    onClick={handleSave}
                  >
                    {t('brokers_detail.save')}
                  </Button>
                )}
              </Stack>
            }
          />
          <CardContent>
            <Stack mb={24} direction="row" spacing={8} alignItems="flex-start">
              <Badge
                sx={{
                  '.MuiBadge-badge': {
                    padding: { xs: 4, sm: 8 },
                    height: 'initial',
                    transform: 'initial',
                  },
                }}
                badgeContent={
                  !detailLoading && (
                    <>
                      <IconButton onClick={handleUpdateImage}>
                        <Icon name="pencil" />
                      </IconButton>
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        ref={fileSelector}
                        onChange={handleUpdateProfileImage}
                        multiple
                      />
                    </>
                  )
                }
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                {detailLoading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: { xs: 78, sm: 132 },
                      height: { xs: 78, sm: 132 },
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      width: { xs: 78, sm: 132 },
                      height: { xs: 78, sm: 132 },
                    }}
                  >
                    <Icon name="image-fill" />
                  </Avatar>
                )}
              </Badge>
              <Box>
                <Typography mb={2} variant="h4" height={28} overflow="hidden">
                  {detailLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {userDetail?.last_name} {userDetail?.first_name}
                    </>
                  )}
                </Typography>
                <Typography height={24} overflow="hidden">
                  {detailLoading ? <Skeleton /> : userDetail?.company}
                </Typography>
                <Typography height={24} overflow="hidden">
                  {detailLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {userDetail?.address?.street}, {userDetail?.address?.zip},{' '}
                      {userDetail?.address?.city}
                    </>
                  )}
                </Typography>
                <Typography
                  mt={8}
                  variant="body2"
                  color="lightIndigo"
                  height={24}
                  overflow="hidden"
                >
                  {detailLoading ? (
                    <Skeleton width={200} />
                  ) : (
                    <>
                      {t('brokers_detail.last_active')}:{' '}
                      {moment(userDetail?.last_login).format('DD.MM.YYYY')}
                    </>
                  )}
                </Typography>
              </Box>
            </Stack>
            <Tabs
              sx={{
                width: {
                  xs: 'calc(100vw - 64px)',
                  md: 'calc(100vw - 360px)',
                },
              }}
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons
            >
              <Tab value={TabPanels.Leads} label={t('brokers_detail.leads')} />
              <Tab
                value={TabPanels.Detected}
                label={t('brokers_detail.detected')}
              />
              <Tab
                value={TabPanels.Submitted}
                label={t('brokers_detail.submitted')}
              />
              <Tab
                value={TabPanels.Rejected}
                label={t('brokers_detail.rejected')}
              />
              <Tab
                value={TabPanels.Bought}
                label={t('brokers_detail.bought')}
              />
              <Tab value={TabPanels.Sold} label={t('brokers_detail.sold')} />
              <Tab
                value={TabPanels.Profile}
                label={t('brokers_detail.profile')}
              />
            </Tabs>
            <S.TabPanel>
              {activeTab === TabPanels.Leads && (
                <LeadPanel brokerId={params.id} status={TabPanels.Leads} />
              )}
              {activeTab === TabPanels.Detected && (
                <ProjectPanel
                  brokerId={params.id}
                  status={TabPanels.Detected}
                />
              )}
              {activeTab === TabPanels.Submitted && (
                <ProjectPanel
                  brokerId={params.id}
                  status={TabPanels.Submitted}
                />
              )}
              {activeTab === TabPanels.Rejected && (
                <ProjectPanel
                  brokerId={params.id}
                  status={TabPanels.Rejected}
                />
              )}
              {activeTab === TabPanels.Bought && (
                <ProjectPanel brokerId={params.id} status={TabPanels.Bought} />
              )}
              {activeTab === TabPanels.Sold && (
                <ProjectPanel brokerId={params.id} status={TabPanels.Sold} />
              )}
              {activeTab === TabPanels.Profile && (
                <FormPanel
                  isLoading={formLoading || detailLoading}
                  setForm={setForm}
                  formData={profileForm}
                  formType={FormType.User}
                  disabled={!isUserAllowed(account, Role.ROLE_ADMIN)}
                  initialFormData={userDetail}
                  refetchFormData={handleRefetch}
                  disableSelfRoleChange={Boolean(
                    account?.id === userDetail?.id
                  )}
                />
              )}
            </S.TabPanel>
          </CardContent>
        </Card>
      )}
      <ConfirmModal
        open={visibleDeleteConfirmModal}
        onClose={handleCloseDeleteConfirmModal}
        onOk={handleDeleteBroker}
      />
    </>
  );
};
