// Dependencies
import styled from 'styled-components';
import { Box, Drawer, Popover as MuiPopover } from '@mui/material';
import { IconButton, Input } from '../../Common';

// Export styled component
export const Header = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
  background-color: ${(props) => props.theme.palette.common.white};
  padding: 20px 36px;
  z-index: ${(props) => props.theme.zIndex.appBar};

  @media only screen and (max-width: 991px) {
    padding: 16px;
  }
`;

export const DesktopHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileHeader = styled(Box)`
  z-index: 1201 !important;
`;

export const SearchInput = styled(Input)`
  background-image: linear-gradient(to right, #f7f9fb, #ebf0f5);
`;

export const Brand = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  margin-right: auto;
`;

export const Logo = styled.img`
  margin-left: 12px;
  height: 36px;

  ${(props) => props.theme.breakpoints.down('md')} {
    height: 30px;
  }
`;

export const MobileDrawer = styled(Drawer)`
  &.MuiModal-root {
    z-index: 1000;
    top: 68px;
    .MuiBackdrop-root {
      top: 68px;
    }

    > .MuiPaper-root {
      top: 68px;
      border-radius: 0;
      height: calc(100vh - 68px);
      padding: 20px 16px;
    }
  }
`;

export const DarkIconButton = styled(IconButton)`
  background-color: ${(props) => props.theme.palette.darkIndigo} !important;
  color: #fff !important;
`;

export const Popover = styled(MuiPopover)`
  && {
    .MuiPopover-paper {
      margin-top: 36px;
      margin-left: 80px;
      max-width: 343px;
      padding: 20px 16px;
      color: ${(props) => props.theme.palette.common.white};
      background-color: ${(props) => props.theme.palette.primary.main};
      position: relative;
      overflow: visible;

      &::after {
        display: block;
        content: '';
        background-color: ${(props) => props.theme.palette.primary.main};
        width: 18px;
        height: 18px;
        position: absolute;
        top: -8px;
        right: 96px;
        transform: rotate(45deg);
      }
    }
  }
`;
