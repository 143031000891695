import { Fragment } from "react";

export const getTabLabel = (isObjectRoute, label, char) => {
  return (
    <>
      {label.split(char).map((l, index) => (
        isObjectRoute ? (
          <Fragment key={`tab-label-${index}`}>
            {l}{index !== label.split(char).length - 1 && char}
            {index !== label.split(char).length - 1 && <br />}
          </Fragment>
        ) : (
          <Fragment key={`tab-label-${index}`}>
            {l}{index !== label.split(char).length - 1 && char.replace('-', '')}
          </Fragment>
        )
      ))}
    </>
  );
};
