// Dependencies
import { IconButton, Stack, styled } from '@mui/material';

// Export styled components
export const ExpandButton = styled(IconButton)`
  && {
    display: none;
    border: 1px solid ${(props) => props.theme.palette.blackCyan};

    ${(props) => props.theme.breakpoints.down('sm')} {
      display: inline-flex;
    }
  }
`;

export const Data = styled(Stack)`
  && {
    flex-direction: row;
    flex-wrap: wrap;

    & > :not(style) + :not(style) {
      margin-left: ${(props) => props.theme.spacing(40)};

      ${(props) => props.theme.breakpoints.down(1900)} {
        margin-left: ${(props) => props.theme.spacing(30)};
      }

      ${(props) => props.theme.breakpoints.down(1800)} {
        margin-left: ${(props) => props.theme.spacing(20)};
      }

      ${(props) => props.theme.breakpoints.down(1700)} {
        margin-left: ${(props) => props.theme.spacing(10)};
      }

      ${(props) => props.theme.breakpoints.down('xl')} {
        margin-left: ${(props) => props.theme.spacing(0)};
      }
    }

    .MuiDivider-root {
      ${(props) => props.theme.breakpoints.down('xl')} {
        display: none;
      }
    }
  }
`;

export const Item = styled(Stack)`
  && {
    ${(props) => props.theme.breakpoints.down('xl')} {
      width: 50%;
      align-items: center;
      border-bottom: 1px solid ${(props) => props.theme.palette.lightCyan};
      padding: ${(props) => props.theme.spacing(16)};

      &:last-of-type {
        width: 100%;
        border-right: none;
        border-bottom: none;
        padding-bottom: ${(props) => props.theme.spacing(0)};
      }

      &:nth-of-type(2n + 1) {
        border-right: 1px solid ${(props) => props.theme.palette.lightCyan};
      }

      .MuiTypography-body2 {
        text-align: center;
      }
    }
  }
`;
