// Dependencies
import {
  Avatar,
  Box,
  Button,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Components
import {
  AssignBrokerModal,
  ChatsModal,
  CloseConfirmModal,
  ConfirmModal,
  DeseacedModal,
  EmergencyContactPersonModal,
  Icon,
  IconButton,
  ObjectIdModal,
  StateMessageModal,
} from '../../../../../components';

// Utils
import {
  getFullName,
  getMissingFileFields,
  getProjectChangedDate,
  getProjectCreatedDate,
  isUserAllowed,
  isUserAllowedForRoles,
} from '../../../../../utils';

// Icons
import { CircleXMarkIcon, SaveIcon } from '../../../../../assets/icons';

// Store
import { getProjectDetail } from '../../../../../redux/actions';
import { RootState } from '../../../../../redux/reducers';

// Constants
import {
  ROUTES,
  Role,
  STATUS_ACTIONS,
  Status,
  isStatusAllowed,
} from '../../../../../constants';

// Services
import { markCustomerDeceased } from '../../../../../services/client.service';
import {
  assignBroker,
  deleteProject,
  updateWorkflow,
} from '../../../../../services/project.service';
import { IAvailableStates } from '../../../../../interfaces';

// Interfaces
interface IActionsProps {
  form: any;
  fileUploading: boolean;
  gotoFileTab: () => void;
}

// Create actions
const Actions: FC<IActionsProps> = ({ form, gotoFileTab, fileUploading }) => {
  // States
  const [visibleAssignBrokerModal, setVisibleAssignBrokerModal] =
    useState<boolean>(false);
  const [visibleChatsModal, setVisibleChatsModal] = useState<boolean>(false);
  const [visibleDeleteConfirmModal, setVisibleDeleteConfirmModal] =
    useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [workFlowState, setWorkFlowState] = useState<any | null>(null);
  const [userEl, setUserEl] = useState<null | HTMLElement>(null);
  const [updateStateLoading, setUpdateStateLoading] = useState<boolean>(false);
  const [missingFilesModal, setMissingFilesModal] = useState(false);
  const [visibleObjectIdModal, setVisibleObjectIdModal] = useState(false);
  const [isEmergencyContactOpened, setIsEmergencyContactOpened] =
    useState(false);
  const [deseacedModal, setDeseacedModal] = useState(false);
  const [isItDecline, setIsItDecline] = useState(false);
  const [
    visibleEmergencyContactPersonModal,
    setVisibleEmergencyContactPersonModal,
  ] = useState<boolean>(false);

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get translation from hook
  const { t } = useTranslation();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get navigate from hook
  const navigate = useNavigate();

  // Get params from hook
  const params = useParams<{ id: string; filter: string }>();

  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Get project detail from store
  const { projectDetail, detailLoading } = useSelector(
    ({ projectReducer: { projectDetail, detailLoading } }: RootState) => ({
      projectDetail,
      detailLoading,
    })
  );

  const { filesForm } = useSelector(
    ({ formReducer }: RootState) => formReducer
  );

  // show info button
  const missingFileFields = useMemo(() => {
    if (projectDetail && filesForm) {
      if (projectDetail.status !== 'new') {
        return null;
      }

      const missingFields = getMissingFileFields(projectDetail, filesForm);
      return missingFields.length > 0 ? missingFields : null;
    }
    return null;
  }, [filesForm, projectDetail]);

  // Open assign broker modal
  const handleOpenAssignBrokerModal = () => {
    setVisibleAssignBrokerModal(true);
  };

  // Assign broker
  const handleAssignBroker = (brokerId: string) => {
    if (projectDetail) {
      assignBroker(projectDetail.id, +brokerId)
        .then((res) => {
          if (res?.success) {
            enqueueSnackbar(res?.message, { variant: 'success' });
            dispatch(getProjectDetail(+projectDetail.id));
          } else {
            enqueueSnackbar(res?.message, { variant: 'error' });
          }
        })
        .catch(() => {
          enqueueSnackbar(t('general.error_occured'), { variant: 'error' });
        });
    }
  };

  // Close object id modal and open emergency contact option modal
  const handleCloseObjectIdModal = () => {
    setVisibleObjectIdModal(false);
    setIsEmergencyContactOpened(true);
  };

  const handleOpenEmergencyContactPersonModal = () => {
    setVisibleEmergencyContactPersonModal(true);
  };

  const handleEmergencyContactClose = () => {
    setIsEmergencyContactOpened(false);
  };

  // Close enter eligible person modal handler
  const handleCloseEmergencyContactPersonModal = () => {
    setVisibleEmergencyContactPersonModal(false);
  };

  // Close assign broker modal
  const handleCloseAssignBrokerModal = () => {
    setVisibleAssignBrokerModal(false);
  };

  // Open chat modal
  const handleOpenChatModal = () => {
    setVisibleChatsModal(true);
  };

  // Close chat modal
  const handleCloseChatsModal = () => {
    setVisibleChatsModal(false);
  };

  // Remove object click handler
  const handleRemoveObjectClick = () => {
    setVisibleDeleteConfirmModal(true);
  };

  // Close Delete modal
  const handleCloseDeleteConfirmModal = () => {
    setVisibleDeleteConfirmModal(false);
  };

  // User click handler
  const handleUserClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserEl(event.currentTarget);
  };

  // Close user menu handler
  const handleCloseUserPopover = () => {
    setUserEl(null);
  };

  // Remove object
  const handleDeleteObject = () => {
    setDeleteLoading(true);
    deleteProject(projectDetail?.id)
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          navigate(ROUTES.CLIENTS.INDEX + `/${params.filter || ''}`);
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' });
        }
        setDeleteLoading(false);
      })
      .catch(() => {
        enqueueSnackbar(t('general.error_occured'), { variant: 'error' });
        setDeleteLoading(false);
      });
  };

  // Change object state handler
  const handleChangeStateClicked = (state: any, isItDecline?: boolean) => {
    setIsItDecline(isItDecline ? true : false);
    setWorkFlowState(state);
  };

  const handleDeclinedByClient = (e: React.ChangeEvent<HTMLInputElement>) => {
    const declinedByCustomer =
      projectDetail?.status === 'approvalOfNonBindingOfferByCustomer'
        ? 'declineNonBindingOfferByCustomer'
        : projectDetail?.status === 'approvalOfBindingOfferByCustomer'
        ? 'declineBindingOfferByCustomer'
        : 'declineNonBindingOfferForCustomerByCustomer';

    if (e.target.checked) {
      setWorkFlowState({ ...workFlowState, transition: declinedByCustomer });
    }
  };

  // Update state handler
  const handleUpdateState = (message) => {
    setUpdateStateLoading(true);

    updateWorkflow(projectDetail?.id, {
      message,
      transition: workFlowState.transition,
    })
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          params?.id && dispatch(getProjectDetail(+params?.id));
          if (workFlowState.transition === 'lock') {
            setVisibleObjectIdModal(true);
          }
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' });
        }
        setWorkFlowState(null);
        setUpdateStateLoading(false);
      })
      .catch(() => {
        enqueueSnackbar(t('general.error_occured'), { variant: 'error' });
        setWorkFlowState(null);
        setUpdateStateLoading(false);
      });
  };

  // Close workflow state modal
  const handleResetWorkFlowState = () => {
    setWorkFlowState(null);
  };

  // Save handler
  const handleSave = () => {
    if (form) {
      form.handleSubmit();
    }
  };

  const handleDeceasedUser = () => {
    setDeseacedModal(true);
  };

  // Get emergency contact person
  const emergencyContactPerson = useMemo(() => {
    const emergencyContactPerson = projectDetail?.emergency_contact_person;
    if (emergencyContactPerson) {
      const items = [
        {
          label: 'Kontoinhaber',
          value: `${emergencyContactPerson.first_name} ${emergencyContactPerson.last_name}`,
        },
        {
          label: 'IBAN',
          value: emergencyContactPerson.iban,
        },
        {
          label: 'Straße',
          value: emergencyContactPerson?.address?.street,
        },
        {
          label: 'PLZ / Ort',
          value:
            emergencyContactPerson?.address?.zip +
            ' ' +
            emergencyContactPerson?.address?.city,
        },

        {
          label: 'E-Mail',
          value: emergencyContactPerson.email,
        },

        {
          label: 'Telefon',
          value: emergencyContactPerson.phone,
        },

        {
          label: 'Fax',
          value: emergencyContactPerson.fax,
        },

        {
          label: 'Mobil',
          value: emergencyContactPerson.mobile,
        },
      ];

      return {
        title: t('project_detail.notfallkontakt'),
        items,
      };
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail]);

  const getAvailableColor = (state) => {
    const { meta, transition } = state;
    const { variant } = meta;
    let color, icon;

    if (STATUS_ACTIONS[transition]) {
      icon = STATUS_ACTIONS[transition].icon;
    }
    color = 'white';

    return { variant: variant === 'blue' ? 'info' : variant, color, icon };
  };

  const handleDeceasedClick = async (id: number, type: string) => {
    await markCustomerDeceased(id, type)
      .then((res) => {
        if (res?.data?.success) {
          enqueueSnackbar(t('deceased.client_passed_away_success'), {
            variant: 'success',
          });
          dispatch(getProjectDetail(+id));
          setDeseacedModal(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar('Error', { variant: 'error' });
      });
  };

  // Method for filtering out transition of all "byCustomers" declines because we only need one icon for declining and not two (declinedByMWR is enough)
  const filterDeclineByTransitions = useCallback(
    (available_states: IAvailableStates) => {
      if (
        projectDetail?.available_states?.some(
          (available_state) =>
            available_state.transition === 'declineNonBindingOffer'
        )
      ) {
        return (
          available_states.transition !== 'declineNonBindingOfferByCustomer'
        );
      } else if (
        projectDetail?.available_states?.some(
          (available_state) =>
            available_state.transition === 'declineBindingOffer'
        )
      ) {
        return available_states.transition !== 'declineBindingOfferByCustomer';
      } else if (
        projectDetail?.available_states?.some(
          (available_state) =>
            available_state.transition === 'declineNonBindingOfferForCustomer'
        )
      ) {
        return (
          available_states.transition !==
          'declineNonBindingOfferForCustomerByCustomer'
        );
      }

      return available_states;
    },
    [projectDetail?.available_states]
  );

  // Constants
  const actions = useMemo(() => {
    const statusUpdateActions =
      projectDetail?.available_states
        ?.filter((available_state) =>
          filterDeclineByTransitions(available_state)
        )
        ?.map((state) => {
          const { variant, color, icon } = getAvailableColor(state);

          const disabled =
            icon === 'reset' && !isUserAllowed(account, Role.ROLE_ADMIN);
          return {
            name: state.meta.title,
            icon:
              icon === 'x-lg' ? (
                <CircleXMarkIcon />
              ) : (
                <Icon name={icon} color={color} />
              ),
            visible: !disabled,
            handler: () =>
              handleChangeStateClicked(state, icon === 'x-lg' ? true : false),
            color: icon === 'x-lg' ? 'error' : variant,
          };
        }) || [];

    return [
      {
        name: '',
        color: 'warning',
        icon: (
          <Box sx={{ color: 'white', width: 20, height: 20, borderRadius: 1 }}>
            i
          </Box>
        ),
        visible: !!missingFileFields,
        handler: () => {
          gotoFileTab();
          setMissingFilesModal(true);
        },
      },
      {
        name: t('project_detail.save'),
        icon: <SaveIcon />,
        visible:
          Boolean(form) &&
          (isUserAllowed(account, Role.ROLE_ADMIN) ||
            (isUserAllowedForRoles(account, [Role.ROLE_AGENTS]) &&
              isStatusAllowed(projectDetail?.status, null, Status.new))),
        handler: handleSave,
      },
      ...statusUpdateActions,
      {
        name: t('project_detail.chat'),
        icon: <Icon name="message" />,
        handler: handleOpenChatModal,
      },
      {
        name: t('assign_broker.change'),
        icon: <Icon name="user" />,
        visible: isUserAllowed(account, Role.ROLE_ADMIN),
        handler: handleUserClick,
      },
      {
        name: t('project_detail.delete_client'),
        color: 'error',
        icon: <Icon name="trash" />,
        visible:
          isUserAllowed(account, Role.ROLE_ADMIN) ||
          (isUserAllowedForRoles(account, [Role.ROLE_USER]) &&
            isStatusAllowed(projectDetail?.status, null, Status.new)),
        handler: handleRemoveObjectClick,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, projectDetail, missingFileFields]);

  // Return actions
  return (
    <>
      <Stack
        spacing={{ xs: 8, sm: 25, md: 35, lg: 25, xl: 35 }}
        direction="row"
      >
        {actions.map(
          (
            {
              name = 'tooltip',
              icon,
              // @ts-ignore
              color = 'primary',
              visible = true,
              handler,
            },
            index
          ) =>
            visible && (
              <Tooltip key={`tooltip-${index}`} arrow title={name}>
                <div>
                  <IconButton
                    /* @ts-ignore */
                    color={color}
                    disabled={detailLoading || fileUploading}
                    onClick={handler}
                  >
                    {icon}
                  </IconButton>
                </div>
              </Tooltip>
            )
        )}
      </Stack>
      <Stack
        spacing={{ xs: 8, sm: 25, md: 35, lg: 25, xl: 35 }}
        direction="row"
        alignSelf={{ xs: 'flex-start', sm: 'flex-end' }}
        sx={{
          display: 'flex',
          padding: { xs: '10px 16px', md: '16px 0px' },
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        {projectDetail?.customer?.partner_first_name &&
          (projectDetail?.customer?.marital_status === 'married' ||
            projectDetail?.customer?.marital_status === 'partnered') &&
          projectDetail?.status !== 'sold' && (
            <Button
              sx={{
                borderRadius: '10px',
                background: '#F45D5B',
                color: 'white',
              }}
              onClick={handleDeceasedUser}
              variant="contained"
            >
              Verstorben
            </Button>
          )}
      </Stack>
      <Popover
        anchorEl={userEl}
        open={Boolean(userEl)}
        onClose={handleCloseUserPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack alignItems="flex-end" padding="16px">
          <Stack direction="row" mb={8}>
            <Box
              component={Link}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                color: '#172844',
              }}
              to={ROUTES.BROKERS.DETAIL.replace(
                ':id',
                `${projectDetail?.creator?.id}`
              )}
            >
              <Avatar
                src={projectDetail?.creator?.image?.path || ''}
                sx={{ width: 48 }}
              />
              <Typography ml={12} variant="body2">
                {getFullName(projectDetail, 'creator')}
                <br />
                {projectDetail && projectDetail.creator?.company}
              </Typography>
            </Box>
            <Typography ml={25} mr={21} variant="body2">
              {t('project_detail.created_date')}:{' '}
              {getProjectCreatedDate(projectDetail)}
              <br />
              {t('project_detail.modification_date')}:{' '}
              {getProjectChangedDate(projectDetail)}
            </Typography>
            <IconButton color="default" onClick={handleCloseUserPopover}>
              <Icon name="x-lg" />
            </IconButton>
          </Stack>
          {isUserAllowed(account, Role.ROLE_ADMIN) && (
            <Button onClick={handleOpenAssignBrokerModal}>
              {t('project_detail.change_broker')}
            </Button>
          )}
        </Stack>
      </Popover>
      {visibleObjectIdModal && (
        <ObjectIdModal
          id={projectDetail?.id}
          open={visibleObjectIdModal}
          onClose={handleCloseObjectIdModal}
        />
      )}
      <ConfirmModal
        open={isEmergencyContactOpened}
        onClose={handleEmergencyContactClose}
        title={'Notfallkontakt hinzufügen'}
        text={'Hat der Kunde bereits einen Notfallkontakt?'}
        confirmLabel={'Ja'}
        cancelLabel={'Nein'}
        onOk={handleOpenEmergencyContactPersonModal}
      />
      <EmergencyContactPersonModal
        isEdit={!!emergencyContactPerson}
        open={visibleEmergencyContactPersonModal}
        onClose={handleCloseEmergencyContactPersonModal}
        onSuccess={handleEmergencyContactClose}
      />
      {visibleAssignBrokerModal && (
        <AssignBrokerModal
          target={getFullName(projectDetail)}
          brokerId={projectDetail?.creator?.id}
          open={visibleAssignBrokerModal}
          onClose={handleCloseAssignBrokerModal}
          onAssign={handleAssignBroker}
        />
      )}
      {visibleChatsModal && (
        <ChatsModal
          open={visibleChatsModal}
          onClose={handleCloseChatsModal}
          projectId={projectDetail?.id}
        />
      )}
      {visibleDeleteConfirmModal && (
        <ConfirmModal
          open={visibleDeleteConfirmModal}
          onClose={handleCloseDeleteConfirmModal}
          onOk={handleDeleteObject}
          loading={deleteLoading}
        />
      )}
      {workFlowState && (
        <StateMessageModal
          title={workFlowState?.meta?.title}
          open={workFlowState}
          loading={updateStateLoading}
          isItDecline={isItDecline}
          onApply={handleUpdateState}
          onDeclinedByClient={handleDeclinedByClient}
          onClose={handleResetWorkFlowState}
        />
      )}

      {deseacedModal && (
        <DeseacedModal
          open={deseacedModal}
          isEdit={true}
          onClose={() => setDeseacedModal(false)}
          id={projectDetail?.customer?.id}
          name={`${projectDetail?.customer?.first_name} ${projectDetail?.customer?.last_name} `}
          handleDeceasedClick={handleDeceasedClick}
        />
      )}

      <CloseConfirmModal
        open={missingFilesModal}
        title={t('close_confirm.customer_saved')}
        hideCancel
        confirmLabel={t('confirm.ok')}
        text={
          <>
            <Box mb={12}>{t('close_confirm.submitted_missing_files')}</Box>
            {missingFileFields &&
              missingFileFields?.map((fileField, index) => (
                <Box key={`fields-${index}`}>{fileField.label}</Box>
              ))}
          </>
        }
        onClose={() => {
          setMissingFilesModal(false);
        }}
        onCancel={() => {
          setMissingFilesModal(false);
        }}
      />
    </>
  );
};

// Export actions
export default Actions;
