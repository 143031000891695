// Dependencies
import styled from 'styled-components';

import { ListItem } from '../../Common/ListItem';

export const ChatListItem = styled(ListItem)`
  && {
    .MuiListItemText-primary {
      max-width: calc(100vw - 150px) !important;
    }

    .MuiListItemText-secondary {
      max-width: calc(100vw - 150px) !important;
    }
  }
`;
