import { ThunkDispatch } from 'redux-thunk';

import { ApiTypes } from '../../interfaces';
import { TASK_ACTIONS } from '../action-types';
import { RootState } from '../store';

export const getTasks =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getTasks = async () => {
      const res: any = await api.task.getTasks();

      return {
        tasks: res.data?.data || [],
      };
    };

    return dispatch({
      type: TASK_ACTIONS.GET_TASKS,
      payload: getTasks(),
    });
  };

export const updateTaskGroup =
  (projectId, taskGroup) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const updateTaskGroup = async () => {
      const res = await api.task.updateTaskGroup(projectId, taskGroup);
      return res.data;
    };

    return dispatch({
      type: TASK_ACTIONS.UPDATE_TASK_GROUP,
      payload: updateTaskGroup(),
    });
  };

export const updateTask =
  (projectId, taskId, task) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const updateTask = async () => {
      const res = await api.task.updateTask(projectId, taskId, task);
      return res.data;
    };

    return dispatch({
      type: TASK_ACTIONS.UPDATE_TASK,
      payload: updateTask(),
    });
  };
