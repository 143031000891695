// Dependencies
import styled from 'styled-components';

// Components
import TableCell from '@mui/material/TableCell';

// Export styled components
export const HeadCell = styled(TableCell)`
  border-top: 8px solid #ea504c !important;
  border-bottom: 8px solid #ea504c !important;
  color: #fff !important;
  &:not(:first-child) {
    border-left: 2px solid #fff !important;
  }
`;

export const BodyCell = styled(TableCell)`
  border-left: 2px solid #ea504c;
  border-top: 2px solid #ea504c;
`;
