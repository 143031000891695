// Dependencies
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import { FormPanel, FormType } from '../../../../../components';
import { DetailTab } from '../Tabs';

// Constants
import { isStatusAllowed, Role, Status } from '../../../../../constants';

// Utils
import { isUserAllowed, isUserAllowedForRoles } from '../../../../../utils';

// Panels
import AdditionalCostsCalculatorPanel from './AdditionalCostsCalculator';
import BindingOfferPanel from './BindingOffer';
import ContractDataPanel from './ContractData';
import IndicativeOfferPanel from './IndicativeOffer';
import TasksPanel from './Tasks';

// Store
import { RootState } from '../../../../../redux/reducers';
import { getProjectDetail } from '../../../../../redux/actions';

// Styles
import * as S from './styles';

// Interfaces
interface ITabPanelsProps {
  activeTab: DetailTab;
  setForm: (form: any) => void;
  setFileUploading: (uploading: boolean) => void;
  setUnsavedData: (val: boolean) => void;
}

// Create tab panels
const TabPanels: FC<ITabPanelsProps> = ({
  activeTab,
  setForm,
  setFileUploading,
  setUnsavedData,
}) => {
  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get params from hook
  const params = useParams<{ id: string; filter: string }>();

  // Get project detail from store
  const { projectDetail, detailLoading } = useSelector(
    ({ projectReducer: { projectDetail, detailLoading } }: RootState) => ({
      projectDetail,
      detailLoading,
    })
  );

  // Get form data from hook
  const {
    personalDataForm,
    modernizationsForm,
    registrationForm,
    formLoading,
  } = useSelector(
    ({
      projectReducer: {
        personalDataForm,
        modernizationsForm,
        registrationForm,
        formLoading,
      },
    }: RootState) => ({
      personalDataForm,
      modernizationsForm,
      registrationForm,
      formLoading,
    })
  );

  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Get form data from store
  const { filesForm, loading: filesLoading } = useSelector(
    ({ formReducer }: RootState) => formReducer
  );

  // Refetch handler
  const handleRefetch = async () => {
    if (params.id) {
      await dispatch(getProjectDetail(+params.id));
    }
  };

  const formDisabled = !(
    isUserAllowed(account, Role.ROLE_ADMIN) ||
    (isUserAllowedForRoles(account, [Role.ROLE_AGENTS]) &&
      isStatusAllowed(projectDetail?.status, null, Status.new))
  );

  // Return tab panels

  return (
    <S.TabPanel>
      {activeTab === DetailTab.PersonalData && (
        <FormPanel
          isLoading={formLoading || detailLoading}
          setForm={setForm}
          formData={personalDataForm}
          formType={FormType.Customer}
          disabled={formDisabled}
          initialFormData={projectDetail && projectDetail.customer}
          refetchFormData={handleRefetch}
          setUnsavedData={setUnsavedData}
        />
      )}
      {activeTab === DetailTab.RegistrationForm && (
        <FormPanel
          isLoading={formLoading || detailLoading}
          formData={registrationForm}
          formType={FormType.Property}
          disabled={formDisabled}
          setForm={setForm}
          initialFormData={projectDetail && projectDetail.property}
          refetchFormData={handleRefetch}
          setUnsavedData={setUnsavedData}
        />
      )}
      {activeTab === DetailTab.Modernizations && (
        <FormPanel
          isLoading={formLoading || detailLoading}
          formData={modernizationsForm}
          formType={FormType.Property}
          disabled={formDisabled}
          setForm={setForm}
          initialFormData={projectDetail && projectDetail.property}
          refetchFormData={handleRefetch}
          setUnsavedData={setUnsavedData}
        />
      )}
      {activeTab === DetailTab.IndicativeOffer && <IndicativeOfferPanel />}
      {activeTab === DetailTab.BindingOffer && <BindingOfferPanel />}
      {activeTab === DetailTab.ContractData && <ContractDataPanel />}
      {activeTab === DetailTab.AdditionalCostsCalculator && (
        <AdditionalCostsCalculatorPanel />
      )}
      {activeTab === DetailTab.Files && (
        <FormPanel
          isLoading={filesLoading || detailLoading}
          formData={filesForm}
          formType={FormType.Property}
          disabled={formDisabled}
          setForm={setForm}
          setFileUploading={setFileUploading}
          initialFormData={projectDetail && projectDetail.property}
          refetchFormData={handleRefetch}
          layout={{ file: 2 }}
          setUnsavedData={setUnsavedData}
        />
      )}
      {activeTab === DetailTab.Tasks && (
        <TasksPanel
          isLoading={detailLoading}
          projectStatus={projectDetail?.status || ''}
          projectDetail={projectDetail}
          refetchFormData={handleRefetch}
        />
      )}
    </S.TabPanel>
  );
};

// Export tab panels
export default TabPanels;
