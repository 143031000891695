// Dependencies
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button, Typography } from '../../Common';

// Styles
import * as S from './styles';
import { Stack } from '@mui/material';

// Interfaces
interface IConfirmModalProps {
  open: boolean;
  loading?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  title?: string;
  text?: ReactNode;
  firstOrLastNameError?: boolean;
  onClose: () => void;
  onOk: () => void;
}

// Export confirm modal
export const ConfirmModal: FC<IConfirmModalProps> = ({
  open,
  loading,
  confirmLabel,
  cancelLabel,
  title,
  text,
  firstOrLastNameError,
  onClose,
  onOk,
}) => {
  // Get translations from hook
  const { t } = useTranslation();

  // Return confirm modal
  return (
    <S.ConfirmModal
      title={title || t('confirm.title')}
      open={open}
      onClose={onClose}
      actions={
        <Stack direction="row" spacing={10}>
          <Button size="large" onClick={onClose}>
            {cancelLabel || t('confirm.cancel')}
          </Button>
          <Button
            size="large"
            color="primary"
            onClick={onOk}
            loading={loading}
            disabled={firstOrLastNameError}
          >
            {confirmLabel || t('confirm.ok')}
          </Button>
        </Stack>
      }
    >
      <Typography>{text || t('confirm.description')}</Typography>
      {firstOrLastNameError && (
        <Typography style={{ paddingTop: '28px', color: 'red' }}>
          Zum Speichern eines Entwurfs sind Vor- und Nachname erforderlich.
        </Typography>
      )}
    </S.ConfirmModal>
  );
};
