// Dependencies
import React, { FC, useState, useMemo, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Stack,
} from '@mui/material';
import { useSelector } from 'react-redux';

// Components
import { Button, Icon, Input, Typography } from '../../components';
import { Table } from './Table';
import { Chart } from './Chart';

// Styles
import * as S from './styles';

// Constants
import { ROUTES } from '../../constants/';
import { Highlighted } from '../../components/Common/HighLighted';
import { RootState } from '../../redux/reducers';

export interface ITable {
  status: boolean;
  row: number;
  column: number;
}

interface Ifaqs {
  label: string;
  inSearch?: boolean;
  question: {
    prefix: string;
    inSearch?: boolean;
    description: {
      inSearch?: boolean;
      style?: {
        weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700;
        color?: string;
        display?: string;
        marginBottom?: string;
        marginTop?: string;
        table?: ITable;
        chart?: {
          status: true;
        };
      };
    }[][];
  };
}

// Questions and answers with indexes
/*
Instruction
If you need to style, then you can create another array in the description field, in which there will be objects. In these objects, use the style field and add the necessary styles already in it.
if you need to display a table, specify the value true for the status in table field and be sure to specify the number of columns and cells.
Keep in mind that everything is related to translations.
*/
let faqs: Ifaqs[] = [
  {
    label: 'your_right_residence',
    question: {
      prefix: 'right',
      description: [
        [{}],
        [
          {},
          {
            style: {
              weight: 600,
            },
          },
        ],
        [
          {},
          {
            style: {
              weight: 600,
              marginBottom: '0',
            },
          },
          {
            style: {
              marginBottom: '0',
            },
          },
          {
            style: {
              marginBottom: '0',
            },
          },
          {
            style: {
              marginBottom: '0',
            },
          },
          {},
          {
            style: {
              chart: {
                status: true,
              },
            },
          },
        ],
      ],
    },
  },
  {
    label: 'your_protection',
    question: {
      prefix: 'protection',
      description: [
        [
          {
            style: {
              display: 'inline',
            },
          },
          {
            style: {
              weight: 600,
              display: 'inline',
            },
          },
          {
            style: {
              marginTop: '20',
            },
          },
          {},
        ],
        [{}, {}],
      ],
    },
  },
  {
    label: 'around_the_contact',
    question: {
      prefix: 'contact',
      description: [[{}], [{}, {}], [{}], [{}]],
    },
  },
  {
    label: 'miscellaneous',
    question: {
      prefix: 'miscellaneous',
      description: [
        [
          {},
          {},
          {
            style: {
              color: '#EA514D',
              marginBottom: '0',
            },
          },
          {
            style: {
              color: '#EA514D',
            },
          },
          {},
        ],
        [{}, {}],
        [
          {
            style: {
              table: {
                status: true,
                row: 10,
                column: 3,
              },
            },
          },
        ],
      ],
    },
  },
];

// Export faq page
export const FAQPage: FC = () => {
  // Get translation from hook
  const { t } = useTranslation();

  // States
  const [expanded, setExpanded] = useState<string | false>(false);
  const [search, setSearch] = useState<string>('');

  // Get layout states from store
  const isDrawerOpened = useSelector(
    ({ uiReducer: { isDrawerOpened } }: RootState) => isDrawerOpened
  );

  // Get navigate from hook
  const navigate = useNavigate();

  const handleOpenWelcomeModal = () => {
    localStorage.setItem('welcomeTourClosed', 'false');
    navigate(ROUTES.DASHBOARD);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  // Search instructions.
  // The first parameter is the text that is compared with the search text.
  // The second parameter is an object containing the text.
  // The third parameter is the parents of the second parameter, which needs to be changed from inSearch to true in order for the second parameter to be visible.

  // For example, the function did not find a match with the subject of the question and closed it, but the function found a match with the answer to the question.
  // Therefore, we open the answer, as well as the question and the subject of the question.
  useMemo(() => {
    const searchInArray = (
      text: string,
      mainSearch: any,
      secondSearch: any[]
    ) => {
      const regexSearch = new RegExp(search.toLocaleLowerCase());
      if (search !== '') {
        if (regexSearch.test(text.toLocaleLowerCase())) {
          mainSearch.inSearch = true;
          secondSearch.forEach((e) => (e.inSearch = true));
        } else {
          mainSearch.inSearch = false;
        }
      } else {
        mainSearch.inSearch = true;
      }
    };

    faqs.forEach(({ label, question: { prefix, description } }, faq_index) => {
      searchInArray(t(`faq.${label}`), faqs[faq_index], []);

      description.forEach((_, index) => {
        searchInArray(
          t(`faq.${prefix}_question_${index + 1}`),
          faqs[faq_index].question.description[index],
          [faqs[faq_index]]
        );

        description[index].forEach((_, d_index) => {
          searchInArray(
            t(`faq.${prefix}_description_${index + 1}_${d_index + 1}`),
            description[index][d_index],
            [faqs[faq_index], faqs[faq_index].question.description[index]]
          );
        });
      });
    });
  }, [search, t]);

  // Return faq page
  return (
    <S.WrapFAQ $opened={isDrawerOpened}>
      <Card>
        <CardHeader
          title={
            <Input
              sx={{ mr: 10 }}
              startAdornment={
                <InputAdornment position="start">
                  <Icon name="search" />
                </InputAdornment>
              }
              placeholder="Search"
              onChange={handleChangeSearchInput}
            />
          }
          action={
            <Button onClick={handleOpenWelcomeModal}>
              {t('welcome.start_tutorial')}
            </Button>
          }
        />
        <CardContent sx={{ p: 20, pt: 26 }}>
          <Stack spacing={40}>
            {faqs.map(
              ({ label, question: { prefix, description } }, faq_index) => {
                let faqSearch = faqs[faq_index].inSearch;
                if (faqSearch === undefined) faqSearch = true;
                return (
                  <Fragment key={faq_index}>
                    {faqSearch && (
                      <Stack spacing={16}>
                        <Typography variant="h3">
                          {t(`faq.${label}`)}
                        </Typography>
                        <Box>
                          {new Array(description.length)
                            .fill(0)
                            .map((_, index) => {
                              let questionSearh =
                                // @ts-ignore
                                // prettier-ignore
                                faqs[faq_index].question.description[index].inSearch;
                              if (questionSearh === undefined)
                                questionSearh = true;
                              return (
                                <Fragment key={`q-${index}`}>
                                  {questionSearh && (
                                    <Accordion
                                      sx={(theme) => ({
                                        border: `1px solid ${theme.palette['cyan']}`,
                                        '&:not(:last-child)': {
                                          borderBottom: 0,
                                        },
                                        '&:before': {
                                          display: 'none',
                                        },
                                      })}
                                      expanded={
                                        expanded === `panel_${prefix}_${index}`
                                      }
                                      onChange={handleChange(
                                        `panel_${prefix}_${index}`
                                      )}
                                      disableGutters
                                      key={index}
                                    >
                                      <Stack paddingRight={32}>
                                        <AccordionSummary
                                          expandIcon={
                                            expanded ===
                                            `panel_${prefix}_${index}` ? (
                                              <Icon size={15} name="dash-lg" />
                                            ) : (
                                              <Icon size={15} name="plus-lg" />
                                            )
                                          }
                                        >
                                          {t(
                                            `faq.${prefix}_question_${
                                              index + 1
                                            }`
                                          )}
                                        </AccordionSummary>
                                      </Stack>
                                      <AccordionDetails>
                                        {new Array(description[index].length)
                                          .fill(0)
                                          .map((_, d_index) => {
                                            const array = description[index];
                                            const weight =
                                              array[d_index]?.style?.weight ||
                                              400;
                                            const color =
                                              array[d_index]?.style?.color ||
                                              '';
                                            const display =
                                              array[d_index]?.style?.display ||
                                              'block';
                                            const marginBottom =
                                              array[d_index]?.style
                                                ?.marginBottom || 20;
                                            const marginTop =
                                              array[d_index]?.style
                                                ?.marginTop || 0;
                                            const table =
                                              array[d_index]?.style?.table
                                                ?.status || false;
                                            const chart =
                                              array[d_index]?.style?.chart
                                                ?.status || false;
                                            return (
                                              <Box
                                                key={d_index}
                                                style={{
                                                  marginBottom: +marginBottom,
                                                  marginTop: +marginTop,
                                                  display: display,
                                                  color: color,
                                                  fontWeight: weight,
                                                }}
                                              >
                                                {table ? (
                                                  <Table
                                                    array={array}
                                                    d_index={d_index}
                                                    prefix={prefix}
                                                  />
                                                ) : chart ? (
                                                  <Chart />
                                                ) : (
                                                  <Typography variant="body2">
                                                    <Highlighted
                                                      text={t(
                                                        `faq.${prefix}_description_${
                                                          index + 1
                                                        }_${d_index + 1}`
                                                      )}
                                                      highlight={search}
                                                    />
                                                  </Typography>
                                                )}
                                              </Box>
                                            );
                                          })}
                                      </AccordionDetails>
                                    </Accordion>
                                  )}
                                </Fragment>
                              );
                            })}
                        </Box>
                      </Stack>
                    )}
                  </Fragment>
                );
              }
            )}
          </Stack>
        </CardContent>
      </Card>
    </S.WrapFAQ>
  );
};
