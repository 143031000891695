// Dependencies
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Skeleton, Stack, Typography } from '@mui/material';

// Store
import { RootState } from '../../../../../redux/reducers';

// Utils
import {
  getProjectChangedDate,
  getProjectCreatedDate,
  getProjectSubmittedDate,
} from '../../../../../utils';

// Props
interface ProjectDateProps {
  hasUnsavedData?: boolean;
}

// Create project date component
const ProjectDate: FC<ProjectDateProps> = ({ hasUnsavedData = false }) => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get project detail from store
  const { projectDetail, detailLoading } = useSelector(
    ({ projectReducer: { projectDetail, detailLoading } }: RootState) => ({
      projectDetail,
      detailLoading,
    })
  );

  // Return project detail component
  return detailLoading ? (
    <Skeleton
      variant="rectangular"
      height={40}
      sx={{ width: { xs: 200, sm: 370 } }}
    />
  ) : (
    <Stack
      spacing={{ xs: 8, md: 20, lg: 30, xl: 30 }}
      direction="row"
      px={{ xs: 20, sm: 0 }}
      justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
      divider={<Divider orientation="vertical" flexItem />}
    >
      {projectDetail?.property?.object_flag && (
        <Typography variant="body2" color="blackCyan">
          {t('project_detail.object_flag')}
          :<br />
          {projectDetail.property.object_flag}
        </Typography>
      )}
      {hasUnsavedData && (
        <Typography variant="body2" color="red" sx={{ maxWidth: 140 }}>
          {t('project_detail.unsaved_data')}
        </Typography>
      )}
      <Typography variant="body2" color="blackCyan">
        {getProjectSubmittedDate(projectDetail)
          ? t('project_detail.submitted_date')
          : t('project_detail.created_date')}
        :<br />
        {getProjectSubmittedDate(projectDetail) ||
          getProjectCreatedDate(projectDetail)}
      </Typography>
      <Typography variant="body2" color="blackCyan">
        {t('project_detail.modification_date')}:<br />
        {getProjectChangedDate(projectDetail)}
      </Typography>
    </Stack>
  );
};

// Export project date
export default ProjectDate;
