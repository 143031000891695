// Dependencies
import React, { FC, ReactNode, useMemo, useState } from 'react';
import {
  Menu,
  MenuItem,
  Table as MuiTable,
  ToggleButton,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// Components
import { Icon } from '../Icon';
import { Dialog } from '../Dialog';
import { TableHead } from './TableHead';
import { Typography } from '../Typography';
import { defaultRowsPerPage, TablePagination } from './TablePagination';

import * as S from './styles';

// Interfaces
import { ISortBy } from '../../../interfaces';
import { ITablePaginationProps } from './TablePagination';

export interface IColumn {
  field: string;
  label: string;
  visible?: boolean;
  sortable?: boolean;
  width?: number;
  render?: (row: any, isSelected?: boolean) => ReactNode;
}

interface IRowAction {
  icon: string;
  label: string;
  hidden?: boolean;
  hiddenFunc?: (row: any) => boolean;

  onClick: (id) => void;
}

export interface ITableProps {
  data: any[];
  columns: IColumn[];
  rowActions?: IRowAction[];
  onSort: (sortBy: ISortBy) => void;
}

export interface IDataTableProps extends ITablePaginationProps {
  sx?: SxProps<Theme>;
  data: any[];
  type?: 'leads' | 'drafts' | 'other';
  columns: IColumn[];
  selectedId?: number;
  rowActions?: IRowAction[];
  onSort?: (sortBy: ISortBy) => void;
  onRowClick?: (id: number) => void;
  paginated?: boolean;
  rowSelectable?: boolean;
  columnEditable?: boolean;
  stickyHeader?: boolean;
  contentSize?: {
    height?: number | string;
    width?: number | string;
  };
  isList?: boolean;
  isLoading?: boolean;
  selected?: readonly number[];
  onChangeSelected?: (value: readonly number[]) => void;
}

// Export DataTable component
export const DataTable: FC<IDataTableProps> = ({
  sx,
  data,
  columns,
  rowActions,
  page = 1,
  type = 'other',
  totalPage,
  rowsPerPage = defaultRowsPerPage,
  rowsPerPageOptions,
  selectedId,
  onSort,
  onPageChange,
  onRowsPerPageChange,
  paginated = false,
  rowSelectable = false,
  columnEditable = false,
  stickyHeader = false,
  contentSize,
  onRowClick,
  isList,
  isLoading,
  selected = [],
  onChangeSelected,
}) => {
  const { t } = useTranslation();

  // Get visible columns from props
  const defaultVisibleColumns = useMemo(
    () =>
      columns
        .map((column) => ({
          ...column,
          visible: column.visible === undefined ? true : column.visible,
          sortable: column.sortable === undefined ? true : column.sortable,
        }))
        .filter(({ visible }) => visible) || [],
    [columns]
  );

  // States
  const [visibleColumns, setVisibleColumns] = useState<IColumn[]>(
    defaultVisibleColumns
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeRowId, setActiveRowId] = useState<number>();

  // Theme
  const theme = useTheme();

  // Check platform
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n, index) => index);
      onChangeSelected && onChangeSelected(newSelecteds);
      return;
    }
    onChangeSelected && onChangeSelected([]);
  };

  const handleClickRow = (id: number) => {
    onRowClick && onRowClick(id);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    onChangeSelected && onChangeSelected(newSelected);
  };

  // Check visible field
  const isVisibleColumn = (key) => {
    const visibleColumn = visibleColumns.find(({ field }) => key === field);
    if (visibleColumn) {
      return visibleColumn.visible;
    } else {
      return false;
    }
  };

  // Visible columns change handler
  const handleChangeVisibleColumns = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVisibleColumns(
      columns
        .map((column) => ({
          ...column,
          visible:
            column.field === event.target.name
              ? event.target.checked
              : isVisibleColumn(column.field),
          sortable: column.sortable === undefined ? true : column.sortable,
        }))
        .filter(({ visible }) => visible)
    );
  };

  // Reset visible columns handler
  const handleResetVisibleColumns = () => {
    setVisibleColumns(defaultVisibleColumns);
  };

  // Row action click handler
  const handleClickRowAction = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    event.stopPropagation();
    setActiveRowId(index);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // Close row action handler
  const handleCloseRowActionMenu = () => {
    setAnchorEl(null);
  };

  // Hide column handler
  const handleHideColumn = (activeColumn: IColumn) => {
    setVisibleColumns(
      visibleColumns.filter((column) => activeColumn.field !== column.field)
    );
  };

  const isSelected = (id: number) => {
    if (selectedId) {
      return selectedId === id;
    } else {
      return selected.indexOf(id) !== -1;
    }
  };

  // Get column count
  const columnCount = useMemo(() => {
    let count = visibleColumns.length;
    if (rowSelectable) {
      count++;
    }
    if (rowActions) {
      count++;
    }

    return count;
  }, [rowActions, rowSelectable, visibleColumns]);

  // Return DataTable component
  return (
    <S.Table sx={sx}>
      {!isList && <S.HeaderDivider />}
      <S.TableWrapper $isList={isList} $contentSize={contentSize}>
        <MuiTable stickyHeader={stickyHeader && !isList}>
          {!isList && (
            <TableHead
              isLoading={isLoading || data?.length === 0}
              numSelected={selected?.length}
              rowCount={data?.length}
              columns={columns}
              visibleColumns={visibleColumns}
              onSort={onSort}
              onHideColumn={handleHideColumn}
              onSelectAllClick={handleSelectAllClick}
              onResetVisibleColumns={handleResetVisibleColumns}
              onChangeVisibleColumns={handleChangeVisibleColumns}
              rowSelectable={rowSelectable}
              columnEditable={columnEditable}
            />
          )}
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={columnCount}
                  sx={{
                    textAlign: 'center',
                    height: contentSize?.height
                      ? `calc((${contentSize.height}) - ${!isList ? 67 : 59}px)`
                      : 300,
                  }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data?.length > 0 ? (
              <>
                {data &&
                  data.map((row, index) => {
                    // @ts-ignore
                    const isItemSelected = isSelected(
                      selectedId ? row.id : index
                    );

                    const isPossible =
                      type === 'leads' ? row?.is_possible : true;

                    // const isActionPossible =
                    //   type === 'leads' ? row?.is_possible : true;

                    const enableRowAction =
                      type === 'drafts'
                        ? rowActions &&
                          rowActions[0] &&
                          rowActions[0].hiddenFunc
                          ? rowActions[0].hiddenFunc(row)
                          : false
                        : true;

                    // Return Table component
                    return (
                      <TableRow
                        hover={isPossible}
                        // @ts-ignore
                        onClick={() => handleClickRow(row.id)}
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected && !isList}
                        sx={{
                          cursor: onRowClick ? 'pointer' : 'auto',
                          background: !isPossible
                            ? theme.palette.lightRed
                            : 'inherit',
                        }}
                      >
                        {rowSelectable && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              sx={{ p: 0 }}
                              size="medium"
                              checked={isItemSelected}
                              onClick={(event) => handleClick(event, index)}
                            />
                          </TableCell>
                        )}
                        {visibleColumns.map(({ field, render }) => (
                          <TableCell key={field}>
                            {render ? render(row, isItemSelected) : row[field]}
                          </TableCell>
                        ))}
                        {columnEditable && (
                          <TableCell align="right">
                            <ToggleButton
                              value=""
                              disabled={!isPossible || !enableRowAction}
                              color={isItemSelected ? 'primary' : 'standard'}
                              selected={
                                Boolean(anchorEl) && activeRowId === index
                              }
                              onClick={(e) => handleClickRowAction(e, index)}
                            >
                              <Icon name="ellipsis" />
                            </ToggleButton>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columnCount}
                  sx={{
                    textAlign: 'center',
                    height: contentSize?.height
                      ? `calc(${contentSize.height} - ${!isList ? 67 : 59}px)`
                      : 300,
                  }}
                >
                  <Typography variant="h2" align="center">
                    {t('table.no_data_yet')}
                  </Typography>
                  <Typography variant="body2" align="center">
                    {t('table.we_have_no_data')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </S.TableWrapper>
      {rowActions && (
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl) && !isMobile}
          anchorEl={anchorEl}
          onClose={handleCloseRowActionMenu}
        >
          {rowActions
            .filter(({ hidden }) => !hidden)
            .map(({ icon, label, onClick: onActionClick }, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  onActionClick(
                    activeRowId || activeRowId === 0
                      ? data[activeRowId].id
                      : activeRowId
                  );
                  handleCloseRowActionMenu();
                }}
              >
                <Icon
                  name={icon}
                  size={18}
                  color={
                    icon === 'trash'
                      ? // @ts-ignore
                        theme.palette.red
                      : // @ts-ignore
                        theme.palette.lightIndigo
                  }
                />
                <Typography variant="body2" ml={14}>
                  {label}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      )}
      {rowActions && (
        <Dialog
          title="Actions"
          open={Boolean(anchorEl) && isMobile}
          onClose={handleCloseRowActionMenu}
        >
          {rowActions
            .filter(({ hidden }) => !hidden)
            .map(({ icon, label, onClick }, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  onClick(
                    activeRowId || activeRowId === 0
                      ? data[activeRowId].id
                      : activeRowId
                  );
                  handleCloseRowActionMenu();
                }}
                sx={(theme) => ({
                  p: `${theme.spacing(16, 20)} !important`,
                })}
              >
                <Icon
                  name={icon}
                  size={20}
                  color={
                    icon === 'trash'
                      ? // @ts-ignore
                        theme.palette.red
                      : // @ts-ignore
                        theme.palette.lightIndigo
                  }
                />
                <Typography ml={14}>{label}</Typography>
              </MenuItem>
            ))}
        </Dialog>
      )}
      {paginated && (
        <TablePagination
          page={page}
          totalPage={totalPage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          isLoading={isLoading || data?.length === 0}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </S.Table>
  );
};
