// Dependencies
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { Dialog } from '../../Common';
import { Button, Icon, IconButton } from '../../Common';
import { ImageList } from '../../Common/ImageList';
import { createNews } from '../../../services/news.service';
import { getNews, getNewsForm } from '../../../redux/actions';
import { RootState } from '../../../redux/store';
import { buildFormInitialValues, buildSingleFieldSchema } from '../../../utils';
import { FormBuilder } from '../../FormBuilder';
import { CloseConfirmModal } from '../CloseConfirmModal';

interface ICreateNewsModalProps {
  open: boolean;
  onClose: () => void;
}

export const CreateNewsModal: FC<ICreateNewsModalProps> = ({
  open,
  onClose,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get add payout form from store
  const newsForm = useSelector(
    ({ formReducer: { newsForm } }: RootState) => newsForm
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [closeModal, setCloseModal] = useState(false);

  const fileSelector = useRef<any>();

  // Create formik
  const formik = useFormik<any>({
    initialValues: buildFormInitialValues(newsForm),
    validationSchema: buildSingleFieldSchema(newsForm),
    onSubmit: async (values) => {
      setLoading(true);
      createNews(values).then((res) => {
        setLoading(false);
        if (res.data?.success) {
          enqueueSnackbar(res.data?.message, { variant: 'success' });
          dispatch(getNews());
        } else {
          enqueueSnackbar(res.data?.message || 'Failed to create news', { variant: 'error' });
        }
        formik.setValues(buildFormInitialValues(newsForm));
        onClose();
      }).catch((e) => {
        setLoading(false);
        onClose();
        enqueueSnackbar(e.response?.data?.message || 'Failed to create news', { variant: 'error' });
      });
    },
  });

  const handleCreateNews = () => {
    formik.handleSubmit();
  };

  const handleAddFile = (e) => {
    const { files: uploadedFiles } = e.target;
    if (uploadedFiles && uploadedFiles.length > 0) {
      formik.setFieldValue('image', uploadedFiles[0]);
    }
  };

  const handleRemoveFile = () => {
    formik.setFieldValue('image', undefined);
  };

  const handleAddFileClick = () => {
    fileSelector.current.click();
  };

  const handleCloseConfirmModal = () => {
    formik.setValues(buildFormInitialValues(newsForm));
    setCloseModal(false);
    onClose();
  };

  const handleClose = () => {
    setCloseModal(true);
  };

  const handleCancelConfirmModal = () => {
    setCloseModal(false);
  };

  // On mounted
  useEffect(() => {
    (async () => {
      await dispatch(getNewsForm());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t('news.create_news')}
      actions={
        <>
          <IconButton
            size="large"
            onClick={handleAddFileClick}
            sx={{ mr: 'auto' }}
          >
            <Icon name="clip" />
          </IconButton>
          <Button
            color="secondary"
            size="large"
            onClick={handleCreateNews}
            loading={loading}
          >
            {t('news.create_news')}
          </Button>
        </>
      }
    >
      <Grid container columns={1} spacing={16}>
        <FormBuilder
          type={newsForm?.type}
          attributes={newsForm?.attributes}
          label={newsForm?.label}
          fields={newsForm?.fields}
          parentFields={newsForm?.fields}
          formik={formik}
          layout={{ text: 2, textarea: 2 }}
          hiddenFields={['file']}
          path=""
        />
      </Grid>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={fileSelector}
        accept="image/jpeg, image/png, image/gif"
        onChange={handleAddFile}
        multiple
      />
      <ImageList
        files={formik?.values?.image ? [formik?.values?.image] : []}
        onChangeFiles={handleRemoveFile}
        allowDelete
      />

      {closeModal &&
      <CloseConfirmModal
        open={closeModal}
        onClose={handleCloseConfirmModal}
        onCancel={handleCancelConfirmModal}
      />}
    </Dialog>
  );
};
