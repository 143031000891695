export enum Status {
  new = 0,
  approvalOfNonBindingOfferForCustomerByMwr = 1,
  approvalOfNonBindingOfferByCustomer = 2,
  approvedOfNonBindingOffer = 3,
  expertReportCommissioned = 4,
  declinedOfNonBindingOffer = 5,
  assessmentOfNonBindingOfferByTüv = 6,
  approvalOfBindingOfferByCustomer = 7,
  approvedOfBindingOfferByCustomer = 8,
  declinedOfBindingOfferByCustomer = 9,
  complete = 10,
  declinedByCustomer = 11,
  declinedOfNonBindingOfferByCustomer = 12,
  declinedOfBindingOffer = 13,
}

export const STATUS = {
  customer: {
    new: {
      name: 'Neu',
      color: '#61be86',
    },
    approvalOfNonBindingOfferForCustomerByMwr: {
      name: 'Eingereicht',
      color: '#ef7c79',
    },
    approvalOfNonBindingOfferByCustomer: {
      name: 'Abgabe Indikatives Angebot',
      color: '#ee855c',
    },
    approvedOfNonBindingOffer: {
      name: 'Indikatives Angebot angenommen',
      color: '#fdbe40',
    },
    expertReportCommissioned: {
      name: 'Gutachten beauftragt',
      color: '#FFA800',
    },
    assessmentOfNonBindingOfferByTüv: {
      name: 'Gutachten',
      color: '#fdbe40',
    },
    approvalOfBindingOfferByCustomer: {
      name: 'Abgabe verbindliches Angebot',
      color: '#fdbe40',
    },
    approvedOfBindingOfferByCustomer: {
      name: 'Verbindliches Angebot angenommen',
      color: '#fdbe40',
    },
    declined: {
      name: 'Abgelehnt',
      color: '#ee855c',
    },
    declinedOfNonBindingOffer: {
      name: 'Unverbindliches Angebot abgelehnt',
      color: '#ee855c',
    },
    declinedOfBindingOfferByCustomer: {
      name: 'Verbindliches Angebot von Kunde abgelehnt',
      color: '#ee855c',
    },
    declinedOfNonBindingOfferByCustomer: {
      name: 'Unverbindliches Angebot von Kunde abgelehnt',
      color: '#ee855c',
    },
    declinedByCustomer: {
      name: 'Abgelehnt durch Kunde',
      color: '#ee855c',
    },
    declinedOfBindingOffer: {
      name: 'Verbindliches Angebot abgelehnt',
      color: '#ee855c',
    },
  },
  property: {
    complete: {
      name: 'Abgeschlossen',
      color: '#bbd356',
    },
    sold: {
      name: 'Verkauf abgeschlossen',
      color: '#61be86',
    },
  },
};

export const STATUS_MAPPING = {
  new: {
    key: 'new',
    color: '#61be86',
    name: 'Neu',
    status: ['new'],
  },
  inProgress: {
    key: 'inProgress',
    color: '#fdbe40',
    name: 'In Arbeit',
    status: [
      'approvalOfNonBindingOfferForCustomerByMwr',
      'approvalOfNonBindingOfferByCustomer',
      'expertReportCommissioned',
      'approvedOfNonBindingOffer',
      'enrichmentOfNonBindingOfferForInspectionByAgent',
      'approvalOfNonBindingOfferForInspectionByMwr',
      'assessmentOfNonBindingOfferByTüv',
      'enrichmentOfNonBindingOfferFromAssessmentByAgent',
      'approvalOfBindingOfferByMwr',
      'approvalOfBindingOfferByCustomer',
      'approvedOfBindingOfferByCustomer',
    ],
  },
  bought: {
    key: 'bought',
    color: '#bbd356',
    name: 'Abgeschlossen',
    status: ['complete', 'archived'],
  },
  declined: {
    key: 'declined',
    color: '#ee855c',
    name: 'Abgelehnt',
    status: [
      'declined',
      'declinedOfNonBindingOffer',
      'declinedOfBindingOfferByCustomer',
      'declinedByCustomer',
      'declinedOfNonBindingOfferByCustomer',
      'declinedOfBindingOffer',
    ],
  },
  sold: {
    key: 'sold',
    color: '#61be86',
    name: 'Verkauf abgeschlossen',
    status: ['sold'],
  },
};

export const getAvailableFilter = (type: string) => {
  const filter: any = {};
  let statusList: string[];

  switch (type) {
    case 'new':
      statusList = STATUS_MAPPING.new.status;
      break;

    case 'detected':
      statusList = STATUS_MAPPING.new.status;
      break;

    case 'submitted':
      statusList = STATUS_MAPPING.inProgress.status;
      break;

    case 'inProgress':
      statusList = STATUS_MAPPING.inProgress.status;
      break;

    case 'declined':
      statusList = STATUS_MAPPING.declined.status;
      break;

    case 'rejected':
      statusList = STATUS_MAPPING.declined.status;
      break;

    case 'bought':
      statusList = STATUS_MAPPING.bought.status;
      break;

    case 'sold':
      statusList = STATUS_MAPPING.sold.status;
      break;

    case 'all':
      statusList = Object.values(STATUS_MAPPING).reduce(
        (allStatus, item) => [...allStatus, ...item.status] as any,
        []
      );
      break;

    default:
      statusList = [];
      break;
  }

  statusList
    .filter((status) =>
      type === 'inProgress' ? STATUS.customer.hasOwnProperty(status) : true
    )
    .forEach((status) => {
      if (STATUS.customer[status]) {
        filter[status] = STATUS.customer[status];
      } else if (STATUS.property[status]) {
        filter[status] = STATUS.property[status];
      }
    });
  return filter;
};

export const getMainStatus = (subStatus: string) => {
  const mainKey = Object.keys(STATUS_MAPPING).find((key) => {
    const statuses = STATUS_MAPPING[key].status;
    return !!statuses.find((status) => status === subStatus);
  });

  return mainKey && STATUS_MAPPING[mainKey].name;
};

export const getMainStatusKey = (subStatus: string) => {
  const mainKey = Object.keys(STATUS_MAPPING).find((key) => {
    const statuses = STATUS_MAPPING[key].status;
    return !!statuses.find((status) => status === subStatus);
  });

  return mainKey && STATUS_MAPPING[mainKey].key;
};

export const getAll = () => {
  return { ...STATUS.customer, ...STATUS.property };
};

export const getFullName = (key: string) => {
  return getAll()[key]?.name ?? 'Unbekannt';
};

export const getMappingInfo = (key: string) => {
  for (let [, mapping] of Object.entries<any>(STATUS_MAPPING)) {
    if (mapping.status.includes(key)) {
      return mapping;
    }
  }
  return { color: 'grey', name: 'Unbekannt', status: [], key: 'key' };
};

export const getName = (key: string) => {
  return getMappingInfo(key).name;
};

export const isStatusAllowed = (
  status?: string,
  min: Status | null = null,
  max: Status | null = null
) => {
  if (max != null && max < Status[status as keyof typeof Status]) {
    return false;
  }
  return !(min != null && min > Status[status as keyof typeof Status]);
};

export const getStatusRoute = (status: string): string | null => {
  switch (status) {
    case 'leads':
      return 'LEADS';

    case 'new':
    case 'inProgress':
    case 'declined':
      return 'CLIENTS';

    case 'bought':
    case 'sold':
      return 'OBJECTS';

    default:
      break;
  }
  return null;
};
