import { APIS } from '../utils';
import { GET } from './axios.services';

export const getProfileForm = async () => {
  return await GET(`${APIS.form.profileForm}`, null, null, true);
};

export const getFilesForm = async () => {
  return await GET(`${APIS.form.filesForm}`, null, null, true);
};

export const getNewBrokerForm = async () => {
  return await GET(`${APIS.form.newBrokerForm}`, null, null, true);
};

export const getCalculationForm = async (formName: string) => {
  return await GET(
    APIS.form.calculationForm.replace(':formName', formName),
    null,
    null,
    true
  );
};

export const getCalculationResultForm = async (formName: string) => {
  return await GET(
    APIS.form.calculationResultForm.replace(':formName', formName),
    null,
    null,
    true
  );
};

export const getContactForm = async () => {
  return await GET(`${APIS.form.contactForm}`, null, null, true);
};

export const getCollectPaymentDataForm = async (formName: string) => {
  return await GET(
    APIS.form.collectPaymentDataForm.replace(':formName', formName),
    null,
    null,
    true
  );
};

export const getEmergencyContactPersonForm = async () => {
  return await GET(`${APIS.form.emergencyContactPersonForm}`, null, null, true);
};

export const getEnterEligiblePersonForm = async () => {
  return await GET(`${APIS.form.enterEligiblePersonForm}`, null, null, true);
};

export const getAddPayoutForm = async () => {
  return await GET(APIS.form.addPayoutForm, null, null, true);
};

export const getRecordOperatingCostsForm = async () => {
  return await GET(APIS.form.recordOperatingCostsForm, null, null, true);
};

export const getAcceptPrepaymentForm = async () => {
  return await GET(APIS.form.acceptPrepaymentForm, null, null, true);
};

export const getNewsForm = async () => {
  return await GET(APIS.form.newsForm, null, null, true);
};

export const getPropertyObjectIdForm = async () => {
  return await GET(APIS.form.propertyObjectId, null, null, true);
};
