// Dependencies
import styled from 'styled-components';
import { Box } from '@mui/material';

// Export styled components
export const Layout = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.lightCyan};
`;

export const Aside = styled.div<{ isDrawerOpened: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: ${(props) => (!props.isDrawerOpened ? '88px' : '0')};

  ${(props) => props.theme.breakpoints.down('md')} {
    padding-left: 0;
  }
`;

// @ts-ignore
export const Content = styled(Box)`
  height: calc(100vh - 92px);
  overflow: auto;
  padding: 36px;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 16px;
    height: calc(100vh - 68px);
  }
`;
