// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Tabs } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import ListTable from './Table';
import Analytics from './Analytics';

// Styles
import * as S from './styles';

// Constants
import { ROUTES } from '../../../constants';

enum ListTab {
  Table = 'table',
  Analytics = 'analytics',
}

// Export project list page
export const ProjectListPage: FC = () => {
  // States
  const [tab, setTab] = useState<ListTab>(ListTab.Table);

  // Get path from hook
  const { pathname } = useLocation();

  // Get translation from hook
  const { t } = useTranslation();

  // Check list type
  const isClientType = useMemo(() => {
    return pathname.includes(ROUTES.CLIENTS.INDEX);
  }, [pathname]);

  // Handle change tab
  const handleChangeTab = (event: React.SyntheticEvent, newTab: ListTab) => {
    setTab(newTab);
  };

  // On list type changed
  useEffect(() => {
    if (!isClientType) {
      setTab(ListTab.Table);
    }
  }, [isClientType]);

  // Return project list page
  return (
    <S.Page>
      {isClientType && (
        <Box sx={{ py: 9, mb: { xs: 12, md: 24 } }}>
          <Tabs value={tab} onChange={handleChangeTab}>
            <S.Tab value={ListTab.Table} label={t('projects.clients')} />
            <S.Tab value={ListTab.Analytics} label={t('projects.analytics')} />
          </Tabs>
        </Box>
      )}
      {tab === ListTab.Table && <ListTable />}
      {tab === ListTab.Analytics && <Analytics />}
    </S.Page>
  );
};
