import { Box, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Button } from '../../Common/Button';
import { CardHeader } from '../../Common/Card/styles';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import { resendAuthCode } from '../../../services/auth.service';
import { useSnackbar } from 'notistack';
export interface ValidateCodeProps {
  formik: any;
  disabled: boolean;
  setDisabled: (value: boolean) => void;
  setTries: (value: number) => void;
}

function ValidateCode({
  formik,
  disabled,
  setDisabled,
  setTries,
}: ValidateCodeProps) {
  const [code, setCodeComponent] = useState<string[]>(['', '', '', '', '', '']);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<any>([]);
  const enterRef = useRef<HTMLButtonElement>(null);

  const handleSend = () => {
    formik.setFieldValue('authCode', code.join(''));
    formik.handleSubmit();
    setCodeComponent(['', '', '', '', '', '']);
  };

  const handleResendCode = async () => {
    setCodeComponent(['', '', '', '', '', '']);
    setDisabled(false);
    formik.setFieldValue('authCode', '');

    try {
      await resendAuthCode({ ...formik.values });
      enqueueSnackbar('Der Authentifizierungscode wurde erfolgreich gesendet', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar('Etwas ist schief gelaufen', { variant: 'error' });
    }
  };

  useEffect(() => {
    inputRef.current = inputRef.current.slice(0, code.length);
    inputRef.current[0]?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!code.some((el) => el === '')) {
      enterRef?.current?.focus();
    }
  }, [code]);

  const handleCodeChange = (e, index) => {
    setCodeComponent((prev) =>
      prev.map((el, i) => (i === index ? e.target.value : el))
    );

    if (index + 1 === 6) {
      enterRef?.current?.focus();
      return;
    }
    inputRef.current[index + 1].focus();
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text').trim();

    const codeArray = [...paste];

    setCodeComponent(codeArray);
  };

  return (
    <S.Container>
      <S.Card
        sx={{
          width: '450px',
          height: '500px',
          boxShadow: '4px 4px 12px 8px rgba(11, 67, 118, 0.04)',
        }}
      >
        <CardHeader
          sx={(theme) => ({
            // @ts-ignore
            justifyContent: 'center',
            textTransform: 'uppercase',
            borderBottom: `10px solid ${theme.palette.lightCyan}`,
            textAlign: 'center',
          })}
          $headerDivider={true}
          title={t('two_factor_auth.title')}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            gap: '24px',
          }}
        >
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {t('two_factor_auth.subtitle')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '12px',
              alignSelf: 'stretch',
            }}
          >
            {[1, 2, 3, 4, 5, 6].map((el, index) => (
              <TextField
                key={`validateField-${index}`}
                value={code[index]}
                disabled={disabled}
                name={`code-${index}`}
                inputProps={{
                  ref: (el) => (inputRef.current[index] = el),
                  onPaste: handlePaste,
                  maxLength: 1,
                  sx: {
                    height: '88px',

                    flex: '1 0 0',
                    textAlign: 'center',
                    fontSize: '24px',
                    border: 'none',
                  },
                }}
                InputProps={{
                  sx: {
                    border: 'none',
                    borderRadius: '12px',
                    background: '#EFF4F8',

                    '& fieldset': {
                      border: 'none',
                    },
                  },
                }}
                onChange={(e) => handleCodeChange(e, index)}
              />
            ))}
          </Box>
          {disabled && (
            <Typography variant="subtitle2" color="GrayText">
              {t('two_factor_auth.resend_message')}
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Typography>{t('two_factor_auth.resend_code')}</Typography>
            <Button
              variant="text"
              sx={{
                color: '#172844',
                padding: '0',
                textDecorationLine: ' underline',
              }}
              onClick={handleResendCode}
            >
              <Typography
                sx={{
                  color: '#172844',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  textDecorationLine: 'underline',
                }}
              >
                {t('two_factor_auth.resend')}
              </Typography>
            </Button>
          </Box>
        </Box>

        <Box>
          <Button
            disabled={code.every((el) => el === '') || disabled}
            onClick={handleSend}
            variant="contained"
            ref={enterRef}
            sx={{
              padding: '12px 32px',
              alignItems: 'flex-start',
              gap: '10px',
              background: '#EA514D',
              borderRadius: '10px',
              color: '#FFFFFF',
            }}
          >
            {t('login.submit')}
          </Button>
        </Box>
      </S.Card>
    </S.Container>
  );
}

export default ValidateCode;
