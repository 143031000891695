// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Box, CircularProgress, Grid } from '@mui/material';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

// Store
import {
  updateCustomer,
  updateProperty,
  updateUser,
} from '../../../redux/actions';

// Utils
import {
  buildFormInitialValues,
  buildSingleFieldSchema,
  getInitialValuesInFormStructure,
} from '../../../utils';

// Components
import { FormBuilder, FormType } from '../../FormBuilder';

// Interfaces
import {
  ICustomerData,
  ILeadData,
  IPropertyData,
  IUserData,
} from '../../../interfaces';

interface IFormPanel {
  formData: any;
  formType: FormType;
  layout?: any;
  isLoading?: boolean;
  disabled?: boolean;
  disableSelfRoleChange?: boolean;
  setForm: (val: any) => void;
  setFileUploading?: (uploading: boolean) => void;
  setUnsavedData?: (val: boolean) => void;
  initialFormData: ICustomerData | ILeadData | IPropertyData | IUserData | null;
  refetchFormData: () => void;
}

// Export Form panel
export const FormPanel: FC<IFormPanel> = ({
  setForm,
  formType,
  formData,
  layout,
  disabled,
  disableSelfRoleChange,
  isLoading,
  initialFormData,
  refetchFormData,
  setFileUploading,
  setUnsavedData,
}) => {
  const [previousValues, setPreviousValues] = useState<any>();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = useMemo(() => {
    return buildSingleFieldSchema(formData);
  }, [formData]);

  const initialValues = useMemo(() => {
    return getInitialValuesInFormStructure(
      initialFormData,
      buildFormInitialValues(formData)
    );
  }, [formData, initialFormData]);

  const gridRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    gridRef?.current?.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik<any>({
    initialValues,
    validationSchema,

    onSubmit: async (values) => {
      try {
        const res: any = await dispatch(
          (() => {
            switch (formType) {
              case FormType.Customer: {
                return updateCustomer(initialFormData?.id, {
                  ...values,
                  stepId: 'single_personalData',
                });
              }

              case FormType.Property: {
                for (const key in values) {
                  if (
                    formData.fields &&
                    formData.fields[key]?.type === 'file'
                  ) {
                    if (values[key] && values[key]?.files?.length > 0) {
                      values[key] = values[key].files.filter(
                        (file) => file instanceof File
                      );
                      if (values[key]?.length === 0) {
                        delete values[key];
                      }
                    } else {
                      delete values[key];
                    }
                  }
                }
                return updateProperty(initialFormData?.id, values);
              }

              case FormType.User: {
                return updateUser(initialFormData?.id, values);
              }
            }
          })()
        );
        if (res.value.success && res.value.message) {
          refetchFormData();
          enqueueSnackbar(res.value.message, { variant: 'success' });
        } else {
          enqueueSnackbar(res.value.message, { variant: 'error' });
        }
        if (typeof setUnsavedData === 'function') {
          setUnsavedData(false);
        }
      } catch (e: any) {
        enqueueSnackbar(e?.response?.data?.message || 'Failed to save', {
          variant: 'error',
        });
        refetchFormData();

        if (typeof setUnsavedData === 'function') {
          setUnsavedData(false);
        }
      }
    },
  });

  useEffect(() => {
    if (previousValues?.passed_away)
      setPreviousValues((prevState) => ({
        ...prevState,
        passed_away: prevState.passed_away.toString(),
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousValues?.passed_away]);

  useEffect(() => {
    if (typeof setUnsavedData === 'function') {
      setUnsavedData(!isEqual(formik.values, previousValues));
    }
  }, [formik.values, setUnsavedData, previousValues]);

  useEffect(() => {
    formik.setValues(initialValues);
    setForm(formik);
    setPreviousValues(initialValues);
    formik.setErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationSchema, initialValues, setForm]);

  useEffect(() => {
    if (setFileUploading) {
      setFileUploading(formik.isSubmitting);
    }
  }, [formik.isSubmitting, setFileUploading]);

  useEffect(() => {
    if (formik.getFieldProps('passed_away').value) {
      formik.setFieldValue(
        'passed_away',
        formik.getFieldProps('passed_away').value.toString()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.getFieldProps('passed_away').value]);

  // Return Form panel
  return (
    <Grid
      container
      columns={2}
      columnSpacing={{ xs: 20, lg: 50 }}
      rowSpacing={25}
      ref={gridRef}
    >
      {isLoading || !Boolean(formData) ? (
        <Box
          sx={{
            height: 300,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <FormBuilder
          formType={formType}
          type={formData?.type}
          attributes={formData?.attributes}
          label={formData?.label}
          fields={formData?.fields}
          formik={formik}
          path=""
          disabled={disabled}
          layout={layout}
          parentFields={initialFormData}
          disableSelfRoleChange={disableSelfRoleChange}
          refetchFormData={refetchFormData}
        />
      )}
    </Grid>
  );
};
