import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Constants
import { Role } from '../constants';
import {
  ConsultationDocumentsWidget,
  LastChatsWidget,
  LastUpdatedObjectsWidget,
  LinkedInFeedWidget,
  ObjectsOverviewWidget,
  ObjectsValueWidget,
} from '../pages/Dashboard/Widgets';

// Store
import { RootState } from '../redux/reducers';

// Utils
import { isUserAllowed } from '../utils';

interface ILayoutMapItem {
  i?: string;
  visible: boolean;
  title: string;
  component: any;
}

export const useLayoutMap = () => {
  const [layoutMap, setLayoutMap] = useState<ILayoutMapItem[]>([]);
  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  useEffect(() => {
    const LAYOUT_MAP: ILayoutMapItem[] = [
      {
        visible: true,
        title: 'last_updated_objects',
        component: LastUpdatedObjectsWidget,
      },
      { visible: true, title: 'last_chats', component: LastChatsWidget },
      {
        visible: !isUserAllowed(account, Role.ROLE_ADMIN),
        title: 'linkedin_feed',
        component: LinkedInFeedWidget,
      },
      {
        visible: true,
        title: 'consultation_documents',
        component: ConsultationDocumentsWidget,
      },
      {
        visible: isUserAllowed(account, Role.ROLE_ADMIN),
        title: 'objects_overview',
        component: ObjectsOverviewWidget,
      },
      {
        visible: isUserAllowed(account, Role.ROLE_ADMIN),
        title: 'objects_value',
        component: ObjectsValueWidget,
      },
      {
        visible: isUserAllowed(account, Role.ROLE_ADMIN),
        title: 'linkedin_feed',
        component: LinkedInFeedWidget,
      },
    ];

    const layout = LAYOUT_MAP.filter(({ visible }) => visible).map(
      (item, index) => {
        const charIndex = String.fromCharCode(
          String('a').charCodeAt(0) + index
        );
        return {
          i: charIndex,
          ...item,
        };
      }
    );

    setLayoutMap(layout);
  }, [account]);

  return layoutMap;
};
