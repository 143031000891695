// Dependencies
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';

// Components
import Actions from './Actions';
import Information from './Information';
import Tabs, { DetailTab } from './Tabs';
import TabPanels from './TabPanels';

// Store
import { RootState } from '../../../../redux/reducers';

// Constants
import {
  getMainStatusKey,
  getStatusRoute,
  ROUTES,
  STATUS,
} from '../../../../constants';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

// Create detail card
const DetailCard = () => {
  // States
  // eslint-disable-next-line
  const [form, setForm] = useState<any>(null);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<DetailTab>(DetailTab.PersonalData);
  const [unsavedData, setUnsavedData] = useState<boolean>(false);

  // Theme
  const theme = useTheme();

  // Check platform
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // Get params from hook
  const params = useParams<{ id: string; filter: string }>();

  // Get location
  const location = useLocation();

  // Get navigate from hook
  const navigate = useNavigate();

  // Get project detail from store
  const { projectDetail } = useSelector(
    ({ projectReducer: { projectDetail, detailLoading } }: RootState) => ({
      projectDetail,
      detailLoading,
    })
  );

  useEffect(() => {
    if (!projectDetail) {
      return;
    }

    const previousRoute = location?.pathname?.split('/')[1]?.toUpperCase();
    const previousStatusKey = params?.filter;

    if (String(projectDetail?.id) === params?.id) {
      const statusKey = getMainStatusKey(projectDetail?.status);
      const route = getStatusRoute(statusKey);

      if (
        route &&
        (route !== previousRoute || previousStatusKey !== statusKey)
      ) {
        navigate(
          ROUTES[route].INDEX + `/${statusKey || ''}/${projectDetail?.id}`
        );
      }
    }
    // eslint-disable-next-line
  }, [projectDetail]);

  // Tab change handler
  const handleTabChange = (tab: DetailTab) => {
    setActiveTab(tab);
  };

  // Goto file page
  const handleGotoFileTab = () => {
    setActiveTab(DetailTab.Files);
  };

  // Active tab changed
  useEffect(() => {
    if (
      [
        DetailTab.IndicativeOffer,
        DetailTab.BindingOffer,
        DetailTab.ContractData,
        DetailTab.AdditionalCostsCalculator,
        DetailTab.Tasks,
      ].indexOf(activeTab) >= 0
    ) {
      setForm(null);
    }
  }, [activeTab]);

  // Return detail card

  return (
    <Card>
      <CardHeader
        sx={{ pb: { xs: 16, lg: 24 } }}
        title={
          isDesktop ? (
            <Information
              form={form}
              fileUploading={fileUploading}
              gotoFileTab={handleGotoFileTab}
              unsavedData={unsavedData}
            />
          ) : (
            <Actions
              form={form}
              fileUploading={fileUploading}
              gotoFileTab={handleGotoFileTab}
            />
          )
        }
        action={
          !isDesktop && (
            <Box
              sx={{
                width: 40,
                height: 40,
                backgroundColor: projectDetail
                  ? STATUS.customer[projectDetail.status]?.color ||
                    STATUS.property[projectDetail.status]?.color
                  : '',
                borderRadius: 20,
              }}
            />
          )
        }
      />
      <CardContent
        sx={{
          pb: { xs: 0, lg: 20 },
          pt: { xs: 0, lg: 8 },
          px: 0,
          height: '100%',
        }}
      >
        {!isDesktop && (
          <Information
            form={form}
            fileUploading={fileUploading}
            gotoFileTab={handleGotoFileTab}
            unsavedData={unsavedData}
          />
        )}
        <Tabs activeTab={activeTab} onTabChange={handleTabChange} />
        <TabPanels
          activeTab={activeTab}
          setForm={setForm}
          setFileUploading={setFileUploading}
          setUnsavedData={setUnsavedData}
        />
      </CardContent>
    </Card>
  );
};

// Export detail card
export default DetailCard;
