// Dependencies
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  ListItemText,
  Select,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

// Components
import {
  ConfirmModal,
  DataTable,
  IColumn,
  Icon,
  IconButton,
  Input,
} from '../../../../components';

// Utils
import { isUserAllowed } from '../../../../utils';

// Constants
import { Role, ROUTES } from '../../../../constants';

// Store
import { RootState } from '../../../../redux/reducers';
import { getDrafts, getDraft } from '../../../../redux/actions';

// Interfaces
import { ISortBy, Order } from '../../../../interfaces';

// Services
import { deleteDraft } from '../../../../services/client.service';

// Constants
import * as StatusService from '../../../../constants';

// Styles
import * as S from './styles';
import { EditDraftModal } from '../../../../components/Modals/EditDraftModal';

// Create list table
export const ListTable = () => {
  // States
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<ISortBy>({
    field: 'first_name',
    order: Order.Desc,
  });
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [selectedId, setSelectedId] = useState<number>();
  const [isEditDraftModalOpen, setIsEditDraftModalOpen] =
    useState<boolean>(false);
  const [visibleDeleteConfirmModal, setVisibleDeleteConfirmModal] =
    useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  // Get filter param
  const { filter } = useParams();

  const [filterBy, setFilterBy] = useState<string[]>(
    Object.keys(StatusService.getAvailableFilter(filter as string)) || []
  );

  const filters = useMemo(
    () => Object.keys(StatusService.getAvailableFilter(filter as string)),
    [filter]
  );

  // Theme
  const theme = useTheme();

  // Get translation from hook
  const { t } = useTranslation();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get path from hook
  const { pathname } = useLocation();

  // Check platform
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Get status from store
  const isDrawerOpened = useSelector(
    ({ uiReducer: { isDrawerOpened } }: RootState) => isDrawerOpened
  );

  // Check list type
  const isObjectType = useMemo(() => {
    return pathname.includes(ROUTES.OBJECTS.INDEX);
  }, [pathname]);

  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  const { drafts, loading } = useSelector(
    ({ clientReducer: { drafts, loading } }: RootState) => ({
      drafts,
      loading,
    })
  );

  // Get all drafts
  const { allDrafts } = useMemo(
    () => ({
      allDrafts: drafts && filter ? drafts[filter as any] : [],
    }),
    [drafts, filter]
  );

  useEffect(() => {
    dispatch(getDrafts());
  }, [dispatch]);

  // Delayed search handler
  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  // Open search input handler
  const handleOpenSearchInput = () => {
    setVisibleSearch(true);
  };

  // Close search input handler
  const handleCloseSearchInput = () => {
    setVisibleSearch(false);
  };

  // Filter change handler
  const handleFilterChange = (e) => {
    const value = e.target.value;
    if (filterBy.indexOf(value) === -1) {
      setFilterBy([...value]);
    } else {
      setFilterBy(filterBy.filter((filter) => filter === value));
    }
  };

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSortBy(value);
  };

  // Page change handler
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
  };

  // Search change handler
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // Open close confirm modal
  const handleOpenDeleteConfirmModal = (id: number) => {
    setSelectedId(id);
    setVisibleDeleteConfirmModal(true);
  };

  const checkAssignedUser = (id: number) => {
    const draft = drafts.filter((draft) => draft.id === id);

    if (
      account?.id !== draft[0]?.assignedUser?.id &&
      !isUserAllowed(account, Role.ROLE_SUPER_ADMIN)
    )
      return account?.id === draft[0]?.assignedUser?.id;

    return true;
  };

  const handleOpenDraft = (id: number) => {
    // const draft = drafts.filter((draft) => draft.id === id);

    if (!checkAssignedUser(id)) return;

    dispatch(getDraft(id));
    setIsEditDraftModalOpen(true);
  };

  const handleCloseCreateClientModal = () => {
    setIsEditDraftModalOpen(false);
  };

  // Close delete confirm modal
  const handleCloseDeleteConfirmModal = () => {
    setVisibleDeleteConfirmModal(false);
  };

  // Delete handler
  const handleDelete = async () => {
    setDeleteLoading(true);
    deleteDraft(selectedId)
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          dispatch(getDrafts());
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' });
        }
        setDeleteLoading(false);
        setVisibleDeleteConfirmModal(false);
      })
      .catch(() => {
        setDeleteLoading(false);
        setVisibleDeleteConfirmModal(false);
      });
  };

  // Columns
  const columns: IColumn[] = [
    {
      field: 'first_name',
      label: t('draft.first_name'),
      render: (row: any, isSelected) => (
        <Stack direction="row" alignItems="center">
          <ListItemText
            primary={`${row.first_name}`}
            secondaryTypographyProps={{
              color: isSelected
                ? theme.palette.darkIndigo
                : theme.palette.lightIndigo,
            }}
          />
        </Stack>
      ),
    },
    {
      field: 'last_name',
      label: t('draft.last_name'),
      render: (row: any, isSelected) => (
        <Stack direction="row" alignItems="center">
          <ListItemText
            primary={`${row.last_name}`}
            secondaryTypographyProps={{
              color: isSelected
                ? theme.palette.darkIndigo
                : theme.palette.lightIndigo,
            }}
          />
        </Stack>
      ),
    },
    // {
    //   field: filter === STATUS_MAPPING.new.key ? 'created' : 'submitted',
    //   label:
    //     filter === STATUS_MAPPING.new.key
    //       ? t('projects.created_date')
    //       : t('projects.submitted_date'),
    //   render: (row: any, isSelected) => (
    //     <Box color={isSelected ? 'darkIndigo' : 'middleCyan'}>
    //       {row.status === STATUS_MAPPING.new.key
    //         ? t('projects.created')
    //         : getProjectSubmittedDate(row)
    //         ? t('projects.submitted')
    //         : t('projects.created')}
    //       <br />{' '}
    //       {row.status === STATUS_MAPPING.new.key
    //         ? getProjectCreatedDate(row)
    //         : getProjectSubmittedDate(row) || getProjectCreatedDate(row)}
    //     </Box>
    //   ),
    // },
    {
      field: 'status',
      label: t('projects.status'),
      render: (row: any) => <S.Status color={'#FFA800'}>Entwurf</S.Status>,
      sortable: false,
    },
  ];

  // Row actions
  const rowActions = [
    {
      icon: 'trash',
      label: t('draft.delete'),
      hidden: !isUserAllowed(account, Role.ROLE_AGENTS),
      hiddenFunc: (row) => checkAssignedUser(row.id),
      onClick: handleOpenDeleteConfirmModal,
    },
  ];

  // On search changed
  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
  }, [search, delayedChangeSearch]);

  // On pagination changed
  // useEffect(() => {
  //   if (pagination?.total) {
  //     setTotalPage(Math.ceil(pagination?.total / rowsPerPage));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pagination, rowsPerPage]);

  // On filter changed
  useEffect(() => {
    setFilterBy(
      Object.keys(StatusService.getAvailableFilter(filter as string))
    );
  }, [filter]);

  // Return list table
  return (
    <>
      <Card>
        <CardHeader
          sx={{ pt: 22, pb: 20 }}
          title={
            visibleSearch ? (
              <Stack spacing={10} direction="row">
                <IconButton onClick={handleCloseSearchInput}>
                  <Icon name="x-lg" />
                </IconButton>
                <Input
                  disabled={loading}
                  startAdornment={<Icon name="search" />}
                  placeholder={t('projects.search')}
                  value={search}
                  sx={{
                    flex: { xs: 1, md: 'unset' },
                    width: { xs: '100%', md: 300 },
                  }}
                  onChange={handleSearchChange}
                />
              </Stack>
            ) : (
              <Stack>
                <IconButton disabled={loading} onClick={handleOpenSearchInput}>
                  <Icon name="search" />
                </IconButton>
              </Stack>
            )
          }
          action={
            isMobile ? (
              !visibleSearch && <Stack direction="row" spacing={12}></Stack>
            ) : (
              <Stack direction="row" spacing={12}>
                {!isObjectType && (
                  <Select
                    sx={(theme) => ({
                      display: { xs: 'none', sm: 'flex' },
                      background: 'transparent',
                      fontFamily: 'Work Sans',
                      fontWeight: '600',
                      color: theme.palette.lightIndigo,
                    })}
                    value={filterBy}
                    displayEmpty
                    multiple
                    onChange={handleFilterChange}
                    renderValue={() => t('projects.filter_by_status')}
                  >
                    {filters.map((option, index) => (
                      <S.MenuItem key={index} value={option}>
                        <Checkbox checked={filterBy.indexOf(option) > -1} />
                        <ListItemText
                          primary={StatusService.STATUS.customer[option]?.name}
                          sx={{ fontSize: 10 }}
                        />
                      </S.MenuItem>
                    ))}
                  </Select>
                )}
              </Stack>
            )
          }
        />
        <CardContent
          sx={(theme) => ({
            p: '0 !important',
            // @ts-ignore
            borderTop: `1px solid ${theme.palette.lightCyan}`,
          })}
        >
          <DataTable
            sx={{ flex: 1 }}
            paginated
            stickyHeader
            columnEditable
            columns={columns}
            rowActions={rowActions}
            data={drafts || []}
            page={page}
            type={'drafts'}
            selected={selected}
            isLoading={loading}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            contentSize={
              isMobile
                ? {
                    width: 'calc(100vw - 32px)',
                    height: isObjectType
                      ? 'calc(100vh - 252px)'
                      : 'calc(100vh - 330px)',
                  }
                : {
                    width: isDrawerOpened
                      ? 'calc(100vw - 328px)'
                      : 'calc(100vw - 160px)',
                    height: isObjectType
                      ? 'calc(100vh - 316px)'
                      : 'calc(100vh - 406px)',
                  }
            }
            onSort={handleSort}
            onRowClick={handleOpenDraft}
            onChangeSelected={setSelected}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </CardContent>
      </Card>
      {visibleDeleteConfirmModal && (
        <ConfirmModal
          open={visibleDeleteConfirmModal}
          onClose={handleCloseDeleteConfirmModal}
          onOk={handleDelete}
          loading={deleteLoading}
        />
      )}
      {isEditDraftModalOpen && (
        <EditDraftModal
          open={isEditDraftModalOpen}
          onClose={handleCloseCreateClientModal}
        />
      )}
    </>
  );
};

// Export list table
export default ListTable;
