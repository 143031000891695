// Dependencies
import {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Components
import {
  Checkbox,
  ConfirmModal,
  Icon,
  Typography,
} from '../../../../../../components';

// Store
import { RootState } from '../../../../../../redux/reducers';
import { getTasks } from '../../../../../../redux/actions';

// Global constants
import * as StatusService from '../../../../../../constants';
import { Role } from '../../../../../../constants';
import {
  IProjectData,
  IProjectTask,
  ITask,
} from '../../../../../../interfaces';
import {
  setProjectTask,
  updateProjectTask,
} from '../../../../../../services/task.service';
import { isUserAllowed } from '../../../../../../utils';

import * as S from './styles';

// Interfaces
interface ITaskPanelProps {
  projectId?: number;
  projectStatus: string;
  isLoading?: boolean;
  projectDetail?: IProjectData | null;
  refetchFormData: () => Promise<void>;
}

// Create task panel
const TasksPanel: FC<ITaskPanelProps> = ({
  projectStatus,
  projectDetail,
  refetchFormData,
}) => {
  // States
  const [expanded, setExpanded] = useState<string>();
  const [visibleConfirmModal, setVisibleConfirmModal] =
    useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<ReactNode>('');
  const [confirmTitle, setConfirmTitle] = useState<string>('');
  const [selectedProjectTask, setSelectedProjectTask] = useState<{
    projectTask: ITask;
    isClosed: number;
  }>();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  // Get translation from hook
  const { t } = useTranslation();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get account from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Get tasks from store
  const { tasks, loading } = useSelector(
    ({ taskReducer }: RootState) => taskReducer
  );

  const tasksByStatus = useMemo(() => {
    const objectTasks = tasks.filter((task) => {
      const statusKeys = Object.keys(StatusService.getAll());

      if (
        statusKeys.indexOf(projectDetail?.status || '') <
        statusKeys.indexOf(task.projectStatus)
      ) {
        return false;
      }

      const resolve = (path: string, obj: any) => {
        return path.split('.').reduce(function (prev, curr) {
          return prev ? prev[curr] : null;
        }, obj);
      };

      if (task.conditions.length) {
        const calcConditions = task.conditions.map((condition) => {
          const projectData = resolve(condition.data, projectDetail);
          if (!projectData == null) {
            return false;
          }
          let is = true;
          switch (condition.condition) {
            case 'eq':
              is = projectData === condition.value;
              break;
            case 'eqn':
              is = projectData !== condition.value;
              break;
            case 'gt':
              is = projectData > condition.value;
              break;
          }
          return is;
        });
        return !calcConditions.includes(false);
      }
      return true;
    });

    let taskData: any[] = [];

    objectTasks?.forEach((task) => {
      const taskTable = taskData.find(
        (data) => data.status === task.projectStatus
      );
      if (taskTable) {
        taskData = taskData.map((table) => {
          if (table.status === task.projectStatus) {
            return {
              status: table.status,
              tasks: [...table.tasks, task],
            };
          } else {
            return table;
          }
        });
      } else {
        taskData.push({
          status: task.projectStatus,
          tasks: [task],
        });
      }
    });

    return taskData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, projectDetail, projectStatus]);

  // Accordion change handler
  const handleChange = (panel: string, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const handleOnChangeTask = async (projectTask) => {
    const task: any = {
      ...projectTask,
      task: projectTask.task.id,
      project: projectDetail?.id,
    };
    if (projectTask.id) {
      await updateProjectTask(projectDetail?.id, task);
    } else {
      await setProjectTask(projectDetail?.id, task);
    }

    return Promise.resolve();
  };

  // Click checkbox handler
  const handleChangeAccepted = async (projectTask, isClosed = 1) => {
    setSelectedProjectTask({
      projectTask,
      isClosed,
    });
    setConfirmTitle(`Aufgabe als ${isClosed ? '' : 'un'}erledigt markieren?`);
    if (isClosed) {
      if (projectTask?.task.children?.length) {
        setConfirmText(
          <>
            Kann <strong>nicht</strong> rückgängig gemacht werden.
            <br />
            Unteraufgaben werden auch als erledigt makiert.
          </>
        );
      } else {
        setConfirmText(
          <>
            Kann <strong>nicht</strong> rückgängig gemacht werden.
          </>
        );
      }
    } else {
      setConfirmText(`Wirklich rückgängig machen.`);
    }
    setVisibleConfirmModal(true);
  };

  const handleChangeTask = async () => {
    setConfirmLoading(true);
    await handleOnChangeTask({
      ...selectedProjectTask?.projectTask,
      isClosed: selectedProjectTask?.isClosed,
    });
    await refetchFormData();
    setConfirmLoading(false);
    setVisibleConfirmModal(false);
  };

  const getProjectTasks = useCallback(
    (task) => {
      return (
        projectDetail?.projectTasks?.find(
          (tempTask) => tempTask.task.id === task.id
        ) ?? ({ task } as IProjectTask)
      );
    },
    [projectDetail]
  );

  const handleCloseConfirmModal = () => {
    setVisibleConfirmModal(false);
  };

  // On mounted
  useEffect(() => {
    dispatch(getTasks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Return Tasks panel
  return (
    <>
      <Typography mb={16} variant="h3">
        {t('project_detail.tasks')}
      </Typography>
      {loading ? (
        <Box
          sx={{
            height: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        tasksByStatus.map(
          ({ status, tasks }, iIndex) =>
            tasks.length > 0 && (
              <Accordion
                sx={(theme) => ({
                  '&:not(:last-child)': {
                    borderBottom: 0,
                  },
                  '&:before': {
                    display: 'none',
                  },
                  mb: theme.spacing(12),
                })}
                key={iIndex}
                disableGutters
                expanded={status === expanded}
                onChange={(_, isExpanded) => handleChange(status, isExpanded)}
              >
                <AccordionSummary
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette['cyan']}`,
                    borderRadius: 2,
                    p: theme.spacing(0, 24, 0, 12),
                    '.MuiAccordionSummary-content': {
                      margin: theme.spacing(11, 17),
                    },
                    '.MuiAccordionSummary-content.Mui-expanded': {
                      margin: theme.spacing(11, 17),
                    },
                  })}
                  expandIcon={
                    expanded === status ? (
                      <Icon size={15} name="dash-lg" />
                    ) : (
                      <Icon size={15} name="plus-lg" />
                    )
                  }
                >
                  {StatusService.getFullName(status)}
                </AccordionSummary>
                <AccordionDetails
                  sx={(theme) => ({ pt: theme.spacing(10), px: 1 })}
                >
                  <S.Scrollbar>
                    <Table sx={{ my: 10 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} />
                          <TableCell>{t('project_detail.tasks')}</TableCell>
                          <TableCell>{t('project_detail.broker')}</TableCell>
                          <TableCell>
                            {t('project_detail.date_and_time')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tasks.map((task, jIndex) => {
                          const { message, children } = task;
                          const projectTask = getProjectTasks(task);
                          return (
                            <Fragment key={jIndex}>
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    checked={projectTask?.isClosed ?? false}
                                    onChange={(_) => {
                                      if (projectTask?.isClosed) {
                                        if (
                                          isUserAllowed(
                                            account,
                                            Role.ROLE_SUPER_ADMIN
                                          )
                                        ) {
                                          handleChangeAccepted(projectTask, 0);
                                        }
                                      } else {
                                        handleChangeAccepted(projectTask);
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell colSpan={2}>{message}</TableCell>
                                <TableCell>
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar
                                        src={
                                          projectTask?.closedUser?.image?.path
                                        }
                                        sx={{
                                          width: 32,
                                          height: 32,
                                          borderRadius: 2,
                                        }}
                                      >
                                        <Icon name="image-fill" />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      secondary={
                                        (projectTask?.closedUser?.first_name ||
                                          '') +
                                        ' ' +
                                        (projectTask?.closedUser?.last_name ||
                                          '')
                                      }
                                    />
                                  </ListItem>
                                </TableCell>
                                <TableCell>
                                  {projectTask?.closedDate
                                    ? moment(projectTask?.closedDate).format(
                                        'DD.MM.YYYY'
                                      )
                                    : '--.--.----'}
                                </TableCell>
                              </TableRow>
                              {children.map((subTask, index) => {
                                const subProjectTask = getProjectTasks(subTask);
                                return (
                                  <TableRow key={index}>
                                    <TableCell />
                                    <TableCell>
                                      <Checkbox
                                        checked={
                                          subProjectTask?.isClosed ?? false
                                        }
                                        onChange={(_) => {
                                          if (subProjectTask?.isClosed) {
                                            if (
                                              isUserAllowed(
                                                account,
                                                Role.ROLE_SUPER_ADMIN
                                              )
                                            ) {
                                              handleChangeAccepted(
                                                subProjectTask,
                                                0
                                              );
                                            }
                                          } else {
                                            handleChangeAccepted(
                                              subProjectTask
                                            );
                                          }
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>{subTask.message}</TableCell>
                                    <TableCell>
                                      <ListItem>
                                        <ListItemAvatar>
                                          <Avatar
                                            src={
                                              subProjectTask?.closedUser?.image
                                                ?.path
                                            }
                                            sx={{
                                              width: 32,
                                              height: 32,
                                              borderRadius: 2,
                                            }}
                                          >
                                            <Icon name="image-fill" />
                                          </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                          secondary={
                                            (subProjectTask?.closedUser
                                              ?.first_name || '') +
                                            ' ' +
                                            (subProjectTask?.closedUser
                                              ?.last_name || '')
                                          }
                                        />
                                      </ListItem>
                                    </TableCell>
                                    <TableCell>
                                      {subProjectTask?.closedDate
                                        ? moment(
                                            subProjectTask?.closedDate
                                          ).format('DD.MM.YYYY')
                                        : '--.--.----'}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </S.Scrollbar>
                </AccordionDetails>
              </Accordion>
            )
        )
      )}
      <ConfirmModal
        open={visibleConfirmModal}
        title={confirmTitle}
        text={confirmText}
        loading={confirmLoading}
        onClose={handleCloseConfirmModal}
        onOk={handleChangeTask}
      />
    </>
  );
};

// Export task panel
export default TasksPanel;
