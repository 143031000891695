// Dependencies
import React, { FC, ReactNode } from 'react';
import {
  Box,
  Card,
  DialogProps,
  Divider,
  PaginationItem,
  PaginationItemProps,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// Components
import { Icon } from '../Icon';

// Styles
import * as S from './styles';

// Interfaces
export interface IDialogProps extends Omit<DialogProps, 'title'> {
  title: ReactNode;
  open: boolean;
  onClose: (event: any, reason?: 'escapeKeyDown' | 'backdropClick') => void;
  children: ReactNode;
  headerChild?: ReactNode;
  actions?: ReactNode;
  contentHeight?: string | number;
  contentPadding?: string | number;
  pagination?: {
    total: number;
    current: number;
  };
  loading?: boolean;
  isEmpty?: boolean;
  onPageChange?: (page: number) => void;
  userSelected?: boolean;
}

// Export Dialog component
export const Dialog: FC<IDialogProps> = ({
  open,
  onClose,
  title,
  children,
  actions,
  contentHeight = '50%',
  contentPadding = '10px',
  headerChild,
  pagination,
  loading,
  isEmpty,
  onPageChange,
  userSelected,
  ...rest
}) => {
  const theme = useTheme();
  const smallScreenSize = useMediaQuery(theme.breakpoints.down('md'));

  // Check platform
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const handleRenderPageItem = (item: PaginationItemProps) => {
    switch (item.type) {
      case 'page': {
        if (
          pagination &&
          (item.page === 1 ||
            item.page === 2 ||
            item.page === pagination?.total - 1 ||
            item.page === pagination?.total ||
            item.selected)
        ) {
          return <PaginationItem {...item} />;
        }
        if (
          pagination &&
          ((item.page === 3 && pagination?.current === 4) ||
            (item.page === pagination?.total - 2 &&
              pagination?.current === pagination?.total - 3))
        ) {
          return <PaginationItem type="start-ellipsis" />;
        }
        break;
      }

      default: {
        return <PaginationItem {...item} />;
      }
    }
  };
  return (
    <S.Dialog onClose={onClose} open={open} fullWidth scroll="body" {...rest}>
      <S.DialogTitle>
        {smallScreenSize ? (
          <Stack direction="column" width={'100%'}>
            <Stack direction={'row'} flex={1} justifyContent={'space-between'}>
              {typeof title === 'string' ? (
                <Typography variant="h2">{title}</Typography>
              ) : (
                title
              )}
              {onClose ? (
                <S.CloseIconButton onClick={onClose} color="default">
                  <Icon name="x-lg" />
                </S.CloseIconButton>
              ) : null}{' '}
            </Stack>

            {headerChild && headerChild}
          </Stack>
        ) : (
          <>
            {typeof title === 'string' ? (
              <Typography variant="h2">{title}</Typography>
            ) : (
              title
            )}

            {headerChild && headerChild}

            {onClose ? (
              <S.CloseIconButton onClick={onClose} color="default">
                <Icon name="x-lg" />
              </S.CloseIconButton>
            ) : null}
          </>
        )}
      </S.DialogTitle>
      <S.DialogContent
        height={contentHeight}
        padding={contentPadding}
        $visiblePagination={Boolean(pagination && pagination.total > 0)}
      >
        {' '}
        {loading ? 'Loading...' : isEmpty ? 'Empty...' : children}
        {isTablet && !userSelected && pagination && pagination.total > 0 && (
          <Stack
            alignItems="center"
            sx={(theme) => ({
              pt: theme.spacing(8),
              borderTop: `1px solid ${theme.palette.lightCyan}`,
            })}
          >
            <S.Pagination
              shape="rounded"
              count={pagination.total}
              page={pagination.current}
              onChange={(e, page: number) =>
                onPageChange ? onPageChange(page) : undefined
              }
              renderItem={handleRenderPageItem}
              sx={{ alignSelf: { sm: 'center', md: 'flex-start' } }}
            />
          </Stack>
        )}
        {!isTablet && pagination && pagination.total > 0 && (
          <Stack
            alignItems="center"
            sx={(theme) => ({
              pt: theme.spacing(8),
              borderTop: `1px solid ${theme.palette.lightCyan}`,
            })}
          >
            <S.Pagination
              shape="rounded"
              count={pagination.total}
              page={pagination.current}
              onChange={(e, page: number) =>
                onPageChange ? onPageChange(page) : undefined
              }
              renderItem={handleRenderPageItem}
              sx={{ alignSelf: { sm: 'center', md: 'flex-start' } }}
            />
          </Stack>
        )}
      </S.DialogContent>
      {actions && <S.DialogActions>{actions}</S.DialogActions>}
    </S.Dialog>
  );
};
