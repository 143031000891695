import styled from 'styled-components';
import { Box, ButtonBase, CircularProgress, Dialog, Stack } from '@mui/material';
import OwlCarousel from 'react-owl-carousel3';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const ImageGallery = styled(Dialog)`
  && {
    .MuiPaper-root {
      background: none;
    }
  }
`;

export const Header = styled(Stack)`
  && {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 28px 20px;
    background-color: rgba(0, 0, 0, 0.3);

    ${props => props.theme.breakpoints.down('lg')} {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
`;

export const Content = styled(Box)`
  display: flex;
  height: 100%;
`;

export const ArrowButton = styled(ButtonBase)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;

    &:first-child {
      ${props => props.theme.breakpoints.down('sm')} {
        position: absolute;
        top: 148px;
        left: 0;
        height: calc(100vh - 148px);
        z-index: 2;
      }

      svg {
        transform: rotate(90deg);
      }
    }

    &:last-child {
      ${props => props.theme.breakpoints.down('sm')} {
        position: absolute;
        top: 148px;
        right: 0;
        height: calc(100vh - 148px);
        z-index: 1;
      }
      
      svg {
        transform: rotate(-90deg);
      }
    }

    &.Mui-disabled {
      svg {
        display: none !important;
      }
    }

    svg {
      background-color: ${props => props.theme.palette.secondary.main};
      border-radius: 3px;
      padding: 5px;
      height: 30px;
      width: 30px;
    }
  }
`;

export const Carousel = styled(OwlCarousel)`
  && {
    width: calc(100vw - 200px);

    ${props => props.theme.breakpoints.down('sm')} {
      width: 100vw;
    }

    .owl-stage-outer {
      height: 100%;

      .owl-stage {
        height: 100%;

        .owl-item {
          height: 100%;

          img {
            width: auto;
            height: auto;
            max-height: calc(100vh - 92px);
            max-width: 100%;
          }
        }
      }
    }

    .owl-nav {
      display: none;
    }
  }
`;

export const Image = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Scrollbar = styled(PerfectScrollbar)`
  width: calc(100vw - 300px);

  ${props => props.theme.breakpoints.down('lg')} {
    width: calc(100vw - 40px);
  }

  .ps__rail-x {
    display: none;
  }
`;

export const LoadingProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
