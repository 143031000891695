import { GET, POST } from './axios.services';
import { APIS } from '../utils';
import { buildFormRequestData } from '../utils';

export const login = async (params: any, headerData: any) => {
  return await POST(`${APIS.auth.login}`, params, headerData, true);
};

export const logout = async () => {
  return await POST(`${APIS.auth.logout}`, null, null, true);
};

export const self = async (params: any, headerData: any) => {
  try {
    return await GET(`${APIS.auth.self}`, params, headerData, true);
  } catch (e: any) {
    throw e?.message;
  }
};

export const getRegisterForms = async () => {
  return await GET(`${APIS.auth.getRegisterForms}`, null, null, true);
};

export const register = async (formData) => {
  const data = buildFormRequestData(formData);

  return await POST(`${APIS.auth.register}`, data);
};

export const changePassword = async (params: any) => {
  return await POST(`${APIS.auth.changePassword}`, params, null, true);
};

export const forgotPassword = async (formData: any) => {
  return await POST(APIS.auth.forgotPassword, formData, null, true);
};

export const resetPassword = async (formData: any) => {
  return await POST(APIS.auth.resetPassword, formData, null, true);
};

export const resendAuthCode = async (params: any) => {
  return await POST(APIS.auth.resendAuthCode, params, null, true);
};

export const optIn = async (data) => {
  return await POST(APIS.auth.optIn, data);
};

export const setFeatureActive = async (data) => {
  return await POST(APIS.auth.setFeatureActive, data, null, true);
};

export const getFeatureActive = async (key) => {
  return await GET(`${APIS.auth.getFeatureActive}/${key}`, null, null, true);
};
