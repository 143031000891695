// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { Avatar, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Chip, DataTable, IColumn, Typography } from '../../Common';

// Global constants
import {
  getStatusRoute,
  ROUTES,
  STATUS,
  STATUS_MAPPING,
} from '../../../constants';

// Store
import { RootState } from '../../../redux/reducers';
import { getProjects } from '../../../redux/actions';

// Interfaces
import { IProjectData, ISortBy, Order } from '../../../interfaces';

// Utils
import { getAddress, getProjectPrimaryImage } from '../../../utils';

interface IProjectPanelProps {
  status: string;
  brokerId: string | number | undefined;
}

// Constants
const panelName = {
  new: 'detected',
  inProgress: 'submitted',
  declined: 'rejected',
  bought: 'bought',
  sold: 'sold',
};

// Export Project Panel
export const ProjectPanel: FC<IProjectPanelProps> = ({ status, brokerId }) => {
  // Get translation from hook
  const { t } = useTranslation();

  // States
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<ISortBy>({
    field: 'date-submit',
    order: Order.Desc,
  });

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get params from hook
  const params = useParams();

  // Get navigate from hook
  const navigate = useNavigate();

  // Get project from store
  const {
    all: projects,
    pagination,
    loading,
  } = useSelector(({ projectReducer: { projects, loading } }: RootState) => ({
    ...projects,
    loading,
  }));

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSortBy(value);
  };

  // Go to detail handler
  const handleGoToDetail = (id) => {
    const statusRoute = getStatusRoute(status);
    if (statusRoute) {
      navigate(
        ROUTES[statusRoute].DETAIL.replace(':filter', status || '').replace(
          ':id',
          id
        )
      );
    }
  };

  // Page change handler
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
    setPage(1);
  };

  // Columns
  const columns: IColumn[] = [
    {
      field: 'photo',
      label: t('brokers_detail.photo'),
      sortable: false,
      render: (row: IProjectData) => (
        <Avatar src={getProjectPrimaryImage(row)} />
      ),
    },
    {
      field: 'name',
      label: t('brokers_detail.name'),
      render: (row: IProjectData) => (
        <Typography variant="body2" fontWeight={500}>
          {`${row.customer.first_name} ${row.customer.last_name}`}
        </Typography>
      ),
    },
    {
      field: 'email',
      label: t('brokers_detail.email'),
      visible: false,
      render: (row: IProjectData) => row.customer?.email,
    },
    {
      field: 'address',
      label: t('brokers_detail.address'),
      render: (row: IProjectData) => getAddress(row),
    },
    {
      field: 'sub-status',
      label: t('brokers_detail.sub_status'),
      render: (row: IProjectData) =>
        row.status ? (
          <Chip
            label={STATUS.customer[row.status]?.name || STATUS.property[row.status]?.name}
            color="success"
          />
        ) : (
          '-'
        ),
    },
  ];

  // On id, sortBy changed
  useEffect(() => {
    if (brokerId) {
      dispatch(
        getProjects('all', {
          page,
          perPage: rowsPerPage,
          creator: brokerId,
          sort: sortBy.field,
          'sort-order': sortBy.order,
          status: STATUS_MAPPING[status].status,
        })
      );
    }
  }, [dispatch, params.id, status, sortBy, brokerId, page, rowsPerPage]);

  // On pagination changed
  useEffect(() => {
    if (pagination?.total || pagination?.total === 0) {
      setTotalPage(Math.ceil(pagination?.total / rowsPerPage));
    }
  }, [pagination, rowsPerPage]);

  // Return Project
  return (
    <Stack spacing={16}>
      <Typography variant="h3">
        {t(`brokers_detail.${panelName[status]}`)}
      </Typography>
      <DataTable
        paginated
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette['cyan']}`,
        })}
        isLoading={loading}
        data={projects || []}
        columns={columns}
        page={page}
        totalPage={totalPage}
        rowsPerPage={rowsPerPage}
        onSort={handleSort}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onRowClick={handleGoToDetail}
      />
    </Stack>
  );
};
