// Dependencies
import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
  Popover,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

// Components
import {
  Button,
  CreateNewClientModal,
  DataTable,
  ConfirmModal,
  DetailViewLayout,
  Icon,
  IconButton,
  Input,
  Typography,
  Chip,
} from '../../components';

import * as S from './styles';
import { darkIndigo, white } from '../../theme/palette';

// Store
import { RootState } from '../../redux/reducers';
import {
  getCreateClientForms,
  getLeadDetail,
  getLeads,
  setLeadDetail,
} from '../../redux/actions';

// Global constants
import { Role, ROUTES } from '../../constants';

// Interfaces
import { ISortBy, ILeadData, Order } from '../../interfaces';
import { LeadStatus } from '../../interfaces/lead-status.type';
import {
  getGengerLabel,
  getLeadAgentName,
  getLeadCreateDate,
  getLeadName,
  getLeadPaymentType,
  getLeadPropertyTypeLabel,
} from '../../utils/leads.utils';
import {
  assignBroker,
  deleteLead,
  updateLead,
} from '../../services/lead.service';
import { AssignBrokerModal } from '../../components/Modals/AssignBrokerModal';
import { LEAD_STATUSES } from '../../constants/lead.constant';
import { getCurrency, isUserAllowed } from '../../utils';

// Export LeadsDetail page
export const LeadsDetailPage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  // Get t from hook
  const { t } = useTranslation();

  const [statusMenuAnchor, setStatusMenuAnchor] = useState<null | HTMLElement>(
    null
  );
  const [status, setStatus] = useState<LeadStatus>(LeadStatus.New);
  const [data, setData] = useState<ILeadData[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<ISortBy>({
    field: 'date',
    order: Order.Desc,
  });
  const [, setVisibleExtraInformationModal] = useState<boolean>(false);
  const [visibleDeleteConfirmModal, setVisibleDeleteConfirmModal] =
    useState<boolean>(false);
  const [openCreateClientModal, setOpenCreateClientModal] =
    useState<boolean>(false);
  const [createClientFormsData, setCreateClientFormsData] = useState({});
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [visibleAssignBrokerModal, setVisibleAssignBrokerModal] =
    useState<boolean>(false);
  const [assignedBrokerPopupEl, setAssignedBrokerPopupEl] =
    useState<null | HTMLElement>(null);
  const [possible, setPossible] = useState<boolean>(false);

  // Theme
  const theme = useTheme();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get state from hook
  const { state } = useLocation();

  // Get params from hook
  const params = useParams();

  // Get account from the store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Get lead data from store
  const { leads, leadDetail, loading, detailLoading, pagination } = useSelector(
    ({ leadReducer }: RootState) => leadReducer
  );

  // Check platform
  const isDesktop = useMediaQuery('(min-width: 1300px)');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Get navigate from hook
  const navigate = useNavigate();

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setStatusMenuAnchor(event.currentTarget);
  };
  const handleCloseStatusMenu = () => {
    setStatusMenuAnchor(null);
  };

  // Go back handler
  const handleGoBack = () => {
    navigate(ROUTES.LEADS.INDEX);
  };

  const handleSelectStatus = (value) => {
    setStatusMenuAnchor(null);
    setStatus(value);

    if (leadDetail) {
      updateLead(leadDetail.id, {
        status: value,
      }).then((res) => {
        if (res.success) {
          enqueueSnackbar(res?.message || 'Lead status changed', {
            variant: 'success',
          });
          dispatch(setLeadDetail(res.data));
        } else {
          enqueueSnackbar(res?.message || 'Failed to change status', {
            variant: 'error',
          });
        }
      });
    }
  };

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSortBy(value);
  };

  // Row click handler
  const handleRowClick = (id) => {
    navigate(ROUTES.LEADS.DETAIL.replace(':id', id));
  };

  // Page change handler
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
    setPage(1);
  };

  // Open delete confirm modal handler
  const handleOpenDeleteConfirmModal = () => {
    setVisibleDeleteConfirmModal(true);
  };

  // Close delete confirm modal handler
  const handleCloseDeleteConfirmModal = () => {
    setVisibleDeleteConfirmModal(false);
  };

  const handleOpenCreateClientModal = () => {
    const lead = leadDetail;
    if (lead) {
      const clientFormsData = {
        personalData: {
          first_name: lead.first_name || '',
          last_name: lead.last_name || '',
          gender: lead.gender || '',
          address: {
            zip: lead.plz || '',
          },
        },
        contactData: {
          phone: lead.phone || '',
          email: lead.email || '',
        },
        familyData: {
          marital_status: lead.has_partner ? 'partnered' : 'unmarried',
          partner_gender: lead.has_partner ? lead.partner_gender : '',
        },
        offerData: {
          option1: !!lead.onetime_payment,
          amount_one_time_payment: lead.onetime_payment
            ? lead.desired_rent
            : '',
        },
        propertyData: {
          property_type: lead.property_type || '',
          living_space: lead.living_space || '',
          year_of_construction: lead.year_of_construction || '',
        },
        propertyProperties: {
          garage_amount: lead.garage_count || '',
        },
        propertyAssesment: {
          customer_property_assessment: lead.comment || '',
          property_value: lead.property_value || '',
        },
      };
      setCreateClientFormsData(clientFormsData);
      setOpenCreateClientModal(true);
    }
  };

  const handleCloseCreateClientModal = () => {
    setOpenCreateClientModal(false);
  };

  // Delete lead handler
  const handleDeleteLead = () => {
    setDeleteLoading(true);
    deleteLead(leadDetail?.id)
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          navigate(ROUTES.LEADS.INDEX);
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' });
        }
        setDeleteLoading(false);
      })
      .catch((e: any) => {
        enqueueSnackbar(e?.response?.data?.message || 'Failed to delete', {
          variant: 'error',
        });
        setDeleteLoading(false);
      });
  };

  // Change search handler
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  // Change comment
  const handleChangeComment = () => {
    if (leadDetail?.id) {
      updateLead(leadDetail?.id, {
        comment,
      })
        .then((res) => {
          if (res.success) {
            enqueueSnackbar(res?.message || 'Lead comment changed', {
              variant: 'success',
            });
            dispatch(setLeadDetail(res.data));
          } else {
            enqueueSnackbar(res?.message || 'Failed to change comment', {
              variant: 'error',
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err?.message || 'Failed to change comment', {
            variant: 'error',
          });
        });
    }
  };

  const handleLocalChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleOpenAssignBrokerModal = () => {
    setVisibleAssignBrokerModal(true);
  };

  const handleOpenAssignedBrokerPopup = (event) => {
    setAssignedBrokerPopupEl(event.currentTarget);
  };

  const handleCloseAssignedBrokerPopup = () => {
    setAssignedBrokerPopupEl(null);
  };

  const handleAssignBroker = (brokerId: string) => {
    if (leadDetail?.id) {
      assignBroker(leadDetail?.id, brokerId)
        .then((res) => {
          if (res.success) {
            enqueueSnackbar(res?.message || 'Lead successfully assigned', {
              variant: 'success',
            });
            dispatch(getLeadDetail(leadDetail?.id));
          } else {
            enqueueSnackbar(res?.message || 'Failed to assign lead', {
              variant: 'error',
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err?.message || 'Failed to assign lead', {
            variant: 'error',
          });
        });
    }
  };

  // Delayed search handler
  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  // Debounce search hook
  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
  }, [search, delayedChangeSearch]);

  // On isMobile changed
  useEffect(() => {
    if (!isMobile) {
      setVisibleExtraInformationModal(false);
    }
  }, [isMobile]);

  // On users changed
  useEffect(() => {
    if (leads) {
      setData(leads);
    }
  }, [leads]);

  // On pagination changed
  useEffect(() => {
    if (pagination?.total || pagination?.total === 0) {
      setTotalPage(Math.ceil(pagination?.total / rowsPerPage));
    }
  }, [pagination, rowsPerPage]);

  // On state of location changed
  useEffect(() => {
    if (state) {
      const { page, rowsPerPage } = state as any;
      if (page) {
        setPage(page);
      }
      if (rowsPerPage) {
        setRowsPerPage(rowsPerPage);
      }
    }
  }, [state]);

  // On page, sortBy and rowsPerPage changed
  useEffect(() => {
    dispatch(
      getLeads({
        page,
        perPage: rowsPerPage,
        sort: sortBy.field,
        'sort-order': sortBy.order,
        search: debouncedSearch,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, sortBy, rowsPerPage, debouncedSearch]);

  // On params changed
  useEffect(() => {
    if (params?.id) {
      dispatch(getLeadDetail(+params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params?.id]);

  // On Lead changed
  useEffect(() => {
    if (leadDetail) {
      // if (!leadDetail?.is_possible) navigate(ROUTES.LEADS.INDEX);

      if (leadDetail?.status) {
        setStatus(leadDetail?.status);
      } else {
        setStatus(LeadStatus.New);
      }

      setPossible(Boolean(leadDetail?.is_possible));

      setComment(leadDetail?.comment || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadDetail]);

  // On mounted
  useEffect(() => {
    dispatch(getCreateClientForms());
  }, [dispatch]);

  const columns = [
    {
      field: 'name',
      numeric: false,
      label: 'Name',
      width: 68,
      render: (row: ILeadData) => (
        <>
          <Typography variant="body2" fontWeight={500}>
            {getLeadName(row)}
          </Typography>
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{ fontWeight: '400' }}
          >
            {t(getLeadPropertyTypeLabel(row))}
          </Typography>
        </>
      ),
    },
    {
      field: 'createdAt',
      numeric: false,
      label: 'Date',
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500} sx={{ fontWeight: '400' }}>
          {getLeadCreateDate(row)}
        </Typography>
      ),
    },
  ];

  const personalInformation = [
    {
      field: 'value',
      label: t('leads_detail.value'),
      render: (row: ILeadData) => (
        <Chip label={row.is_sprengnetter ? 'Sprengnetter' : 'Kundenwert '} />
      ),
    },
    {
      field: 'first_name',
    },
    {
      field: 'last_name',
    },
    {
      field: 'gender',
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {t(getGengerLabel(row.gender))}
        </Typography>
      ),
    },
    {
      field: 'birth_year',
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {row.birth_year ? row.birth_year : ''}
        </Typography>
      ),
    },
    {
      field: 'plz',
    },
    {
      field: 'email',
    },
    {
      field: 'phone',
    },
    {
      field: 'has_partner',
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {t(row.has_partner ? 'leads_detail.yes' : 'leads_detail.no')}
        </Typography>
      ),
    },
  ];

  const partnerInformation = [
    {
      field: 'partner_gender',
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {t(getGengerLabel(row.partner_gender))}
        </Typography>
      ),
    },
    {
      field: 'partner_birth_year',
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {row.partner_birth_year ? row.partner_birth_year : ''}
        </Typography>
      ),
    },
  ];

  const propertyInformation = [
    {
      field: 'property_type',
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {t(getLeadPropertyTypeLabel(row))}
        </Typography>
      ),
    },
    {
      field: 'property_value',
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {row.property_value ? getCurrency(row.property_value) + ' €' : '-'}
        </Typography>
      ),
    },
    {
      field: 'mwr_property_value',
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {row.property_value_min && row.property_value_max
            ? `${getCurrency(row.property_value_min)} € -
              ${getCurrency(row.property_value_max)} €`
            : '-'}
        </Typography>
      ),
    },
    {
      field: 'living_space',
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {row.living_space} qm
        </Typography>
      ),
    },
    {
      field: 'year_of_construction',
    },
    {
      field: 'payment_type',
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {t(`leads_detail.${getLeadPaymentType(row)}`)}
        </Typography>
      ),
    },
  ];

  // Return LeadsDetail page

  return (
    <>
      {isDesktop ? (
        <DetailViewLayout
          items={
            <Card>
              <CardHeader
                sx={(theme) => ({
                  pt: 25,
                  pb: 22,
                  // @ts-ignore
                  borderBottom: `1px solid ${theme.palette.lightCyan}`,
                })}
                title={
                  <Stack direction="row" spacing={8}>
                    <IconButton onClick={handleGoBack}>
                      <Icon name="arrow-back" />
                    </IconButton>
                    <Input
                      fullWidth
                      value={search}
                      disabled={loading}
                      startAdornment={<Icon name="search" />}
                      placeholder={t('leads_detail.search')}
                      onChange={handleChangeSearch}
                    />
                    <IconButton>
                      <Icon name="funnel-fill" />
                    </IconButton>
                  </Stack>
                }
              />
              <DataTable
                isList
                paginated
                stickyHeader
                data={data}
                type="leads"
                columns={columns}
                isLoading={loading}
                page={page}
                totalPage={totalPage}
                rowsPerPage={rowsPerPage}
                selectedId={Number(params?.id)}
                contentSize={{
                  height: 'calc(100vh - 321px)',
                }}
                onSort={handleSort}
                onRowClick={handleRowClick}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Card>
          }
          detail={
            <Card>
              <CardHeader
                title={
                  <S.DetailCardHeaderTitle>
                    <Typography variant="h3" mr={20}>
                      {leadDetail ? getLeadName(leadDetail) : ''}
                    </Typography>
                    {possible ? (
                      <>
                        <S.StatusMenuButton
                          color="info"
                          loading={detailLoading || deleteLoading}
                          onClick={handleOpenStatusMenu}
                        >
                          {t(
                            LEAD_STATUSES.find((menu) => menu.value === status)
                              ?.label || ''
                          )}
                          <Icon name="arrow-down" size={12} />
                        </S.StatusMenuButton>
                        <S.StatusMenu
                          anchorEl={statusMenuAnchor}
                          open={!!statusMenuAnchor}
                          onClose={handleCloseStatusMenu}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          {LEAD_STATUSES.map((item, index) => (
                            <MenuItem
                              key={index}
                              className={item.value === status ? 'active' : ''}
                              sx={{
                                background:
                                  item.value === status
                                    ? darkIndigo
                                    : 'transparent',
                                color:
                                  item.value === status ? white : darkIndigo,
                              }}
                              onClick={() => handleSelectStatus(item.value)}
                            >
                              {t(item.label)}
                              {item.value === status && (
                                <Icon name="tick" size={16} color="default" />
                              )}
                            </MenuItem>
                          ))}
                        </S.StatusMenu>
                      </>
                    ) : (
                      <S.StatusMenuButton
                        color="error"
                        onClick={handleOpenStatusMenu}
                      >
                        <Typography>nicht möglich</Typography>
                      </S.StatusMenuButton>
                    )}
                  </S.DetailCardHeaderTitle>
                }
                action={
                  <Stack direction="row" spacing={8}>
                    {possible && (
                      <>
                        {leadDetail?.assignedAgent && (
                          <Tooltip
                            arrow
                            title={t('leads_detail.assigned_broker')}
                          >
                            <div>
                              <IconButton
                                disabled={detailLoading || deleteLoading}
                                onClick={handleOpenAssignedBrokerPopup}
                              >
                                <Icon name="user" />
                              </IconButton>
                            </div>
                          </Tooltip>
                        )}

                        <Button onClick={handleOpenCreateClientModal}>
                          {t('leads_detail.create_customer')}
                        </Button>
                        {isUserAllowed(account, Role.ROLE_ADMIN) && (
                          <Button onClick={handleOpenAssignBrokerModal}>
                            {t('leads_detail.assign_broker')}
                          </Button>
                        )}
                      </>
                    )}
                    {isUserAllowed(account, Role.ROLE_ADMIN) && (
                      <IconButton
                        disabled={detailLoading || deleteLoading}
                        onClick={handleOpenDeleteConfirmModal}
                      >
                        <Icon name="trash" />
                      </IconButton>
                    )}
                  </Stack>
                }
                subheader={
                  <Typography mt={8} variant="body2">
                    {t('leads_detail.created_date')}:{' '}
                    {getLeadCreateDate(leadDetail)}
                  </Typography>
                }
              />
              <CardContent sx={{ p: 20 }}>
                <S.Scrollbar>
                  {possible && (
                    <>
                      <FormControl>
                        <FormLabel>{t('leads_detail.comment')}</FormLabel>
                        <Input
                          size="medium"
                          disabled={detailLoading || deleteLoading}
                          multiline
                          minRows={5}
                          placeholder={t('leads_detail.comment_placeholder')}
                          value={comment}
                          onChange={handleLocalChangeComment}
                        />
                      </FormControl>
                      <Box sx={{ display: 'flex' }}>
                        <Button
                          sx={{ mt: 8, ml: 'auto' }}
                          color="primary"
                          onClick={handleChangeComment}
                        >
                          {t('leads_detail.save')}
                        </Button>
                      </Box>
                    </>
                  )}
                  <Typography mt={40} mb={12} variant="h3">
                    {t('leads_detail.personal_information')}
                  </Typography>
                  <Table>
                    <TableBody>
                      {personalInformation.map(({ field, render }, index) => (
                        <TableRow key={index}>
                          <S.TableCellHeader>
                            {t(`leads_detail.${field}`)}
                          </S.TableCellHeader>
                          <TableCell>
                            {leadDetail
                              ? render
                                ? render(leadDetail)
                                : leadDetail[field]
                              : ''}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {leadDetail?.has_partner && (
                    <>
                      <Typography mt={40} mb={12} variant="h3">
                        {t('leads_detail.partners_personal_information')}
                      </Typography>
                      <Table>
                        <TableBody>
                          {partnerInformation.map(
                            ({ field, render }, index) => (
                              <TableRow key={index}>
                                <S.TableCellHeader>
                                  {t(`leads_detail.${field}`)}
                                </S.TableCellHeader>
                                <TableCell>
                                  {leadDetail
                                    ? render
                                      ? render(leadDetail)
                                      : leadDetail[field]
                                    : ''}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </>
                  )}
                  <Typography mt={40} mb={12} variant="h3">
                    {t('leads_detail.property_details')}
                  </Typography>
                  <Table>
                    <TableBody>
                      {propertyInformation.map(({ field, render }, index) => (
                        <TableRow key={index}>
                          <S.TableCellHeader>
                            {t(`leads_detail.${field}`)}
                          </S.TableCellHeader>
                          <TableCell>
                            {leadDetail
                              ? render
                                ? render(leadDetail)
                                : leadDetail[field]
                              : ''}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </S.Scrollbar>
              </CardContent>
            </Card>
          }
        />
      ) : (
        <Card>
          <CardHeader
            sx={{ px: 16, pt: 18, pb: 4 }}
            title={
              <IconButton onClick={handleGoBack}>
                <Icon name="arrow-back" />
              </IconButton>
            }
            action={
              <Stack direction="row" spacing={10}>
                {possible && (
                  <>
                    <Button onClick={handleOpenCreateClientModal}>
                      {t('leads_detail.create_customer')}
                    </Button>
                    {isUserAllowed(account, Role.ROLE_ADMIN) && (
                      <Button onClick={handleOpenAssignBrokerModal}>
                        {t('leads_detail.assign_broker')}
                      </Button>
                    )}
                  </>
                )}

                {isUserAllowed(account, Role.ROLE_ADMIN) && (
                  <IconButton onClick={handleOpenDeleteConfirmModal}>
                    <Icon name="trash" />
                  </IconButton>
                )}
              </Stack>
            }
          />
          <CardContent>
            <S.DetailCardHeaderTitle>
              <Typography variant="h3" mr={20}>
                {leadDetail?.first_name} {leadDetail?.last_name}
              </Typography>
              {possible ? (
                <>
                  <S.StatusMenuButton
                    color="info"
                    onClick={handleOpenStatusMenu}
                  >
                    {t(
                      LEAD_STATUSES.find((menu) => menu.value === status)
                        ?.label || ''
                    )}
                    <Icon name="arrow-down" size={12} />
                  </S.StatusMenuButton>
                  <S.StatusMenu
                    anchorEl={statusMenuAnchor}
                    open={!!statusMenuAnchor}
                    onClose={handleCloseStatusMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {LEAD_STATUSES.map((item, index) => (
                      <MenuItem
                        key={index}
                        className={item.value === status ? 'active' : ''}
                        sx={{
                          background:
                            item.value === status ? darkIndigo : 'transparent',
                          color: item.value === status ? white : darkIndigo,
                        }}
                        onClick={() => handleSelectStatus(item.value)}
                      >
                        {t(item.label)}
                        {item.value === status && (
                          <Icon name="tick" size={16} color="default" />
                        )}
                      </MenuItem>
                    ))}
                  </S.StatusMenu>
                </>
              ) : (
                <S.StatusMenuButton
                  color="error"
                  onClick={handleOpenStatusMenu}
                >
                  <Typography>nicht möglich</Typography>
                </S.StatusMenuButton>
              )}
            </S.DetailCardHeaderTitle>
            <Typography mt={8} mb={28} variant="body2">
              {t('leads_detail.created_date')}: {leadDetail?.createdAt}
            </Typography>
            <S.Scrollbar>
              {possible && (
                <FormControl required>
                  <FormLabel>{t('leads_detail.comment')}</FormLabel>

                  <Input
                    size="medium"
                    multiline
                    minRows={5}
                    placeholder={t('leads_detail.comment_placeholder')}
                  />

                  <Button
                    sx={{ mt: 8, ml: 'auto' }}
                    color="primary"
                    onClick={handleChangeComment}
                  >
                    {t('leads_detail.save')}
                  </Button>
                </FormControl>
              )}

              <Typography mt={28} mb={12} variant="h3">
                {t('leads_detail.personal_information')}
              </Typography>
              <Table>
                <TableBody>
                  {personalInformation.map(({ field, render }, index) => (
                    <TableRow key={index}>
                      <S.TableCellHeader>
                        {t(`leads_detail.${field}`)}
                      </S.TableCellHeader>
                      <TableCell>
                        {leadDetail
                          ? render
                            ? render(leadDetail)
                            : leadDetail[field]
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {leadDetail?.has_partner && (
                <>
                  <Typography mt={40} mb={12} variant="h3">
                    {t('leads_detail.partners_personal_information')}
                  </Typography>
                  <Table>
                    <TableBody>
                      {partnerInformation.map(({ field, render }, index) => (
                        <TableRow key={index}>
                          <S.TableCellHeader>
                            {t(`leads_detail.${field}`)}
                          </S.TableCellHeader>
                          <TableCell>
                            {leadDetail
                              ? render
                                ? render(leadDetail)
                                : leadDetail[field]
                              : ''}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
              <Typography mt={40} mb={12} variant="h3">
                {t('leads_detail.property_details')}
              </Typography>
              <Table>
                <TableBody>
                  {propertyInformation.map(({ field, render }, index) => (
                    <TableRow key={index}>
                      <S.TableCellHeader>
                        {t(`leads_detail.${field}`)}
                      </S.TableCellHeader>
                      <TableCell>
                        {leadDetail
                          ? render
                            ? render(leadDetail)
                            : leadDetail[field]
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </S.Scrollbar>
          </CardContent>
        </Card>
      )}
      <Popover
        anchorEl={assignedBrokerPopupEl}
        open={Boolean(assignedBrokerPopupEl)}
        onClose={handleCloseAssignedBrokerPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack alignItems="flex-end" padding="16px">
          <Stack direction="row" mb={8}>
            <Box
              component={Link}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                textDecoration: 'none',
                color: '#172844',
              }}
              to={ROUTES.BROKERS.DETAIL.replace(
                ':id',
                `${leadDetail?.assignedAgent?.id}`
              )}
            >
              <Avatar
                src={leadDetail?.assignedAgent?.image?.path || ''}
                sx={{ width: 48 }}
              />
              <Typography ml={12} variant="body2">
                {getLeadAgentName(leadDetail)}
                <br />
                {leadDetail && leadDetail?.assignedAgent?.company}
              </Typography>
            </Box>
            <IconButton
              color="default"
              onClick={handleCloseAssignedBrokerPopup}
            >
              <Icon name="x-lg" />
            </IconButton>
          </Stack>
        </Stack>
      </Popover>
      <ConfirmModal
        open={visibleDeleteConfirmModal}
        onClose={handleCloseDeleteConfirmModal}
        onOk={handleDeleteLead}
      />
      {openCreateClientModal && (
        <CreateNewClientModal
          open={openCreateClientModal}
          onClose={handleCloseCreateClientModal}
          createClientFormsData={createClientFormsData}
        />
      )}
      <AssignBrokerModal
        isLead
        target={leadDetail?.first_name || ' ' + leadDetail?.last_name || ''}
        brokerId={leadDetail?.assignedAgent && leadDetail?.assignedAgent.id}
        open={visibleAssignBrokerModal}
        onClose={() => setVisibleAssignBrokerModal(false)}
        onAssign={handleAssignBroker}
      />
    </>
  );
};
