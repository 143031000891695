// Dependencies
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactHighcharts from 'react-highcharts';
import { Box, Checkbox, useMediaQuery, useTheme } from '@mui/material';

// Components
import { Icon } from '../../Icon';

// Interfaces
import { RootState } from '../../../../redux/reducers';

// Styles
import * as S from './styles';

// Interface
export interface IPieChartProps {
  data: any[];
  showDone: boolean;
  onShowDone: (value: boolean) => void;
}

// Colors
const Colors = [
  '#172844',
  '#C0D5EA',
  '#EA504C',
  '#497EA1',
  '#697990',
  '#F7BE42',
  '#2CA85E',
];
const sizes = { xl: 320, lg: 320, md: 290, sm: 166 };
const yOffsets = { xl: -8, lg: -8, md: -16, sm: -8 };

// Export pie chart component
export const PieChart: FC<IPieChartProps> = ({
  data,
  showDone,
  onShowDone,
}) => {
  const breakpoint = useSelector(
    ({ uiReducer: { breakpoint } }: RootState) => breakpoint
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [chartData, setChartData] = useState(data);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setChartData(
      data.map((item, index) => ({
        y: item.value,
        name: item.name,
        color: Colors[index % Colors.length],
      }))
    );

    setTotal(data.reduce((totalCount, object) => totalCount + object.value, 0));
  }, [data]);

  const chartOptions: any = useMemo(
    () => ({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        type: 'pie',
        width: sizes[breakpoint],
        height: sizes[breakpoint],
        spacingTop: 0,
      },
      title: {
        useHTML: true,
        text: `
          <div class='centered-title'>
            <p>${total}</p>
            <p>Objects</p>
          </div>
        `,
        align: 'center',
        verticalAlign: 'middle',
        y: yOffsets[breakpoint],
        x: 0,
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      tooltip: {
        useHTML: true,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderRadius: 8,
        animation: true,
        shadow: false,
        style: {
          color: theme.palette.darkIndigo,
          fontFamily: 'Work Sans',
        },
        shape: 'square',
        formatter() {
          // @ts-ignore
          const { point } = this;
          return `
          <div class='pie-chart-tooltip'>
            <div class='pie-legend-name'>${point.name}</div>
            <div>${point.y}</div>
          </div>
        `;
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
        },
      },
      series: [
        {
          type: 'pie',
          innerSize: '40%',
          borderWidth: '3px',
          data: chartData.filter((item) => !item.disabled),
          size: '100%',
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData]
  );

  const handleClickLegendItem = (seriesName: string) => {
    setChartData(
      chartData.map((item: any) => {
        if (item.name === seriesName) {
          item = {
            ...item,
            disabled: !item.disabled,
          };

          setTotal((prev) => {
            if (item.disabled) return prev - item.y;
            return prev + item.y;
          });
        }
        return item;
      })
    );
  };

  // Check handler
  const handleCheck = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onShowDone(checked);
  };

  return (
    <S.PieChart direction="row" spacing={{ xs: 20, sm: 50 }}>
      <ReactHighcharts config={chartOptions} />
      <S.LegendWrapper>
        <S.Checkbox
          control={
            <Checkbox
              checked={showDone}
              icon={
                <Box width={{ xs: 16, sm: 24 }} height={{ xs: 16, sm: 24 }} />
              }
              checkedIcon={<Icon name="check" size={isMobile ? 16 : 24} />}
              onChange={handleCheck}
            />
          }
          label="Abgeschlossene Kunden anzeigen"
        />
        {chartData.map((item, index) => (
          <div
            key={`item-${index}`}
            className={`legend-item ${item.disabled ? 'disabled' : ''}`}
            onClick={() => handleClickLegendItem(item.name)}
          >
            <div className="legend-symbol" style={{ background: item.color }} />
            <span>{item.name}</span>
          </div>
        ))}
      </S.LegendWrapper>
    </S.PieChart>
  );
};
