import moment from 'moment';

export const getNewsDate = (date: string) => {
  if (!date) {
    return;
  }

  const momentDate = moment(date);
  return momentDate.format('DD.MM.YYYY');
};
