// Dependencies
import styled from 'styled-components';
import { Box, Typography, ListItem as MuiListItem } from '@mui/material';

// Export styled components
export const ListItem = styled(MuiListItem)`
  && {
    padding: 12px 0;

    &:hover {
      background-color: ${(props) => props.theme.palette.whiteIndigo};
      cursor: pointer;
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid ${props => props.theme.palette.lightCyan} !important;
    }

    &.Mui-selected {
      background-color: ${(props) => props.theme.palette.whiteIndigo};
    }

    .MuiIconButton-root {
      width: 36px;
      height: 36px;
      display: flex;
    }

    .MuiIconButton-root {
      display: none;
    }

    .MuiListItemText-secondary {
      margin-top: 0;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      padding: 10px 0;

      .MuiListItemText-secondary {
        margin-top: 0;
        white-space: nowrap;
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .MuiListItemText-primary {
        white-space: nowrap;
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
      .MuiListItemText-primary {
        max-width: 70px;
        font-size: 14px;
      }

      .MuiListItemText-secondary {
        max-width: 70px;
      }

      .MuiListItemAvatar-root {
        margin-top: auto;
        margin-bottom: auto;
      }

      .MuiIconButton-root {
        display: flex;
      }
    }
  }
`;

export const SecondaryListItemText = styled(Typography)`
  && {
    margin-top: 7px;
    margin-right: 10px;
    margin-bottom: auto;

    ${(props) => props.theme.breakpoints.down('sm')} {
      font-size: 12px;
    }
  }
`;

export const SecondarySubTitle = styled(Typography)`
  && {
    color: ${props => props.theme.palette.middleCyan};
    min-width: 191.5px;
  }
`;

export const Status = styled(Typography)<{ color: string }>`
  && {
    min-width: 60px;
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: ${props => props.color};
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    border-radius: 24px;
    padding: 12px 10px;
    height: 40px;
    white-space: nowrap;
    color: ${props => props.theme.palette.common.white};
    
    &:hover {
      position: absolute;
      top: -20px;
      max-width: unset;
    }
  }
`;

export const Notification = styled(Typography)`
  && {
    margin-left: 8px;
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.theme.palette.error.main};
    color: white;
    font-size: 12px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Message = styled(Box)`
  margin-left: 8px;
`;

export const ChatText = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) => props.theme.breakpoints.down('xl')} {
    width: calc(100% - 160px);
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 12px;
    width: 90px;
  }
`;
