/*
 *  Colors
 * */

// Common
export const black = '#000000';
export const white = '#FFFFFF';
export const gray = '#646464';

// Cyan
export const lightCyan = '#eff4f8';
export const cyan = '#d7e2eb';
export const middleCyan = '#b4bcc7';
export const darkCyan = '#a3b2c5';
export const whiteCyan = '#f3f7fa';
export const blackCyan = '#8f9bac';

// Indigo
export const lightIndigo = '#697990';
export const indigo = '#07487e';
export const darkIndigo = '#172844';
export const whiteIndigo = '#F9FBFC';

// Red
export const lightRed = '#FF6E6E';
export const red = '#EA504C';

// Blue
export const lightBlue = '#b5c7e7';
export const blue = '#2974FF';

// Green
export const lightGreen = '#bbdcd0';
export const green = '#04B000';
export const darkGreen = '#2ca85e';

// Orange
export const orange = '#FF8A00';

// Define palette
const palette = {
  common: {
    black,
    white,
  },
  primary: {
    main: darkIndigo,
    contrastText: white,
  },
  secondary: {
    main: lightCyan,
    contrastText: darkIndigo,
  },
  info: {
    main: lightBlue,
  },
  success: {
    main: lightGreen,
  },
  error: {
    main: red,
  },
  text: {
    primary: darkIndigo,
    secondary: lightIndigo,
  },
  lightCyan,
  cyan,
  middleCyan,
  darkCyan,
  whiteCyan,
  blackCyan,
  whiteIndigo,
  lightIndigo,
  indigo,
  darkIndigo,
  lightRed,
  red,
  lightBlue,
  blue,
  lightGreen,
  green,
  darkGreen,
  orange,
  gray,
};

// Export palette
export default palette;
