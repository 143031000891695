// Dependencies
import { FC } from 'react';
import { Select as MuiSelect, SelectProps } from '@mui/material';

// Export Select component
export const Select: FC<SelectProps> = (props) => {
  const { value, ...restProps } = props;
  const valueNotNull = value === null ? '' : value;
  return <MuiSelect value={valueNotNull} {...restProps} />;
}
