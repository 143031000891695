// Dependencies
import styled from 'styled-components';

import { ListItem } from '../../../../components/Common/ListItem';
import { Box } from '@mui/material';

export const ChartListItemWrapper = styled(Box)`
  .MuiListItem-root {
    align-items: flex-start !important;
  }
`;

export const ChatListItem = styled(ListItem)`
  .MuiListItemText-secondary {
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
