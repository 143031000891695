import moment from 'moment';

import {
  IAdditionalCosts,
  IFile,
  IFileSection,
  IProjectData,
  IProjectTask,
} from '../interfaces';
import { IMAGE_TYPES, VIDEO_TYPES } from '../constants';
import { isNumber } from 'util';

export const getProjectCustomerNameAndObjectId = (
  project: IProjectData | null
) => {
  if (!project || !project.customer) return '';
  let result = `${project.customer.first_name} ${project.customer.last_name}`;
  if (project.property.object_id) {
    result += ` (${project.property.object_id})`;
  }
  return result;
};

export const getProjectPrimaryImage = (project: IProjectData | null) => {
  if (!project) {
    return '';
  }

  return project.property?.property_images?.files?.find((file) => {
    return IMAGE_TYPES.includes(
      file.path?.split('.').pop()?.toLowerCase() || ''
    );
  })?.path;
};

// export const getCurrency = (
//   value: string | number = '',
//   floatingPoints: number = 2
// ) => {

//   if (value == null) {
//     return '0';
//   }

//   const floatingNumber = Math.pow(10, floatingPoints);

//   if (isNumber(value)) value = value.toString();

//   value = value.replace(',', '.');
//   const last = value[value.length - 1];
//   const isTypingFloat = last === '.';
//   if (isTypingFloat) {
//     value = value.substring(0, value.length - 1);
//   }

//   let val: string = (
//     Math.round(parseFloat(value) * floatingNumber) / floatingNumber
//   ).toString();
//   if (val === 'NaN') {
//     return '';
//   }

//   const isNegative = val[0] === '-';
//   if (isNegative) {
//     val = val.substring(1);
//   }

//   let splits = val.split('.');
//   if (value.includes('.0') && splits.length === 1) {
//     splits[1] = '0';
//   }
//   console.log('VALUE2', value);

//   const tempVal = splits[0]
//     .split('')
//     .reverse()
//     .join('')
//     .match(/.{1,3}/g);

//   let returnValue = isNegative ? '-' : '';

//   if (tempVal != null) {
//     returnValue += tempVal
//       .map((x: string) => {
//         return x.split('').reverse().join('');
//       })
//       .reverse()
//       .join('.');
//     if (isTypingFloat) {
//       returnValue += ',';
//     }
//     if (splits[1] || splits[1] === '0') {
//       returnValue += ',' + splits[1];
//     }
//   } else {
//     returnValue = '0';
//   }
//   console.log('VALUE', value);
//   return returnValue;
// };

export const getCurrency = (
  value: string | number = '',
  floatingPoints: number = 2,
  allowNegative: boolean = true
) => {
  if (value == null) {
    return '0';
  }

  const floatingNumber = Math.pow(10, floatingPoints);

  if (typeof value === 'number') {
    value = value.toString();
  }

  // Allow '-' only at the beginning of the string
  const isNegative = allowNegative && value[0] === '-';
  if (isNegative) {
    value = value.substring(1);
  }

  value = value.replace(',', '.');
  const last = value[value.length - 1];
  const isTypingFloat = last === '.';
  if (isTypingFloat) {
    value = value.substring(0, value.length - 1);
  }

  let val: string = (
    Math.round(parseFloat(value) * floatingNumber) / floatingNumber
  ).toString();

  if (val === 'NaN' && isNegative) {
    return '-';
  } else if (val === 'NaN') return '';

  let splits = val.split('.');
  if (value.includes('.0') && splits.length === 1) {
    splits[1] = '0';
  }

  const tempVal = splits[0]
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g);

  let returnValue = isNegative ? '-' : '';

  if (tempVal != null) {
    returnValue += tempVal
      .map((x: string) => {
        return x.split('').reverse().join('');
      })
      .reverse()
      .join('.');
    if (isTypingFloat) {
      returnValue += ',';
    }
    if (splits[1] || splits[1] === '0') {
      returnValue += ',' + splits[1];
    }
  } else {
    returnValue = '0';
  }

  return returnValue;
};

export const getFromCurrency = (currency: string) => {
  return currency.split('.').join('').replace(',', '.');
};

export const getFullName = (
  project: IProjectData | any | null | undefined,
  field: string = 'customer'
) => {
  return project && project[field]
    ? `${project[field]?.first_name} ${project[field]?.last_name}`
    : `${project?.first_name} ${project?.last_name}`;
};

export const getAddress = (project: IProjectData | null) => {
  return project
    ? `${project.customer?.address?.street}, ${project.customer?.address?.zip} ${project.customer?.address?.city}`
    : '';
};

const getProjectDates = (project: IProjectData | null) => {
  let createdDate = '';
  let changedDate = '';
  let submittedDate = '';

  if (project && project.changes) {
    const changes = project.changes;
    changes.sort((changeA, changeB) => changeB.id - changeA.id);
    const first = changes.find((change) => change.type === 'created');
    const submittedItem = changes.find(
      (change) =>
        change.type === 'workflow' &&
        JSON.parse(change.context)?.transitionName ===
          'requestApproveOfNonBindingOfferForCustomer'
    );
    const last = changes.find(() => true);

    if (last) changedDate = moment(last.date).format('DD.MM.YYYY');
    if (submittedItem)
      submittedDate = moment(submittedItem.date).format('DD.MM.YYYY');
    if (first) {
      createdDate = moment(first.date).format('DD.MM.YYYY');
    } else {
      const firstById = changes.reverse().find(() => true);
      if (firstById) createdDate = moment(firstById.date).format('DD.MM.YYYY');
    }
  }

  return {
    changedDate,
    createdDate,
    submittedDate,
  };
};

export const getProjectChangedDate = (project: IProjectData | null) =>
  getProjectDates(project).changedDate;

export const getProjectCreatedDate = (project: IProjectData | null) =>
  getProjectDates(project).createdDate;

export const getProjectSubmittedDate = (project: IProjectData | null) =>
  getProjectDates(project).submittedDate;

export const getTwoDigit = (oneDigit: number) => {
  const val = oneDigit.toString();
  return val.length === 1 ? '0' + val : val;
};

export const getDate = (date: string) => {
  const parsed = Date.parse(date);

  if (isNaN(parsed)) {
    return null;
  }
  return new Date(parsed);
};

export const getEndDate = (date: string, yearsToAdd: number = 0) => {
  let tempDate = getDate(date);
  if (tempDate == null) tempDate = new Date();
  tempDate.setFullYear(tempDate.getFullYear() + yearsToAdd);
  tempDate.setDate(tempDate.getDate() - 1);
  return getDateStringFromDate(tempDate);
};

export const getDateTime = (date: string) => {
  if (!date) {
    return '';
  }

  const momentDate = moment(date);
  const isToday = momentDate.isSame(moment(), 'date');
  return isToday ? momentDate.format('HH:mm') : momentDate.format('DD.MM.YYYY');
};

export const getDateString = (date: string) => {
  return moment(date).format('DD.MM.YYYY');
};

export const getDateStringFromDate = (date: Date) => {
  if (date == null) {
    return '';
  }
  const { day, month, year } = getDateObj(date);
  return `${day}.${month}.${year}`;
};

export const getDateObj = (date: Date) => {
  return {
    month: getTwoDigit(date.getMonth() + 1),
    day: getTwoDigit(date.getDate()),
    year: date.getFullYear(),
    seconds: date.getSeconds(),
    minutes: date.getMinutes(),
    hours: date.getHours(),
  };
};

export const getPeriodInDays = (
  dateStart: Date | string,
  dateEnd: Date | string
): string => {
  let dateStartDate: Date | null;
  let dateEndDate: Date | null;

  if (typeof dateStart === 'string') {
    dateStartDate = getDate(dateStart);
  } else {
    dateStartDate = dateStart;
  }
  if (typeof dateEnd === 'string') {
    dateEndDate = getDate(dateEnd);
  } else {
    dateEndDate = dateEnd;
  }
  if (dateStartDate && dateEndDate) {
    dateStartDate.setHours(0);
    dateStartDate.setMinutes(0);
    dateStartDate.setSeconds(0);
    dateStartDate.setMilliseconds(0);
    dateEndDate.setHours(0);
    dateEndDate.setMinutes(0);
    dateEndDate.setSeconds(0);
    dateEndDate.setMilliseconds(0);

    return moment
      .utc(dateEndDate)
      .add(1, 'day')
      .diff(moment.utc(dateStartDate), 'days')
      .toString();
  }
  return '';
};

export const compareDateStrings = (a: string, b: string) => {
  if (Date.parse(a) === Date.parse(b)) return 0;
  return Date.parse(a) > Date.parse(b) ? -1 : 1;
};

export const getGender = (value: String | number) => {
  return value === 1 || value === '1' ? 'Herr' : 'Frau';
};

export const getLastMessage = (project: IProjectData | any) => {
  const messages = project.messages || project?.internalMessages;
  if (messages && messages.length > 0) {
    return messages[messages.length - 1];
  } else {
    return null;
  }
};

export const getProjectTasks = (projectTasks, task) => {
  return (
    projectTasks?.find((tempTask) => tempTask.task.id === task.id) ??
    ({ task } as IProjectTask)
  );
};

export const getLastTask = (project: IProjectData) => {
  return project.projectTasks
    .sort((a, b) => compareDateStrings(a.closedDate, b.closedDate))
    .find(() => true);
};

export const splitFilesByExtension = (files: IFile[]) => {
  const images: IFile[] = [];
  const documents: IFile[] = [];

  if (files && files.length > 0) {
    files.forEach((file) => {
      // @ts-ignore
      if (file?.file) {
        if (
          IMAGE_TYPES.some((imageType) => {
            // @ts-ignore
            return file.file.name?.toLowerCase()?.includes('.' + imageType);
          })
        ) {
          // @ts-ignore
          images.push({ file: file.file, ...file });
        } else {
          // @ts-ignore
          documents.push({ file: file.file, ...file });
        }
      } else {
        if (
          IMAGE_TYPES.some((imageType) =>
            file.name?.toLowerCase()?.includes('.' + imageType)
          )
        ) {
          images.push(file);
        } else {
          documents.push(file);
        }
      }
    });
  }

  return { images, documents };
};

export const extractImageSection = (
  files: IFile[],
  fieldName: string,
  label: string
) => {
  let gallerySection: IFileSection | null = null;
  const imageFiles: any[] = [];
  files.forEach((file: any) => {
    if (!file?.path) return;
    if (
      ['jpg', 'png', 'gif', 'jpeg'].includes(
        file.path.split('.').pop()?.toLowerCase() || ''
      )
    ) {
      imageFiles.push(file);
    }
  });
  if (imageFiles.length > 0) {
    gallerySection = {
      name: fieldName,
      label: label,
      files: imageFiles,
    };
  }

  return gallerySection;
};

export const getProjectFilesByExtension = (ProjectDetail: IProjectData) => {
  const documents: IFile[] = [];
  const videos: IFile[] = [];
  const images: IFile[] = [];
  Object.values(ProjectDetail?.property).forEach((value) => {
    if (value?.files && value?.files?.length > 0) {
      value.files.forEach((file) => {
        if (
          VIDEO_TYPES.some((videType) =>
            file.name?.toLowerCase()?.includes('.' + videType)
          )
        ) {
          videos.push(file);
        } else if (
          IMAGE_TYPES.some((imageType) =>
            file.name?.toLowerCase()?.includes('.' + imageType)
          )
        ) {
          images.push(file);
        } else {
          documents.push(file);
        }
      });
    }
  });

  return { images, documents, videos };
};

export const getPercent = (
  value: string | number = '',
  floatingPoints: number = 1
) => {
  if (value == null) {
    return '0';
  }
  const floatingNumber = Math.pow(10, floatingPoints);

  if (isNumber(value)) value = value.toString();

  value = value.replace(',', '.');
  const last = value[value.length - 1];
  const isTypingFloat = last === '.';
  if (isTypingFloat) {
    value = value.substring(0, value.length - 1);
  }

  const valueParsed =
    Math.round(parseFloat(value) * floatingNumber) / floatingNumber;
  if (valueParsed === 0) {
    return '0';
  }

  let val: string;
  if (parseFloat(value) !== valueParsed) {
    val = parseFloat(value).toString();
  } else {
    val = valueParsed.toFixed(floatingPoints);
  }

  if (val === 'NaN') {
    return '';
  }

  let splits = val.split('.');
  if (value.includes('.0') && splits.length === 1) {
    splits[1] = '0';
  }

  const tempVal = splits[0]
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g);

  let returnValue = '';

  if (tempVal != null) {
    returnValue += tempVal
      .map((x: string) => {
        return x.split('').reverse().join('');
      })
      .reverse()
      .join('.');
    if (isTypingFloat) {
      returnValue += ',';
    }
    if (splits[1] || splits[1] === '0') {
      returnValue += ',' + splits[1];
    }
  } else {
    returnValue = '0';
  }
  return returnValue;
};

export const cleanSprengnetterValue = (val: string) => {
  return Number(
    val
      .split('.')
      .join('')
      .replace('/\\D/g', '')
      .replace('/ /g', '')
      .replace('€', '')
      .replace('/m²', '')
      .replace(',', '.')
  );
};

export const getProjectAdditionalCosts = (
  project: IProjectData,
  finalized: boolean = false
): IAdditionalCosts[] | null => {
  if (
    project.additional_costs &&
    Array.isArray(project.additional_costs) &&
    project.additional_costs.length > 0
  ) {
    if (finalized) {
      const additionalCosts = project.additional_costs.filter(
        (item) => item.is_finalized && item.is_accepted
      );
      if (additionalCosts.length === 0) {
        return null;
      }
      return additionalCosts;
    }
    return project.additional_costs;
  }
  return null;
};

export const getFirstYearFromAdditionalCosts = (
  additionalCostsItem: IAdditionalCosts
): string => {
  let firstYear = '';
  if (additionalCostsItem && additionalCostsItem.usage_period_start) {
    const usagePeriodStartDate = getDate(
      additionalCostsItem.usage_period_start
    );
    if (usagePeriodStartDate) {
      firstYear = usagePeriodStartDate?.getFullYear().toString();
    }
  }
  return firstYear;
};

export const getFirstAdditionalCosts = (
  project: IProjectData,
  finalized: boolean = false
): IAdditionalCosts | null => {
  const additionalCosts = getProjectAdditionalCosts(project, finalized);
  if (additionalCosts) {
    const additionalCostsSorted = additionalCosts.sort((a, b) =>
      String(a.calc_period_start).localeCompare(b.calc_period_end)
    );
    return additionalCostsSorted[0];
  }
  return null;
};

export const getLastAdditionalCosts = (
  project: IProjectData
): IAdditionalCosts | null => {
  const additionalCosts = getProjectAdditionalCosts(project);
  if (additionalCosts) {
    const additionalCostsSorted = additionalCosts.sort((a, b) =>
      String(b.calc_period_start).localeCompare(a.calc_period_end)
    );
    return additionalCostsSorted[0];
  }
  return null;
};

export const getSecondToLastAdditionalCosts = (
  project: IProjectData
): IAdditionalCosts | null => {
  const additionalCosts = getProjectAdditionalCosts(project);
  if (additionalCosts) {
    let secondToLastAdditionalCosts;
    if (additionalCosts.length > 1) {
      const additionalCostsSorted = additionalCosts.sort((a, b) =>
        String(b.calc_period_start).localeCompare(a.calc_period_end)
      );
      secondToLastAdditionalCosts = additionalCostsSorted[1];
    } else {
      secondToLastAdditionalCosts = additionalCosts[0];
    }
    return secondToLastAdditionalCosts;
  }
  return null;
};
