// Dependencies
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Box, Grid } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import { Button, Dialog } from '../../Common';
import { FormBuilder } from '../../FormBuilder';

// Interfaces
import { RootState } from '../../../redux/reducers';

// Utils
import { buildFormInitialValues, buildSingleFieldSchema } from '../../../utils';

// Store
import {
  getEmergencyContactPersonForm,
  getProjectDetail,
} from '../../../redux/actions';
import {
  addEmergencyContactPersonData,
  updateEmergencyContactPersonData,
} from '../../../services/project.service';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

// Interfaces
interface IEmergencyContactPersonModalProps {
  isEdit: boolean;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

// Export emergency contact person modal
export const EmergencyContactPersonModal: FC<
  IEmergencyContactPersonModalProps
> = ({ isEdit, open, onClose, onSuccess }) => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get snack bar hook
  const { enqueueSnackbar } = useSnackbar();

  // Get dispatch from store
  const dispatch = useDispatch();

  // Get project id
  const params = useParams<{ id: string }>();

  // Get emergency contact person form from store
  const emergencyContactPersonForm = useSelector(
    ({ formReducer: { emergencyContactPersonForm } }: RootState) =>
      emergencyContactPersonForm
  );

  // Get project detail from store
  const { projectDetail } = useSelector(
    ({ projectReducer }: RootState) => projectReducer
  );

  const [loading, setLoading] = useState<boolean>(false);

  // Get initial value from form data
  const initialValue = useMemo(() => {
    return buildFormInitialValues(emergencyContactPersonForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get validation schema from form data
  const validationSchema = useMemo(() => {
    return buildSingleFieldSchema(emergencyContactPersonForm);
  }, [emergencyContactPersonForm]);

  // Create formik
  const formik = useFormik<any>({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      if (projectDetail?.emergency_contact_person?.id) {
        updateEmergencyContactPersonData(
          projectDetail?.id,
          projectDetail.emergency_contact_person.id,
          values
        )
          .then((res) => {
            setLoading(false);
            if (res.data?.success) {
              enqueueSnackbar(res.data?.message, { variant: 'success' });
              if (onSuccess) onSuccess();
              if (params.id) {
                dispatch(getProjectDetail(+params.id));
              }
            } else {
              enqueueSnackbar(res.data?.message || 'Failed', {
                variant: 'error',
              });
            }
            onClose();
          })
          .catch((err) => {
            enqueueSnackbar(err.response?.data?.message || 'Failed', {
              variant: 'error',
            });
            setLoading(false);
          });
      } else {
        addEmergencyContactPersonData(projectDetail?.id, values)
          .then((res) => {
            setLoading(false);
            if (res.data?.success) {
              enqueueSnackbar(res.data?.message, { variant: 'success' });
              if (onSuccess) onSuccess();
            } else {
              enqueueSnackbar(res.data?.message || 'Failed', {
                variant: 'error',
              });
            }
            if (projectDetail?.id)
              dispatch(getProjectDetail(projectDetail?.id));
            onClose();
          })
          .catch((err) => {
            enqueueSnackbar(err.response?.data?.message || 'Failed', {
              variant: 'error',
            });
            setLoading(false);
          });
      }
    },
  });

  // On mounted
  useEffect(() => {
    dispatch(getEmergencyContactPersonForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On project detail changed
  useEffect(() => {
    if (isEdit && projectDetail) {
      formik.setValues(projectDetail?.emergency_contact_person);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, projectDetail]);

  // Return emergency contact person modal
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('enter_emergency_person.title')}
      actions={
        <Button
          loading={loading}
          color="primary"
          size="large"
          onClick={formik.submitForm}
        >
          {t('enter_emergency_person.save')}
        </Button>
      }
    >
      <Box component={PerfectScrollbar} sx={{ height: 600 }}>
        <Grid container columns={2} spacing={16}>
          <FormBuilder
            type={emergencyContactPersonForm?.type}
            attributes={emergencyContactPersonForm?.attributes}
            label={emergencyContactPersonForm?.label}
            fields={emergencyContactPersonForm?.fields}
            formik={formik}
            layout={{
              password: 2,
              date: 2,
              text: 2,
              currency: 2,
              select: 2,
              tel: 2,
              integer: 2,
              float: 2,
            }}
            path=""
          />
        </Grid>
      </Box>
    </Dialog>
  );
};
