// Dependencies
import React, { FC, useRef, useState } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';

import { Icon } from '../Icon';
import * as S from './styles';
import { ConfirmModal } from '../../Modals';

interface IImageListProps {
  files: any[];
  loading?: boolean;
  deleteLoading?: boolean;
  hasAddImageButton?: boolean;
  allowDelete: boolean;
  onRemoveFile?: (index: any) => void;
  onChangeFiles?: (values: File[]) => void;
  onClickImage?: (position: number) => void;
}

// Export ImageList component
export const ImageList: FC<IImageListProps> = ({
  files,
  loading,
  deleteLoading,
  hasAddImageButton,
  allowDelete,
  onRemoveFile,
  onChangeFiles,
  onClickImage,
}) => {
  const fileSelector = useRef<any>();

  const [confirmDeleteId, setConfirmDeleteId] = useState<
    string | number | null
  >(null);
  const [dragActive, setDragActive] = useState<boolean>(false);

  const handleAddFileClick = () => {
    fileSelector.current.click();
  };

  const handleAddFile = (e) => {
    const { files: uploadedFiles } = e.target;
    if (uploadedFiles && uploadedFiles.length > 0) {
      onChangeFiles && onChangeFiles([...files, ...uploadedFiles]);
    }
  };

  const handleRemoveFile = (event: any, fileId: number) => {
    event.stopPropagation();
    if (onRemoveFile) {
      setConfirmDeleteId(fileId);
    } else if (onChangeFiles) {
      onChangeFiles(files.filter(({ id }) => id !== fileId));
    }
  };

  const handleRemoveSelectedFile = (index: number) => {
    onChangeFiles && onChangeFiles(files.filter((_, idx) => idx !== index));
  };

  const handleCloseDeleteConfirmModal = () => {
    setConfirmDeleteId(null);
  };

  const handleDelete = async () => {
    if (onRemoveFile && confirmDeleteId) {
      try {
        await onRemoveFile(confirmDeleteId);
      } catch (e) {
        console.log(e);
      }
      setConfirmDeleteId(null);
    }
  };

  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    const { files: uploadedFiles } = event.dataTransfer;
    if (uploadedFiles && uploadedFiles.length > 0) {
      onChangeFiles && onChangeFiles([...files, ...uploadedFiles]);
    }
  };

  return (
    <S.ImageList sx={{ mt: files.length > 0 ? 28 : 0 }}>
      {files.map((file, index) => (
        <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
          <S.ImageItem
            key={index}
            onClick={onClickImage ? () => onClickImage(index) : undefined}
            sx={onClickImage ? { cursor: 'pointer' } : undefined}
          >
            {file.file ? (
              file.uploadPercent && file.uploadPercent !== 100 ? (
                `${file.uploadPercent} %`
              ) : (
                <img src={URL.createObjectURL(file.file)} alt={file.name} />
              )
            ) : (
              <img
                src={file.path || URL.createObjectURL(file)}
                alt={file.name}
              />
            )}
            {(allowDelete || !file?.id) && (
              <S.RemoveButton
                disabled={loading}
                onClick={(event) =>
                  file.id
                    ? handleRemoveFile(event, file.id)
                    : handleRemoveSelectedFile(index)
                }
              >
                <Icon name="trash" />
              </S.RemoveButton>
            )}
          </S.ImageItem>
          {!!(file.updated_at || file.date_added) && (
            <S.ImageDate>
              {moment(file.date_added).format('DD.MM.YYYY')}
            </S.ImageDate>
          )}
        </Box>
      ))}
      {hasAddImageButton && (
        <>
          <S.AddImageButton
            disabled={loading}
            onClick={handleAddFileClick}
            $activated={dragActive}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <Icon name="plus-lg" />
          </S.AddImageButton>
          <input
            style={{ display: 'none' }}
            type="file"
            ref={fileSelector}
            onChange={handleAddFile}
            multiple
          />
        </>
      )}
      <ConfirmModal
        open={!!confirmDeleteId}
        onClose={handleCloseDeleteConfirmModal}
        onOk={handleDelete}
        loading={deleteLoading}
      />
    </S.ImageList>
  );
};
