// Dependencies
import { FC } from 'react';

// Pages
import {
  BrokersDetailPage,
  BrokersPage,
  DashboardPage,
  FAQPage,
  ForgotPasswordPage,
  LeadsDetailPage,
  LeadsPage,
  LoginPage,
  OptInPage,
  ProfilePage,
  ProjectDetailPage,
  ProjectListPage,
  DraftListPage,
  RegisterPage,
  ResetPasswordPage,
} from '../pages';

// Constants
import { Role } from './role.constant';

// Export routes
export const ROUTES = {
  DASHBOARD: '/',
  LEADS: {
    PREFIX: '/leads',
    INDEX: '/leads',
    DETAIL: '/leads/:id',
  },
  CLIENTS: {
    INDEX: '/clients',
    DETAIL: '/clients/:filter/:id',
    DRAFTS: '/clients/customer-drafts',
  },
  OBJECTS: {
    PREFIX: '/objects',
    INDEX: '/objects',
    DETAIL: '/objects/:filter/:id',
  },
  BROKERS: {
    PREFIX: '/brokers',
    INDEX: '/brokers',
    DETAIL: '/brokers/:id',
  },
  FAQ: '/faqs',
  PROFILE: '/profile',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:id/:key',
  OPT_IN: '/optIn/:id/:key',
};

// Interfaces
interface IRoute {
  path: string;
  element: FC;
}

interface IMainPageRoute extends IRoute {
  pageLabel: string;
  role: {
    min: Role;
    max?: Role;
  };
}

export const PUBLIC_ROUTES: IRoute[] = [
  {
    path: ROUTES.LOGIN,
    element: LoginPage,
  },
  {
    path: ROUTES.REGISTER,
    element: RegisterPage,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: ForgotPasswordPage,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: ResetPasswordPage,
  },
  {
    path: ROUTES.OPT_IN,
    element: OptInPage,
  },
];

export const MAIN_ROUTES: IMainPageRoute[] = [
  {
    path: ROUTES.DASHBOARD,
    element: DashboardPage,
    pageLabel: 'Hello',
    role: {
      min: Role.ROLE_USER,
    },
  },
  {
    path: ROUTES.LEADS.INDEX,
    element: LeadsPage,
    pageLabel: 'leads',
    role: {
      min: Role.ROLE_USER,
    },
  },
  {
    path: ROUTES.LEADS.DETAIL,
    element: LeadsDetailPage,
    pageLabel: 'leads',
    role: {
      min: Role.ROLE_USER,
    },
  },
  {
    path: ROUTES.CLIENTS.DRAFTS,
    element: DraftListPage,
    pageLabel: 'draft',
    role: {
      min: Role.ROLE_USER,
    },
  },
  {
    path: ROUTES.CLIENTS.INDEX + '/:filter',
    element: ProjectListPage,
    pageLabel: 'clients',
    role: {
      min: Role.ROLE_USER,
    },
  },
  {
    path: ROUTES.CLIENTS.DETAIL,
    element: ProjectDetailPage,
    pageLabel: 'clients',
    role: {
      min: Role.ROLE_USER,
    },
  },
  {
    path: ROUTES.OBJECTS.INDEX + '/:filter',
    element: ProjectListPage,
    pageLabel: 'objects',
    role: {
      min: Role.ROLE_USER,
    },
  },
  {
    path: ROUTES.OBJECTS.DETAIL,
    element: ProjectDetailPage,
    pageLabel: 'objects',
    role: {
      min: Role.ROLE_USER,
    },
  },
  {
    path: ROUTES.BROKERS.INDEX,
    element: BrokersPage,
    pageLabel: 'brokers',
    role: {
      min: Role.ROLE_MANAGER,
    },
  },
  {
    path: ROUTES.BROKERS.DETAIL,
    element: BrokersDetailPage,
    pageLabel: 'brokers',
    role: {
      min: Role.ROLE_MANAGER,
    },
  },
  {
    path: ROUTES.FAQ,
    element: FAQPage,
    pageLabel: 'faq',
    role: {
      min: Role.ROLE_USER,
    },
  },
  {
    path: ROUTES.PROFILE,
    element: ProfilePage,
    pageLabel: 'profile',
    role: {
      min: Role.ROLE_USER,
    },
  },
];
