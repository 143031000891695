// Dependencies
import styled from 'styled-components';
import { Box } from '@mui/material';

// Export styled components
export const WrapFAQ = styled(Box)<{ $opened: boolean }>`
  && {
    width: ${props => props.$opened ? 'calc(100vw - 334px)' : 'calc(100vw - 166px)'};
    ${(props) => props.theme.breakpoints.down('md')} {
      width: calc(100vw - 38px);
    }
  }
  /* Table */
  .MuiTableContainer-root {
    background: #ea504c;
    border-radius: 0;
    border: 2px solid #ea504c;
  }
  .MuiTableBody-root {
    background: #fff;
  }
  .MuiTableCell-root {
    white-space: normal;
    word-wrap: break-word;
    vertical-align: top;
  }
  /* Table */
`;
