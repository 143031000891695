// Dependencies
import React, { FC, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Components
import DetailList from './List';
import DetailCard from './Card';
import { DetailViewLayout, Typography } from '../../../components';

// Store
import {
  getFilesForm,
  getModernizationsForm,
  getObjectPropertiesForm,
  getPersonalDataForm,
  getProjectDetail,
  getRegistrationForm,
  getContactForm,
} from '../../../redux/actions';

// Constants
import { ROUTES } from '../../../constants';

// Export project detail page
export const ProjectDetailPage: FC = () => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get params from hook
  const params = useParams<{ id: string; filter: string }>();

  // Get state from hook
  const { pathname } = useLocation();

  // Check detail type
  const isObjectType = useMemo(() => {
    return pathname.includes(ROUTES.OBJECTS.INDEX);
  }, [pathname]);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Theme
  const theme = useTheme();

  // Check platform
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // On mounted
  useEffect(() => {
    dispatch(getPersonalDataForm());
    dispatch(getRegistrationForm());
    dispatch(getModernizationsForm());
    dispatch(getObjectPropertiesForm());
    dispatch(getFilesForm());
    dispatch(getContactForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On id changed
  useEffect(() => {
    if (params?.id) {
      dispatch(getProjectDetail(+params.id));
    }
  }, [dispatch, params?.id]);

  // Return project detail page
  return (
    <>
      <Typography
        variant="h2"
        color="lightIndigo"
        sx={{ py: 17, mb: { xs: 16, md: 24 } }}
      >
        {t(isObjectType ? 'projects.objects' : 'projects.clients')}
      </Typography>
      {isDesktop ? (
        <DetailViewLayout items={<DetailList />} detail={<DetailCard />} />
      ) : (
        <DetailCard />
      )}
    </>
  );
};
