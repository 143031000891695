// Dependencies
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Button, Typography } from '../../Common';

// Styles
import * as StatusService from '../../../constants';
import { IProjectData, Order } from '../../../interfaces';
import { getClients, getProjects } from '../../../redux/actions';
import { getProjectDetail } from '../../../services/project.service';
import { getFullName } from '../../../utils';
import { StartNewChatModal } from '../StartNewChatModal';
import ExternalChat from './ExternalChat';
import InternalChat from './InternalChat';
import MobileHeader from './MobileHeader';
import * as S from './styles';
import { getClientById } from '../../../services/client.service';
import { RootState } from '../../../redux/store';

// Interface
interface IChatsModalProps {
  open: boolean;
  projectId?: number;
  onClose: () => void;
}

interface IChat {
  message: string;
  type: 'received' | 'sent';
  createdAt: string;
  image?: string;
}

export interface IClient {
  id: number;
  image?: string;
  firstName: string;
  lastName: string;
  chats: IChat[];
  unread?: number;
}

export enum VisibleSection {
  Clients = 'clients',
  Detail = 'detail',
  Info = 'info',
  Photos = 'photos',
}

export enum ChatType {
  Internal = 'internal',
  External = 'external',
}

const PER_PAGE = 10;
// Export Chats modal
export const ChatsModal: FC<IChatsModalProps> = ({
  open,
  onClose,
  projectId,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get dispatch from store
  const dispatch = useDispatch();

  // get me userInfo from store
  const me = useSelector((state: RootState) => state.authReducer.account);

  // States
  const [client, setClient] = useState<IClient>();
  const [visibleSection, setVisibleSection] = useState<VisibleSection>(
    VisibleSection.Clients
  );
  const [project, setProject] = useState<IProjectData>();

  const [customer, setCustomer] = useState<any>();
  const [clientID, setClientID] = useState<any>(projectId ? projectId : null);
  const [loading, setLoading] = useState(false);
  const [clientSearch, setClientSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');

  const [visibleInformation, setVisibleInformation] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<Order>(Order.Desc);
  const [statusFilter, setStatusFilter] = useState('all');
  const [visibleNewChatModal, setVisibleNewChatModal] =
    useState<boolean>(false);

  const [openFromWidget, setOpenFromWidget] = useState<boolean>(
    projectId ? true : false
  );

  const [page, setPage] = useState<number>(1);

  const [total, setTotal] = useState<number>(0);

  const admin = me?.roles.includes('ROLE_ADMIN' as any);

  const [chatType, setChatType] = useState<ChatType>(
    !admin || projectId ? ChatType.External : ChatType.Internal
  );
  const [user, setUser] = useState<boolean>(false);
  // Theme
  const theme = useTheme();

  // Check platform
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  // Close handler
  const handleClose = () => {
    onClose();
    setVisibleSection(VisibleSection.Clients);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleClickClient = useCallback(
    async (id: number) => {
      setLoading(true);
      setOpenFromWidget(false);
      setClientID(id);
      await getProjectDetail(id)
        .then((res) => {
          setProject(res);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

      setClient(client);
      setUser(true);
      if (isTablet) {
        setVisibleSection(VisibleSection.Detail);
      }
    },
    [client, isTablet]
  );

  const handleInternalClickClient = useCallback(
    async (id: number) => {
      setLoading(true);
      setClientID(id);

      await getClientById(id)
        .then((res) => {
          setCustomer(res?.data?.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

      setClient(client);
      setUser(true);
      if (isTablet) {
        setVisibleSection(VisibleSection.Detail);
      }
    },
    [client, isTablet]
  );

  const handleOpenInformation = () => {
    setVisibleInformation(true);
    if (isTablet) {
      setVisibleSection(VisibleSection.Info);
    }
  };

  // Close information handler
  const handleCloseInformation = () => {
    setVisibleInformation(false);
  };

  // Close photo viewr
  const handleClosePhotoViewer = () => {
    setVisibleSection(VisibleSection.Info);
  };

  // Open photos handler
  const handleOpenPhotos = () => {
    setVisibleSection(VisibleSection.Photos);
  };

  // Go back handler
  const handleGoBack = () => {
    switch (visibleSection) {
      case VisibleSection.Detail: {
        setVisibleSection(VisibleSection.Clients);
        break;
      }
      case VisibleSection.Info: {
        setVisibleSection(VisibleSection.Detail);
        break;
      }
      case VisibleSection.Photos: {
        setVisibleSection(VisibleSection.Info);
        break;
      }
      default:
        break;
    }
    setUser(false);
    setOpenFromWidget(false);
  };

  // Open new chat modal handler

  // Close new chat modal handler
  const handleCloseNewChatModal = () => {
    setVisibleNewChatModal(false);
  };

  const fetchClients = async () => {
    await dispatch(
      getProjects('chat', {
        groups: 'project:messages',
        messages: 1,
        status:
          statusFilter !== 'all'
            ? StatusService.STATUS_MAPPING[statusFilter].status
            : undefined,
        sort: 'date-message',
        'sort-order': sortOrder,
        search: debouncedSearch,
        pagination: true,
        page,
        perPage: PER_PAGE,
      })
    );
  };

  const fetchInternalClients = async () => {
    await dispatch(
      getClients({
        status:
          statusFilter !== 'all'
            ? StatusService.STATUS_MAPPING[statusFilter].status
            : undefined,
        sort: 'id',
        'sort-order': sortOrder,
        search: debouncedSearch,
        pagination: true,
        page,
        perPage: PER_PAGE,
      })
    );
  };

  useEffect(() => {
    if (chatType === ChatType.External) {
      fetchClients();
    } else {
      fetchInternalClients();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, sortOrder, statusFilter]);

  // On change platform
  useEffect(() => {
    setVisibleSection(VisibleSection.Clients);
  }, [isTablet]);

  return (
    <>
      <S.ChatsModal
        open={open}
        onClose={handleClose}
        onPageChange={handlePageChange}
        title={
          isTablet ? (
            <>
              {visibleSection === VisibleSection.Clients && (
                <Typography variant="h2">{t('chats.chats')}</Typography>
              )}
              {visibleSection === VisibleSection.Detail && (
                <MobileHeader
                  title={
                    chatType === ChatType.External
                      ? getFullName(project)
                      : getFullName(customer)
                  }
                  subtitle={`Chats /  ${
                    chatType === ChatType.External
                      ? getFullName(project)
                      : getFullName(customer)
                  }`}
                  onGoBack={handleGoBack}
                />
              )}
              {visibleSection === VisibleSection.Info && (
                <MobileHeader
                  title={
                    chatType === ChatType.External
                      ? getFullName(project)
                      : getFullName(customer)
                  }
                  subtitle={`Chats / ${
                    chatType === ChatType.External
                      ? getFullName(project)
                      : getFullName(customer)
                  } / User Info`}
                  onGoBack={handleGoBack}
                />
              )}
              {visibleSection === VisibleSection.Photos && (
                <MobileHeader
                  title="Photos"
                  subtitle={`Chats / ${
                    chatType === ChatType.External
                      ? getFullName(project)
                      : getFullName(customer)
                  } / User Info / Photos`}
                  onGoBack={handleGoBack}
                />
              )}
            </>
          ) : (
            <Typography variant="h2">{t('chats.chats')}</Typography>
          )
        }
        headerChild={
          (!user || (user && !isTablet) || (!user && !isTablet)) && (
            <Box
              display={!admin ? 'none' : 'flex'}
              alignItems="center"
              flex={1}
              padding={isTablet ? '12px' : '24px'}
              gap="24px"
            >
              <Button
                color="primary"
                size="large"
                disabled={loading}
                style={{
                  backgroundColor:
                    chatType === ChatType.Internal
                      ? 'rgba(244, 93, 91, 0.15)'
                      : 'inherit',
                }}
                onClick={() => {
                  setChatType(ChatType.Internal);
                  setPage(1);
                }}
              >
                {t('chats.internal_chat')}
              </Button>

              <Button
                color="primary"
                size="large"
                disabled={loading}
                style={{
                  backgroundColor:
                    chatType === ChatType.External ? '#E1F3FF' : 'inherit',
                }}
                onClick={() => {
                  setChatType(ChatType.External);
                  setPage(1);
                }}
              >
                {t('chats.external_chat')}
              </Button>
            </Box>
          )
        }
        userSelected={user}
        pagination={{
          total: chatType === ChatType.Internal ? total : 0,
          current: page,
        }}
      >
        {chatType === ChatType.Internal && admin ? (
          <InternalChat
            onClose={onClose}
            openFromWidget={openFromWidget}
            projectId={clientID}
            handleGoBack={handleGoBack}
            setVisibleNewChatModal={setVisibleNewChatModal}
            chatType={chatType}
            clientSearch={clientSearch}
            setClientSearch={setClientSearch}
            handleClickClient={handleInternalClickClient}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
            fetchClients={fetchInternalClients}
            handleOpenInformation={handleOpenInformation}
            handleCloseInformation={handleCloseInformation}
            handleOpenPhotos={handleOpenPhotos}
            handleClosePhotoViewer={handleClosePhotoViewer}
            visibleSection={visibleSection}
            setVisibleSection={setVisibleSection}
            setDebouncedSearch={setDebouncedSearch}
            debouncedSearch={debouncedSearch}
            visibleInformation={visibleInformation}
            setPage={setPage}
            page={page}
            setTotal={setTotal}
          />
        ) : (
          <ExternalChat
            onClose={onClose}
            projectId={clientID}
            openFromWidget={openFromWidget}
            handleGoBack={handleGoBack}
            setVisibleNewChatModal={setVisibleNewChatModal}
            chatType={chatType}
            clientSearch={clientSearch}
            setClientSearch={setClientSearch}
            handleClickClient={handleClickClient}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
            fetchClients={fetchClients}
            handleOpenInformation={handleOpenInformation}
            handleCloseInformation={handleCloseInformation}
            handleOpenPhotos={handleOpenPhotos}
            handleClosePhotoViewer={handleClosePhotoViewer}
            visibleSection={visibleSection}
            setVisibleSection={setVisibleSection}
            setDebouncedSearch={setDebouncedSearch}
            debouncedSearch={debouncedSearch}
            visibleInformation={visibleInformation}
            setPage={setPage}
            page={page}
            setTotal={setTotal}
          />
        )}
      </S.ChatsModal>
      {visibleNewChatModal && (
        <StartNewChatModal
          open={visibleNewChatModal}
          onClose={handleCloseNewChatModal}
          onClickClient={handleClickClient}
        />
      )}
    </>
  );
};
