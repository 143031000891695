import { FC, useEffect, useMemo, useState } from 'react';
import { Button, darken, Stack, Typography, useTheme } from '@mui/material';

import * as S from './styles';
import { IFile, IFileSection } from '../../../interfaces';
import { IconButton } from '../IconButton';
import { Icon } from '../Icon';

interface IImageGalleryProps {
  open: boolean;
  onClose: () => void;
  startImageNumber: number;
  imageGroupNumber?: number;
  files?: IFile[];
  imageGallery?: IFileSection[];
}

const defaultOption = {
  loop: false,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: false,
  autoplayTimeout: 5,
  items: 1,
  mouseDrag: true,
};

export const ImageGallery: FC<IImageGalleryProps> = ({
  open,
  files,
  imageGallery,
  startImageNumber,
  imageGroupNumber,
  onClose,
}) => {
  const [activeId, setActiveId] = useState<number>(0);
  const [imageNumber, setImageNumber] = useState(1);

  const theme = useTheme();

  const galleryLength = useMemo(() => {
    if (imageGallery && imageGallery[activeId]) {
      return imageGallery[activeId].files?.length || 0;
    }
    if (files) {
      return files.length;
    }
    return 0;
  }, [activeId, files, imageGallery]);

  const handleClick = (id) => () => {
    if (id !== activeId) {
      setActiveId(id);
      setImageNumber(0);
    }
  };

  const handleNext = () => {
    if (imageNumber < galleryLength - 1) {
      setImageNumber(imageNumber + 1);
    }
  };

  const handlePrev = () => {
    if (imageNumber > 0) {
      setImageNumber(imageNumber - 1);
    }
  };

  useEffect(() => {
    // setImageNumber(startImageNumber + 1);
    setImageNumber(startImageNumber);
  }, [startImageNumber]);

  useEffect(() => {
    if (imageGroupNumber) {
      setActiveId(imageGroupNumber);
    }
  }, [imageGroupNumber]);

  return (
    <S.ImageGallery fullScreen open={open} onClose={onClose}>
      <S.Header>
        <S.Scrollbar>
          <Stack direction="row" spacing={10}>
            {imageGallery ? (
              imageGallery.map(({ label }, index) => (
                <Button
                  key={index}
                  onClick={handleClick(index)}
                  sx={
                    activeId !== index
                      ? {
                          backgroundColor: darken(
                            theme.palette.secondary.main,
                            0.2
                          ),
                        }
                      : undefined
                  }
                >
                  {label}
                </Button>
              ))
            ) : (
              <Typography color="white">
                {imageNumber + 1}/{files?.length}
              </Typography>
            )}
          </Stack>
        </S.Scrollbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={20}
        >
          {imageGallery && (
            <Typography color="white">
              {imageNumber + 1}/{galleryLength}
            </Typography>
          )}
          <IconButton onClick={onClose} color="primary">
            <Icon name="x-lg" />
          </IconButton>
        </Stack>
      </S.Header>
      <S.Content>
        <S.ArrowButton disabled={imageNumber === 0} onClick={handlePrev}>
          <Icon name="arrow-down" />
        </S.ArrowButton>
        {open && (
          <S.Carousel {...defaultOption} startPosition={imageNumber}>
            {imageGallery
              ? imageGallery[activeId]?.files.map(
                  ({ name, original }, index) => (
                    <S.Image key={index}>
                      <S.LoadingProgress color="inherit" size={24} />
                      <img src={original} alt={name} />
                    </S.Image>
                  )
                )
              : files?.map(({ name, original }, index) => (
                  <S.Image key={index}>
                    <S.LoadingProgress color="inherit" size={24} />
                    <img src={original} alt={name} />
                  </S.Image>
                ))}
          </S.Carousel>
        )}
        <S.ArrowButton
          disabled={imageNumber === galleryLength - 1}
          onClick={handleNext}
        >
          <Icon name="arrow-down" />
        </S.ArrowButton>
      </S.Content>
    </S.ImageGallery>
  );
};
