// Dependencies
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Typography, Popover as MuiPopover } from '@mui/material';
import { IconButton } from '../../../../components';

export const MobileMapBoxWrapper = styled(PerfectScrollbar)`
  display: none;
  height: 242px;
  margin: 0 10px 10px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: block;
  }
`;

export const FilterPopover = styled(Box)`
  width: 400px;
  padding: 20px;

  @media only screen and (max-width: 765px) {
    width: auto;
  }
`;

export const Popover = styled(MuiPopover)`
  .MuiPopover-paper {
    margin-top: 4px;
    box-shadow: 0 0 12px rgba(85, 101, 129, 0.1);
  }
`;

export const SelectWrapper = styled(Box)`
  flex: 1;

  .MuiInput-root {
    width: 100%;
  }
`;

export const ClearButton = styled(Typography)`
  cursor: pointer;
`;

export const FilterIconButton = styled(IconButton)<{ active: boolean }>`
  background-color: ${(props) => props.active && props.theme.palette.darkIndigo} !important; 
  ${(props) => props.active ? `
    svg {
      fill: white;
    }
  ` : ''}
`;
