// Dependencies
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  ClickAwayListener,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Utils
import { getAddress, getProjectCustomerNameAndObjectId, getProjectPrimaryImage, isUserAllowed } from '../../../../utils';

// Constants
import { getMainStatusKey, getStatusRoute, Role, ROUTES } from '../../../../constants';

// Styles
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { getUsers } from '../../../../services/user.service';
import { getProjects } from '../../../../services/project.service';
import { debounce } from 'lodash';
import { IProjectData, IUserData } from '../../../../interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { useTranslation } from 'react-i18next';

// Interfaces
interface ISearchPanelProps {
  search?: string;
  anchorEl?: HTMLElement;
  onClose: () => void;
}

// Create search panel
const SearchPanel: FC<ISearchPanelProps> = ({ search, anchorEl, onClose }) => {
  // States
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [brokers, setBrokers] = useState<IUserData[]>([]);
  const [objects, setObjects] = useState<IProjectData[]>([]);

  // Get translation from hook
  const { t } = useTranslation();

  // Get navigate from hook
  const navigate = useNavigate();

  // Get user data from store
  const { account } = useSelector(({ authReducer }: RootState) => authReducer);

  // Object click handler
  const handleClickObject = (id, status) => () => {
    const statusKey = getMainStatusKey(status);
    const route = getStatusRoute(statusKey);
    if (route) {
      navigate(ROUTES[route].DETAIL.replace(':id', id).replace(':filter', statusKey));
    }
    onClose();
  };

  // Broker click handler
  const handleClickBroker = (id) => () => {
    navigate(ROUTES.BROKERS.DETAIL.replace(':id', id));
    onClose();
  };

  const delayedChangeSearch = useRef(
    debounce((search) => {
      setDebouncedSearch(search);
    }, 500)
  );

  useEffect(() => {
    delayedChangeSearch.current(search);
    return delayedChangeSearch.current.cancel;
  }, [search, delayedChangeSearch]);

  useEffect(() => {
    if (isUserAllowed(account, Role.ROLE_ADMIN)) {
      getUsers({
        search: debouncedSearch,
      }).then((res) => {
        if (res.data?.success) {
          setBrokers(res.data?.data);
        }
      }).catch((err) => {
        console.log(err);
      });
    }

    getProjects({
      search: debouncedSearch
    }).then((res) => {
      if (res.data?.success) {
        setObjects(res.data?.data);
      }
    }).catch((err) => {
      console.log(err);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  // Return search panel
  return (
    <ClickAwayListener
      onClickAway={onClose}
    >
      <S.SearchPanel
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <Card sx={{ width: 500 }}>
          {((objects && objects.length > 0) || (brokers && brokers.length > 0)) ? (
            <Typography variant="h2" mb={10}>{t('header.search_results')}</Typography>
          ): <Typography>{t('header.search_empty')}</Typography> }
          {objects && objects.length > 0 && <Typography variant="h5">{t('header.results_by_object_name_and_id')}</Typography>}
          {objects && objects.length > 0 ? (
            <Box component={PerfectScrollbar} sx={{ maxHeight: 200 }}>
              {objects.map((object , index) => (
                <ListItem
                  key={index}
                  sx={{ cursor: 'pointer' }}
                  onClick={handleClickObject(object.id, object.status)}
                >
                  <ListItemAvatar>
                    <Avatar src={getProjectPrimaryImage(object)} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={getProjectCustomerNameAndObjectId(object)}
                    secondary={getAddress(object)}
                  />
                </ListItem>
              ))}
            </Box>
          ) : <></>}
          {
            isUserAllowed(account, Role.ROLE_ADMIN) && (
              <>
                {brokers && brokers.length > 0 && <Typography variant="h5">{t('header.results_by_broker')}</Typography>}
                {brokers && brokers.length > 0 ? (
                  <Box component={PerfectScrollbar} sx={{ maxHeight: 200 }}>
                    {brokers.map((broker, index) => (
                      <ListItem
                        key={index}
                        sx={{ cursor: 'pointer' }}
                        onClick={handleClickBroker(broker.id)}
                      >
                        <ListItemAvatar>
                          <Avatar src={broker?.image?.path} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${broker?.first_name} ${broker?.last_name}`}
                          secondary={broker?.company}
                        />
                      </ListItem>
                    ))}
                  </Box>
                ) : <></>}
              </>
            )
          }
        </Card>
      </S.SearchPanel>
    </ClickAwayListener>
  );
};

// Export search panel
export default SearchPanel;