// Dependencies
import { FC, ReactNode } from 'react';
import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';

import * as S from './styles';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { lightIndigo } from '../../../theme/palette';
import { IconButton } from '../IconButton';

export interface IListItemProps {
  className?: string;
  sx?: SxProps;
  selected?: boolean;
  title?: string;
  subTitle?: string | ReactNode;
  secondarySubTitle?: string;
  avatar?: string;
  avatarShape?: 'circular' | 'rounded';
  secondaryText?: string | ReactNode;
  status?: {
    name: string;
    color: string;
  };
  notifyCount?: number;
  notify?: boolean;
  onDownload?: () => void;
  onClick?: () => void;
}

// Export ListItem component
export const ListItem: FC<IListItemProps> = ({
  className,
  avatar,
  avatarShape = 'rounded',
  sx,
  selected,
  title,
  subTitle,
  secondaryText,
  secondarySubTitle,
  status,
  onDownload,
  notify,
  notifyCount,
  onClick,
}) => {
  return (
    <S.ListItem
      className={className}
      sx={sx}
      selected={selected}
      onClick={onClick && onClick}
      secondaryAction={
        onDownload && (
          <>
            <Button
              variant="outlined"
              sx={{ display: { xs: 'none', sm: 'block' } }}
              onClick={onDownload}
            >
              Download
            </Button>
            <IconButton
              sx={{ display: { xs: 'block', sm: 'none' } }}
              onClick={onDownload}
            >
              <Icon name="box-arrow-right" />
            </IconButton>
          </>
        )
      }
    >
      <ListItemAvatar sx={{ minWidth: avatarShape === 'circular' ? 62 : 80 }}>
        <Avatar src={avatar} variant={avatarShape} />
      </ListItemAvatar>
      <ListItemText
        primary={
          !notifyCount && !notify ? (
            title
          ) : (
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle1">{title}</Typography>
              {notifyCount && <S.Notification>{notifyCount}</S.Notification>}
              {notify && (
                <S.Message>
                  <Icon name="chat-square-dots-fill" color={lightIndigo} />
                </S.Message>
              )}
            </Stack>
          )
        }
        secondaryTypographyProps={{ component: 'div' }}
        secondary={
          subTitle &&
          (secondarySubTitle ? (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">{subTitle}</Typography>
              <S.SecondarySubTitle variant="body2">
                {secondarySubTitle}
              </S.SecondarySubTitle>
            </Stack>
          ) : (
            <S.ChatText>{subTitle}</S.ChatText>
          ))
        }
      />
      {secondaryText && (
        <S.SecondaryListItemText variant="body2">
          {secondaryText}
        </S.SecondaryListItemText>
      )}
      {status && (
        <Stack width={180} alignItems="flex-end" position="relative">
          <S.Status color={status.color} align="center">
            {status.name}
          </S.Status>
        </Stack>
      )}
      {onDownload && <></>}
    </S.ListItem>
  );
};
