// Dependencies
import styled from 'styled-components';
import { Select } from '@mui/material';

// Export styled components
export const Dashboard = styled.div`
  & .react-grid-item {
    display: flex;
    flex-direction: column;
  }
`;

export const CardActionSelect = styled(Select)<any>`
  background: transparent !important;
  color: ${(props) => props.theme.palette.text.secondary} !important;
  font-size: 16px !important;
  font-family: Exo, serif;
`;
