export const APIS = {
  auth: {
    login: `v1/login`,
    logout: `v1/logout`,
    self: `v1/user/self`,
    getRegisterForms: `v2/form/steps_register`,
    register: `v1/register`,
    changePassword: `v1/resetpassword/update`,
    forgotPassword: 'v1/resetpassword',
    resetPassword: 'v1/resetpassword/change',
    optIn: 'v1/optIn',
    setFeatureActive: 'v1/setFeatureActive',
    getFeatureActive: 'v1/getFeatureActive',
    resendAuthCode: 'v1/resendAuthCodeEmail',
  },
  lead: {
    leads: `v1/leads`,
    getLead: `v1/leads`,
    exportLeads: `v1/export/leads`,
  },
  client: {
    getClients: `v1/customer`,
    getDrafts: `v1/customer-draft/getAll`,
    getDraft: `v1/customer-draft/get`,
    getClientById: 'v1/customer',
    deleteDraft: 'v1/customer-draft/delete',
    updateDraft: `v1/customer-draft/edit`,
    getCreateClientForms: `v2/form/steps_createCustomer`,
    createNewClient: `v1/project`,
    createDraft: `v1/customer-draft/create`,
    markClientDeceased: `v1/customer-deceased`,
  },
  project: {
    projects: `v1/project`,
    personalDataForm: `v2/form/single_personalData`,
    modernizationsForm: 'v2/form/single_propertyModernization',
    objectPropertiesForm: 'v2/form/single_propertyProperties',
    registrationForm: `v2/form/single_propertyData`,
    updateCustomer: `v1/customer`,
    updatePreferredCalculation: 'v1/calculation/:id/preferred',
    getProject: `v1/project`,
    updateProperty: 'v1/property',
    deleteProject: 'v1/project/:id',
    deleteMultipleProjects: 'v1/projects',
    analysis: 'v1/project/count',
    calculatePayout: 'v1/calculation',
    deleteCalculation: 'v1/calculation/:id',
    addPayout: 'v1/project/:id/payout_variant',
    updateCalculation: 'v1/calculation/:id',
    deletePayout: 'v1/payout_variant/:id',
    paymentDetails: 'v1/project/:id/paymentDetails',
    enterEligiblePersonForm: '/v2/form/beneficiaryPerson',
    enterEligiblePerson: '/v1/project/:id/beneficiaryPerson',
    enterEmergencyContactPerson: '/v1/project/:id/emergencyContactPerson',
    updateEligiblePerson: '/v1/project/:id/beneficiaryPerson/:personId',
    assignBroker: '/v1/project/:id/agent',
    additionalCosts: 'v1/project/:id/additionalCosts',
    updateAdditionalCosts:
      '/v1/project/:projectId/additionalCosts/:additionalCostsId',
    deleteAdditionalCosts:
      '/v1/project/:projectId/additionalCosts/:additionalCostsId',
    finalizeAdditionalCosts:
      '/v1/project/:projectId/additionalCosts/:additionalCostsId/finalize',
    reopenAdditionalCosts:
      '/v1/project/:projectId/additionalCosts/:additionalCostsId/reopen',
    acceptPrepayment:
      '/v1/project/:projectId/additionalCosts/:additionalCostsId/accept',
    checkAdditionalCostsTask:
      '/v1/project/:projectId/additionalCosts/:additionalCostsId/tasks',
    exportProjects: `v1/export/projects`,
    acceptBindingOffer: '/v1/project/:projectId/accept/:calculationId',
  },
  user: {
    users: 'v1/user',
    getUser: 'v1/user/:id',
    postUser: 'v1/user/:id',
    removeMultiple: 'v1/users',
  },
  form: {
    profileForm: 'v2/form/single_agentProfileAdmin',
    filesForm: 'v2/form/single_propertyFiles',
    newBrokerForm: 'v2/form/steps_agentCreate',
    contactForm: 'v2/form/single_contactData',
    collectPaymentDataForm: 'v2/form/:formName',
    emergencyContactPersonForm: '/v2/form/single_emergencyContactPerson',
    enterEligiblePersonForm: '/v2/form/single_beneficiaryPerson',
    calculationForm: 'v2/form/:formName',
    addPayoutForm: 'v2/form/single_addOffer',
    calculationResultForm: 'v2/form/:formName',
    recordOperatingCostsForm: '/v2/form/steps_propertyAdditionalCosts',
    acceptPrepaymentForm:
      '/v2/form/single_propertyAdditionalCostsPrepaymentAdjustment',
    newsForm: '/v2/form/single_news',
    propertyObjectId: '/v2/form/single_propertyObjectId',
  },
  task: {
    tasks: 'v1/task',
    updateTaskGroup: 'v1/project/:projectId/tasks',
    updateTask: 'v1/project/:projectId/tasks/:taskId',
    setTask: 'v1/project/:projectId/tasks',
  },
  file: {
    file: 'v1/file',
  },
  news: {
    news: 'v1/news',
  },
};

export const buildURLWithParams = (base, url, params) => {
  let baseWithSlash = base;
  baseWithSlash += baseWithSlash?.endsWith('/') ? '' : '/';
  const result = new URL(url, baseWithSlash);
  for (let param in params) {
    if (Array.isArray(params[param])) {
      for (let item of params[param]) {
        result.searchParams.append(param + '[]', item);
      }
    } else {
      result.searchParams.append(param, params[param]);
    }
  }
  return result;
};
