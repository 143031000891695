import { RootState } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import ClientsView from '../ClientsView';
import ChatsView from '../ChatsView';
import InfoView from '../InfoView';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useEffect, useRef, useState } from 'react';
import { ChatType, IClient, VisibleSection } from '..';
// import { getProjects } from '../../../../redux/actions';
import { IProjectData, Order } from '../../../../interfaces';
import {
  ROUTES,
  getMainStatusKey,
  getStatusRoute,
} from '../../../../constants';
import {
  deleteProject,
  getProjectDetail,
} from '../../../../services/project.service';
// import * as StatusService from '../../../../constants';

import { debounce } from 'lodash';
import PhotosView from '../PhotosView';
interface ExternalChatProps {
  onClose: () => void;
  projectId?: number;
  chatType: ChatType;
  setVisibleNewChatModal: (visible: boolean) => void;
  setClientSearch: (search: string) => void;
  clientSearch: string;
  handleClickClient: (id: number) => void;
  setStatusFilter: (status: string) => void;
  statusFilter: string;
  setSortOrder: (order: Order) => void;
  sortOrder: Order;
  fetchClients: () => void;
  handleGoBack?: () => void;
  handleOpenInformation: () => void;
  handleCloseInformation: () => void;
  visibleInformation?: boolean;
  setVisibleSection: (section: VisibleSection) => void;
  visibleSection?: VisibleSection;
  visiblePhotoViewer?: boolean;
  handleClosePhotoViewer: () => void;
  handleOpenPhotos: () => void;
  setDebouncedSearch: (search: string) => void;
  debouncedSearch: string;
  setPage?: (page: number) => void;
  page: number;
  setTotal: (total: number) => void;
  openFromWidget?: boolean;
}

function ExternalChat({
  onClose,
  projectId,
  chatType,
  setVisibleNewChatModal,
  clientSearch,
  setClientSearch,
  handleClickClient,
  setStatusFilter,
  statusFilter,
  setSortOrder,
  sortOrder,
  fetchClients,
  handleGoBack,
  handleOpenInformation,
  handleCloseInformation,
  visibleInformation,
  setVisibleSection,
  visibleSection,
  handleClosePhotoViewer,
  handleOpenPhotos,
  setDebouncedSearch,
  debouncedSearch,
  openFromWidget,
  setPage,
  page,
  setTotal,
}: ExternalChatProps) {
  // Get navigate from hook
  const navigate = useNavigate();

  // Get snackbar
  const { enqueueSnackbar } = useSnackbar();

  // Get products from store
  const { chat: projects, loading: listLoading } = useSelector(
    ({ projectReducer: { projects, loading } }: RootState) => ({
      ...projects,
      loading,
    })
  );

  // States
  const [client, setClient] = useState<IClient>();
  const [project, setProject] = useState<IProjectData>();
  const [loading, setLoading] = useState(openFromWidget ? true : false);
  const [projectList, setProjectList] = useState<IProjectData[]>([]);

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  // Theme
  const theme = useTheme();

  // Check platform
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  // Open client information

  // Go back handler
  // Open new chat modal handler
  const handleOpenNewChat = () => {
    setVisibleNewChatModal(true);
  };

  // Handle sort change
  const handleChangeSort = () => {
    setSortOrder(sortOrder === Order.Asc ? Order.Desc : Order.Asc);
  };

  // Go to detail handler
  const handleGoToDetail = () => {
    if (project) {
      const statusKey = getMainStatusKey(project.status);
      const route = getStatusRoute(statusKey);
      if (route) {
        navigate(
          ROUTES[route].DETAIL.replace(':filter', statusKey).replace(
            ':id',
            project.id.toString()
          )
        );
      }
      onClose();
    }
  };

  // Remove object
  const handleDeleteObject = async () => {
    if (project) {
      setDeleteLoading(true);

      deleteProject(project?.id)
        .then((res) => {
          if (res?.success) {
            enqueueSnackbar(res?.message, { variant: 'success' });
            setProject(undefined);
          } else {
            enqueueSnackbar(res?.message, { variant: 'error' });
          }
          setDeleteLoading(false);
          fetchClients();
          return Promise.resolve();
        })
        .catch((e) => {
          setDeleteLoading(false);
          enqueueSnackbar(e?.response?.data?.message || 'Failed to remove', {
            variant: 'error',
          });
          return Promise.reject();
        });
    }
  };

  const handleResetProjectList = async (updatedProject) => {
    if (projectList.find((item) => item.id === updatedProject.id)) {
      setProjectList([
        updatedProject,
        ...projectList.filter((item) => item.id !== updatedProject.id),
      ]);
    } else {
      await fetchClients();
    }
  };

  // Delayed search handler
  const delayedChangeSearch = useRef(
    debounce((clientSearch) => {
      setDebouncedSearch(clientSearch);
    }, 500)
  );

  // Set list
  useEffect(() => {
    if (projects) {
      setProjectList(projects);
    }
  }, [projects]);

  // On search changed
  useEffect(() => {
    delayedChangeSearch.current(clientSearch);
    return delayedChangeSearch.current.cancel;
  }, [clientSearch, delayedChangeSearch]);

  useEffect(() => {
    fetchClients();

    setTotal(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, sortOrder, statusFilter]);

  // On change platform
  useEffect(() => {
    setVisibleSection(VisibleSection.Clients);
  }, [isTablet, setVisibleSection]);

  useEffect(() => {
    if (projectId) {
      setLoading(true);
      getProjectDetail(projectId)
        .then((res) => {
          setProject(res);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

      setClient(client);
      if (isTablet) {
        setVisibleSection(VisibleSection.Detail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <>
      <PerfectScrollbar style={{ margin: -20 }}>
        <Stack direction="row">
          <ClientsView
            visible={visibleSection === VisibleSection.Clients}
            onClickClient={handleClickClient}
            projects={projectList || []}
            selectedId={project?.id}
            search={clientSearch}
            statusFilter={statusFilter}
            loading={openFromWidget ? loading : listLoading}
            setSearch={setClientSearch}
            setStatusFilter={setStatusFilter}
            onChangeSortOrder={handleChangeSort}
            onStartNewChat={handleOpenNewChat}
            chatType={chatType}
          />

          <ChatsView
            project={project}
            visible={visibleSection === VisibleSection.Detail}
            loading={loading}
            fetchClients={fetchClients}
            resetProjectList={handleResetProjectList}
            onClickInformation={handleOpenInformation}
            chatType={chatType}
          />
          {visibleInformation &&
            (visibleSection === VisibleSection.Photos ? (
              <PhotosView
                visible={visibleSection === VisibleSection.Photos}
                files={project?.property?.property_images?.files}
                onClose={handleClosePhotoViewer}
              />
            ) : (
              <InfoView
                project={project}
                visible={visibleSection === VisibleSection.Info}
                deleteLoading={deleteLoading}
                onDeleteObject={handleDeleteObject}
                onCloseInformation={handleCloseInformation}
                onPhotoClick={handleOpenPhotos}
                onShowDetail={handleGoToDetail}
              />
            ))}
        </Stack>
      </PerfectScrollbar>
    </>
  );
}

export default ExternalChat;
