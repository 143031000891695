import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Scrollbar = styled(PerfectScrollbar)`
  width: calc(100vw - 78px);

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: calc(100vw - 374px);
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    width: calc(100vw - 792px);
  }

  .ps__rail-x {
    bottom: unset !important;
    top: 0;
  }
`;
