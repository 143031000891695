// Dependencies
import styled from 'styled-components';
import ReacTour from 'reactour';

// Export styled components
export const Tours = styled(ReacTour)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};
  border-radius: 12px;
  max-width: 343px;
  padding: 20px 16px;
  &::after {
    display: block;
    content: '';
    background-color: ${(props) => props.theme.palette.primary.main};
    width: 18px;
    height: 18px;
    position: absolute;
    top: -8px;
    right: 36px;
    transform: rotate(45deg);
  }
  [data-tour-elem='controls'] {
    margin-top: 16px;
    justify-content: space-between;
  }
  [aria-label='Close'] {
    color: ${(props) => props.theme.palette.secondary.main};
    width: 12px;
    &:hover {
      opacity: 0.5;
    }
  }
`;
