import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { optIn } from '../../services/auth.service';
import { ROUTES } from '../../constants';
import * as S from '../ResetPassword/styles';
import { Button, Typography } from '../../components';
import { CircularProgress, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const OptInPage: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);

  const { id, key } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    optIn({ id, key })
      .then((res) => {
        if (res.data.success) {
          setVerified(true);
          setTimeout(() => {
            navigate(ROUTES.LOGIN);
          }, 5000);
        } else {
          setVerified(true);
        }
      })
      .catch(() => {
        setVerified(false);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      <S.Logo src="/assets/images/logo.svg" alt="logo" />

      <S.Card>
        <S.Title mb={24}>
          <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
            {t('opt_in.title')}
          </Typography>
        </S.Title>

        {loading ? (
          <CircularProgress />
        ) : verified ? (
          <>
            <Typography variant="subtitle1" mb={20} align="center">
              {t('opt_in.verified')}
            </Typography>
            <Button color="primary" size="large">
              {t('opt_in.go_to_login')}
            </Button>
          </>
        ) : (
          <>
            <Typography variant="subtitle1" mb={20} align="center">
              {t('opt_in.not_verified')}
            </Typography>
            <Link
              size="large"
              component={Button}
              href="mailto:partner@meinewohnrente.de?subject=Manuelle Zugangsaktivierung"
              sx={{
                textDecoration: 'none',
              }}
            >
              {t('opt_in.access')}
            </Link>
          </>
        )}
      </S.Card>
    </S.Container>
  );
};
