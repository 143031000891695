// Dependencies
import React, { FC, useRef, useState } from 'react';
import { Box, Link } from '@mui/material';

import { Icon } from '../Icon';
import { Typography } from '../Typography';
import * as S from './styles';
import { lightIndigo } from '../../../theme/palette';
import { ConfirmModal } from '../../Modals';

interface IFileList {
  files: any[];
  loading?: boolean;
  deleteLoading?: boolean;
  hideSelectFileButton?: boolean;
  allowDelete: boolean;
  onChangeFiles?: (values: File[]) => void;
  onRemoveFile?: (index: any) => void;
}

// Export fileList component
export const FileList: FC<IFileList> = ({
  files,
  loading,
  deleteLoading,
  hideSelectFileButton,
  allowDelete,
  onChangeFiles,
  onRemoveFile,
}) => {
  const fileSelector = useRef<any>();

  const [confirmDeleteId, setConfirmDeleteId] = useState<
    string | number | null
  >(null);

  const handleAddFileClick = () => {
    fileSelector.current.click();
  };

  const handleAddFile = (e) => {
    const { files: uploadedFiles } = e.target;
    if (uploadedFiles && uploadedFiles.length > 0) {
      onChangeFiles && onChangeFiles([...files, ...uploadedFiles]);
    }
  };

  const handleRemoveFile = (event: any, file: any) => {
    event.stopPropagation();
    if (onRemoveFile) {
      if (file?.id && typeof file?.id === 'number') {
        setConfirmDeleteId(file.id);
      } else {
        onChangeFiles &&
          onChangeFiles(files.filter((photo, idx) => idx !== file));
      }
    } else {
      onChangeFiles &&
        onChangeFiles(files.filter((photo, idx) => idx !== file));
    }
  };

  const handleCloseDeleteConfirmModal = () => {
    setConfirmDeleteId(null);
  };

  const handleDelete = async () => {
    if (onRemoveFile && confirmDeleteId) {
      try {
        await onRemoveFile(confirmDeleteId);
      } catch (e) {
        console.log(e);
      }
      setConfirmDeleteId(null);
    }
  };

  return (
    <S.FileList sx={{ mt: files.length > 0 ? 28 : 0 }}>
      {files.map((file, index) => (
        <S.FileItem key={index} direction="row" alignItems="center" spacing={8}>
          <Icon name="document" color={lightIndigo} />
          <Box display="flex" flexDirection="column">
            {file.file ? (
              <Typography variant="caption">{file.file.name}</Typography>
            ) : (
              <Link href={file.path} target="_blank" underline="hover">
                <Typography variant="caption">
                  {file.filename || file.name}
                </Typography>
              </Link>
            )}
            <Typography variant="caption">
              {file.file
                ? file.uploadPercent
                  ? file.uploadPercent === 100
                    ? `${(file.file.size / 1024).toFixed(0)} KB`
                    : `${file.uploadPercent} %`
                  : '0 %'
                : `${(file.size / 1024).toFixed(0)} KB`}
            </Typography>
          </Box>
          {(allowDelete || !file?.id) && (
            <S.RemoveButton
              disabled={loading}
              onClick={(event) =>
                handleRemoveFile(event, file?.id ? file : index)
              }
            >
              <Icon name="trash" />
            </S.RemoveButton>
          )}
        </S.FileItem>
      ))}
      {!hideSelectFileButton && (
        <>
          <S.SelectFileButton disabled={loading} onClick={handleAddFileClick}>
            Select Files
          </S.SelectFileButton>
          <input
            style={{ display: 'none' }}
            type="file"
            ref={fileSelector}
            onChange={handleAddFile}
            multiple
          />
        </>
      )}

      <ConfirmModal
        open={!!confirmDeleteId}
        onClose={handleCloseDeleteConfirmModal}
        onOk={handleDelete}
        loading={deleteLoading}
      />
    </S.FileList>
  );
};
