import { ThunkDispatch } from 'redux-thunk';

import { ApiTypes } from '../../interfaces';
import { CLIENT_ACTIONS, PROJECT_ACTIONS } from '../action-types';
import { RootState } from '../store';
import moment from 'moment';

export const getProjects =
  (projectType, params) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getProjects = async () => {
      const res: any = await api.project.getProjects(params);

      return {
        [projectType]: res.data?.data || [],
        pagination: res.data?.pageInfo,
      };
    };

    return dispatch({
      type: PROJECT_ACTIONS.GET_PROJECTS,
      payload: getProjects(),
    });
  };

export const refetchProjects =
  () => (dispatch: ThunkDispatch<any, any, any>) => {
    return dispatch({
      type: PROJECT_ACTIONS.REFETCH_PROJECTS,
      payload: Promise.resolve({}),
    });
  };

export const deleteProject =
  (id) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const deleteProject = async () => {
      const res = await api.project.deleteProject(id);
      return res.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.DELETE_PROJECT,
      payload: deleteProject(),
    });
  };

export const getPersonalDataForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForms = async () => {
      const res = await api.project.getPersonalDataForm();
      return res.data.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.GET_PERSONAL_DATA_FORM,
      payload: getForms(),
    });
  };

export const getModernizationsForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForms = async () => {
      const res = await api.project.getModernizationsForm();
      return res.data.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.GET_MODERNIZATIONS_FORM,
      payload: getForms(),
    });
  };

export const getObjectPropertiesForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForms = async () => {
      const res = await api.project.getObjectPropertiesForm();
      return res.data.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.GET_OBJECT_PROPERTIES_FORM,
      payload: getForms(),
    });
  };

export const getProjectDetail =
  (id: number) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    return dispatch({
      type: PROJECT_ACTIONS.GET_PROJECT_DETAIL,
      payload: api.project.getProjectDetail(id),
    });
  };

export const updateCustomer =
  (id, forms) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const update = async () => {
      const res = await api.project.updateCustomer(id, forms);
      return res.data;
    };
    return dispatch({
      type: CLIENT_ACTIONS.CREATE_CLIENT,
      payload: update(),
    });
  };

export const updateProperty =
  (id, forms) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const update = async () => {
      const res = await api.project.updateProperty(id, forms);
      return res.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.UPDATE_PROPERTY_DATA,
      payload: update(),
    });
  };

export const updatePreferredCalculation =
  (id, forms) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const update = async () => {
      const res = await api.project.updatePreferredCalculation(id, forms);
      return res.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.UPDATE_PREFERRED_CALCULATION_DATA,
      payload: update(),
    });
  };

export const getRegistrationForm =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getForms = async () => {
      const res = await api.project.getRegistrationForm();
      return res.data.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.GET_REGISTRATION_FORM,
      payload: getForms(),
    });
  };

export const getYearAnalyseData =
  (type) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getData = async () => {
      const res = await api.project.getAnalysisData('year', type);
      const data = Object.keys(res.data.data).map((item) => {
        return {
          month: +item.split('.')[0],
          value: res.data.data[item],
        };
      });

      return { type, data };
    };

    return dispatch({
      type: PROJECT_ACTIONS.GET_YEAR_ANALYSIS_DATA,
      payload: getData(),
    });
  };

export const getMonthAnalyseData =
  (type) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getData = async () => {
      const res = await api.project.getAnalysisData('month', type);
      const data = Object.keys(res.data.data).map((item) => {
        return {
          date: +moment(item, 'DD.M.YYYY').format('DD'),
          value: res.data.data[item],
        };
      });

      return { type, data };
    };

    return dispatch({
      type: PROJECT_ACTIONS.GET_MONTH_ANALYSIS_DATA,
      payload: getData(),
    });
  };

export const calculatePayout =
  (forms) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const update = async () => {
      const res = await api.project.calculatePayout(forms);
      return res.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.CALCULATE_PAYOUT,
      payload: update(),
    });
  };

export const updateCalculation =
  (id, forms) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const update = async () => {
      const res = await api.project.updateCalculation(id, forms);
      return res.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.UPDATE_CALCULATION,
      payload: update(),
    });
  };

export const deleteCalculation =
  (id) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const deleteCalculation = async () => {
      const res = await api.project.deleteCalculation(id);
      return res.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.DELETE_CALCULATION,
      payload: deleteCalculation(),
    });
  };

export const addPayout =
  (id, formData) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const addPayout = async () => {
      const res = await api.project.addPayout(id, formData);
      return res.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.ADD_PAYOUT,
      payload: addPayout(),
    });
  };

export const deletePayout =
  (id) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const deletePayout = async () => {
      const res = await api.project.deletePayout(id);
      return res.data;
    };

    return dispatch({
      type: PROJECT_ACTIONS.DELETE_PAYOUT,
      payload: deletePayout(),
    });
  };
