// Dependencies
import React, { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import IndicativeOffer from '../IndicativeOffer';
import {
  CalculationInformationModal,
  CalculationModal,
  CalculationResultModal,
  ConfirmModal,
  Icon,
  IconButton,
  Typography,
} from '../../../../../../../../components';

// Icons
import {
  CalculatorIcon,
  CircleInfoIcon,
  DownloadIcon,
} from '../../../../../../../../assets/icons';

// Interfaces
import {
  ICalcType,
  IOfferCalculationResult,
} from '../../../../../../../../interfaces';

// Store
import {
  deleteCalculation,
  deletePayout,
  getProjectDetail,
} from '../../../../../../../../redux/actions';
import { RootState } from '../../../../../../../../redux/reducers';

// Styles
import * as S from './styles';
import moment from 'moment';
import i18n from '../../../../../../../../i18n';

// Interfaces
interface IPayoutVariantCardProps {
  id: string;
  title: string;
  items: any[];
  offer: any;
  calcObj: any;
  calcType: ICalcType;
  calculateAllowed: boolean;
  deleteAllowed: boolean;
  index: number;
  visibleOffer: boolean;
  onToggleVisible: (id: number) => void;
}

// Create payout variant card
const PayoutVariantCard: FC<IPayoutVariantCardProps> = ({
  id,
  title,
  items,
  offer,
  calcObj,
  calcType,
  calculateAllowed,
  deleteAllowed,
  index,
  visibleOffer,
  onToggleVisible,
}) => {
  // States
  const [visibleCalculationModal, setVisibleCalculationModal] = useState(false);
  const [calculationResult, setCalculationResult] = useState<any>();
  const [visibleCalculationResultModal, setVisibleCalculationResultModal] =
    useState<boolean>(false);
  const [visibleDeleteConfirmModal, setVisibleDeleteConfirmModal] =
    useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [visibleInformationModal, setVisibleInformationModal] =
    useState<boolean>(false);

  // Get translation from hook
  const { t } = useTranslation();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get project detail from store
  const projectDetail = useSelector(
    ({ projectReducer: { projectDetail } }: RootState) => projectDetail
  );

  // Open calculation modal
  const handleOpenCalculationModal = () => {
    setVisibleCalculationModal(true);
  };

  // Close calculation modal handler
  const handleCloseCalculationModal = (
    calculationResult?: IOfferCalculationResult
  ) => {
    if (calculationResult) {
      setCalculationResult(calculationResult);
      setVisibleCalculationResultModal(true);
    }
    setVisibleCalculationModal(false);
  };

  // Open delete confirm modal handler
  const handleOpenDeleteConfirmModal = (id) => () => {
    setSelectedId(id);
    setVisibleDeleteConfirmModal(true);
  };

  // Close delete confirm modal handler
  const handleCloseDeleteConfirmModal = () => {
    setVisibleDeleteConfirmModal(false);
  };

  // Close calculation result modal
  const handleCloseCalculationResultModal = async () => {
    setVisibleCalculationResultModal(false);
    await dispatch(getProjectDetail(projectDetail?.id as number));
  };

  // Confirm delete handler
  const handleConfirmDelete = async () => {
    if (offer) {
      await dispatch(deleteCalculation(selectedId));
    } else {
      await dispatch(deletePayout(selectedId));
    }
    await dispatch(getProjectDetail(projectDetail?.id as number));
    setVisibleDeleteConfirmModal(false);
  };

  // Open information modal handler
  const handleOpenInformationModal = () => {
    setVisibleInformationModal(true);
  };

  // Close information modal handler
  const handleCloseInformationModal = () => {
    setVisibleInformationModal(false);
  };

  // Download handler
  const handleDownload = () => {
    const anchor = document.createElement('A');
    anchor.setAttribute('target', '_blank');
    anchor.setAttribute(
      'href',
      `${process.env.REACT_APP_API_SERVER}/v1/nonBinding/${offer?.calculated.id}`
    );
    anchor.click();
  };

  // Return payout variant card

  return (
    <>
      <S.PayoutVariantCard
        color={offer?.color || '#bbdcd0'}
        visibleOffer={visibleOffer}
      >
        <S.Header
          title={
            <Stack
              height={1}
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h4">{title}</Typography>
                {items.map(({ label, value, createdAt }, index) => (
                  <>
                    <Stack key={index} direction="row" alignItems="center">
                      <Typography mr={8} variant="body2">
                        {label}:
                      </Typography>
                      <Typography variant="body2">{value}</Typography>
                    </Stack>
                  </>
                ))}
                {offer && offer?.calculated && (
                  <Typography variant="body2">
                    {i18n.t('projects.created')}:{' '}
                    {moment(offer?.calculated?.created).format('DD.MM.YYYY')}
                  </Typography>
                )}
              </Box>
              {offer && offer?.calculated?.preferred && (
                <S.PreferChip
                  label={t('project_detail.preferred_by_customer')}
                />
              )}
            </Stack>
          }
          action={
            <Stack>
              {calculateAllowed && (
                <Stack>
                  <IconButton onClick={handleOpenCalculationModal}>
                    <CalculatorIcon />
                  </IconButton>
                </Stack>
              )}
              {offer?.visibleInformation && (
                <IconButton onClick={handleOpenInformationModal}>
                  <CircleInfoIcon />
                </IconButton>
              )}
              {offer
                ? offer.isDelete &&
                  offer.calculation?.offerCalculated?.id && (
                    <IconButton
                      onClick={handleOpenDeleteConfirmModal(
                        offer.calculation.offerCalculated.id
                      )}
                    >
                      <Icon name="trash" />
                    </IconButton>
                  )
                : deleteAllowed && (
                    <IconButton onClick={handleOpenDeleteConfirmModal(id)}>
                      <Icon name="trash" />
                    </IconButton>
                  )}
            </Stack>
          }
        />
        <S.Content>
          {offer && (
            <>
              {offer.isDownload && (
                <IconButton onClick={handleDownload}>
                  <DownloadIcon />
                </IconButton>
              )}
              <IndicativeOffer
                index={index}
                visibleOffer={visibleOffer}
                onDelete={handleOpenDeleteConfirmModal}
                onDownload={handleDownload}
                onCalculate={handleOpenCalculationModal}
                onToggleVisibleOffer={onToggleVisible}
                {...offer}
              />
            </>
          )}
        </S.Content>
      </S.PayoutVariantCard>
      {visibleCalculationModal && (
        <CalculationModal
          open={visibleCalculationModal}
          calculation={calcObj}
          calculationType={calcType}
          onClose={handleCloseCalculationModal}
        />
      )}
      {calculationResult && (
        <CalculationResultModal
          calculationResult={calculationResult}
          open={visibleCalculationResultModal}
          onClose={handleCloseCalculationResultModal}
        />
      )}
      {visibleDeleteConfirmModal && (
        <ConfirmModal
          open={visibleDeleteConfirmModal}
          onClose={handleCloseDeleteConfirmModal}
          onOk={handleConfirmDelete}
        />
      )}
      {visibleInformationModal && (
        <CalculationInformationModal
          open={visibleInformationModal}
          onClose={handleCloseInformationModal}
          calculation={offer?.calculation}
        />
      )}
    </>
  );
};

// Export payout variant card
export default PayoutVariantCard;
