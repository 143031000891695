// Dependencies
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Typography,
  Stack,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from '@mui/material';

export const HeaderWrapper = styled(Stack)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    max-width: 220px !important;
    position: absolute;
    top: 12px;
  }
`;

// Export styled components
// @ts-ignore
export const FeedItemWrapper = styled(PerfectScrollbar)`
  padding: 16px;

  .MuiListItem-root {
    margin-bottom: 12px;
    padding: 0;

    @media only screen and (max-width: 765px) {
      margin-bottom: 6px;
    }
  }
`;

export const ContentWrapper = styled(PerfectScrollbar)`

  @media only screen and (max-width: 992px) {
    height: 344px;
  }
`;

export const TextContent = styled(Box)`
  position: relative;
  margin-bottom: 12px;

  @media only screen and (max-width: 765px) {
    margin-bottom: 6px;
  }
`;

export const SeeMoreButton = styled(Typography)`
  position: absolute;
  right: 0;
  bottom: 0;
  padding-left: 8px;
  cursor: pointer;
  font-weight: 500 !important;
  background: #fff;
`;

export const PortalNews = styled(Stack)`
  height: 100%;
  padding: 30px 0;
  
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 10px 0;
    width: calc(100% - 60px);
  }
`;

export const FeedImage = styled(Box)`
  && {
    display: flex;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    align-items: center;
    width: 45%;

    img {
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
`;

export const PortalNewsContent = styled(Stack)`
  width: 50%;
`;

export const NoImageContent = styled(Box)`
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.palette.lightCyan};
  display: flex;
  justify-content: center;

  .MuiTypography-root {
    display: flex;
    align-items: center;
  }
`;

export const FeedInfo = styled(Box)`
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 14px;
  cursor: pointer;

  svg {
    margin-right: 4px;
  }
`;

export const PortalTitle = styled(Typography)`
  font-weight: 700 !important;
  ${(props) => props.theme.breakpoints.down('sm')} {
    white-space: nowrap;
    width: calc(100vw - 62px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const PortalImage = styled(Avatar)`
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)`
  && {
    width: 100%;
    .MuiToggleButton-root {
      border-radius: 12px !important;
      padding: 16px;
      background-color: transparent;
      color: ${(props) => props.theme.palette.primary.main} !important;
      justify-content: flex-start;
      text-transform: none;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }
    .Mui-selected {
      background-color: ${(props) => props.theme.palette.lightCyan} !important;
    }
  }
`;

export const Link = styled.a``;
