// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Chip, DataTable, IColumn, Typography } from '../../Common';

// Global constants
import {
  getStatusRoute,
  ROUTES,
} from '../../../constants';

// Store
import { RootState } from '../../../redux/reducers';
import { getLeads } from '../../../redux/actions';

// Interfaces
import { ILeadData, ISortBy, Order } from '../../../interfaces';

// Utils
import { getLeadCreateDate, getLeadName, getLeadPropertyTypeLabel, getLeadStatusLabel } from '../../../utils/leads.utils';

interface ILeadPanelProps {
  status: string;
  brokerId: string | number | undefined;
}

// Constants
const panelName = {
  leads: 'leads',
};

// Export Lead Panel
export const LeadPanel: FC<ILeadPanelProps> = ({ status, brokerId }) => {
  // Get translation from hook
  const { t } = useTranslation();

  // States
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<ISortBy>({
    field: 'date',
    order: Order.Desc,
  });

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get params from hook
  const params = useParams();

  // Get navigate from hook
  const navigate = useNavigate();

  // Get project from store
  const {
    leads,
    pagination,
    loading,
  } = useSelector(({ leadReducer: { leads, pagination, loading } }: RootState) => ({
    leads,
    pagination,
    loading,
  }));

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSortBy(value);
  };

  // Go to detail handler
  const handleGoToDetail = (id) => {
    const statusRoute = getStatusRoute(status);
    if (statusRoute) {
      navigate(
        ROUTES[statusRoute].DETAIL.replace(
          ':id',
          id
        )
      );
    }
  };

  // Page change handler
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
    setPage(1);
  };

  // Columns
  const columns: IColumn[] = [
    {
      field: 'name',
      label: t('leads_detail.name'),
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {getLeadName(row)}
        </Typography>
      ),
    },
    {
      field: 'email',
      label: t('leads_detail.email'),
      render: (row: ILeadData) => row.email,
    },
    {
      field: 'propertyType',
      label: t('leads_detail.property_type'),
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500}>
          {t(getLeadPropertyTypeLabel(row))}
        </Typography>
      ),
    },
    {
      field: 'status',
      label: t('leads_detail.status'),
      render: (row: ILeadData) => (
        <Chip
          label={t(getLeadStatusLabel(row))}
          color="default"
        />
      ),
    },
    {
      field: 'date',
      label: t('leads_detail.date'),
      render: (row: ILeadData) => (
        <Typography variant="body2" fontWeight={500} sx={{ fontWeight: '400' }}>
          {getLeadCreateDate(row)}
        </Typography>
      ),
    },
  ];

  // On id, sortBy changed
  useEffect(() => {
    if (brokerId) {
      dispatch(
        getLeads({
          page,
          perPage: rowsPerPage,
          assignedAgentId: brokerId,
          sort: sortBy.field,
          'sort-order': sortBy.order,
        })
      );
    }
  }, [dispatch, params.id, status, sortBy, brokerId, page, rowsPerPage]);

  // On pagination changed
  useEffect(() => {
    if (pagination?.total || pagination?.total === 0) {
      setTotalPage(Math.ceil(pagination?.total / rowsPerPage));
    }
  }, [pagination, rowsPerPage]);

  // Return Project
  return (
    <Stack spacing={16}>
      <Typography variant="h3">
        {t(`brokers_detail.${panelName[status]}`)}
      </Typography>
      <DataTable
        paginated
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette['cyan']}`,
        })}
        isLoading={loading}
        data={leads || []}
        columns={columns}
        page={page}
        totalPage={totalPage}
        rowsPerPage={rowsPerPage}
        onSort={handleSort}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onRowClick={handleGoToDetail}
      />
    </Stack>
  );
};
