// Dependencies
import styled from 'styled-components';
import { Dialog } from '../../Common';

export const EditDraftDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: calc(100% - 350px) !important;
    min-width: calc(100% - 350px) !important;
  }

  .MuiDialogContent-root {
    max-height: calc(100vh - 400px) !important;
    min-height: calc(100vh - 400px) !important;
  }

  ${(props) => props.theme.breakpoints.down('lg')} {
    .MuiDialog-paper {
      max-width: calc(100% - 50px) !important;
    }

    .MuiDialogContent-root {
      max-height: calc(100vh - 300px) !important;
      min-height: calc(100vh - 300px) !important;
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    .MuiDialog-paper {
      max-width: 100% !important;
    }
  }
`;
