import { ThunkDispatch } from 'redux-thunk';

import { ApiTypes } from '../../interfaces';
import { AUTH_ACTIONS } from '../action-types';
import { RootState } from '../store';
import { ICredential } from '../../interfaces';

export const login =
  (params: ICredential) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const login = async () => {
      const res = await api.auth.login(params);

      if (res.data.success) {
        dispatch(getAccount());
      }
      return res.data;
    };

    return dispatch({
      type: AUTH_ACTIONS.LOGIN,
      payload: login(),
    });
  };

export const logout =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const logout = async () => {
      const res = await api.auth.logout();
      return res.data;
    };

    return dispatch({
      type: AUTH_ACTIONS.LOGOUT,
      payload: logout(),
    });
  };

export const getAccount =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getSelf = async () => {
      try {
        const res = await api.auth.self();
        return dispatch({
          type: AUTH_ACTIONS.GET_ACCOUNT_SUCCESS,
          payload: res.data.data,
        });
      } catch (e) {
        return dispatch({
          type: AUTH_ACTIONS.GET_ACCOUNT_ERROR,
        });
      }
    };

    return getSelf();
  };

export const getRegisterForms =
  () =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getRegisterForms = async () => {
      try {
        const res = await api.auth.getRegisterForms();
        return dispatch({
          type: AUTH_ACTIONS.GET_REGISTER_FORMS_SUCCESS,
          payload: res.data.data,
        });
      } catch (err) {
        return dispatch({
          type: AUTH_ACTIONS.GET_REGISTER_FORMS_ERROR,
        });
      }
    };

    return getRegisterForms();
  };

export const register =
  (formData: any) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const toRegister = async () => {
      const res = await api.auth.register(formData);
      return res.data;
    };

    return dispatch({
      type: AUTH_ACTIONS.REGISTER,
      payload: toRegister(),
    });
  };

export const forgotPassword =
  (formData) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const forgotPassword = async () => {
      const res = await api.auth.forgotPassword(formData);
      return res.data;
    };

    return dispatch({
      type: AUTH_ACTIONS.FORGOT_PASSWORD,
      payload: forgotPassword(),
    });
  };

export const getFeatureActive =
  (key: string) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const getActiveFeatures = async () => {
      try {
        const res = await api.auth.getFeatureActive(key);
        return dispatch({
          type: AUTH_ACTIONS.GET_FEATURE_ACTIVE_SUCCESS,
          payload: res.data,
        });
      } catch (err) {
        return dispatch({
          type: AUTH_ACTIONS.GET_FEATURE_ACTIVE_ERROR,
        });
      }
    };

    return getActiveFeatures();
  };

export const setFeatureActive =
  (data: any) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: RootState,
    api: ApiTypes
  ) => {
    const setActiveFeatures = async () => {
      const res = await api.auth.setFeatureActive(data);
      return res.data;
    };

    return dispatch({
      type: AUTH_ACTIONS.SET_FEATURE_ACTIVE,
      payload: setActiveFeatures(),
    });
  };
