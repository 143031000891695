import { combineReducers } from 'redux';
import uiReducer, { UiState } from './ui.reducer';
import authReducer, { AuthReducerState } from './auth.reducer';
import leadReducer, { LeadReducerState } from './lead.reducer';
import clientReducer, { ClientReducerState } from './client.reducer';
import projectReducer, { ProjectReducerState } from './project.reducer';
import userReducer, { UserReducerState } from './user.reducer';
import formReducer, { FormReducerState } from './form.reducer';
import taskReducer, { TaskReducerState } from './task.reducer';
import newsReducer, { NewsReducerState } from './news.reducer';

export interface RootState {
  uiReducer: UiState;
  authReducer: AuthReducerState;
  leadReducer: LeadReducerState;
  clientReducer: ClientReducerState;
  projectReducer: ProjectReducerState;
  userReducer: UserReducerState;
  taskReducer: TaskReducerState;
  newsReducer: NewsReducerState;
  formReducer: FormReducerState;
}

const rootReducer = combineReducers<RootState>({
  uiReducer,
  authReducer,
  leadReducer,
  clientReducer,
  projectReducer,
  userReducer,
  formReducer,
  taskReducer,
  newsReducer,
});

export default rootReducer;
