// Dependencies
import styled from 'styled-components';
import { Tabs as MuiTabs } from '@mui/material';

// Export styled components
export const Tabs = styled(MuiTabs)`
  && {
    border-bottom: 1px solid ${(props) => props.theme.palette.lightCyan};
    padding: ${(props) => props.theme.spacing(0, 20)};

    ${(props) => props.theme.breakpoints.down('lg')} {
      width: calc(100vw - 328px);
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      width: calc(100vw - 32px);
    }

    .MuiTabs-flexContainer {
      margin-left: ${(props) => props.theme.spacing(-10)};
    }

    .MuiTab-root {
      text-transform: initial;
      padding: ${(props) => props.theme.spacing(16, 10)};
      margin-right: 0;
    }
  }
`;
