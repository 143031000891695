import React, { FC, useState } from 'react';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Button, Typography } from '../../components';
import { RootState } from '../../redux/reducers';
import { ROUTES } from '../../constants';
import * as S from './styles';
import { resetPassword } from '../../services/auth.service';
import { Visibility, VisibilityOff } from '../../assets/icons';

export const ResetPasswordPage: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // Get params from hook
  const params = useParams<{ id: string; key: string }>();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  // Get navigate from hook
  const navigate = useNavigate();
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const loading = useSelector(
    ({ authReducer: { loading } }: RootState) => loading
  );

  const schema = Yup.object().shape({
    password: Yup.string()
      .max(80, t('validation.max_length'))
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{};':"\\|,.<>?]).*$/,
        'Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character'
      )
      .required(t('validation.password_required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], t('validation.password_not_matched'))
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{};':"\\|,.<>?]).*$/,
        'Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character'
      )
      .required(t('validation.confirm_password_required')),
  });

  const formik = useFormik<any>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (loading) {
        return;
      }

      resetPassword({
        id: params?.id,
        key: params?.key,
        password: values.password,
      })
        .then((res) => {
          if (res.data?.success) {
            enqueueSnackbar(res.data?.message, { variant: 'success' });
            navigate(ROUTES.LOGIN);
          } else {
            enqueueSnackbar(res.data?.message, { variant: 'error' });
          }
        })
        .catch((err) => {
          enqueueSnackbar(
            err.response?.data?.message || 'Fehler bei Serverkommunikation',
            { variant: 'error' }
          );
        });
    },
  });

  return (
    <S.Container>
      <S.Logo src="/assets/images/logo.svg" alt="logo" />

      <form onSubmit={formik.handleSubmit}>
        <S.Card>
          <S.Title mb={24}>
            <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
              {t('reset_password.title')}
            </Typography>
          </S.Title>

          <S.FormWrapper mb={12}>
            <S.FormInput
              type={!showPassword ? 'password' : 'text'}
              error={!!(formik.touched.password && formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              placeholder={t('reset_password.password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps('password')}
            />
          </S.FormWrapper>

          <S.FormWrapper mb={24}>
            <S.FormInput
              type={!showPassword ? 'password' : 'text'}
              error={
                !!(
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                )
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              placeholder={t('reset_password.confirm_password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps('confirmPassword')}
            />
          </S.FormWrapper>

          <Box mt={16}>
            <Button
              size="large"
              color="primary"
              type="submit"
              loading={loading}
            >
              {t('reset_password.send_password')}
            </Button>
          </Box>
        </S.Card>
      </form>

      <Box display="flex" mt={16}>
        <Typography variant="body1" mr={8}>
          {t('reset_password.question')}
        </Typography>
        <Box component={Link} to={ROUTES.LOGIN} color="primary.main">
          <Typography variant="h4">{t('login.sign_in')}</Typography>
        </Box>
      </Box>
    </S.Container>
  );
};
