// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Grid, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import moment from 'moment';

// Components
import { Button, Dialog, Icon } from '../../Common';
import { FormBuilder } from '../../FormBuilder';

// Store
import {
  getProjectDetail,
  getRecordOperatingCostsForm,
} from '../../../redux/actions';

// Utils
import {
  buildFormInitialData,
  buildFormSchema,
  getFormikTouchedValue,
  getPeriodInDays,
} from '../../../utils';

// Interfaces
import { RootState } from '../../../redux/store';
import { IForm } from '../../../interfaces';
import { Stepper } from '../../Common/Stepper';
import {
  addAdditionalCost,
  updateAdditionalCost,
} from '../../../services/project.service';
import { useSnackbar } from 'notistack';

interface IRecordOperatingCostsModal {
  projectId?: number;
  open: boolean;
  operatingCosts: any;
  onClose: () => void;
}

// Export record operating costs modal
export const RecordOperatingCostsModal: FC<IRecordOperatingCostsModal> = ({
  projectId,
  open,
  onClose,
  operatingCosts,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get snackbar from hook
  const { enqueueSnackbar } = useSnackbar();

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get theme
  const theme = useTheme();

  // Get project detail
  const projectDetail = useSelector(
    ({ projectReducer: { projectDetail } }: RootState) => projectDetail
  );

  // Get record operating costs form from store
  const recordOperatingCostsForm = useSelector(
    ({ formReducer: { recordOperatingCostsForm } }: RootState) =>
      recordOperatingCostsForm
  );

  const [formikTouched, setFormikTouched] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [valueCache, setValueCache] = useState<any>({});

  // Create formik
  const formik = useFormik<any>({
    initialValues: buildFormInitialData(recordOperatingCostsForm),
    validationSchema: buildFormSchema(recordOperatingCostsForm),
    onSubmit: async (values) => {
      const dateValueKey = Object.keys(values)[0];
      const elemKey = Object.keys(values)[1];
      const dateValues = Object.keys(values[dateValueKey]).reduce(
        (value, key) => ({
          ...value,
          [key]: moment(values[dateValueKey][key]).format('YYYY-MM-DD'),
        }),
        {}
      );

      setLoading(true);
      const updateData = {
        [dateValueKey]: dateValues,
        [elemKey]: values[elemKey],
      };
      let additionalCostsPromise;
      if (operatingCosts[dateValueKey].id) {
        additionalCostsPromise = updateAdditionalCost(
          projectId,
          operatingCosts[dateValueKey].id,
          updateData
        );
      } else {
        additionalCostsPromise = addAdditionalCost(projectId, updateData);
      }
      additionalCostsPromise
        .then((res) => {
          if (res?.data?.success) {
            enqueueSnackbar(res.data.message, { variant: 'success' });
            dispatch(getProjectDetail(projectId as number));
            onClose();
          } else {
            enqueueSnackbar(res.data.message, { variant: 'error' });
          }
          setLoading(false);
        })
        .catch((e) => {
          enqueueSnackbar(
            e?.response?.data?.message || 'Failed to submit additional cost',
            { variant: 'error' }
          );
          setLoading(false);
        });
    },
  });

  const [steps, setSteps] = useState<string[]>([]);
  const [step, setStep] = useState(0);
  const [preStep, setPreStep] = useState(-1);

  useEffect(() => {
    if (steps.length === 0) {
      let initialSteps: string[] = [];
      Object.entries(recordOperatingCostsForm).forEach((field: any) => {
        if (field[1].type === 'array' && field[1].allowAdd) {
          initialSteps.push(`${field[0]}[0]`);
        } else {
          initialSteps.push(field[0]);
        }
      });

      setSteps(initialSteps);
    }
  }, [recordOperatingCostsForm, steps]);

  const currentStepContent: IForm = useMemo(() => {
    const initStepValues: IForm[] = Object.values(recordOperatingCostsForm);
    const currentStepNumber = preStep > -1 ? preStep : step;

    return currentStepNumber === 0
      ? initStepValues[currentStepNumber]
      : initStepValues[1];
  }, [preStep, step, recordOperatingCostsForm]);

  const currentStepKey: string = useMemo(
    () => steps[preStep > -1 ? preStep : step],
    [preStep, step, steps]
  );

  const isVisibleRemoveStepButton = useMemo(() => {
    if (steps && steps.length > 2) {
      return true;
    } else {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, step, preStep]);

  // Next handler
  const handleNext = async () => {
    if (step === steps.length - 1) {
      formik.handleSubmit();
      return;
    } else {
      const key = currentStepKey.replace(/\[(\d+)\]/, '');
      await formik.setTouched({ [key]: formikTouched[key] }, true);
      setPreStep(step);
      setStep(step + 1);
    }
  };

  // Back handler
  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  // Add step handler
  const handleAddStep = () => {
    setSteps([...steps, `${steps[1].replace('[0]', '')}[${steps.length - 1}]`]);
    setStep(steps.length);

    let formikValues: any = {};
    Object.keys(formik.values).forEach((key) => {
      if (Array.isArray(formik?.values[key])) {
        formikValues[key] = [
          ...formik.values[key],
          Object.keys(recordOperatingCostsForm[key]?.fields)?.reduce(
            (field, itemKey) => ({ ...field, [itemKey]: '' }),
            {}
          ),
        ];
      } else {
        formikValues[key] = formik.values[key];
      }
    });

    formik.setValues(formikValues);
  };

  // Remove step handler
  const handleRemoveStep = () => {
    if (steps.length > 2) {
      let elemValues: any = Object.values(formik.values)[1];
      const elemKey = Object.keys(formik.values)[1];
      elemValues = elemValues.filter((_, index) => index !== step - 1);

      formik.setValues({
        ...formik.values,
        [elemKey]: elemValues,
      });

      setSteps(steps.slice(0, steps.length - 1));
      setStep(step - 1);
    }
  };

  // Handle submit
  const handleSubmit = () => {
    formik.handleSubmit();
  };

  // Close handler
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setStep(0);
    onClose();
  };

  // Change step
  const handleChangeStep = async (stepIndex: number) => {
    setStep(stepIndex);
  };

  // Change field
  const handleFieldChange = async (path, field, value) => {
    switch (field) {
      case 'usage_period_end':
        const start = formik.getFieldProps(`${path}.usage_period_start`).value;
        const end = formik.getFieldProps(`${path}.usage_period_end`).value;
        const period = getPeriodInDays(start, end);
        handleSetValueCache('amount_used', '1', period); // 1 - Tage
        break;

      case 'additional_costs_unit':
        const totalAmountValue = getValueFromCache('amount_total', value);
        const amountUsedValue = getValueFromCache('amount_used', value);

        if (totalAmountValue) {
          handleUpdateFieldValue(path, 'amount_total', totalAmountValue);
        }

        if (amountUsedValue) {
          handleUpdateFieldValue(path, 'amount_used', amountUsedValue);
        }
        break;

      case 'amount_total':
        handleSetValueCache(
          field,
          formik.getFieldProps(`${path}.additional_costs_unit`).value,
          value
        );
        break;

      case 'amount_used':
        handleSetValueCache(
          field,
          formik.getFieldProps(`${path}.additional_costs_unit`).value,
          value
        );
        break;

      default:
        break;
    }
  };

  const handleUpdateFieldValue = (path, field, value) => {
    formik.setFieldValue(`${path}.${field}`, value);
  };

  const handleSetValueCache = (
    fieldName: string,
    keyValue: string,
    value: string,
    field?: any
  ) => {
    const key = fieldName + '-' + keyValue;

    if (field) {
      return {
        ...field,
        [key]: value,
      };
    } else {
      setValueCache({
        ...valueCache,
        [key]: value,
      });

      return { [key]: value };
    }
  };

  const getValueFromCache = (fieldName: string, keyValue: string) => {
    const key = fieldName + '-' + keyValue;

    if (valueCache[key]) return valueCache[key];
    return null;
  };

  // On record operating costs changed
  useEffect(() => {
    (async () => {
      await dispatch(getRecordOperatingCostsForm());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (recordOperatingCostsForm) {
      const initData = buildFormInitialData(recordOperatingCostsForm);

      if (!Object.values(initData) || Object.values(initData).length === 0) {
        return;
      }

      const dateField = getFormikTouchedValue(Object.values(initData)[0]);
      const dateKey = Object.keys(initData)[0];
      // @ts-ignore
      const elemField = getFormikTouchedValue(Object.values(initData)[1][0]);
      const elemKey = Object.keys(initData)[1];

      setFormikTouched({
        [dateKey]: dateField,
        [elemKey]: [elemField],
      });

      setFormikTouched({
        [dateKey]: dateField,
        [elemKey]: steps.slice(1).map((_, index) => {
          const currentStep: number = +(preStep > -1 ? preStep : step);
          if (+currentStep === index) {
            return Object.keys(elemField).reduce((field, key) => {
              return { ...field, [key]: false };
            }, {});
          }
          return elemField;
        }),
      });
    }
  }, [steps, step, preStep, recordOperatingCostsForm]);

  useEffect(() => {
    if (step > preStep && preStep > -1) {
      const previousKey = Object.keys(recordOperatingCostsForm)[
        preStep >= 1 ? 1 : 0
      ];
      if (formik.errors[previousKey]) {
        if (preStep >= 1) {
          // @ts-ignore
          if (formik?.errors[previousKey][preStep - 1]) {
            setStep(preStep);
          }
        } else {
          setStep(preStep);
        }
      }
      setPreStep(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preStep, formik.errors, step]);

  // On operating costs changed
  useEffect(() => {
    if (operatingCosts && Object.keys(operatingCosts).length > 0) {
      const formKeys: string[] = Object.keys(operatingCosts);
      const formValues: any = Object.values(operatingCosts)[1];
      let formSteps: string[] = [];

      formSteps[0] = formKeys[0];

      if (formValues && formValues.length > 0) {
        formValues.forEach((_, index) => {
          formSteps.push(`${formKeys[1]}[${index}]`);
        });
      } else if (formKeys[1]) {
        formSteps.push(`${formKeys[1]}[0]`);
      } else {
        formSteps.push(`${Object.keys(formik.values)[1]}[0]`);
      }
      setSteps(formSteps);

      if (formKeys.length === 1) {
        formik.setValues({
          ...formik.values,
          [formKeys[0]]: operatingCosts[formKeys[0]],
        });
      } else {
        formik.setValues(
          Object.keys(operatingCosts).reduce((formikValue, itemKey) => {
            if (Array.isArray(operatingCosts[itemKey])) {
              return {
                ...formikValue,
                [itemKey]: operatingCosts[itemKey].map((item) => {
                  const { id, total_calc_cost, ...rest } = item;
                  return rest;
                }),
              };
            } else {
              return { ...formikValue, [itemKey]: operatingCosts[itemKey] };
            }
          }, {})
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatingCosts]);

  // Set default values to cache
  useEffect(() => {
    let defaultCache = {};
    if (projectDetail) {
      defaultCache = handleSetValueCache(
        'amount_total',
        '3',
        '1000',
        defaultCache
      ); // 3 - Gradtagszahlen
      defaultCache = handleSetValueCache('amount_used', '6', '1', defaultCache); // 6 - Anzahl Wohneinheit
      if (projectDetail?.property?.property_type === 'condominium') {
        // appartment
        defaultCache = handleSetValueCache(
          'amount_used',
          '2',
          String(projectDetail?.property?.living_space),
          defaultCache
        ); // 2 - Flaeche
        defaultCache = handleSetValueCache(
          'amount_used',
          '3',
          '100',
          defaultCache
        ); // 3 - Gradtagszahlen
      } else {
        // houses
        defaultCache = handleSetValueCache(
          'amount_total',
          '2',
          String(projectDetail?.property?.living_space),
          defaultCache
        ); // 2 - Flaeche
        defaultCache = handleSetValueCache(
          'amount_used',
          '2',
          String(projectDetail?.property?.living_space),
          defaultCache
        ); // 2 - Flaeche
        defaultCache = handleSetValueCache(
          'amount_used',
          '3',
          '1000',
          defaultCache
        ); // 3 - Gradtagszahlen
      }
    }

    if (
      operatingCosts.additionalCosts.calc_period_start &&
      operatingCosts.additionalCosts.calc_period_end
    ) {
      const daysCalcPeriod = getPeriodInDays(
        operatingCosts.additionalCosts.calc_period_start,
        operatingCosts.additionalCosts.calc_period_end
      );
      defaultCache = handleSetValueCache(
        'amount_total',
        '1',
        daysCalcPeriod.toString(),
        defaultCache
      ); // 1 - Tage
    }

    if (
      operatingCosts.additionalCosts.usage_period_start &&
      operatingCosts.additionalCosts.usage_period_end
    ) {
      const daysUsagePeriod = getPeriodInDays(
        operatingCosts.additionalCosts.usage_period_start,
        operatingCosts.additionalCosts.usage_period_end
      );
      defaultCache = handleSetValueCache(
        'amount_used',
        '1',
        daysUsagePeriod.toString(),
        defaultCache
      ); // 1 - Tage
    }

    setValueCache({ ...valueCache, ...defaultCache });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail, operatingCosts]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t('record_operating_costs.title')}
      actions={
        <>
          <Button size="large" disabled={step === 0} onClick={handleBack}>
            {t('record_operating_costs.back')}
          </Button>
          <Button
            size="large"
            color="primary"
            disabled={step === steps.length - 1}
            onClick={handleNext}
          >
            {t('record_operating_costs.next')}
          </Button>
          {step > 0 && (
            <Button
              size="large"
              color="primary"
              onClick={handleSubmit}
              loading={loading}
              disabled={step < steps.length - 1}
            >
              {t('record_operating_costs.save')}
            </Button>
          )}
        </>
      }
      headerChild={
        <Stepper
          step={preStep > -1 ? preStep : step}
          onChangeStep={handleChangeStep}
          length={steps.length}
        />
      }
    >
      <Grid container columns={2} spacing={16}>
        <FormBuilder
          type={currentStepContent?.type}
          attributes={currentStepContent?.attributes}
          label={currentStepContent?.label}
          fields={currentStepContent?.fields}
          formik={formik}
          path={currentStepKey}
          layout={{ date: 2, text: 2, float: 2, integer: 2, select: 2 }}
          onFieldChange={handleFieldChange}
        />
        {currentStepContent?.allowAdd && (
          <Grid item xs={2} md={2}>
            <Stack direction="row" spacing={20}>
              <Button
                fullWidth
                startIcon={<Icon name="plus-lg" />}
                sx={(theme) => ({ color: theme.palette['green'] })}
                onClick={handleAddStep}
              >
                {currentStepContent?.arrayHandlerButtonName} hinzufügen
              </Button>
              {isVisibleRemoveStepButton && (
                <Button
                  fullWidth
                  startIcon={<Icon name="x-lg" color={theme.palette['red']} />}
                  sx={(theme) => ({ color: theme.palette['red'] })}
                  onClick={handleRemoveStep}
                >
                  {currentStepContent?.arrayHandlerButtonName} entfernen
                </Button>
              )}
            </Stack>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};
