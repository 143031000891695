// Dependencies
import { Box } from '@mui/material';
import styled from 'styled-components';

// Export styled component
export const Cards = styled(Box)`
  position: relative;

  .MuiGrid-item {
    ${(props) => props.theme.breakpoints.down('sm')} {
      z-index: 1;
    }
  }
`;
